import { create } from "zustand";

type StoreState = {
  isLoading: boolean;
  loadingText?: string;
};

type StoreActions = {
  reset: () => void;
};

const defaultState: StoreState = {
  isLoading: false,
  loadingText: undefined,
};

const useLoadingScreenStore = create<StoreActions & StoreState>((set) => ({
  ...defaultState,
  reset: () => set(defaultState),
}));

export default useLoadingScreenStore;
