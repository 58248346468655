import DateType from "src/shared/types/DateType";
import { Maybe } from "src/shared/types/maybe/Maybe";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import dayjs from "src/shared/utils/dates/dayjs";

export default function formatDate(
  date: Maybe<DateType>,
  template = "MM/DD/YYYY",
): MaybeNull<string> {
  const dayjsObject = dayjs.isDayjs(date) ? date : dayjs(date);
  return date == null ? null : dayjsObject.format(template);
}
