import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useTagsQuery() {
  const { brandId, enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getTags.useQuery(
    {
      brandId,
    },
    {
      enabled,
    },
  );
}
