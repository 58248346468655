import BREAKPOINTS from "src/frontend/constants/Breakpoints";
import { useWindowSize } from "usehooks-ts";

export type BreakpointState = {
  isDoubleExtraLarge: boolean;
  isExtraLarge: boolean;
  isLarge: boolean;
  isMedium: boolean;
  isSmall: boolean;
};

export default function useBreakpoints(): BreakpointState {
  const { width } = useWindowSize();
  return {
    isDoubleExtraLarge: width < BREAKPOINTS.xxl,
    isExtraLarge: width < BREAKPOINTS.xl,
    isLarge: width < BREAKPOINTS.lg,
    isMedium: width < BREAKPOINTS.md,
    isSmall: width < BREAKPOINTS.sm,
  };
}
