import { GetCompetitorIntelligenceQueryInput } from "src/shared/trpc/queries/getCompetitorIntelligenceQuerySchema";
import { z } from "zod";

export const DownloadCompetitorIntelligenceCsvPayload =
  GetCompetitorIntelligenceQueryInput;

export type DownloadCompetitorIntelligenceCsvPayloadType = z.infer<
  typeof DownloadCompetitorIntelligenceCsvPayload
>;

export const DownloadCompetitorIntelligenceCsvResponse = z.object({
  csv: z.string(),
});

export type DownloadCompetitorIntelligenceCsvResponseType = z.infer<
  typeof DownloadCompetitorIntelligenceCsvResponse
>;
