import { useEffect, useState } from "react";
import {
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router";
import { createBrowserRouter } from "react-router-dom";
import RouteErrorBoundary from "src/frontend/components/RouteErrorBoundary";
import MockCompetitorPriceLinkPage from "src/frontend/components/admin/MockCompetitorPriceLinkPage";
import SwaggerPublicApiDocs from "src/frontend/components/misc/SwaggerPublicApiDocs";
import TestSentryRoute from "src/frontend/components/misc/TestSentryRoute";
import TestComponents from "src/frontend/components/test-components/TestComponents";
import Toaster from "src/frontend/components/ui/Toaster";
import useCanAccessFeature from "src/frontend/hooks/useCanAccessFeature";
import useFlags from "src/frontend/hooks/useFlags";
import useFlagsContext from "src/frontend/hooks/useFlagsContext";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import Root from "src/frontend/pages/Root";
import Login from "src/frontend/pages/auth/Login";
import LoginRoot from "src/frontend/pages/auth/LoginRoot";
import ProductCatalog from "src/frontend/pages/catalog/ProductCatalog";
import GoalsAndSettingsTabs from "src/frontend/pages/company/GoalsAndSettingsTabs";
import PriceSettings from "src/frontend/pages/company/price-settings/PriceSettings";
import CreateProductRelationship from "src/frontend/pages/company/relationships/CreateProductRelationship";
import CreateUpdatePriceParityGroup from "src/frontend/pages/company/relationships/CreateUpdatePriceParityGroup";
import ProductRelationships from "src/frontend/pages/company/relationships/ProductRelationships";
import CreateUpdatePricingRulesGroup from "src/frontend/pages/company/rules/CreateUpdatePricingRulesGroup";
import StrategiesSummary from "src/frontend/pages/company/strategies/StrategiesSummary";
import CreateUpdatePricingStrategy from "src/frontend/pages/company/strategy/CreateUpdatePricingStrategy";
import CompetitorIntelligence from "src/frontend/pages/competitor-intelligence/CompetitorIntelligence";
import HomePage from "src/frontend/pages/home/HomePage";
import PricePlan from "src/frontend/pages/pricing/PricePlan";
import PricePlanSummary from "src/frontend/pages/pricing/PricePlanSummary";
import PricePlans from "src/frontend/pages/pricing/PricePlans";
import Pricing from "src/frontend/pages/pricing/Pricing";
import PricePlanGroupDetail from "src/frontend/pages/pricing/details/PricePlanGroupDetail";
import UserSettings from "src/frontend/pages/settings/UserSettings";
import TeamSettings from "src/frontend/pages/team/TeamSettings";
import TeamSettingsTabs from "src/frontend/pages/team/TeamSettingsTabs";
import useAuthStore from "src/frontend/stores/useAuthStore";
import useBrandStore from "src/frontend/stores/useBrandStore";
import { PricePlanStatusType } from "src/shared/trpc/queries/getPricePlansQuerySchema";

function useRouter(userExists: boolean) {
  const { devFeaturesEnabled } = useFlags();
  const isLucaAdmin = useIsLucaAdmin();
  const featureBlocklist = useBrandStore(
    (state) => state.user_feature_blocklist,
  );
  const canAccessCompetitorIntelligence = useCanAccessFeature(
    "COMPETITOR_INTELLIGENCE",
  );
  const canAccessBasePricing = !featureBlocklist.includes("BASE_PRICING");

  if (typeof window === "undefined") {
    return null;
  }

  const pricePlanDraftsStatus: PricePlanStatusType[] = isLucaAdmin
    ? ["DRAFT", "INTERNAL_DRAFT", "PREVIEW"]
    : ["DRAFT"];

  if (!userExists) {
    return createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route
            element={<LoginRoot />}
            errorElement={<RouteErrorBoundary />}
            path="/"
          >
            <Route element={<Login type="LOGIN" />} index />
            <Route element={<Login type="LOGIN" />} path="login" />
            <Route element={<Login type="REGISTER" />} path="register" />
            <Route
              element={<Login type="FORGOT_PASSWORD" />}
              path="forgot-password"
            />
            <Route element={<Navigate to="/" />} path="*" />
          </Route>
        </>,
      ),
    );
  }

  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          element={<Root />}
          errorElement={<RouteErrorBoundary />}
          path="/"
        >
          {canAccessBasePricing && (
            <>
              <Route element={<Navigate to="home" />} index />
              <Route element={<HomePage />} path="home" />
              <Route element={<Pricing />} path="pricing">
                <Route
                  element={<PricePlans status={pricePlanDraftsStatus} />}
                  path="drafts"
                />
                <Route
                  element={
                    <PricePlans status={["CURRENT", "COMPLETED", "REJECTED"]} />
                  }
                  path="launched"
                />
                <Route element={<PricePlan />} path=":tab">
                  <Route element={<PricePlanSummary />} path=":pricePlanId" />
                  <Route
                    element={<PricePlanGroupDetail />}
                    path=":pricePlanId/:groupId"
                  />
                </Route>
              </Route>
              <Route element={<ProductCatalog />} path="product-catalog" />
            </>
          )}
          {canAccessCompetitorIntelligence && (
            <Route
              element={<CompetitorIntelligence />}
              path="competitor-intelligence"
            />
          )}
          {canAccessBasePricing && (
            <>
              <Route
                element={<CreateUpdatePricingStrategy />}
                path="company-settings/strategy/create"
              />
              <Route
                element={<CreateUpdatePricingStrategy />}
                path="company-settings/strategy/:pricingStrategyId"
              />
              <Route
                element={<CreateUpdatePricingRulesGroup />}
                path="company-settings/strategy/rules/create/:pricingStrategyId"
              />
              <Route
                element={<CreateUpdatePricingRulesGroup />}
                path="company-settings/strategy/rules/:pricingStrategyId/:pricingRulesGroupId"
              />
              <Route
                element={<CreateProductRelationship />}
                path="company-settings/relationships/create"
              />
              <Route
                element={<CreateUpdatePriceParityGroup />}
                path="company-settings/relationships/price-parity/create"
              />
              <Route
                element={<CreateUpdatePriceParityGroup />}
                path="company-settings/relationships/price-parity/:priceParityGroupId"
              />
              <Route element={<GoalsAndSettingsTabs />} path="company-settings">
                <Route element={<StrategiesSummary />} path="strategy" />
                <Route
                  element={<ProductRelationships />}
                  path="relationships"
                />
                <Route element={<PriceSettings />} path="price-settings" />
              </Route>
            </>
          )}
          <Route element={<TeamSettingsTabs />} path="team">
            <Route element={<TeamSettings />} path="settings" />
          </Route>
          <Route element={<UserSettings />} path="user/settings" />
          {isLucaAdmin && canAccessCompetitorIntelligence && (
            <Route
              element={<MockCompetitorPriceLinkPage />}
              path="competitor-pricing-link"
            />
          )}
          {devFeaturesEnabled && (
            <Route element={<TestComponents />} path="dev/components" />
          )}
        </Route>
        <Route element={<SwaggerPublicApiDocs />} path="docs" />
        <Route element={<TestSentryRoute />} path="test-error" />
        <Route element={<Navigate to="/" />} path="*" />
      </>,
    ),
  );
}

export default function Router() {
  const [isClient, setIsClient] = useState(false);
  const user = useAuthStore((state) => state.user);
  const ldInitialized = useFlagsContext(user);
  const waitForFlags = user !== null && !ldInitialized;
  const router = useRouter(user != null);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient || user === undefined || router == null || waitForFlags) {
    return null;
  }

  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
}
