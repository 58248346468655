import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import getColorForProductCategoryFilterType from "src/frontend/utils/getColorForProductCategoryFilterType";

export const SHOW_SKU_ACTIVE = "Active";
export const SHOW_SKU_INACTIVE = "Inactive";

export const ACTIVE_SKUS_OPTION = {
  id: SHOW_SKU_ACTIVE,
  label: "Active SKUs",
  value: SHOW_SKU_ACTIVE,
};

export const SHOW_SKUS_OPTIONS: FancyBoxItem[] = [
  ACTIVE_SKUS_OPTION,
  {
    id: SHOW_SKU_INACTIVE,
    label: "Inactive SKUs",
    value: SHOW_SKU_INACTIVE,
  },
];

export const INCLUDE_EMPTY_SUPPLIER_ITEMS = {
  color: getColorForProductCategoryFilterType(
    ProductCategoryFilterType.Supplier,
  ),
  id: "include-empty-suppliers",
  label: "Include Empty Suppliers",
  type: ProductCategoryFilterType.Supplier,
  value: "Include Empty Suppliers",
};

export const INCLUDE_UNTAGGED_PRODUCTS_ITEM = {
  color: getColorForProductCategoryFilterType(ProductCategoryFilterType.Tag),
  id: "include-untagged-products",
  label: "Include Untagged Products",
  type: ProductCategoryFilterType.Tag,
  value: "Include Untagged Products",
};

export const INCLUDE_EMPTY_CATEGORY_ITEMS = {
  color: getColorForProductCategoryFilterType(
    ProductCategoryFilterType.Category,
  ),
  id: "include-empty-category-products",
  label: "Include Empty Categories",
  type: ProductCategoryFilterType.Category,
  value: "Include Empty Categories",
};

export const INCLUDE_EMPTY_SUB_CATEGORY_ITEMS = {
  color: getColorForProductCategoryFilterType(
    ProductCategoryFilterType.Subcategory,
  ),
  id: "include-empty-sub-category-products",
  label: "Include Empty Sub Categories",
  type: ProductCategoryFilterType.Subcategory,
  value: "Include Empty Sub Categories",
};

export const INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS = {
  color: getColorForProductCategoryFilterType(
    ProductCategoryFilterType.ProductBrandName,
  ),
  id: "include-empty-product-brand-names",
  label: "Include Empty Product Brand Names",
  type: ProductCategoryFilterType.ProductBrandName,
  value: "Include Empty Product Brand Names",
};

export const INCLUDE_EMPTY_PRICE_ZONE_ITEMS = {
  color: getColorForProductCategoryFilterType(
    ProductCategoryFilterType.PriceZone,
  ),
  id: "include-empty-price-zones",
  label: "Include Empty Price Zones",
  type: ProductCategoryFilterType.PriceZone,
  value: "Include Empty Product Brand Names",
};

export const EXCLUSION_FILTER_IDS = [
  INCLUDE_UNTAGGED_PRODUCTS_ITEM,
  INCLUDE_EMPTY_CATEGORY_ITEMS,
  INCLUDE_EMPTY_SUB_CATEGORY_ITEMS,
  INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS,
  INCLUDE_EMPTY_PRICE_ZONE_ITEMS,
].map((val) => val.id);
