import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetPricePlanSummaryQuery() {
  const { enabled } = useIsBrandInitialized();
  const { pricePlanId } = useParams();
  return TrpcClient.internal.getPricePlanSummary.useQuery(
    {
      pricePlanId: pricePlanId!,
    },
    { enabled: enabled && pricePlanId != null },
  );
}
