export type TailwindColors = {
  BORDER: string;
  CARD: string;
  CHART_AXIS: string;
  MUTED: string;
  SECONDARY: string;
  TEXT: string;
};

export type TailwindColorKey = keyof TailwindColors;

export type TailwindColorConfig = {
  DARK: TailwindColors;
  LIGHT: TailwindColors;
};

/**
 * Keep in sync with globals.css.
 *
 * I couldn't find another way to dynamically read these values directly from
 * the Tailwind defined CSS variables. It might be nice to write a tool which
 * will generate this object based on the global.css styles.
 */
const TAILWIND_COLORS: TailwindColorConfig = {
  DARK: {
    BORDER: "rgb(70, 75, 80)",
    CARD: "rgb(46, 48, 53)",
    CHART_AXIS: "rgb(173, 177, 184)",
    MUTED: "rgb(173, 177, 184)",
    SECONDARY: "rgb(255, 111, 105)",
    TEXT: "rgb(248, 250, 252)",
  },
  LIGHT: {
    BORDER: "rgb(226, 232, 240)",
    CARD: "rgb(255, 255, 255)",
    CHART_AXIS: "rgb(70, 75, 80)",
    MUTED: "rgb(105, 110, 119)",
    SECONDARY: "rgb(255, 111, 105)",
    TEXT: "rgb(2, 8, 23)",
  },
};

export default TAILWIND_COLORS;
