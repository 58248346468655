import { Location } from "react-router-dom";
import TeamSettingsTab from "src/shared/types/TeamSettingsTab";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import capitalize from "src/shared/utils/strings/capitalize";

export default function getTeamSettingsTabFromLocation(
  location: Location,
): TeamSettingsTab {
  const path = location.pathname;
  if (path.startsWith("/team/")) {
    const tab = path
      .split("/")[2]
      .split("-")
      .map(capitalize)
      .join(" ") as TeamSettingsTab;
    switch (tab) {
      case "Settings":
        return tab;
      default:
        return assertUnreachable(tab);
    }
  }

  return "Settings";
}
