import React, { useState } from "react";
import SortableContainer from "src/frontend/components/sortable/SortableContainer";
import { SortableItem } from "src/frontend/components/sortable/SortableItem";
import Button from "src/frontend/components/ui/Button";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useFlags from "src/frontend/hooks/useFlags";
import stringToColor from "src/frontend/utils/stringToColor";
import WithPriorityField from "src/shared/types/WithPriorityField";
import randomId from "src/shared/utils/random/randomId";

type CustomSortableItem = WithPriorityField & {
  dataField: string;
  id: string;
};

type TestSortableContainerProps = {
  hide?: boolean;
  useGrid?: boolean;
};

const LETTERS = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

export default function TestSortableContainer({
  hide = false,
  useGrid = false,
}: TestSortableContainerProps) {
  const { successToast } = useToast();
  const { devFeaturesEnabled } = useFlags();
  const [items, setItems] = useState<CustomSortableItem[]>(
    LETTERS.slice(0, useGrid ? 9 : 5).map((letter, index) => ({
      dataField: letter,
      id: randomId(),
      priority: index,
    })),
  );

  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <Button
        className="mb-4"
        onClick={() =>
          successToast(
            `The order is: ${items
              .map((item) => item.dataField.slice(0, 3))
              .join(", ")}!`,
          )
        }
        variant="outline"
      >
        Trigger action
      </Button>
      <SortableContainer
        className={cn("w-[75px]", useGrid && "w-[300px]")}
        items={items}
        setItems={setItems}
        useGrid={useGrid}
      >
        {items.map((item) => {
          return (
            <SortableItem
              className="m-1 flex h-[75px] w-[75px] items-center justify-center border text-base text-white"
              id={item.id}
              key={item.id}
              style={{
                background: stringToColor(String(item.id).repeat(10))
                  .backgroundColor,
              }}
            >
              <div>
                <p>{item.dataField.slice(0, 3)}</p>
              </div>
            </SortableItem>
          );
        })}
      </SortableContainer>
    </div>
  );
}
