enum ProductCategoryFilterType {
  AllSkus = "All SKUs",
  Category = "Category",
  CustomSkusSelection = "Custom SKUs",
  PriceZone = "Price Zone",
  ProductBrandName = "Brand Name",
  Subcategory = "Subcategory",
  Supplier = "Supplier",
  Tag = "Tag",
}

export default ProductCategoryFilterType;
