import { TrashIcon } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Button from "src/frontend/components/ui/Button";
import { Modal } from "src/frontend/components/ui/Modal";
import { Textarea } from "src/frontend/components/ui/Textarea";
import useToast from "src/frontend/components/ui/useToast";
import useGetExperimentsForecastQuery from "src/frontend/hooks/queries/useGetExperimentsForecastQuery";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";

type DeclineGroupModalProps = {
  experiment_ids: Set<string>;
  isAllExperimentsSelected: boolean;
  resetSelectedExperiments: () => void;
};

export default function DeclineGroupModal({
  experiment_ids,
  isAllExperimentsSelected,
  resetSelectedExperiments,
}: DeclineGroupModalProps) {
  const isBrandAdmin = useIsBrandAdmin();
  const { groupId, pricePlanId } = useParams();
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errorToast, successToast } = useToast();
  const experimentsOpportunityQuery = useGetExperimentsForecastQuery();
  const declineGroupMutation = TrpcClient.internal.declineGroup.useMutation();
  if (pricePlanId == null) {
    return null;
  }

  const onSuccess = async () => {
    await experimentsOpportunityQuery.refetch();
    setIsModalOpen(false);
    setIsLoading(false);
    resetSelectedExperiments();
    successToast({
      description: "Price changes declined successfully.",
    });
  };

  const disabled =
    !(isAllExperimentsSelected || experiment_ids.size) || !isBrandAdmin;

  return (
    <Modal
      confirmText="Yes, decline these"
      dialogDescription={
        <>
          <p>
            Are you sure you want to decline the price changes for{" "}
            {isAllExperimentsSelected ? "all" : "the"} selected SKUs?
          </p>
          <Textarea
            className="mt-4"
            onChange={(e) => setReason(e.target.value)}
            placeholder="(Optional) Please share why you are declining these changes."
            value={reason}
          />
        </>
      }
      dialogTitle="Decline Price Groups"
      loading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        declineGroupMutation.mutate(
          {
            experimentIds: Array.from(experiment_ids),
            groupId,
            isAllExperimentsSelected: isAllExperimentsSelected,
            pricePlanId,
            reason,
          },
          {
            onError: () => {
              setIsLoading(false);
              errorToast({ description: "Failed to decline groups." });
            },
            onSuccess: () => {
              void onSuccess();
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button disabled={disabled} size="sm" variant="outline">
          <TrashIcon className="mr-1" size={16} /> Decline{" "}
          {experiment_ids.size === 0 ? "prices" : "selected prices"}
        </Button>
      }
    />
  );
}
