import useBrandStore from "src/frontend/stores/useBrandStore";
import { ProductCatalogVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import mappedColumnVisibility from "./mappedColumnVisibility";

export const memberColumns = new Set<keyof ProductCatalogVisibilityFiltersType>(
  ["member_price", "member_price_margin", "member_price_contribution_margin"],
);

export const discountColumns = new Set<
  keyof ProductCatalogVisibilityFiltersType
>([
  "discounted_price",
  "discounted_price_margin",
  "discounted_price_contribution_margin",
]);

export const subscriberColumns = new Set<
  keyof ProductCatalogVisibilityFiltersType
>([
  "subscriber_price",
  "subscriber_price_margin",
  "subscriber_price_contribution_margin",
]);

export default function useProductCatalogColumnVisibility(
  visibilityFilters: ProductCatalogVisibilityFiltersType,
) {
  const brand = useBrandStore();
  const {
    discounted_price_enabled,
    member_price_enabled,
    subscriber_price_enabled,
  } = brand;

  const filters = visibilityFilters;

  if (!member_price_enabled) {
    memberColumns.forEach((key) => (filters[key] = false));
  }
  if (!discounted_price_enabled) {
    discountColumns.forEach((key) => (filters[key] = false));
  }
  if (!subscriber_price_enabled) {
    subscriberColumns.forEach((key) => (filters[key] = false));
  }

  return mappedColumnVisibility(filters);
}
