import formatCurrency from "src/shared/utils/numbers/formatCurrency";

export default function formatPriceRange(
  min: number | null,
  max: number | null,
): string {
  if (min == null && max == null) {
    return "--";
  }

  if (min == null) {
    return `≤ ${formatCurrency(max)}`;
  }

  if (max == null) {
    return `≥ ${formatCurrency(min)}`;
  }

  return `${formatCurrency(min)} - ${formatCurrency(max)}`;
}
