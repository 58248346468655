import { useEffect } from "react";
import { useRouteError } from "react-router";
import ErrorFallbackComponent from "src/frontend/components/error/ErrorFallbackComponent";
import logClientError from "src/frontend/utils/logClientError";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

// See https://reactrouter.com/en/main/route/error-element
export default function RouteErrorBoundary() {
  const error: any = useRouteError();

  useEffect(() => {
    if (error != null) {
      logClientError(
        new Error(error ?? "RouteErrorBoundary hit"),
        SentryErrorEvent.RouteErrorBoundaryHit,
      );
    }
  }, [error]);

  return <ErrorFallbackComponent />;
}
