import { AlertCircle, AlertTriangle, CheckCircle, Info } from "lucide-react";
import React, { ReactElement } from "react";
import { usePlainToast } from "src/frontend/components/ui/usePlainToast";
import isJSXElement from "src/frontend/utils/isJSXElement";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function ErrorTitle({ title }: { title: string }): ReactElement {
  return (
    <div className="flex flex-row items-center">
      <AlertCircle className="mr-2 text-destructive" size={20} />{" "}
      <span className="text-destructive">{title}</span>
    </div>
  );
}

function SuccessTitle({ title }: { title: string }): ReactElement {
  return (
    <div className="flex flex-row items-center">
      <CheckCircle className="mr-2 text-green-500" size={20} /> {title}
    </div>
  );
}

function WarningTitle({ title }: { title: string }): ReactElement {
  return (
    <div className="flex flex-row items-center">
      <AlertTriangle className="mr-2 text-warning" size={20} />{" "}
      <span className="text-warning">{title}</span>
    </div>
  );
}

function InfoTitle({ title }: { title: string }): ReactElement {
  return (
    <div className="flex flex-row items-center">
      <Info className="mr-2" size={20} /> <span>{title}</span>
    </div>
  );
}

type ToastType = "Error" | "Info" | "Success" | "Warning";

function getToastTitle(type: ToastType, title: string): ReactElement {
  switch (type) {
    case "Error":
      return <ErrorTitle title={title} />;
    case "Success":
      return <SuccessTitle title={title} />;
    case "Warning":
      return <WarningTitle title={title} />;
    case "Info":
      return <InfoTitle title={title} />;
    default:
      return assertUnreachable(type);
  }
}

type ToastDescription = JSX.Element | string;

type ToasterProps = {
  description: ToastDescription;
  timeout?: number;
  title: string;
};

type ToastArgs =
  | ToastDescription
  | {
      description: ToastDescription;
      timeout?: number;
      title?: string;
    };

function argsToToastTitleAndDescription(
  props: ToastArgs,
  type: ToastType,
  defaultTitle: string,
): ToasterProps {
  if (typeof props === "string" || isJSXElement(props)) {
    return {
      description: props,
      title: getToastTitle(type, defaultTitle) as any,
    };
  }

  const {
    description,
    timeout = undefined,
    title = defaultTitle,
  } = typeof props === "string" ? { description: props } : props;
  return {
    description,
    timeout,
    title: getToastTitle(type, title) as any,
  };
}

export type ToastFunction = (props: ToastArgs) => void;

type Toaster = {
  dismiss: (toastId?: string | undefined) => void;
  errorToast: ToastFunction;
  infoToast: ToastFunction;
  successToast: ToastFunction;
  warningToast: ToastFunction;
};

export default function useToast(): Toaster {
  const { dismiss, toast } = usePlainToast();

  const errorToast = (props: ToastArgs) => {
    toast(argsToToastTitleAndDescription(props, "Error", "Error"));
  };

  const successToast = (args: ToastArgs) => {
    toast(argsToToastTitleAndDescription(args, "Success", "Success!"));
  };

  const warningToast = (props: ToastArgs) => {
    toast(argsToToastTitleAndDescription(props, "Warning", "Warning"));
  };

  const infoToast = (props: ToastArgs) => {
    toast(argsToToastTitleAndDescription(props, "Info", "Info"));
  };

  return { dismiss, errorToast, infoToast, successToast, warningToast };
}
