import { price_target_type } from "@prisma/client";
import { z } from "zod";

export const PriceTargetTypeEnum = z.nativeEnum(price_target_type);

export type PriceTargetType = z.infer<typeof PriceTargetTypeEnum>;

export const PriceTargetTypeVariants = Object.keys(
  PriceTargetTypeEnum.enum,
) as PriceTargetType[];
