import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { CompetitorPriceLookbackPeriodType } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import getCompetitorRecentPriceFieldKey from "src/shared/utils/getCompetitorRecentPriceFieldKey";
import invariant from "tiny-invariant";

export default function getCompetitorPriceFromRecentPriceType(
  competitorPricing: CompetitorMatchWithAveragePricesType,
  recentPriceOption: CompetitorPriceLookbackPeriodType,
  displayPriceTarget: PriceTargetType,
): MaybeNumber {
  const key = getCompetitorRecentPriceFieldKey(
    recentPriceOption,
    displayPriceTarget,
  );
  invariant(
    key in competitorPricing,
    `Key ${key} must exist in competitorPricing object.`,
  );
  return competitorPricing[key] as MaybeNumber;
}
