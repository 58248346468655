import ConstraintBound from "src/frontend/components/constraints/ConstraintBound";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";

type ConstraintBoundsSummaryProps = {
  constraint: PricingConstraintType;
  hideActualValues?: boolean;
};

export default function ConstraintBoundsSummary({
  constraint,
  hideActualValues,
}: ConstraintBoundsSummaryProps) {
  const priceTarget = formatGenericEnumLabel(
    constraint.price_target_type ?? "",
  );

  switch (constraint.type) {
    case "ROUNDING":
      return <span>Price endings of: {constraint.round_to.join(", ")}</span>;
    case "PRICE_COMPARISON": {
      const LowerBound = (
        <ConstraintBound
          bound={constraint.lower_bound}
          type={constraint.bound_type}
        />
      );

      const UpperBound = (
        <ConstraintBound
          bound={constraint.upper_bound}
          type={constraint.bound_type}
        />
      );

      const comparisonTarget = formatGenericEnumLabel(
        constraint.comparison_price_target ?? "",
      );

      return (
        <span>
          {priceTarget} between {LowerBound} and {UpperBound} of{" "}
          {comparisonTarget}
        </span>
      );
    }
    case "MARGIN":
    case "AVERAGE_MARGIN":
    case "PRICE_CHANGE":
    case "COMPETITOR_PRICE": {
      const showUpperBound = constraint.upper_bound != null;
      const showLowerBound = constraint.lower_bound != null;
      const showUpperAndLowerBounds = showUpperBound && showLowerBound;
      const compareLabel = showUpperAndLowerBounds
        ? "between"
        : showUpperBound
          ? "less than"
          : showLowerBound
            ? "greater than"
            : "";

      const LowerBound = (
        <span>
          {" "}
          <ConstraintBound
            bound={constraint.lower_bound}
            showSign={!constraint.type.includes("MARGIN")}
            type={constraint.bound_type}
          />
          {!hideActualValues && (
            <span>
              {" "}
              = {formatCurrency(constraint.lower_bound_calculated, 2, "n/a")}
            </span>
          )}
        </span>
      );

      const UpperBound = (
        <span>
          <ConstraintBound
            bound={constraint.upper_bound}
            showSign={!constraint.type.includes("MARGIN")}
            type={constraint.bound_type}
          />{" "}
          {!hideActualValues && (
            <span>
              = {formatCurrency(constraint.upper_bound_calculated, 2, "n/a")}
            </span>
          )}
        </span>
      );

      return (
        <span>
          {constraint.type === "AVERAGE_MARGIN" && "Average "}
          {priceTarget} {constraint.type === "AVERAGE_MARGIN" && "Margin "}
          {compareLabel}
          {constraint.lower_bound_calculation_error != null ? (
            <span>{constraint.lower_bound_calculation_error}</span>
          ) : (
            showLowerBound && LowerBound
          )}{" "}
          {showUpperAndLowerBounds && "and"}{" "}
          {constraint.upper_bound_calculation_error != null ? (
            <span>{constraint.upper_bound_calculation_error}</span>
          ) : (
            showUpperBound && UpperBound
          )}
        </span>
      );
    }
    default:
      return assertUnreachable(constraint.type);
  }
}
