import { DotIcon } from "lucide-react";
import Col from "src/frontend/components/Col";

export default function ThreeDotsVertical() {
  return (
    <Col className="flex h-full flex-col justify-center font-semibold">
      <DotIcon className="w-[12px]" height={7} opacity={0.6} strokeWidth={14} />
      <DotIcon className="w-[12px]" height={7} opacity={0.6} strokeWidth={14} />
      <DotIcon className="w-[12px]" height={7} opacity={0.6} strokeWidth={14} />
    </Col>
  );
}
