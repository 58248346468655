import { User } from "@firebase/auth";
import { useTheme } from "next-themes";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PublicApiCompany from "src/backend/constants/PublicApiCompanies";
import useFlags from "src/frontend/hooks/useFlags";
import useAuthStore from "src/frontend/stores/useAuthStore";
import getBearerAuthHeader from "src/frontend/utils/getBearerAuthHeader";
import "swagger-ui-react/swagger-ui.css";

// @ts-ignore
const SwaggerUI = dynamic(() => import("swagger-ui-react"), { ssr: false });

type SwaggerRequest = Record<string, any>;

async function addAuthHeadersToRequest(
  req: SwaggerRequest,
  user: User,
  docsName: string,
): Promise<SwaggerRequest> {
  // Skip adding the authentication header if the request is made from the
  // Swagger UI. We know because the requested url matches the following:
  if (typeof req.url === "string" && req.url.includes(`/api/${docsName}`)) {
    return req;
  }

  const token = await user.getIdToken();
  return {
    ...req,
    headers: {
      ...req.headers,
      Authorization: getBearerAuthHeader(token),
    },
  };
}

export default function SwaggerPublicApiDocs() {
  const navigate = useNavigate();
  const { publicApiDocsEnabled } = useFlags();
  const { user } = useAuthStore.getState();
  const docsName = publicApiDocsEnabled ? PublicApiCompany.Public : null;
  const { setTheme, theme } = useTheme();
  const [originalThemeSetting] = useState(theme === "dark" ? "dark" : "light");

  useEffect(() => {
    if (docsName == null || user == null) {
      navigate("/");
    }
  }, [docsName, user, navigate]);

  useEffect(() => {
    setTheme("light");
  }, [setTheme, originalThemeSetting]);

  useEffect(() => {
    return () => setTheme(originalThemeSetting);
  }, [originalThemeSetting, setTheme]);

  useEffect(() => {
    const beforeUnloadFn = () => {
      setTheme(originalThemeSetting);
    };

    window.addEventListener("beforeunload", beforeUnloadFn);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadFn);
    };
  }, [setTheme, originalThemeSetting]);

  if (docsName == null || user == null) {
    return null;
  }

  return (
    <SwaggerUI
      requestInterceptor={(req) => addAuthHeadersToRequest(req, user, docsName)}
      url={`/api/docs/${docsName}`}
    />
  );
}
