import { PriceParitySummaryGroup } from "src/shared/trpc/common/PriceParitySummaryGroup";
import { ProductMasterObject } from "src/shared/trpc/common/ProductMasterObject";
import isPriceParityGroupValid from "src/shared/validation/isPriceParityGroupValid";
import { z } from "zod";

export const PriceParityGroupCreateInput = z.object({
  is_active: z.boolean(),
  name: z.string(),
  product_ids: z.string().array(),
});

export const PriceParityGroupUpdateInput = z.object({
  id: z.string(),
  is_active: z.boolean(),
  name: z.string(),
  product_ids: z.string().array(),
});

export type PriceParityGroupCreateInputType = z.infer<
  typeof PriceParityGroupCreateInput
>;

export const CreateOrUpdatePriceParityGroupInput = z
  .object({
    create: PriceParityGroupCreateInput.nullable(),
    update: PriceParityGroupUpdateInput.nullable(),
  })
  .refine((input) => {
    if (input.create != null) {
      const valid = isPriceParityGroupValid(input.create);
      if (!valid) {
        return false;
      }
    }

    if (input.update != null) {
      const valid = isPriceParityGroupValid(input.update);
      if (!valid) {
        return false;
      }
    }

    return true;
  }, "Price parity group validation failed.");

export type CreateOrUpdatePriceParityGroupInputType = z.infer<
  typeof CreateOrUpdatePriceParityGroupInput
>;

export const CreateOrUpdatePriceParityGroupResponse =
  PriceParitySummaryGroup.array();

export type CreateOrUpdatePriceParityGroupResponseType = z.infer<
  typeof CreateOrUpdatePriceParityGroupResponse
>;

export const PriceParityGroupDuplicateProductErrorInfo = z.object({
  duplicate_products: ProductMasterObject.array(),
});

export type PriceParityGroupDuplicateProductErrorInfoType = z.infer<
  typeof PriceParityGroupDuplicateProductErrorInfo
>;
