import { Link } from "react-router-dom";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import SummaryImpact from "src/frontend/components/SummaryImpact";
import PricePlanInsightsDialog from "src/frontend/components/dialogs/PricePlanInsightsDialog";
import { Badge } from "src/frontend/components/ui/Badge";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import useCurrentlySelectedPricePlan from "src/frontend/hooks/useCurrentlySelectedPricePlan";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import formatDate from "src/shared/utils/dates/formatDate";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";
import truncateTextMiddle from "src/shared/utils/strings/truncateTextMiddle";

export default function PlanDetailsGrid() {
  const pricePlanMetrics = useGetPricePlanSummaryQuery();
  const isLucaAdmin = useIsLucaAdmin();
  const currentPricePlan = useCurrentlySelectedPricePlan();

  if (pricePlanMetrics.isLoading) {
    return null;
  }

  if (pricePlanMetrics.error) {
    return <p>Error loading price plan.</p>;
  }

  const data = pricePlanMetrics.data;
  if (data?.price_plan == null) {
    return (
      <div className="my-4">
        <p>No price plan found.</p>
      </div>
    );
  }

  const { price_plan } = data;

  return (
    <div className="grid grid-cols-3 grid-rows-2 gap-8 p-4">
      <SummaryImpact
        child={
          <p className="whitespace-nowrap text-xl font-bold">
            {price_plan.published_on == null
              ? "--"
              : formatDate(price_plan.published_on, "MM/DD/YYYY h:mma")}
          </p>
        }
        title="Published On"
      />
      <SummaryImpact
        child={
          <p className="whitespace-nowrap text-xl font-bold">
            {price_plan.start_time == null
              ? "--"
              : formatDate(price_plan.start_time, "MM/DD/YYYY h:mma")}
          </p>
        }
        title="Launched On"
      />
      {currentPricePlan?.pricing_strategy_id != null && (
        <SummaryImpact
          child={
            <Col>
              <Tooltip
                content={
                  <p>{currentPricePlan?.pricing_strategy_name ?? "Link"}</p>
                }
                disabled={currentPricePlan?.pricing_strategy_name == null}
              >
                <p className="w-[150px] truncate text-sm font-bold">
                  {currentPricePlan?.pricing_strategy_name ?? "Link"}
                </p>
              </Tooltip>
              {isLucaAdmin && (
                <Row>
                  <Link
                    target="_blank"
                    to={`/company-settings/strategy/${currentPricePlan.pricing_strategy_id}`}
                  >
                    <Code className="whitespace-nowrap bg-transparent p-0 text-xs underline">
                      {truncateTextMiddle(
                        currentPricePlan?.pricing_strategy_id,
                        6,
                      )}
                    </Code>
                  </Link>
                </Row>
              )}
            </Col>
          }
          title="Pricing Strategy"
        />
      )}
      <SummaryImpact
        impact={formatNumberRounded(data?.impacted_skus ?? null)}
        impactType="number"
        title="Impacted SKUs"
      />
      {isLucaAdmin && (
        <SummaryImpact
          impact={roundToPrecision(price_plan.price_plan_lambda)}
          title="Price Plan Lambda"
        />
      )}
      {price_plan.insights != null && (
        <SummaryImpact
          child={<PricePlanInsightsDialog insights={price_plan.insights} />}
          title="Insights"
        />
      )}
      <SummaryImpact
        child={<Badge>{formatGenericEnumLabel(price_plan.status)}</Badge>}
        title="Status"
      />
    </div>
  );
}
