import React, { useState } from "react";
import { ScorecardImpactType } from "src/backend/prisma-utils/getScorecardImpactForBrand";
import Row from "src/frontend/components/Row";
import SummaryImpact from "src/frontend/components/SummaryImpact";
import CardWithContent from "src/frontend/components/ui/Card";
import { Separator } from "src/frontend/components/ui/Separator";
import { Tabs, TabsTrigger, TabsList } from "src/frontend/components/ui/Tabs";
import useBrandStore, { BrandStore } from "src/frontend/stores/useBrandStore";
import len from "src/shared/utils/arrays/len";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

type ScorecardProps = {
  brand_scorecard: ScorecardImpactType;
  company_scorecard: ScorecardImpactType;
};

const SCORECARD_TABS = ["company", "brand"] as const;

type ScorecardTab = (typeof SCORECARD_TABS)[number];

function renderTabTitle(tab: ScorecardTab, brand: BrandStore): string {
  switch (tab) {
    case "brand":
      return `${brand.brand_name} 30 Day Scorecard`;
    case "company":
      return `${brand.company_name} 30 Day Scorecard`;
    default:
      return assertUnreachable(tab);
  }
}

export default function Scorecard({
  brand_scorecard,
  company_scorecard,
}: ScorecardProps) {
  const brand = useBrandStore();
  const [tab, setTab] = useState<ScorecardTab>("company");
  const data = tab === "company" ? company_scorecard : brand_scorecard;
  const includeBrandScorecard = len(brand.company_brand_ids) > 1;
  const TABS = includeBrandScorecard ? SCORECARD_TABS : [SCORECARD_TABS[0]];
  return (
    <CardWithContent cardContentClassName="p-0" className="flex-grow basis-0">
      <Tabs
        defaultValue={tab}
        onValueChange={(val) => setTab(val as ScorecardTab)}
        value={tab}
      >
        <TabsList className="flex border-b">
          {TABS.map((tab) => {
            return (
              <TabsTrigger
                className="w-[248px] flex-1 rounded-t-lg px-2 sm:px-6"
                key={tab}
                value={tab}
              >
                <p className="whitespace-break-spaces text-xs sm:text-base">
                  {renderTabTitle(tab, brand)}
                </p>
              </TabsTrigger>
            );
          })}
        </TabsList>
        <div className="py-6">
          <Row className="mb-2 justify-evenly">
            <SummaryImpact
              impact={data.revenue_impact}
              impactType="currency"
              isDelta
              title="Revenue Impact"
              valueClassName="text-3xl"
            />
            <Separator className="h-[44px]" orientation="vertical" />
            <SummaryImpact
              impact={data.profit_impact}
              impactType="currency"
              isDelta
              title="Profit Impact"
              valueClassName="text-3xl"
            />
          </Row>
        </div>
      </Tabs>
    </CardWithContent>
  );
}
