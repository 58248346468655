import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconArrowsUpDown,
} from "@tabler/icons-react";
import React from "react";

type Props = {
  onSortClick: (order?: "asc" | "desc") => void;
  size?: number;
  sortOrder?: "asc" | "desc";
};

export default function SortIcon({ onSortClick, size = 14, sortOrder }: Props) {
  return sortOrder === "asc" ? (
    <IconArrowNarrowDown
      onClick={() => onSortClick("desc")}
      size={size}
      stroke={1.5}
    />
  ) : sortOrder === "desc" ? (
    <IconArrowNarrowUp onClick={() => onSortClick()} size={size} stroke={1.5} />
  ) : (
    <IconArrowsUpDown
      onClick={() => onSortClick("asc")}
      size={size}
      stroke={1.5}
    />
  );
}
