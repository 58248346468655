import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import { PricePlanStatusType } from "src/shared/trpc/queries/getPricePlansQuerySchema";

export default function usePricePlanQuery(
  status: PricePlanStatusType[],
  searchValue: string,
) {
  const { brandId, enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getPricePlans.useQuery(
    {
      brandId,
      pricePlanTitle: searchValue.length > 0 ? searchValue : undefined,
      status: status,
    },
    {
      enabled,
    },
  );
}
