import React from "react";
import { Input, InputProps } from "src/frontend/components/ui/Input";
import isNumber from "src/shared/utils/numbers/isNumber";

type NumberInputProps = InputProps & {
  onBlur?: () => void;
  onChangeValue: (val: string) => void;
  onPressEnter?: () => void;
  onPressEscape?: () => void;
};

const VALID_INPUTS = ["", "-", ".", "0", "0."];

export default function NumberInput({
  onBlur,
  onChangeValue,
  onPressEnter,
  onPressEscape,
  value,
  ...props
}: NumberInputProps) {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (isNumber(value)) {
      onChangeValue(String(value));
    } else if (VALID_INPUTS.includes(value)) {
      onChangeValue(value);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      onPressEnter != null && onPressEnter();
    } else if (e.code === "Escape") {
      onPressEscape != null && onPressEscape();
    }
  };

  return (
    <Input
      {...props}
      onBlur={onBlur}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      value={value}
    />
  );
}
