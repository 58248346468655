import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";

export default function intersperseArray<T, E>(
  arr: T[],
  interspersedElement: E,
): (E | T)[] {
  if (arrayEmpty(arr)) {
    return [];
  }

  const result: (E | T)[] = [];
  for (const val of arr) {
    result.push(val);
    result.push(interspersedElement);
  }

  result.pop();
  return result;
}
