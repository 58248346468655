import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import { ACTIVE_SKUS_OPTION } from "src/frontend/constants/CustomProductFilters";
import LocalStorageKey from "src/frontend/constants/LocalStorageKey";
import { InStockFilterType } from "src/shared/trpc/common/InStockFilter";
import { ProductConditionFiltersType } from "src/shared/trpc/common/ProductConditionFilters";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { CompetitorPriceLookbackPeriodType } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { MatchLevelFiltersExceptForSimilarProduct } from "src/shared/utils/primaryMatchType";
import { SortDirectionType } from "src/shared/zod/SortDirection";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type CompetitorIntelligenceFiltersStore = ProductConditionFiltersType & {
  displayPriceTarget: PriceTargetType;
  filteredSkus: FancyBoxItem[];
  inStockFilter: InStockFilterType;
  matchLevelFilters: MatchLevelFiltersExceptForSimilarProduct[];
  page: number;
  recentPriceOption: CompetitorPriceLookbackPeriodType;
  searchText: string;
  selectedProductIds: string[];
  sortBy: ProductMasterObjectSortKeyType;
  sortDirection: SortDirectionType;
};

const defaultState: CompetitorIntelligenceFiltersStore = {
  applyAndConditionForCategory: false,
  applyAndConditionForFilters: false,
  applyAndConditionForPriceZone: false,
  applyAndConditionForProductBrandName: false,
  applyAndConditionForSubCategory: false,
  applyAndConditionForSupplier: false,
  applyAndConditionForTags: false,
  displayPriceTarget: "LIST_PRICE",
  filteredSkus: [ACTIVE_SKUS_OPTION],
  inStockFilter: "IN_STOCK",
  matchLevelFilters: [],
  page: 1,
  recentPriceOption: "MOST_RECENT",
  searchText: "",
  selectedProductIds: [],
  sortBy: "product_name",
  sortDirection: "asc",
};

type Actions = {
  reset: () => void;
  setFilteredSkus: (
    newItems: FancyBoxItem[] | ((prevItems: FancyBoxItem[]) => FancyBoxItem[]),
  ) => void;
};

const useCompetitorIntelligenceFiltersStore = create<
  Actions & CompetitorIntelligenceFiltersStore
>()(
  persist(
    (set) => ({
      reset: (): void => {
        set(defaultState);
      },
      setFilteredSkus: (newItems) =>
        set((state) => ({
          filteredSkus:
            newItems instanceof Function
              ? newItems(state.filteredSkus)
              : newItems,
        })),
      ...defaultState,
    }),
    {
      name: LocalStorageKey.CompetitorIntelligenceFilters,
    },
  ),
);

export default useCompetitorIntelligenceFiltersStore;
