import { z } from "zod";

export const brandSettingsShape = z.object({
  disablePricePlanCreation: z.boolean(),
});

export type BrandSettingsType = z.infer<typeof brandSettingsShape>;

export const defaultBrandSettings: BrandSettingsType = {
  disablePricePlanCreation: false,
};

const allBrandSettings = z.record(z.string(), brandSettingsShape);

type LaunchDarklyFlagSet = {
  adminFeaturesEnabled: boolean;
  backgroundRefreshEnabled: boolean;
  brandSettings: z.infer<typeof allBrandSettings>;
  devFeaturesEnabled: boolean;
  lucaCommandMenuEnabled: boolean;
  maintenanceUiEnabled: boolean;
  navigationEventNotificationsEnabled: boolean;
  pricePlanAutoApproveEnabled: boolean;
  pricePlanV2Enabled: boolean;
  publicApiDocsEnabled: boolean;
};

export const LaunchDarklyDefaultFlagValues: LaunchDarklyFlagSet = {
  adminFeaturesEnabled: false,
  backgroundRefreshEnabled: false,
  brandSettings: {},
  devFeaturesEnabled: false,
  lucaCommandMenuEnabled: false,
  maintenanceUiEnabled: false,
  navigationEventNotificationsEnabled: false,
  pricePlanAutoApproveEnabled: false,
  pricePlanV2Enabled: false,
  publicApiDocsEnabled: false,
};

export default LaunchDarklyFlagSet;
