import { ProductCatalogItem } from "src/shared/trpc/common/ProductCatalogItem";
import { z } from "zod";

export const ProductCatalogQueryResults = z.object({
  products: ProductCatalogItem.array(),
  totalCount: z.number(),
});

export type ProductCatalogQueryResultsType = z.infer<
  typeof ProductCatalogQueryResults
>;
