import useBrandStore from "src/frontend/stores/useBrandStore";
import { PricePlanVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import mappedColumnVisibility from "./mappedColumnVisibility";

export const memberColumns = new Set<keyof PricePlanVisibilityFiltersType>([
  "new_member_price",
  "previous_member_price",
  "previous_member_discounted_percent",
  "new_member_discounted_percent",
  "member_price_change_percent",
  "new_member_price_margin",
  "new_member_price_contribution_margin",
  "previous_member_price_margin",
  "previous_member_price_contribution_margin",
  "member_constraints",
  "member_elasticity",
  "member_price_range",
]);

export const discountColumns = new Set<keyof PricePlanVisibilityFiltersType>([
  "new_discounted_price",
  "previous_discounted_price",
  "previous_discounted_percent",
  "new_discounted_percent",
  "discounted_price_change_percent",
  "new_discounted_price_margin",
  "new_discounted_price_contribution_margin",
  "previous_discounted_price_margin",
  "previous_discounted_price_contribution_margin",
]);

export const subscriberColumns = new Set<keyof PricePlanVisibilityFiltersType>([
  "new_subscriber_price",
  "previous_subscriber_price",
  "previous_subscriber_discounted_percent",
  "new_subscriber_discounted_percent",
  "subscriber_price_change_percent",
  "new_subscriber_price_margin",
  "new_subscriber_price_contribution_margin",
  "previous_subscriber_price_margin",
  "previous_subscriber_price_contribution_margin",
]);

export default function usePricePlanColumnVisibility(
  visibilityFilters: PricePlanVisibilityFiltersType,
) {
  const brand = useBrandStore();
  const {
    discounted_price_enabled,
    member_price_enabled,
    subscriber_price_enabled,
  } = brand;

  const filters = visibilityFilters;

  if (!member_price_enabled) {
    memberColumns.forEach((key) => (filters[key] = false));
  }
  if (!discounted_price_enabled) {
    discountColumns.forEach((key) => (filters[key] = false));
  }
  if (!subscriber_price_enabled) {
    subscriberColumns.forEach((key) => (filters[key] = false));
  }

  return mappedColumnVisibility(filters);
}
