import { BaseResultsMetrics } from "src/shared/trpc/common/BaseResultsMetrics";
import { z } from "zod";

export const BrandResultsMetrics = BaseResultsMetrics.merge(
  z.object({
    brand_control_margin: z.number().nullable(),
    brand_margin_change_percent: z.number().nullable(),
    brand_profit_change: z.number().nullable(),
    brand_profit_change_percent: z.number().nullable(),
    brand_profit_control: z.number().nullable(),
    brand_profit_treatment: z.number().nullable(),
    brand_revenue_change: z.number().nullable(),
    brand_revenue_change_percent: z.number().nullable(),
    brand_revenue_control: z.number().nullable(),
    brand_revenue_treatment: z.number().nullable(),
    brand_treatment_margin: z.number().nullable(),
    brand_units_sold_change: z.number().nullable(),
    brand_units_sold_change_percent: z.number().nullable(),
    brand_units_sold_control: z.number().nullable(),
    brand_units_sold_treatment: z.number().nullable(),
  }),
);

export type BrandResultsMetricsType = z.infer<typeof BrandResultsMetrics>;
