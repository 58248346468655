import { z } from "zod";

const ProductShorthand = z.object({
  id: z.string(),
  product_name: z.string(),
  sku: z.string(),
});

export const PriceParitySummaryGroup = z.object({
  id: z.string(),
  is_active: z.boolean(),
  name: z.string(),
  product_short_list: ProductShorthand.array(),
});

export type PriceParitySummaryGroup = z.infer<typeof PriceParitySummaryGroup>;
