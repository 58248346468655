import capitalize from "src/shared/utils/strings/capitalize";

// e.g. Converts "product_name" to "Product Name".
export default function snakeCaseToTitleCase(str: string): string {
  return capitalize(
    str
      .toLowerCase()
      .replace(/([-_][a-z0-9])/g, (group) =>
        group.toUpperCase().replace("-", " ").replace("_", " "),
      ),
  );
}
