import { Download } from "lucide-react";
import Button from "src/frontend/components/ui/Button";
import Tooltip from "src/frontend/components/ui/Tooltip";

type DownloadButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  tooltipLabel?: string;
};

export default function DownloadButton({
  disabled = false,
  loading = false,
  onClick,
  tooltipLabel,
}: DownloadButtonProps) {
  return (
    <Tooltip
      content={tooltipLabel}
      disabled={tooltipLabel == null || disabled}
      side="bottom"
    >
      <Button
        className="h-10 rounded-full p-2"
        disabled={disabled || loading}
        onClick={onClick}
        variant="ghost"
      >
        <Download />
      </Button>
    </Tooltip>
  );
}
