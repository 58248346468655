import { ProductCatalogQueryInput } from "src/shared/trpc/common/ProductCatalogQueryInput";
import { ProductCatalogQueryResults } from "src/shared/trpc/common/ProductCatalogQueryResults";
import { z } from "zod";

export const GetProductCatalogQueryInput = ProductCatalogQueryInput;

export type GetProductCatalogQueryInputType = z.infer<
  typeof GetProductCatalogQueryInput
>;

export const GetProductCatalogQueryResponse = ProductCatalogQueryResults;

export type GetProductCatalogQueryResponseType = z.infer<
  typeof GetProductCatalogQueryResponse
>;
