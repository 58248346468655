import {
  AlertDialogAction,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogRoot,
  AlertDialogHeader,
  AlertDialogFooter,
} from "src/frontend/components/ui/AlertDialog";
import Button from "src/frontend/components/ui/Button";
import noop from "src/shared/utils/noop";

type PromptProps = {
  confirmText?: string;
  description?: React.ReactNode;
  key?: string;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  title?: string;
  trigger: React.ReactElement | string;
};

export function Prompt(props: PromptProps) {
  const {
    confirmText = "Confirm",
    description,
    key,
    loading = false,
    onCancel = noop,
    onConfirm,
    onOpenChange,
    open,
    title = "Are you sure?",
    trigger,
  } = props;
  const isControlledComponent = open != null && onOpenChange != null;
  return (
    <AlertDialogRoot key={key} onOpenChange={onOpenChange} open={open}>
      <AlertDialogTrigger asChild>
        {typeof trigger === "string" ? (
          <Button variant="basic">{trigger}</Button>
        ) : (
          trigger
        )}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <form
          onSubmit={(e) => {
            onConfirm();
            e.preventDefault();
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            {description && (
              <AlertDialogDescription className="py-2">
                {description}
              </AlertDialogDescription>
            )}
            {description == null && <div className="h-8" />}
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-2 gap-2">
            <AlertDialogCancel disabled={loading} onClick={onCancel}>
              Cancel
            </AlertDialogCancel>
            {isControlledComponent ? (
              <Button disabled={loading} type="submit">
                {loading ? "Loading..." : confirmText}
              </Button>
            ) : (
              <AlertDialogAction disabled={loading} type="submit">
                {loading ? "Loading..." : confirmText}
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialogRoot>
  );
}
