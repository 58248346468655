import React from "react";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type CenteredProps = HtmlDivProps & {
  children: React.ReactNode;
};

const Centered = React.forwardRef<HTMLDivElement, CenteredProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <div
        className={cn("flex items-center justify-center", className)}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

Centered.displayName = "Centered";

export default Centered;
