import { useState } from "react";
import Markdown from "src/frontend/components/Markdown";
import Button from "src/frontend/components/ui/Button";
import Dialog from "src/frontend/components/ui/Dialog";

type PricePlanInsightsDialogProps = {
  insights: string;
};

export default function PricePlanInsightsDialog({
  insights,
}: PricePlanInsightsDialogProps) {
  const [open, onOpenChange] = useState(false);
  return (
    <Dialog
      confirmButtonVariant="outline"
      confirmTitle="Ok"
      dialogTitle="Insights"
      loading={false}
      onConfirm={() => onOpenChange(false)}
      onOpenChange={onOpenChange}
      open={open}
      trigger={
        <Button className="text-xl font-normal" size="none" variant="link">
          link
        </Button>
      }
    >
      <Markdown>{insights}</Markdown>
    </Dialog>
  );
}
