import { Location } from "react-router-dom";

const PRICING_TABS = [
  "preview",
  "internal",
  "draft",
  "current",
  "past",
  "drafts",
  "launched",
] as const;

type PRICING_TAB = (typeof PRICING_TABS)[number];

export default function getPricePlanTabFromLocation(
  location: Location,
): PRICING_TAB | null {
  const path = location.pathname;
  if (path.startsWith("/pricing")) {
    const tab = path.split("/")[2] as PRICING_TAB;
    if (PRICING_TABS.includes(tab)) {
      return tab;
    } else {
      return null;
    }
  }

  return "draft";
}
