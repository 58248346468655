export default function filterMaybeValuesFromObject<
  T extends Record<string, any>,
>(obj: T): Partial<T> {
  const result: Partial<T> = {};
  for (const [k, v] of Object.entries(obj)) {
    if (v != null) {
      result[k as keyof T] = v;
    }
  }
  return result;
}
