import { CSSProperties } from "react";
import { CopyText } from "src/frontend/components/CopyText";
import Row from "src/frontend/components/Row";
import SortIcon from "src/frontend/components/SortIcon";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { cn } from "src/frontend/components/ui/utils";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import usePriceParityGroupStore from "src/frontend/stores/usePriceParityGroupStore";
import useProductDetailSidePanelStore from "src/frontend/stores/useProductDetailSidePanelStore";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import getStickyColumnStyles from "src/frontend/utils/table/getStickyColumnStyles";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { ProductMasterWithCompetitorMatchesType } from "src/shared/trpc/common/ProductMasterWithCompetitorMatches";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";

const MIN_WIDTH = 100;
const minWidthStyles: CSSProperties = {
  minWidth: MIN_WIDTH,
};

type SkuSelectionTableProps = HtmlDivProps & {
  enableSorting?: boolean;
  loading?: boolean;
  onSelectAllSkus: (productIds: string[]) => void;
  onSelectSku: (productId: string, checked: boolean) => void;
  products: ProductMasterWithCompetitorMatchesType[];
  selectedProductIds: string[];
};

export default function SkuSelectionTable({
  className,
  enableSorting = true,
  loading,
  onSelectAllSkus,
  onSelectSku,
  products,
  selectedProductIds,
  ...rest
}: SkuSelectionTableProps) {
  const isDarkTheme = useIsDarkTheme();
  const breakpointState = useBreakpoints();
  const sortBy = usePriceParityGroupStore((state) => state.sortBy);
  const sortDirection = usePriceParityGroupStore(
    (state) => state.sortDirection,
  );

  const skuOffset = 35;
  const productOffset = 75 + skuOffset;

  const checkboxStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    width: skuOffset,
  });
  const skuStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    width: 75,
  });
  const productStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 2,
    width: 225,
  });

  const toggleSortKey = (key: ProductMasterObjectSortKeyType) => {
    if (!enableSorting) {
      return;
    }

    if (sortBy === key) {
      usePriceParityGroupStore.setState((state) => {
        return {
          page: 1,
          sortDirection: state.sortDirection === "asc" ? "desc" : "asc",
        };
      });
    } else {
      usePriceParityGroupStore.setState({
        page: 1,
        sortBy: key,
        sortDirection: "desc",
      });
    }
  };

  const competitorPricingData = products.map((val) => val.competitor_matches);

  return (
    <Table className={cn("bg-card", className)} {...rest}>
      <TableHeader>
        <tr className="border-b align-top">
          <TableHeaderCell
            className="left-0"
            style={checkboxStickyColumnStyles}
          >
            <Checkbox
              checked={selectedProductIds.length === products.length}
              disabled={loading}
              id="check-all-experiments"
              onCheckedChange={(_, checked) => {
                onSelectAllSkus(
                  !checked ? [] : products.map((product) => product.id),
                );
              }}
              size="sm"
            />
          </TableHeaderCell>
          <TableHeaderCell
            className={cn(enableSorting && "hover:cursor-pointer")}
            onClick={() => toggleSortKey("sku")}
            style={{ ...skuStickyColumnStyles, left: skuOffset }}
          >
            <Row className="items-start">
              <p className="pl-1">SKU</p>
              {enableSorting && (
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="sku"
                />
              )}
            </Row>
          </TableHeaderCell>
          <TableHeaderCell
            className={cn(enableSorting && "hover:cursor-pointer")}
            onClick={() => toggleSortKey("product_name")}
            style={{ ...productStickyColumnStyles, left: productOffset }}
          >
            <Row className="items-start">
              <p>Product Name</p>
              {enableSorting && (
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="product_name"
                />
              )}
            </Row>
          </TableHeaderCell>
          <TableHeaderCell
            className={cn(enableSorting && "hover:cursor-pointer")}
            onClick={() => toggleSortKey("list_price")}
            style={minWidthStyles}
          >
            <Row className="items-start">
              <p>Price</p>
              {enableSorting && (
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="list_price"
                />
              )}
            </Row>
          </TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        {products.map((product, productIndex) => {
          return (
            <tr
              className="border-b last:border-b-0 hover:cursor-pointer"
              key={product.id}
              onClick={() => {
                useProductDetailSidePanelStore.setState({
                  competitorMatchIndex: 1,
                  competitorNamesList: product.competitor_names_list,
                  competitorPricingData,
                  isOpen: true,
                  productIndex,
                  products,
                  tab: "Product",
                });
              }}
            >
              <TableDataCell
                className="left-0 bg-background"
                onClick={(e) => e.stopPropagation()}
                style={checkboxStickyColumnStyles}
              >
                <Checkbox
                  checked={selectedProductIds.includes(product.id)}
                  disabled={loading}
                  id={product.id}
                  onCheckedChange={(_, checked) => {
                    onSelectSku(product.id, checked);
                  }}
                  size="sm"
                />
              </TableDataCell>
              <TableDataCell
                className="bg-background"
                style={{ ...skuStickyColumnStyles, left: skuOffset }}
              >
                <CopyText includeTooltip isCode textToCopy={product.sku}>
                  <p className="truncate text-ellipsis text-left text-sm">
                    {product.sku}
                  </p>
                </CopyText>
              </TableDataCell>
              <TableDataCell
                className="bg-background"
                style={{ ...productStickyColumnStyles, left: productOffset }}
              >
                <p className="line-clamp-1 min-w-[8ch] max-w-[32ch] text-left text-sm">
                  {product.product_name}
                </p>
              </TableDataCell>
              <TableDataCell style={minWidthStyles}>
                {formatCurrency(product.list_price)}
              </TableDataCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
