import {
  IconBook2,
  IconChartHistogram,
  IconFileCode,
  IconGraph,
  IconHome,
  IconShoppingBag,
  IconStar,
} from "@tabler/icons-react";
import { Location, To, useLocation } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import BrandSelect from "src/frontend/components/BrandSelect";
import FeatureRequestDialog from "src/frontend/components/dialogs/FeatureRequestDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/frontend/components/ui/Accordion";
import { cn } from "src/frontend/components/ui/utils";
import useCanAccessFeature from "src/frontend/hooks/useCanAccessFeature";
import useFlags from "src/frontend/hooks/useFlags";
import { usePricePlanRoute } from "src/frontend/hooks/usePricePlanRoute";
import useBrandStore from "src/frontend/stores/useBrandStore";
import DOCUMENTATION_WEBSITE from "src/shared/constants/DocumentationWebsite";

type SideBarNavItemProps = LinkProps & {
  locked?: boolean;
  matchPath?: string[] | string;
  testId?: string;
};

const isRouteActive = (
  location: Location,
  to: To,
  matchPath?: string[] | string,
) => {
  if (to === "") {
    return location.pathname === "/";
  }
  if (matchPath === undefined) {
    return location.pathname.includes(String(to));
  }
  if (typeof matchPath === "string") {
    return location.pathname.startsWith(matchPath);
  }

  return matchPath.some((path) => location.pathname.startsWith(path));
};

const SideBarNavItem = ({
  children,
  className,
  locked,
  matchPath,
  testId,
  to,
  ...rest
}: SideBarNavItemProps) => {
  const location = useLocation();

  return (
    <Link
      {...rest}
      className={cn(
        "mx-3 flex h-10 items-center rounded-md p-2 py-1 text-sm ",
        locked ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        locked
          ? ""
          : isRouteActive(location, to, matchPath)
            ? "bg-n-200/50 dark:bg-sidebar md:dark:bg-background"
            : "hover:bg-n-200 hover:bg-opacity-40 dark:bg-background dark:hover:bg-background md:dark:bg-sidebar",
        className,
      )}
      data-testid={testId}
      to={locked ? "" : to}
    >
      <div className="mr-3 flex items-center gap-3">{children}</div>
    </Link>
  );
};

export default function SideBar() {
  const { publicApiDocsEnabled } = useFlags();
  const pricePlanRoute = usePricePlanRoute();
  const canAccessCompetitorIntelligence = useCanAccessFeature(
    "COMPETITOR_INTELLIGENCE",
  );
  const featureBlocklist = useBrandStore(
    (state) => state.user_feature_blocklist,
  );
  const canAccessBasePricing = !featureBlocklist.includes("BASE_PRICING");
  return (
    <div className={cn("flex min-w-max flex-col bg-background md:bg-sidebar")}>
      <div className="px-4 py-5">
        <BrandSelect />
      </div>
      <div className="flex flex-col gap-3">
        <Accordion
          className="mb-3 flex w-full flex-col gap-3"
          collapsible
          defaultValue={pricePlanRoute != null ? "pricing" : undefined}
          type="single"
        >
          {canAccessBasePricing && (
            <SideBarNavItem matchPath="/home" to="/home">
              <IconHome size={20} stroke={1.5} />
              <p>Dashboard</p>
            </SideBarNavItem>
          )}
          {canAccessBasePricing && (
            <AccordionItem className="flex flex-col border-0" value="pricing">
              <AccordionTrigger className="mx-3 flex justify-start gap-3 rounded-md p-2 hover:bg-n-200/40">
                <IconGraph size={20} stroke={1.5} />
                <p className="text-sm">Base Pricing</p>
              </AccordionTrigger>
              <AccordionContent className="flex h-full flex-col gap-2 pt-2">
                <SideBarNavItem
                  className="h-8 pl-10"
                  matchPath={[
                    "/pricing/drafts",
                    "/pricing/draft/",
                    "/pricing/internal",
                    "/pricing/preview",
                  ]}
                  to={"/pricing/drafts"}
                >
                  <p>Drafts</p>
                </SideBarNavItem>
                <SideBarNavItem
                  className="h-8 pl-10"
                  matchPath={[
                    "/pricing/launched",
                    "/pricing/current",
                    "/pricing/past",
                  ]}
                  to={"/pricing/launched"}
                >
                  <p>Launched</p>
                </SideBarNavItem>
              </AccordionContent>
            </AccordionItem>
            // </Accordion>
          )}
          {canAccessBasePricing && (
            <SideBarNavItem
              matchPath="/company-settings"
              to="/company-settings/strategy"
            >
              <IconStar size={20} stroke={1.5} />
              <p>Goals & Settings</p>
            </SideBarNavItem>
          )}
          {canAccessBasePricing && (
            <SideBarNavItem matchPath="/product-catalog" to="product-catalog">
              <IconShoppingBag size={20} stroke={1.5} />
              <p> Product Catalog</p>
            </SideBarNavItem>
          )}
          {canAccessCompetitorIntelligence &&
            !featureBlocklist.includes("COMPETITOR_INTELLIGENCE") && (
              <SideBarNavItem
                matchPath="/competitor-intelligence"
                to="/competitor-intelligence"
              >
                <IconChartHistogram size={20} stroke={1.5} />
                <p> Competitor Intelligence</p>
              </SideBarNavItem>
            )}
        </Accordion>
      </div>
      <div className="mb-auto flex flex-col" />
      <hr className="bg-grey-200" />
      <FeatureRequestDialog />
      {publicApiDocsEnabled && (
        <SideBarNavItem to="/docs">
          <IconFileCode size={20} stroke={1.5} />
          <p>API Swagger Docs</p>
        </SideBarNavItem>
      )}
      <SideBarNavItem target="_blank" to={DOCUMENTATION_WEBSITE}>
        <IconBook2 size={20} stroke={1.5} />
        <p>Luca Documentation</p>
      </SideBarNavItem>
    </div>
  );
}
