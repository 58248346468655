import { MenuSquare } from "lucide-react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import SideBar from "src/frontend/components/Sidebar";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "src/frontend/components/ui/Sheet";
import useBrandId from "src/frontend/hooks/useBrandId";

type MobileNavigationSheetProps = {
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

export function MobileNavigationSheet(props: MobileNavigationSheetProps) {
  const { isNavOpen, setIsNavOpen } = props;
  const brandId = useBrandId();
  const location = useLocation();

  useEffect(() => {
    setIsNavOpen(false);
  }, [brandId, setIsNavOpen]);

  useEffect(() => {
    setIsNavOpen(false);
  }, [location.pathname, setIsNavOpen]);

  return (
    <Sheet onOpenChange={setIsNavOpen} open={isNavOpen}>
      <SheetTrigger asChild>
        <MenuSquare size={46} strokeWidth={0.88} />
      </SheetTrigger>
      <SheetContent hideCloseIcon side="left">
        <SideBar />
      </SheetContent>
    </Sheet>
  );
}
