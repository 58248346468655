import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";
import invariant from "tiny-invariant";

type DuplicatePricingStrategyModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function DuplicatePricingStrategyModal({
  isModalOpen,
  setIsModalOpen,
}: DuplicatePricingStrategyModalProps) {
  const t = useToast();
  const pricingStrategyQuery = useGetPricingStrategiesQuery();
  const { loading, pricingStrategyId } = usePricingStrategyDropdownStateStore();
  const duplicatePricingStrategyMutation =
    TrpcClient.internal.duplicatePricingStrategy.useMutation();

  const onSuccess = async () => {
    await pricingStrategyQuery.refetch();
    usePricingStrategyDropdownStateStore.setState({
      isDuplicateModalOpen: false,
      loading: false,
      pricingStrategyId: null,
    });
    setIsModalOpen(false);
    t.successToast("Pricing strategy duplicated successfully.");
    usePricePlanStore.setState({
      pricePlanId: undefined,
      tab: undefined,
    });
  };

  const handleDuplicatePricingStrategy = () => {
    usePricingStrategyDropdownStateStore.setState({ loading: true });
    t.infoToast("Duplicating pricing strategy... This will take a moment.");
    invariant(pricingStrategyId != null);
    duplicatePricingStrategyMutation.mutate(
      {
        pricingStrategyId,
      },
      {
        onError: () => {
          t.errorToast("Failed to duplicate price plan.");
          usePricingStrategyDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Modal
      confirmText="Continue"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            This will duplicate the current pricing strategy and all pricing
            rules.
          </p>
        </div>
      }
      dialogTitle="Duplicate Strategy"
      loading={loading}
      onConfirm={handleDuplicatePricingStrategy}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
