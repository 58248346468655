import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import {
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import Head from "next/head";
import Image from "next/image";
import { useEffect, useState } from "react";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import Button from "src/frontend/components/ui/Button";
import { Input } from "src/frontend/components/ui/Input";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import FirebaseAuth from "src/frontend/utils/firebase/FirebaseAuth";
import FirebaseAuthErrors from "src/frontend/utils/firebase/FirebaseAuthErrors";
import logClientError from "src/frontend/utils/logClientError";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import isValidEmail from "src/shared/utils/isValidEmail";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

function HeadComponent() {
  return (
    <Head>
      <title>Luca</title>
      <meta
        content="Login - Luca - Smart Pricing for your eCommerce business"
        key="title"
        property="og:title"
      />
    </Head>
  );
}

function LucaImage() {
  return (
    <Image
      alt="Luca"
      className="mt-4 self-center"
      height={145}
      src="/luca-logo.png"
      title="Luca"
      width={145}
    />
  );
}

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z]).*$/;

type LoginEmailPasswordProps = {
  type: "FORGOT_PASSWORD" | "LOGIN" | "REGISTER";
};

const MicrosoftProvider = new OAuthProvider("microsoft.com");

export default function Login({ type }: LoginEmailPasswordProps) {
  const t = useToast();
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(type === "LOGIN");
  const [isPasswordReset, setIsPasswordReset] = useState(
    type === "FORGOT_PASSWORD",
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [canResendLink, setCanSendLink] = useState(false);
  const [resetLinkTimer, setResetLinkTimer] =
    useState<MaybeNull<NodeJS.Timeout>>(null);

  useEffect(() => {
    return () => {
      if (resetLinkTimer != null) {
        clearTimeout(resetLinkTimer);
      }
    };
  }, [resetLinkTimer]);

  const sendPasswordReset = async () => {
    try {
      setIsLoading(true);
      setCanSendLink(false);
      if (resetLinkTimer != null) {
        clearTimeout(resetLinkTimer);
      }
      await sendPasswordResetEmail(FirebaseAuth, email);
      setIsLoading(false);
      setPasswordResetSent(true);
      const timer = setTimeout(() => setCanSendLink(true), 10_000);
      setResetLinkTimer(timer);
    } catch (err: any) {
      setIsLoading(false);
      console.error(err);
      t.errorToast(
        "Failed to send password reset email. Please try again later or contact Luca for support.",
      );
      logClientError(err, SentryErrorEvent.FrontendAuthError, {
        extra: { email },
      });
    }
  };

  const register = async () => {
    try {
      setIsLoading(true);
      await createUserWithEmailAndPassword(FirebaseAuth, email, password);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.error(err);
      t.errorToast(
        "Failed to create account. If you are a new customer, contact Luca to proceed with your account setup.",
      );
      logClientError(err, SentryErrorEvent.FrontendAuthError, {
        extra: { email },
      });
    }
  };

  const login = async () => {
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(FirebaseAuth, email, password);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.error(err);
      t.errorToast(
        "Failed to login. If you are sure your credentials are correct, contact Luca for support.",
      );
      logClientError(err, SentryErrorEvent.FrontendAuthError, {
        extra: { email },
      });
    }
  };

  const trySignupWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithPopup(FirebaseAuth, provider).catch((error) => {
      if (error?.code && FirebaseAuthErrors[error.code]) {
        t.errorToast({
          description: FirebaseAuthErrors[error.code],
          title: "Oops, there was an error.",
        });
      }
    });
  };

  const trySignupWithMicrosoft = () => {
    MicrosoftProvider.setCustomParameters({
      prompt: "consent",
      tenant: "common",
    });
    signInWithPopup(FirebaseAuth, MicrosoftProvider).catch((error) => {
      if (error?.code && FirebaseAuthErrors[error.code]) {
        t.errorToast({
          description: FirebaseAuthErrors[error.code],
          title: "Oops, there was an error.",
        });
      }
    });
  };

  const emailHasError = email.trim() === "" || !isValidEmail(email);
  const passwordHasError =
    password.trim() === "" ||
    (!isLogin && password.length < 10) ||
    (!isLogin && !PASSWORD_REGEX.test(password));
  const hasError = emailHasError || passwordHasError;

  const resetErrorState = () => {
    setShowErrors(false);
  };

  const handleSubmit = () => {
    if (hasError) {
      setShowErrors(true);
      return;
    }

    if (loading) {
      return;
    }

    if (isLogin) {
      void login();
    } else {
      void register();
    }
  };

  const handleResetPassword = () => {
    if (emailHasError) {
      setShowErrors(true);
      return;
    }

    if (loading) {
      return;
    }

    void sendPasswordReset();
  };

  if (isPasswordReset) {
    if (passwordResetSent) {
      return (
        <>
          <HeadComponent />
          <Col className="min-h-screen flex-1 items-center justify-center">
            <LucaImage />
            <div className="mb-5 mt-2">
              <h4 className="font-bond text-center text-2xl">
                Email has been sent
              </h4>
              <p className="mt-2 max-w-sm text-center text-sm text-muted-foreground">
                We just sent you an email with a reset link. Please follow the
                link in the email to reset your password.
              </p>
            </div>
            <Col className="w-full items-center justify-center gap-2">
              <div className="flex w-full max-w-sm flex-col">
                <Button
                  className="mt-1 rounded-lg"
                  disabled={loading}
                  onClick={() => {
                    resetErrorState();
                    setIsPasswordReset(false);
                    setIsLogin(true);
                  }}
                >
                  Back to login
                </Button>
                <Row className="mt-1 justify-center gap-1">
                  <p className="text-xs">Didn&apos;t receive an email?</p>
                  <Tooltip
                    content={
                      <div className="max-w-[250px] text-center">
                        <p>
                          Please wait before requesting another password reset
                          email.
                        </p>
                      </div>
                    }
                    disabled={canResendLink}
                    side="bottom"
                  >
                    <Button
                      className="p-0 font-bold text-primary no-underline hover:underline"
                      disabled={loading || !canResendLink}
                      onClick={() => {
                        void sendPasswordReset();
                      }}
                      variant="link"
                    >
                      <p className="text-xs">Resend</p>
                    </Button>
                  </Tooltip>
                </Row>
              </div>
            </Col>
          </Col>
        </>
      );
    }

    return (
      <>
        <HeadComponent />
        <Col className="min-h-screen flex-1 items-center justify-center">
          <LucaImage />
          <div className="mb-5 mt-2">
            <h4 className="font-bond text-center text-2xl">
              Forgot your password?
            </h4>
            <p className="mt-2 max-w-sm text-center text-sm text-muted-foreground">
              Enter your email address and we will send you instructions to
              reset your password
            </p>
          </div>
          <Col className="w-full items-center justify-center gap-2">
            <form
              className="flex w-full max-w-sm flex-col"
              onSubmit={(e) => {
                e.preventDefault();
                handleResetPassword();
              }}
            >
              <Col>
                <label
                  className="mb-2 text-xs text-muted-foreground"
                  htmlFor="email"
                >
                  Email address *
                </label>
                <Input
                  errorClassName="text-xs"
                  errorMessage={
                    emailHasError && showErrors
                      ? "A valid email is required"
                      : undefined
                  }
                  name="email"
                  onChange={(e) => {
                    resetErrorState();
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter email"
                  type="email"
                  value={email}
                />
              </Col>
              <Button
                className="mt-6 rounded-lg"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
                type="submit"
              >
                Reset password
              </Button>
              <Row className="mt-2 justify-center">
                <Button
                  className="p-0 font-bold text-primary no-underline hover:underline"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    resetErrorState();
                    setIsPasswordReset(false);
                  }}
                  variant="link"
                >
                  <p className="text-xs">Back to Login</p>
                </Button>
              </Row>
            </form>
          </Col>
        </Col>
      </>
    );
  }

  return (
    <>
      <HeadComponent />
      <Col className="min-h-screen flex-1 items-center justify-center">
        <LucaImage />
        <div className="mb-5 mt-2">
          <h4 className="font-bond text-center text-2xl">
            {isLogin ? "Welcome back!" : "Create an account below"}
          </h4>
          {isLogin && (
            <p className="mt-2 max-w-sm text-center text-sm text-muted-foreground">
              Sign into your Luca account below
            </p>
          )}
        </div>
        <Col className="w-full items-center justify-center gap-4">
          <Button
            className="h-[50px] w-full max-w-sm rounded-lg bg-white shadow outline-none hover:shadow-md focus:outline-none active:bg-gray-100"
            disabled={loading}
            onClick={trySignupWithGoogle}
            variant="ghost"
          >
            <img alt="Google Logo" className="mr-4 w-5" src="/google.svg" />
            <p>Continue with Google</p>
          </Button>
          <Button
            className="h-[50px] w-full max-w-sm rounded-lg bg-white shadow outline-none hover:shadow-md focus:outline-none active:bg-gray-100"
            disabled={loading}
            onClick={trySignupWithMicrosoft}
            variant="ghost"
          >
            <img
              alt="Microsoft Logo"
              className="mr-4 w-5"
              src="/microsoft.svg"
            />
            <p>Continue with Microsoft</p>
          </Button>
        </Col>
        <Row className="my-6 w-full max-w-sm justify-evenly gap-2">
          <div className="h-[1px] w-full rounded-full bg-muted" />
          <p className="text-sm text-muted-foreground">OR</p>
          <div className="h-[1px] w-full rounded-full bg-muted" />
        </Row>
        <Col className="w-full items-center justify-center gap-2">
          <form
            className="flex w-full max-w-sm flex-col gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Col>
              <label
                className="mb-1 text-xs text-muted-foreground"
                htmlFor="email"
              >
                Email address *
              </label>
              <Input
                errorClassName="text-xs"
                errorMessage={
                  emailHasError && showErrors
                    ? "A valid email is required"
                    : undefined
                }
                name="email"
                onChange={(e) => {
                  resetErrorState();
                  setEmail(e.target.value);
                }}
                placeholder="Enter email"
                testId="email-input"
                type="email"
                value={email}
              />
            </Col>
            <Col>
              <label
                className="mb-1 text-xs text-muted-foreground"
                htmlFor="password"
              >
                Password *
              </label>
              <Input
                errorClassName="text-xs"
                errorMessage={
                  passwordHasError && showErrors
                    ? isLogin
                      ? "Password is required"
                      : "Password is required (must be at least 10 characters with lower and uppercase letters)"
                    : undefined
                }
                name="password"
                onChange={(e) => {
                  resetErrorState();
                  setPassword(e.target.value);
                }}
                placeholder="Enter password"
                testId="password-input"
                type="password"
                value={password}
              />
            </Col>
            <Row>
              <Button
                className="p-0 font-bold text-primary no-underline hover:underline"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setIsPasswordReset(true);
                }}
                type="button"
                variant="link"
              >
                <p className="text-xs">Forgot password?</p>
              </Button>
            </Row>
            <Button
              className="rounded-lg"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              testId="login-button"
              type="submit"
            >
              Continue
            </Button>
            {isLogin ? (
              <Row className="justify-center gap-1">
                <p className="text-xs">Don&apos;t have an account?</p>
                <Button
                  className="p-0 font-bold text-primary no-underline hover:underline"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    resetErrorState();
                    setIsLogin(false);
                  }}
                  variant="link"
                >
                  <p className="text-xs">Create account</p>
                </Button>
              </Row>
            ) : (
              <Row className="justify-center gap-1">
                <p className="text-xs">Already have an account?</p>
                <Button
                  className="p-0 font-bold text-primary no-underline hover:underline"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    resetErrorState();
                    setIsLogin(true);
                  }}
                  variant="link"
                >
                  <p className="text-xs">Login</p>
                </Button>
              </Row>
            )}
          </form>
        </Col>
      </Col>
    </>
  );
}
