import { CSSProperties } from "react";
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Card from "src/frontend/components/Card";
import Centered from "src/frontend/components/Centered";
import Row from "src/frontend/components/Row";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import COLORS from "src/frontend/constants/Colors";
import TAILWIND_COLORS from "src/frontend/constants/TailwindColors";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import useRechartsStyles from "src/frontend/hooks/useRechartsStyles";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import RechartsReferenceLineProps from "src/frontend/types/RechartsReferenceLineProps";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { GroupResultsMetricsResponseType } from "src/shared/trpc/common/GroupResultsMetrics";
import { OpportunityMetricsResponseType } from "src/shared/trpc/common/OpportunityMetrics";
import {
  ConfidenceIntervalMetricType as ConfidenceDistributionMetricType,
  PricePlanConfidenceIntervalType,
} from "src/shared/trpc/common/PricePlanConfidenceInterval";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import getFirst from "src/shared/utils/arrays/getFirst";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import avg from "src/shared/utils/math/avg";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import multiplyMaybeNumbers from "src/shared/utils/numbers/multiplyMaybeNumbers";
import roundToNearestHundredths from "src/shared/utils/numbers/roundToNearestHundredths";
import sign from "src/shared/utils/numbers/sign";
import capitalize from "src/shared/utils/strings/capitalize";

type ConfidenceIntervalsChartData = {
  avg: number;
  count: number;
};

type ConfidenceIntervalsTooltipProps = {
  active: boolean;
  metric: ConfidenceDistributionMetricType;
  payload: { payload: ConfidenceIntervalsChartData }[];
};

type ConfidenceBounds = {
  lower: MaybeNumber;
  upper: MaybeNumber;
};

function getConfidenceBounds(
  segment: GroupResultsMetricsResponseType | OpportunityMetricsResponseType,
  metric: ConfidenceDistributionMetricType,
): ConfidenceBounds {
  switch (metric) {
    case "quantity":
      return {
        lower:
          "new_quantity_lower_bound" in segment
            ? segment.new_quantity_lower_bound
            : segment.units_sold_lower_bound,
        upper:
          "new_quantity_upper_bound" in segment
            ? segment.new_quantity_upper_bound
            : segment.units_sold_upper_bound,
      };
    case "margin":
      return {
        lower: multiplyMaybeNumbers(
          "new_margin_lower_bound" in segment
            ? segment.new_margin_lower_bound
            : segment.margin_lower_bound,
          100,
        ),
        upper: multiplyMaybeNumbers(
          "new_margin_upper_bound" in segment
            ? segment.new_margin_upper_bound
            : segment.margin_upper_bound,
          100,
        ),
      };
    case "profit":
      return {
        lower:
          "new_profit_lower_bound" in segment
            ? segment.new_profit_lower_bound
            : segment.profit_lower_bound,
        upper:
          "new_profit_upper_bound" in segment
            ? segment.new_profit_upper_bound
            : segment.profit_upper_bound,
      };
    case "revenue":
      return {
        lower:
          "new_revenue_lower_bound" in segment
            ? segment.new_revenue_lower_bound
            : segment.revenue_lower_bound,
        upper:
          "new_revenue_upper_bound" in segment
            ? segment.new_revenue_upper_bound
            : segment.revenue_upper_bound,
      };
    default:
      return assertUnreachable(metric);
  }
}

function formatMetric(
  metric: ConfidenceDistributionMetricType,
  value: number,
  digits = 2,
): string {
  switch (metric) {
    case "quantity":
      return `${sign(value)}${formatNumberRounded(value, digits)}`;
    case "margin":
      return formatSignedPercent(value, 2);
    case "profit":
    case "revenue":
      return `${sign(value)}${formatCurrency(value, digits)}`;
    default:
      return assertUnreachable(metric);
  }
}

function formatAxisMetric(
  metric: ConfidenceDistributionMetricType,
  value: number,
  digits = 2,
): string {
  switch (metric) {
    case "quantity":
      const val = formatNumberRounded(roundToNearestHundredths(value));
      return `${sign(value)}${val}`;
    case "margin":
      return formatSignedPercent(value, 2);
    case "profit":
    case "revenue":
      return `${sign(value)}${formatCurrency(
        roundToNearestHundredths(value),
        digits,
      )}`;
    default:
      return assertUnreachable(metric);
  }
}

function ConfidenceIntervalsTooltip({
  active,
  metric,
  payload,
}: ConfidenceIntervalsTooltipProps) {
  const { tooltipStyle } = useRechartsStyles();
  if (!active || payload == null) {
    return null;
  }

  const { avg, count } = getFirst(payload)?.payload ?? {};
  if (count == null || avg == null) {
    return null;
  }

  return (
    <div className="rounded-sm border bg-card p-2" style={tooltipStyle}>
      <p>
        {count} simulations of {formatMetric(metric, avg)} {metric}
      </p>
    </div>
  );
}

type BoundType = "lower" | "upper";

type ConfidenceReferenceLineProps = {
  bound: BoundType;
  metric: ConfidenceDistributionMetricType;
  val: number;
};

export function ConfidenceReferenceLine(
  props: ConfidenceReferenceLineProps & RechartsReferenceLineProps,
) {
  const isDarkTheme = useIsDarkTheme();
  const textColor = isDarkTheme
    ? TAILWIND_COLORS.DARK.MUTED
    : TAILWIND_COLORS.LIGHT.TEXT;
  const textStyles: CSSProperties = {
    fill: textColor,
    fontSize: 10,
  };
  const { bound, metric, val, viewBox } = props;
  const x = viewBox.width + viewBox.x;
  const y = viewBox.y;
  const width = 36;
  const xPosition = width / 2;
  return (
    <g>
      <foreignObject height="100%" width={width} x={x - xPosition} y={y} />
      <text style={textStyles} x={x + 4} y={y + 12}>
        {capitalize(bound)} Bound
      </text>
      <text style={textStyles} x={x + 4} y={y + 26}>
        {formatMetric(metric, val, 0)}
      </text>
    </g>
  );
}

const MetricOptions: ConfidenceDistributionMetricType[] = [
  "quantity",
  "revenue",
  "profit",
  "margin",
];

type ConfidenceDistributionChartProps = {
  intervals: MaybeNull<PricePlanConfidenceIntervalType[]>;
  opportunity: MaybeNull<OpportunityMetricsResponseType[]>;
  results: MaybeNull<GroupResultsMetricsResponseType[]>;
  type: "opportunity" | "results";
};

export default function ConfidenceDistributionChart({
  intervals,
  opportunity,
  results,
  type,
}: ConfidenceDistributionChartProps) {
  const customerSegment = usePricePlanStore((state) => state.customerSegment);
  const selectedMetric = usePricePlanStore((state) => state.selectedMetric);
  const { axisColor, backgroundColor, fontFamily } = useRechartsStyles();
  const intervalSegment = (intervals ?? []).find(
    (val) => val.customer_segment == customerSegment,
  );
  const opportunitySegment = (opportunity ?? []).find(
    (val) => val.customer_segment == customerSegment,
  );
  const resultsSegment = (results ?? []).find(
    (val) => val.customer_segment == customerSegment,
  );
  if (
    intervalSegment == null ||
    (opportunitySegment == null && type === "opportunity") ||
    (resultsSegment == null && type === "results")
  ) {
    return (
      <Card className="flex-grow basis-0">
        <Centered className="h-full p-4">
          <p className="w-80 text-center font-semibold">
            No confidence distribution data currently exists for this price
            plan.
          </p>
        </Centered>
      </Card>
    );
  }

  const metricLabel = capitalize(selectedMetric);
  const intervalSegmentData = intervalSegment[selectedMetric];
  const data: ConfidenceIntervalsChartData[] = intervalSegmentData.map(
    ({ count, max, min }) => ({
      avg: avg([min, max]),
      count: count,
    }),
  );
  const confidenceBounds =
    type === "opportunity"
      ? getConfidenceBounds(opportunitySegment!, selectedMetric)
      : getConfidenceBounds(resultsSegment!, selectedMetric);
  return (
    <Card className="flex-grow basis-0 pt-2">
      <Row className="justify-between px-4 pt-2">
        <p className="font-semibold">
          Confidence Distribution - {metricLabel} Impact
        </p>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="hover:cursor-pointer">
            <div className="mr-1 flex items-center justify-start">
              <p className="text-sm">•••</p>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {MetricOptions.map((option) => {
              return (
                <DropdownMenuCheckboxItem
                  checked={selectedMetric === option}
                  className="hover:cursor-pointer"
                  key={option}
                  onClick={() =>
                    usePricePlanStore.setState({ selectedMetric: option })
                  }
                >
                  <Row className="w-36">
                    <p>{formatGenericEnumLabel(option)}</p>
                  </Row>
                </DropdownMenuCheckboxItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </Row>
      <ResponsiveContainer height={290} style={{ backgroundColor }} width="99%">
        <AreaChart
          data={data}
          height={290}
          margin={{
            bottom: 15,
            left: -15,
            right: 10,
            top: 15,
          }}
        >
          <XAxis
            dataKey="avg"
            domain={["dataMin", "dataMax"]}
            label={{
              dy: 20,
              style: { fontFamily, fontSize: 12 },
              value: `${metricLabel} Impact`,
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 10 }}
            tickCount={8}
            tickFormatter={(value) => {
              return formatAxisMetric(selectedMetric, value, 0);
            }}
            type="number"
          />
          <YAxis
            label={{
              angle: -90,
              dx: 10,
              style: { fontFamily, fontSize: 12 },
              value: "# of simulations",
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tick={false}
            tickFormatter={(value) => {
              return formatNumberRounded(value);
            }}
          />
          <Tooltip
            // @ts-ignore
            content={<ConfidenceIntervalsTooltip metric={selectedMetric} />}
          />
          <Area
            dataKey="count"
            fill={COLORS.PRIMARY}
            fillOpacity={0.25}
            stroke={COLORS.PRIMARY}
            strokeOpacity={0.85}
            strokeWidth={1.25}
            type="monotone"
          />
          {confidenceBounds.lower != null && (
            <ReferenceLine
              label={
                // @ts-ignore
                <ConfidenceReferenceLine
                  bound="lower"
                  metric={selectedMetric}
                  val={confidenceBounds.lower}
                  value="Price Plan launched"
                />
              }
              stroke={COLORS.MINT}
              strokeDasharray="2 2"
              strokeWidth={1.25}
              x={confidenceBounds.lower}
            />
          )}
          {confidenceBounds.upper != null && (
            <ReferenceLine
              label={
                // @ts-ignore
                <ConfidenceReferenceLine
                  bound="upper"
                  metric={selectedMetric}
                  val={confidenceBounds.upper}
                  value="Price Plan launched"
                />
              }
              stroke={COLORS.MINT}
              strokeDasharray="2 2"
              strokeWidth={1.25}
              x={confidenceBounds.upper}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
