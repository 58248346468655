import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import { Prompt } from "src/frontend/components/ui/Prompt";
import useToast from "src/frontend/components/ui/useToast";
import useGetPriceParityGroupsQuery from "src/frontend/hooks/queries/useGetPriceParityGroupsQuery";
import { PriceParitySummaryGroup } from "src/shared/trpc/common/PriceParitySummaryGroup";

type PriceParityGroupActiveToggleProps = {
  group: PriceParitySummaryGroup;
  isPromptOpen: boolean;
  setIsPromptOpen: (open: boolean) => void;
  trigger: React.ReactElement | string;
};

export default function PriceParityGroupActiveToggle({
  group,
  isPromptOpen,
  setIsPromptOpen,
  trigger,
}: PriceParityGroupActiveToggleProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { errorToast, successToast } = useToast();

  const priceParityGroupsQuery = useGetPriceParityGroupsQuery();
  const updatePriceParityGroupIsActiveMutation =
    TrpcClient.internal.updatePriceParityGroupIsActive.useMutation();

  const isActive = group?.is_active ?? false;
  const verb = isActive ? "Disable" : "Enable";

  const onSuccess = async () => {
    await priceParityGroupsQuery.refetch();
    setIsLoading(false);
    setIsPromptOpen(false);
    successToast("Price parity group updated successfully.");
  };

  const handleToggleIsActive = (group: PriceParitySummaryGroup) => {
    setIsLoading(true);
    updatePriceParityGroupIsActiveMutation.mutate(
      {
        isActive: !isActive,
        priceParityGroupId: group.id,
      },
      {
        onError: () => {
          errorToast("Failed to update price parity group.");
          setIsLoading(false);
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Prompt
      confirmText={`${verb} Rule`}
      description={
        <p>This change will be applied to all future price plans.</p>
      }
      loading={isLoading}
      onConfirm={() => handleToggleIsActive(group)}
      onOpenChange={setIsPromptOpen}
      open={isPromptOpen}
      title={`${verb} Price Parity Group Rule`}
      trigger={trigger}
    />
  );
}
