import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function usePricePlanQuery() {
  const { brandId, enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getPricePlans.useQuery(
    { brandId },
    {
      enabled,
    },
  );
}
