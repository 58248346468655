import MaybeString from "src/shared/types/maybe/MaybeString";
import { create } from "zustand";

export type StoreState = {
  isCreateModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isDuplicateModalOpen: boolean;
  isGenerateModalOpen: boolean;
  loading: boolean;
  pricingStrategyId: MaybeString;
};

const defaultState: StoreState = {
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  isDuplicateModalOpen: false,
  isGenerateModalOpen: false,
  loading: false,
  pricingStrategyId: null,
};

type StoreActions = {
  reset: () => void;
};

const usePricingStrategyDropdownStateStore = create<StoreActions & StoreState>(
  (set) => ({
    reset: (): void => {
      set(defaultState);
    },
    ...defaultState,
  }),
);

export default usePricingStrategyDropdownStateStore;
