import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ALL_SKUS_SELECT_VALUE from "src/frontend/constants/AllSkusSelectValue";
import {
  EXCLUSION_FILTER_IDS,
  INCLUDE_EMPTY_CATEGORY_ITEMS,
  INCLUDE_EMPTY_PRICE_ZONE_ITEMS,
  INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS,
  INCLUDE_EMPTY_SUB_CATEGORY_ITEMS,
  INCLUDE_EMPTY_SUPPLIER_ITEMS,
  INCLUDE_UNTAGGED_PRODUCTS_ITEM,
  SHOW_SKU_ACTIVE,
  SHOW_SKU_INACTIVE,
} from "src/frontend/constants/CustomProductFilters";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";

type ImpactedFilters = {
  allSkus: boolean;
  impactedCategories: string[];
  impactedPriceZones: string[];
  impactedProductBrandNames: string[];
  impactedSubCategories: string[];
  impactedSuppliers: string[];
  impactedTags: string[];
  includeEmptyCategory: boolean;
  includeEmptyPriceZone: boolean;
  includeEmptyProductBrandName: boolean;
  includeEmptySubCategory: boolean;
  includeEmptySupplier: boolean;
  includeUntaggedSkus: boolean;
  showActiveSkus: boolean;
  showInactiveSkus: boolean;
};

function filterIncludeEmptyItems(item: FancyBoxItem): boolean {
  return item.id == null || !EXCLUSION_FILTER_IDS.includes(item.id);
}

export default function parseImpactedSkusSelection(
  impactedSkus: FancyBoxItem[],
): ImpactedFilters {
  const allSkus = impactedSkus.some(
    (item) => item.value === ALL_SKUS_SELECT_VALUE,
  );
  const all_impacted = impactedSkus.filter(
    (item) => item.value !== ALL_SKUS_SELECT_VALUE,
  );

  const impactedCategories = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.Category)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.value);

  const impactedSubCategories = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.Subcategory)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.value);

  const impactedProductBrandNames = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.ProductBrandName)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.value);

  const impactedSuppliers = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.Supplier)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.value);

  const impactedPriceZones = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.PriceZone)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.value);

  const impactedTags = all_impacted
    .filter((item) => item.type === ProductCategoryFilterType.Tag)
    .filter(filterIncludeEmptyItems)
    .map((item) => item.id!);

  const showActiveSkus = arrayNotEmpty(
    all_impacted.filter((item) => item.id === SHOW_SKU_ACTIVE),
  );
  const showInactiveSkus = arrayNotEmpty(
    all_impacted.filter((item) => item.id === SHOW_SKU_INACTIVE),
  );

  const includeUntaggedSkus = all_impacted.some(
    (item) => item.id === INCLUDE_UNTAGGED_PRODUCTS_ITEM.id,
  );
  const includeEmptyCategory = all_impacted.some(
    (item) => item.id === INCLUDE_EMPTY_CATEGORY_ITEMS.id,
  );
  const includeEmptySubCategory = all_impacted.some(
    (item) => item.id === INCLUDE_EMPTY_SUB_CATEGORY_ITEMS.id,
  );
  const includeEmptySupplier = all_impacted.some(
    (item) => item.id === INCLUDE_EMPTY_SUPPLIER_ITEMS.id,
  );
  const includeEmptyProductBrandName = all_impacted.some(
    (item) => item.id === INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS.id,
  );
  const includeEmptyPriceZone = all_impacted.some(
    (item) => item.id === INCLUDE_EMPTY_PRICE_ZONE_ITEMS.id,
  );

  return {
    allSkus,
    impactedCategories,
    impactedPriceZones,
    impactedProductBrandNames,
    impactedSubCategories,
    impactedSuppliers,
    impactedTags,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
    showActiveSkus,
    showInactiveSkus,
  };
}
