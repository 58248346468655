import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import MaybeString from "src/shared/types/maybe/MaybeString";
import pricingConstraintIncludePriceBounds from "src/shared/utils/constraints/pricingConstraintIncludePriceBounds";
import getGenericPriceBoundsErrorMessage from "src/shared/validation/getGenericPriceBoundsErrorMessage";

export default function getConstraintErrorMessage(
  constraint: PricingConstraintType,
): MaybeString {
  if (!pricingConstraintIncludePriceBounds(constraint.type)) {
    return null;
  }

  return getGenericPriceBoundsErrorMessage(constraint);
}
