import { ProductCompetitorMatches } from "src/shared/trpc/common/ProductCompetitorMatches";
import { ProductMasterObject } from "src/shared/trpc/common/ProductMasterObject";
import { z } from "zod";

export const ProductMasterWithCompetitorMatches = ProductMasterObject.merge(
  ProductCompetitorMatches,
);

export type ProductMasterWithCompetitorMatchesType = z.infer<
  typeof ProductMasterWithCompetitorMatches
>;
