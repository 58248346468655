import SummaryMetricsSkeleton from "src/frontend/components/skeletons/SummaryMetricsSkeleton";
import useGetGroupSummaryQuery from "src/frontend/hooks/queries/useGetGroupSummaryQuery";
import GroupSummaryMetrics from "src/frontend/pages/pricing/summary/GroupSummaryMetrics";

export default function GroupSummary() {
  const getGroupSummaryQuery = useGetGroupSummaryQuery();
  if (getGroupSummaryQuery.isLoading) {
    return <SummaryMetricsSkeleton />;
  }

  if (getGroupSummaryQuery.error) {
    return (
      <div className="my-4">
        <p>Failed to load the group summary metrics.</p>
      </div>
    );
  }

  const { data } = getGroupSummaryQuery;
  if (data == null) {
    return null;
  }

  return (
    <GroupSummaryMetrics
      className="mt-6"
      groupDetails={data.group_details}
      isDetailView
      metrics={data}
    />
  );
}
