import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import getColorForProductCategoryFilterType from "src/frontend/utils/getColorForProductCategoryFilterType";

export default function brandProductCategoryToFancyBoxItem(
  value: string,
  type: ProductCategoryFilterType,
): FancyBoxItem {
  const label = value;
  const color = getColorForProductCategoryFilterType(type);
  return {
    color,
    label,
    type,
    value,
  };
}
