"use client";

import posthog from "posthog-js";
import { PostHogProvider as PHProvider } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import invariant from "tiny-invariant";

const NEXT_PUBLIC_POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const NEXT_PUBLIC_POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST;
invariant(
  NEXT_PUBLIC_POSTHOG_KEY != null && NEXT_PUBLIC_POSTHOG_HOST != null,
  "PostHog credentials should exist.",
);

if (typeof window !== "undefined") {
  posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
  });
}

export function PostHogPageView(): null {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname) {
      const url = window.origin + pathname;
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  }, [pathname]);

  return null;
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PHProvider client={posthog}>{children}</PHProvider>;
}
