import { customer_segment } from "@prisma/client";
import { z } from "zod";

export const CustomerSegment = z.nativeEnum(customer_segment);

export type CustomerSegmentType = z.infer<typeof CustomerSegment>;

export const CustomerSegmentVariants = Object.keys(
  CustomerSegment.enum,
) as CustomerSegmentType[];
