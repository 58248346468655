import { StrategyObjectiveType } from "src/shared/trpc/common/StrategyObjective";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { PricingStrategyObjectiveType } from "src/shared/trpc/common/enum/PricingStrategyObjectiveType";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import isNumber from "src/shared/utils/numbers/isNumber";
import uuid from "src/shared/utils/uuid";
import { create } from "zustand";

type StoreState = Omit<StrategyObjectiveType, "modeling_price" | "type"> & {
  lower_bound_input: string;
  modeling_price: MaybeUndefined<PriceTargetType>;
  type: MaybeUndefined<PricingStrategyObjectiveType>;
  upper_bound_input: string;
};

type StoreActions = {
  convertInput: () => StrategyObjectiveType;
  reset: () => void;
};

const defaultState: StoreState = {
  bound_type: "PERCENT",
  constraint_type: "MARGIN",
  id: uuid(),
  lower_bound: null,
  lower_bound_input: "",
  modeling_price: undefined,
  tradeoff: null,
  type: undefined,
  upper_bound: null,
  upper_bound_input: "",
};

function convertInputToBound(input_value: string): MaybeNumber {
  return isNumber(input_value) ? Number(input_value) : null;
}

const useStrategyObjectiveStore = create<StoreActions & StoreState>(
  (set, get) => ({
    ...defaultState,
    convertInput: () => {
      const state = get();
      const result = {
        ...state,
        lower_bound: convertInputToBound(state.lower_bound_input),
        upper_bound: convertInputToBound(state.upper_bound_input),
      };
      return result as StrategyObjectiveType;
    },
    reset: () => set(defaultState),
  }),
);

export default useStrategyObjectiveStore;
