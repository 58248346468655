import { ChevronRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import ChangeDirectionIcon from "src/frontend/components/ChangeDirectionIcon";
import Code from "src/frontend/components/Code";
import ConfidenceScore from "src/frontend/components/ConfidenceScore";
import LightLabel from "src/frontend/components/LightLabel";
import LucaContextMenu, {
  AdminMenuActionItem,
} from "src/frontend/components/admin/LucaContextMenu";
import Button from "src/frontend/components/ui/Button";
import CardWithContent from "src/frontend/components/ui/Card";
import useToast from "src/frontend/components/ui/useToast";
import useBrandId from "src/frontend/hooks/useBrandId";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import GroupSummaryLabel from "src/frontend/pages/pricing/GroupSummaryLabel";
import useAdminDeleteGroupStore from "src/frontend/stores/useAdminDeleteGroupStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import copyTextToClipboard from "src/frontend/utils/copyTextToClipboard";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { GetPricePlanGroupsQueryResponseTypeV2 } from "src/shared/trpc/queries/getPricePlanGroupsQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import ifNotNull from "src/shared/utils/ifNotNull";

type PricePlanGroupCardProps = {
  group: GetPricePlanGroupsQueryResponseTypeV2;
  onClick: () => void;
};

export default function PricePlanGroupCard({
  group,
  onClick,
}: PricePlanGroupCardProps) {
  const { successToast } = useToast();
  const tab = usePricePlanTab();
  const location = useLocation();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const brandId = useBrandId();

  const adminActions: MaybeNull<AdminMenuActionItem>[] = [
    tab === "draft"
      ? {
          fn: () => {
            useAdminDeleteGroupStore.setState({
              groupIds: [group.group_id],
              isModalOpen: true,
            });
          },
          label: (
            <span>
              Delete <Code>DRAFT</Code> Group
            </span>
          ),
        }
      : null,
    {
      fn: async () => {
        if (pricePlanId != null) {
          await copyTextToClipboard(pricePlanId);
          successToast(
            <span>
              Copied <Code>{pricePlanId}</Code>.
            </span>,
          );
        }
      },
      label: (
        <span>
          Copy <Code>price_plan_id</Code>
        </span>
      ),
    },
    {
      fn: async () => {
        await copyTextToClipboard(group.group_id);
        successToast(
          <span>
            Copied <Code>{group.group_id}</Code>.
          </span>,
        );
      },
      label: (
        <span>
          Copy <Code>group_id</Code>
        </span>
      ),
    },
    {
      fn: async () => {
        if (brandId != null) {
          await copyTextToClipboard(brandId);
          successToast(
            <span>
              Copied <Code>{brandId}</Code>.
            </span>,
          );
        }
      },
      label: (
        <span>
          Copy <Code>brand_id</Code>
        </span>
      ),
    },
  ];

  const devActions: MaybeNull<AdminMenuActionItem>[] = [];

  return (
    <LucaContextMenu adminActions={adminActions} devActions={devActions}>
      <Link
        onClick={onClick}
        state={{ opportunity: group }}
        to={`${location.pathname}/${group.group_id}`}
      >
        <CardWithContent className="cursor-pointer">
          <div className="flex flex-row justify-between gap-8 pt-8">
            <div className="flex w-full flex-col justify-between gap-4 xl:flex-row xl:gap-0">
              <div className="flex flex-col lg:w-[245px] lg:pr-8">
                <LightLabel>Product Group</LightLabel>
                <p className="text-md font-normal leading-snug xl:text-lg">
                  {group.title}
                </p>
                <LightLabel>{group.impacted_skus} SKUs</LightLabel>
              </div>
              <div className="flex flex-row gap-4 lg:w-[440px]">
                <div className="flex flex-col gap-1">
                  <div className="items-col relative flex flex-col">
                    <ChangeDirectionIcon
                      className="-left-10 top-2 hidden flex-shrink-0 xl:absolute xl:flex"
                      priceChangePercent={group.price_change_percent}
                    />
                    <LightLabel>Avg Price Change</LightLabel>
                    <div className="items-center">
                      <p className="text-lg font-normal leading-snug">
                        {formatSignedPercent(group.price_change_percent)}
                      </p>
                    </div>
                  </div>
                  <GroupSummaryLabel group={group} />
                </div>
              </div>
              <div className="flex lg:w-[100px] xl:justify-end">
                {tab === "draft" && (
                  <div className="items-col flex flex-col">
                    <LightLabel>Confidence</LightLabel>
                    <ConfidenceScore
                      confidence={ifNotNull(
                        group.confidence_score,
                        (val) => val * 10,
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <Button
              className="inline-flex h-12 items-center justify-center self-center rounded-full p-2"
              variant="ghost"
            >
              <ChevronRight size={36} strokeWidth={3} />
            </Button>
          </div>
        </CardWithContent>
      </Link>
    </LucaContextMenu>
  );
}
