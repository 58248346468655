import PageContainer from "src/frontend/components/PageContainer";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type ErrorPageProps = HtmlDivProps & {
  children?: React.ReactNode;
  enforceMaxWidth?: boolean;
  label?: string;
};

export default function ErrorPage({
  children,
  className,
  enforceMaxWidth,
  label,
  ...rest
}: ErrorPageProps) {
  return (
    <PageContainer
      className={className}
      enforceMaxWidth={enforceMaxWidth}
      {...rest}
    >
      {label ?? children}
    </PageContainer>
  );
}
