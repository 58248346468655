import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";

export default function formatSignedPercent(
  pct: MaybeNumber | MaybeString,
  digits = 2,
): string {
  if (pct == null) {
    return "--";
  }

  const prefix = Number(pct) >= 0 ? "+" : "";
  const value = roundToPrecision(Number(pct), digits);
  return `${prefix}${value}%`;
}
