import { ChevronLeft, ChevronRight } from "lucide-react";
import Row from "src/frontend/components/Row";
import ThreeDotsHorizontal from "src/frontend/components/ThreeDotsHorizontal";
import Button from "src/frontend/components/ui/Button";
import { cn } from "src/frontend/components/ui/utils";
import range from "src/shared/utils/arrays/range";

type PaginationRange = {
  hasMore: boolean;
  hasPrev: boolean;
  pageRange: number[];
  showFinalPage: boolean;
  showStartingPage: boolean;
};

type GetPaginationRangeArgs = {
  currentPage: number;
  numberOfPages: number;
};

function getPaginationRange({
  currentPage,
  numberOfPages,
}: GetPaginationRangeArgs): PaginationRange {
  const hasMore = currentPage < numberOfPages;
  const hasPrev = currentPage > 1;

  let showStartingPage = false;
  let showFinalPage = numberOfPages > 3;

  let pageRange: number[] = [];
  if (currentPage <= 2) {
    pageRange = range(Math.min(3, numberOfPages)).map((val) => val + 1);
  } else if (currentPage >= numberOfPages - 2) {
    showStartingPage = true;
    showFinalPage = false;
    pageRange = [numberOfPages - 2, numberOfPages - 1, numberOfPages];
  } else {
    showStartingPage = true;
    pageRange = [currentPage - 1, currentPage, currentPage + 1];
  }

  return {
    hasMore,
    hasPrev,
    pageRange,
    showFinalPage,
    showStartingPage,
  };
}

type PaginationControlsProps = {
  currentPage: number;
  disabled?: boolean;
  hidePageButtons?: boolean;
  pageSize: number;
  setPage: (page: number) => void;
  showAllPages?: boolean;
  totalCount: number;
};

export default function PaginationControls({
  currentPage,
  disabled = false,
  hidePageButtons = false,
  pageSize,
  setPage,
  showAllPages = false,
  totalCount,
}: PaginationControlsProps) {
  const numberOfPages = Math.ceil(totalCount / pageSize);
  const { hasMore, hasPrev, pageRange, showFinalPage, showStartingPage } =
    getPaginationRange({ currentPage, numberOfPages });
  const pageRangeToUse = showAllPages
    ? range(totalCount).map((val) => val + 1)
    : pageRange;
  return (
    <Row className="gap-2">
      <Button
        className="mr-1"
        disabled={!hasPrev || disabled}
        onClick={() => setPage(currentPage - 1)}
        size="circle"
        variant="ghost"
      >
        <ChevronLeft />
      </Button>
      {showStartingPage && !showAllPages && (
        <>
          <Button
            disabled={disabled}
            onClick={() => setPage(1)}
            size="sm"
            variant="circle"
          >
            1
          </Button>
          <ThreeDotsHorizontal />
        </>
      )}
      {!hidePageButtons &&
        pageRangeToUse.map((pageNumber) => {
          const active = pageNumber === currentPage;
          return (
            <Button
              className={cn(active && "bg-muted/75 font-bold")}
              disabled={disabled || active}
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              size="sm"
              variant="circle"
            >
              {pageNumber}
            </Button>
          );
        })}
      {showFinalPage && !showAllPages && (
        <>
          <ThreeDotsHorizontal />
          <Button
            disabled={disabled}
            onClick={() => setPage(numberOfPages)}
            size="sm"
            variant="circle"
          >
            {numberOfPages}
          </Button>
        </>
      )}
      <Button
        className="ml-1"
        disabled={!hasMore || disabled}
        onClick={() => setPage(currentPage + 1)}
        size="circle"
        variant="ghost"
      >
        <ChevronRight />
      </Button>
    </Row>
  );
}
