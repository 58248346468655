/* eslint-disable sort-keys-fix/sort-keys-fix */
// Values from: https://tailwindcss.com/docs/screens
const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export default BREAKPOINTS;
