import { CSSProperties } from "react";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import getTailwindColor from "src/frontend/utils/getTailwindColor";

type RechartsStyles = {
  axisColor: string;
  backgroundColor: string;
  fontFamily: string;
  gridColor: string;
  tooltipStyle: CSSProperties;
};

export default function useRechartsStyles(): RechartsStyles {
  const isDarkTheme = useIsDarkTheme();
  const fontFamily = "Nunito";
  const axisColor = getTailwindColor("CHART_AXIS", isDarkTheme);
  const gridColor = getTailwindColor("BORDER", isDarkTheme);
  const backgroundColor = getTailwindColor("CARD", isDarkTheme);
  const tooltipStyle: CSSProperties = {
    filter: "brightness(98%)",
  };
  const styles: RechartsStyles = {
    axisColor,
    backgroundColor,
    fontFamily,
    gridColor,
    tooltipStyle,
  };
  return styles;
}
