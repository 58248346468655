import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function getColorForProductCategoryFilterType(
  type: ProductCategoryFilterType,
): string {
  switch (type) {
    case ProductCategoryFilterType.AllSkus:
    case ProductCategoryFilterType.Category:
      return "#7D66D9";
    case ProductCategoryFilterType.Subcategory:
      return "#0EB39E";
    case ProductCategoryFilterType.ProductBrandName:
      return "#EC6142";
    case ProductCategoryFilterType.CustomSkusSelection:
      return "#DE51A8";
    case ProductCategoryFilterType.PriceZone:
      return "##FFC53D";
    case ProductCategoryFilterType.Tag:
      return "#2563eb";
    case ProductCategoryFilterType.Supplier:
      return "#00B2FF";
    default:
      return assertUnreachable(type);
  }
}
