import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "src/frontend/components/ui/Tabs";
import getCompanySettingsTabFromLocation from "src/frontend/utils/getCompanySettingsTabFromLocation";
import CompanySettingsTab from "src/shared/types/CompanySettingsTab";

export default function GoalsAndSettingsTabs() {
  const location = useLocation();
  const [tab, setTab] = useState(getCompanySettingsTabFromLocation(location));
  useEffect(() => {
    setTab(getCompanySettingsTabFromLocation(location));
  }, [location, setTab]);
  return (
    <PageContainer enforceMaxWidth>
      <PageTitle>Goals and Settings</PageTitle>
      <Tabs
        onValueChange={(val) => setTab(val as CompanySettingsTab)}
        value={tab}
      >
        <TabsList
          className="text-s my-8 flex justify-center gap-4 border-b md:justify-start md:gap-14 md:text-base"
          variant="ghost"
        >
          <TabsTrigger value="Strategy" variant="ghost">
            <Link to="strategy">Strategy</Link>
          </TabsTrigger>
          <TabsTrigger value="Relationships" variant="ghost">
            <Link to="relationships">Relationships</Link>
          </TabsTrigger>
          <TabsTrigger value="Price Settings" variant="ghost">
            <Link to="price-settings">Price Settings</Link>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="Strategy">
          <Outlet />
        </TabsContent>
        <TabsContent value="Relationships">
          <Outlet />
        </TabsContent>
        <TabsContent value="Price Settings">
          <Outlet />
        </TabsContent>
      </Tabs>
    </PageContainer>
  );
}
