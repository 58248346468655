import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import {
  getUnitOfMeasurement,
  isWeightUnitOfMeasurement,
} from "src/shared/constants/UnitOfMeasurementMapping";
import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { ProductMasterObjectType } from "src/shared/trpc/common/ProductMasterObject";
import { Maybe } from "src/shared/types/maybe/Maybe";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import getCompetitorUnits from "src/shared/utils/getCompetitorUnits";
import { getFormattedFinalPricePerUnit } from "src/shared/utils/getFormattedFinalPricePerUnit";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import numberOrNull from "src/shared/utils/numbers/numberOrNull";
import calculateFinalPrice from "../../../shared/utils/calculateFinalPrice";

function getCompetitorPriceDelta(
  competitorPrice: Maybe<number>,
  brandPrice: Maybe<number>,
): MaybeNumber {
  if (competitorPrice == null || brandPrice == null) {
    return null;
  }

  const diff = competitorPrice - brandPrice;
  return (diff / brandPrice) * 100;
}

type CompetitorDetailComparisonTableProps = {
  competitor: CompetitorMatchWithAveragePricesType;
  product: ProductMasterObjectType;
};

function getFormattedCompetitorPrice(competitorPrice: number | null): string {
  return competitorPrice == null ? "--" : formatCurrency(competitorPrice);
}

function getFormattedPriceDelta(
  competitorPrice: MaybeNumber,
  brandPrice: MaybeNumber,
): MaybeString {
  const delta = getCompetitorPriceDelta(competitorPrice, brandPrice);
  if (delta == null) {
    return null;
  }

  return ` (${formatSignedPercent(
    getCompetitorPriceDelta(competitorPrice, brandPrice),
  )})`;
}

export default function CompetitorDetailComparisonTable({
  competitor,
  product,
}: CompetitorDetailComparisonTableProps) {
  const {
    discounted_price,
    int_product_attributes,
    list_price,
    member_price,
    product_brand_name,
    product_name,
    product_url,
    subscriber_price,
  } = product;

  const hasMemberPrice =
    member_price != null || competitor.competitor_member_price != null;
  const hasDiscountedPrice =
    discounted_price != null || competitor.competitor_discounted_price != null;
  const hasSubscriberPrice =
    subscriber_price != null || competitor.competitor_subscriber_price != null;

  const brandQuantity = numberOrNull(int_product_attributes?.weight);
  const brandCount = numberOrNull(int_product_attributes?.count);
  const brandFinalPrice = calculateFinalPrice([
    list_price,
    discounted_price,
    member_price,
    subscriber_price,
  ]);
  const brandUnit =
    (int_product_attributes?.weight_unit as string | undefined) ?? "";
  const formattedBrandFinalPricePerUnit = getFormattedFinalPricePerUnit({
    count: brandCount,
    prices: [brandFinalPrice],
    quantity: brandQuantity,
    unit: brandUnit,
  });

  const competitorFinalPrice = calculateFinalPrice([
    competitor.competitor_list_price,
    competitor.competitor_discounted_price,
    competitor.competitor_member_price,
    competitor.competitor_subscriber_price,
  ]);

  const {
    countValue: competitorCountValue,
    weightUom: competitorWeightUom,
    weightValue: competitorWeightValue,
  } = getCompetitorUnits({
    quantity: competitor.quantity,
    secondaryQuantity: competitor.secondary_quantity,
    secondaryUom: competitor.secondary_unit_of_measurement,
    uom: competitor.unit_of_measurement,
  });

  const formattedCompetitorFinalPricePerUnit = getFormattedFinalPricePerUnit({
    count: competitorCountValue,
    prices: [competitorFinalPrice],
    quantity: competitorWeightValue,
    unit: competitorWeightUom ?? "",
  });

  const productUnitOfMeasurement = isWeightUnitOfMeasurement(
    int_product_attributes?.unit_of_measurement ?? null,
  )
    ? getUnitOfMeasurement(int_product_attributes?.unit_of_measurement ?? null)
    : int_product_attributes?.unit_of_measurement ?? null;

  const competitorUnitOfMeasurement = isWeightUnitOfMeasurement(
    competitor.unit_of_measurement,
  )
    ? getUnitOfMeasurement(competitor.unit_of_measurement)
    : competitor.unit_of_measurement ?? null;

  return (
    <Table className="overflow-auto rounded-md border border-n-100">
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-44 bg-n-50" colSpan={1} />
          <TableHeaderCell
            className="bg-n-50"
            colSpan={1}
            style={{ maxWidth: 250 }}
          >
            <p className="text-center font-bold text-n-800">My Brand</p>
          </TableHeaderCell>
          <TableHeaderCell
            className="bg-n-50"
            colSpan={1}
            style={{ maxWidth: 250 }}
          >
            <p className="text-center font-bold text-n-800">
              {competitor.competitor_name}
            </p>
          </TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        <tr>
          <TableDataCell className="py-2 font-semibold">
            Product name
          </TableDataCell>
          <TableDataCell
            className="text-wrap truncate-none whitespace-break-spaces text-center text-muted-foreground"
            maxWidth="max-w-[225px]"
          >
            {product_url == null ? (
              <p className="text-wrap whitespace-break-spaces text-center">
                {product_name ?? "--"}
              </p>
            ) : (
              <p className="text-wrap text-center">
                <a
                  className="text-balance whitespace-normal text-primary hover:underline"
                  href={product_url}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                >
                  {product_name}
                </a>
              </p>
            )}
          </TableDataCell>
          <TableDataCell
            className="text-wrap truncate-none whitespace-normal text-muted-foreground"
            maxWidth="max-w-[225px]"
          >
            {competitor.competitor_link == null ? (
              <p className="whitespace-break-spaces text-center">
                {competitor.competitor_product_name ?? "--"}
              </p>
            ) : (
              <p className="whitespace-break-spaces text-center">
                <a
                  className="text-primary hover:underline"
                  href={competitor.competitor_link}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                >
                  {competitor.competitor_product_name ?? "--"}
                </a>
              </p>
            )}
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 font-semibold">
            Product brand
          </TableDataCell>
          <TableDataCell
            className="whitespace-break-spaces text-center text-muted-foreground"
            style={{ width: 225 }}
          >
            <p>{product_brand_name ?? "--"}</p>
          </TableDataCell>
          <TableDataCell
            className="whitespace-break-spaces text-center text-muted-foreground"
            style={{ width: 225 }}
          >
            <p>{competitor.competitor_product_brand ?? "--"}</p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 pb-0 font-semibold">
            Final Price
          </TableDataCell>
          <TableDataCell className="pb-0 text-center text-muted-foreground">
            <p>{formatCurrency(brandFinalPrice)}</p>
          </TableDataCell>
          <TableDataCell className="pb-0 text-center text-muted-foreground">
            <p>
              {getFormattedCompetitorPrice(competitorFinalPrice)}
              <span className="text-xs">
                {getFormattedPriceDelta(competitorFinalPrice, brandFinalPrice)}
              </span>
            </p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-0 pl-6 leading-3">
            List Price
          </TableDataCell>
          <TableDataCell className="py-0 text-center text-muted-foreground">
            <p className="leading-3">{formatCurrency(list_price)}</p>
          </TableDataCell>
          <TableDataCell className="py-0 text-center  text-muted-foreground">
            <p className="leading-3">
              {getFormattedCompetitorPrice(competitor.competitor_list_price)}
              <span className="text-xs">
                {getFormattedPriceDelta(
                  competitor.competitor_list_price,
                  list_price,
                )}
              </span>
            </p>
          </TableDataCell>
        </tr>
        {hasDiscountedPrice && (
          <tr>
            <TableDataCell className="py-0 pl-6 leading-3">
              Discounted Price
            </TableDataCell>
            <TableDataCell className="py-0 text-center text-muted-foreground">
              <p>{formatCurrency(discounted_price)}</p>
            </TableDataCell>
            <TableDataCell className="py-0 text-center text-muted-foreground">
              <p>
                {getFormattedCompetitorPrice(
                  competitor.competitor_discounted_price,
                )}
                <span className="text-xs">
                  {getFormattedPriceDelta(
                    competitor.competitor_discounted_price,
                    discounted_price,
                  )}
                </span>
              </p>
            </TableDataCell>
          </tr>
        )}
        {hasMemberPrice && (
          <tr>
            <TableDataCell className="py-0 pl-6 leading-3">
              Member Price
            </TableDataCell>
            <TableDataCell className="py-0 text-center  text-muted-foreground">
              <p>{formatCurrency(member_price)}</p>
            </TableDataCell>
            <TableDataCell className="py-0 text-center text-muted-foreground">
              <p>
                {getFormattedCompetitorPrice(
                  competitor.competitor_member_price,
                )}
                <span className="text-xs">
                  {getFormattedPriceDelta(
                    competitor.competitor_member_price,
                    member_price,
                  )}
                </span>
              </p>
            </TableDataCell>
          </tr>
        )}
        {hasSubscriberPrice && (
          <tr>
            <TableDataCell className="py-0 pl-6 leading-3">
              Subscriber Price
            </TableDataCell>
            <TableDataCell className="py-0 text-center text-muted-foreground">
              <p>{formatCurrency(subscriber_price)}</p>
            </TableDataCell>
            <TableDataCell className="py-0 text-center text-muted-foreground">
              <p>
                {getFormattedCompetitorPrice(
                  competitor.competitor_subscriber_price,
                )}
                <span className="text-xs">
                  {getFormattedPriceDelta(
                    competitor.competitor_subscriber_price,
                    subscriber_price,
                  )}
                </span>
              </p>
            </TableDataCell>
          </tr>
        )}
        <tr>
          <TableDataCell className="py-2 font-semibold">In Stock</TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>--</p>
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{competitor.in_stock ? "Yes" : "No"}</p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 font-semibold">
            Unit Size
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>
              {brandQuantity ?? "--"} {getUnitOfMeasurement(brandUnit)}
            </p>
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>
              {competitorWeightValue ?? "--"}{" "}
              {getUnitOfMeasurement(competitorWeightUom)}
            </p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 font-semibold">Count</TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{int_product_attributes?.count ?? "--"}</p>
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{competitorCountValue ?? "--"}</p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 font-semibold">
            Final Price Per Unit
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{formattedBrandFinalPricePerUnit}</p>
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{formattedCompetitorFinalPricePerUnit}</p>
          </TableDataCell>
        </tr>
        <tr>
          <TableDataCell className="py-2 font-semibold">Sold By</TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{productUnitOfMeasurement ?? "--"}</p>
          </TableDataCell>
          <TableDataCell className="text-center text-muted-foreground">
            <p>{competitorUnitOfMeasurement ?? "--"}</p>
          </TableDataCell>
        </tr>
      </tbody>
    </Table>
  );
}
