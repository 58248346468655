import { z } from "zod";

const FinalTreatmentPriceFields = z.object({
  final_treatment_discounted_price: z.number().nullable(),
  final_treatment_list_price: z.number().nullable(),
  final_treatment_member_price: z.number().nullable(),
  final_treatment_subscriber_price: z.number().nullable(),
});

export type FinalTreatmentPriceFieldsType = z.infer<
  typeof FinalTreatmentPriceFields
>;

export default FinalTreatmentPriceFields;
