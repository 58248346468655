import { ArrowDown, ArrowUp } from "lucide-react";
import { cn } from "src/frontend/components/ui/utils";
import ChangeDirection from "src/frontend/types/ChangeDirection";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import { Maybe } from "src/shared/types/maybe/Maybe";

type ChangeDirectionIconProps = HtmlDivProps & {
  priceChangePercent: Maybe<number>;
};

export default function ChangeDirectionIcon(props: ChangeDirectionIconProps) {
  const { className, priceChangePercent } = props;

  const direction: ChangeDirection =
    Number(priceChangePercent) >= 0 ? "increase" : "decrease";

  if (direction === "increase") {
    return (
      <div
        className={cn(
          "flex h-8 w-8 items-center justify-center self-center rounded-full bg-blue-600",
          className,
        )}
      >
        <ArrowUp color="white" strokeWidth={3} />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "flex h-8 w-8 items-center justify-center self-center rounded-full bg-purple-800",
        className,
      )}
    >
      <ArrowDown color="white" strokeWidth={3} />
    </div>
  );
}
