import { useEffect, useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import useFlags from "src/frontend/hooks/useFlags";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import useNavigateInterruptPromptStore from "src/frontend/stores/useNavigateInterrupt";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

const CLIENT_BUILD_HASH = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
const REFETCH_INTERVAL_MS = 1_000 * 60 * 5; // 5 minutes
const BACKGROUND_REFRESH_DELAY_MS = 250; // Slightly delay the reload event after the window is backgrounded.

export default function useBackgroundRefresh() {
  const { enabled } = useIsBrandInitialized();
  const [refreshTimeout, setRefreshTimeout] =
    useState<MaybeNull<NodeJS.Timeout>>(null);
  const [clientIsStale, setClientIsStale] = useState(false);
  const [isWindowHidden, setIsWindowHidden] = useState(false);
  const { backgroundRefreshEnabled } = useFlags();
  const navigateInterruptMounted = useNavigateInterruptPromptStore(
    (state) => state.navigateInterruptMounted,
  );
  const isAppBuildStaleQuery = TrpcClient.internal.getIsAppBuildStale.useQuery(
    {
      clientBuildHash: CLIENT_BUILD_HASH!,
    },
    {
      enabled:
        enabled &&
        !clientIsStale &&
        backgroundRefreshEnabled &&
        !navigateInterruptMounted &&
        CLIENT_BUILD_HASH !== "" &&
        CLIENT_BUILD_HASH != null,
      refetchInterval: REFETCH_INTERVAL_MS,
    },
  );

  useEffect(() => {
    const windowVisibilityListener = () => {
      setIsWindowHidden(document.hidden);
    };

    document.addEventListener("visibilitychange", windowVisibilityListener);
    return () => {
      document.removeEventListener(
        "visibilitychange",
        windowVisibilityListener,
      );
    };
  }, [isAppBuildStaleQuery]);

  useEffect(() => {
    if (isAppBuildStaleQuery.data?.stale === true) {
      setClientIsStale(true);
    }
  }, [isAppBuildStaleQuery]);

  useEffect(() => {
    if (!isWindowHidden && refreshTimeout != null) {
      clearTimeout(refreshTimeout);
      setRefreshTimeout(null);
    }
  }, [isWindowHidden, refreshTimeout]);

  useEffect(() => {
    if (backgroundRefreshEnabled && clientIsStale && isWindowHidden) {
      const timer = setTimeout(() => {
        location.reload();
      }, BACKGROUND_REFRESH_DELAY_MS);
      setRefreshTimeout(timer);
      return () => {
        clearTimeout(timer);
        setRefreshTimeout(null);
      };
    }
  }, [backgroundRefreshEnabled, clientIsStale, isWindowHidden]);
}
