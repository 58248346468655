import ReactMarkdown from "react-markdown";

type MarkdownProps = {
  children: string;
};

export default function Markdown({ children }: MarkdownProps) {
  return (
    <article className="prose dark:prose-invert">
      <ReactMarkdown>{children}</ReactMarkdown>
    </article>
  );
}
