import { CustomerSegment } from "src/shared/trpc/common/enum/CustomerSegment";
import { z } from "zod";

export const BaseResultsMetrics = z.object({
  customer_segment: CustomerSegment.nullable(),
  profit_change: z.number().nullable(),
  profit_change_percent: z.number().nullable(),
  profit_control: z.number().nullable(),
  profit_treatment: z.number().nullable(),
  revenue_change: z.number().nullable(),
  revenue_change_percent: z.number().nullable(),
  revenue_control: z.number().nullable(),
  revenue_treatment: z.number().nullable(),
  units_sold_change: z.number().nullable(),
  units_sold_change_percent: z.number().nullable(),
  units_sold_control: z.number().nullable(),
  units_sold_treatment: z.number().nullable(),
});

export type BaseResultsMetricsType = z.infer<typeof BaseResultsMetrics>;
