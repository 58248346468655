import { useEffect, useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import { RadioGroupOptions } from "src/frontend/components/ui/RadioGroup";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useGetUserBrandsQuery from "src/frontend/hooks/queries/useGetUserBrandsQuery";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import useBrandStore from "src/frontend/stores/useBrandStore";
import findBrandById from "src/frontend/utils/findBrandById";
import {
  PricePlanFrequencyType,
  PricePlanFrequencyVariants,
} from "src/shared/trpc/common/enum/PricePlanFrequency";
import capitalize from "src/shared/utils/strings/capitalize";

function formatPricePlanFrequency(frequency: PricePlanFrequencyType): string {
  return frequency
    .toLowerCase()
    .split("_")
    .map((val) => capitalize(val))
    .join("-");
}

type InnerProps = {
  isBrandAdmin: boolean;
  pricePlanFrequency: PricePlanFrequencyType;
};

function Inner({ isBrandAdmin, pricePlanFrequency }: InnerProps) {
  const { errorToast, successToast } = useToast();
  const brandsQuery = useGetUserBrandsQuery();
  const [pricePlanFrequencySetting, setPricePlanFrequencySetting] =
    useState(pricePlanFrequency);
  const updateBrandPricePlanFrequencyMutation =
    TrpcClient.internal.updateBrandPricePlanFrequency.useMutation();

  useEffect(() => {
    setPricePlanFrequencySetting(pricePlanFrequency);
  }, [pricePlanFrequency]);

  const handleSetPricePlanFrequencySetting = (
    frequency: PricePlanFrequencyType,
  ) => {
    updateBrandPricePlanFrequencyMutation.mutate(
      {
        pricePlanFrequency: frequency,
      },
      {
        onError: () => {
          errorToast("Failed to update price plan frequency setting.");
        },
        onSuccess: () => {
          void brandsQuery.refetch();
          const formatted = formatPricePlanFrequency(frequency);
          successToast(`Updated price plan frequency setting to ${formatted}.`);
          setPricePlanFrequencySetting(frequency);
        },
      },
    );
  };

  return (
    <RadioGroupOptions
      disabled={
        !isBrandAdmin ||
        brandsQuery.isLoading ||
        updateBrandPricePlanFrequencyMutation.isLoading
      }
      onValueChange={(val: PricePlanFrequencyType) =>
        handleSetPricePlanFrequencySetting(val)
      }
      options={PricePlanFrequencyVariants.map((value) => {
        const frequency = formatPricePlanFrequency(value);
        return {
          label: (isSelected: boolean) => {
            return <p className={cn(isSelected && "font-bold")}>{frequency}</p>;
          },
          value,
        };
      })}
      value={pricePlanFrequencySetting}
    />
  );
}

export default function PriceFrequencySettings() {
  const brandId = useBrandStore((state) => state.brand_id);
  const isBrandAdmin = useIsBrandAdmin();
  const brandsQuery = useGetUserBrandsQuery();

  if (brandsQuery.isLoading) {
    return (
      <Col className="gap-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
      </Col>
    );
  }

  if (brandsQuery.isError) {
    return (
      <div>
        <p>Failed to load price plan frequency settings.</p>
      </div>
    );
  }

  const brand = findBrandById(brandsQuery.data ?? [], brandId);

  return (
    <Inner
      isBrandAdmin={isBrandAdmin}
      pricePlanFrequency={brand.price_plan_frequency}
    />
  );
}
