import {
  MatchDetailsObjectKeys,
  MatchDetailsType,
} from "src/shared/trpc/common/CompetitorPricingObject";
import capitalize from "src/shared/utils/strings/capitalize";
import { z } from "zod";

export const PrimaryMatchTypeEnum = z.enum([
  "EXACT_MATCH",
  "DIFFERENT_FLAVOR",
  "DIFFERENT_SIZE",
  "DIFFERENT_BRAND",
  "DIFFERENT_ORGANICNESS",
  "DIFFERENT_VARIETY",
  "DIFFERENT_FLAVOR_AND_SIZE",
  "SIMILAR_PRODUCT",
]);

export type PrimaryMatchType = z.infer<typeof PrimaryMatchTypeEnum>;
export type MatchLevelFiltersExceptForSimilarProduct = Exclude<
  keyof typeof PrimaryMatchTypeEnum.enum,
  "SIMILAR_PRODUCT"
>;

export function getPrimaryMatchType(
  matchDetails: MatchDetailsType,
  matchLevel: number | null,
): PrimaryMatchType {
  if (matchDetails == null || Object.keys(matchDetails).length === 0) {
    if (matchLevel != null && matchLevel < 1) {
      return PrimaryMatchTypeEnum.enum.SIMILAR_PRODUCT;
    }
    return PrimaryMatchTypeEnum.enum.EXACT_MATCH;
  }

  const mismatchList: MatchDetailsObjectKeys[] = Object.entries(
    matchDetails ?? {},
  )
    .filter(([, isMatch]) => !isMatch)
    .map(([matchCriteria]) => matchCriteria as MatchDetailsObjectKeys);

  if (mismatchList.length === 0) {
    return PrimaryMatchTypeEnum.enum.EXACT_MATCH;
  }

  const inMismatchList = (criteria: MatchDetailsObjectKeys): boolean =>
    mismatchList.includes(criteria);

  if (inMismatchList("brand_match")) {
    return PrimaryMatchTypeEnum.enum.DIFFERENT_BRAND;
  }

  if (mismatchList.length === 1) {
    switch (mismatchList[0]) {
      case "flavor_match":
        return PrimaryMatchTypeEnum.enum.DIFFERENT_FLAVOR;
      case "size_match":
        return PrimaryMatchTypeEnum.enum.DIFFERENT_SIZE;
      case "organic_match":
        return PrimaryMatchTypeEnum.enum.DIFFERENT_ORGANICNESS;
      case "variety_match":
        return PrimaryMatchTypeEnum.enum.DIFFERENT_VARIETY;
    }
  }

  if (inMismatchList("flavor_match") && inMismatchList("size_match")) {
    return PrimaryMatchTypeEnum.enum.DIFFERENT_FLAVOR_AND_SIZE;
  }

  return PrimaryMatchTypeEnum.enum.SIMILAR_PRODUCT;
}

export function getPrimaryMatchTypeLabel(matchType: PrimaryMatchType): string {
  return capitalize(matchType.toLowerCase().replaceAll("_", " "));
}
