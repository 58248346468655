import TrpcClient from "src/frontend/api/TrpcClient";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import useBrandId from "src/frontend/hooks/useBrandId";
import parseImpactedSkusSelection from "src/frontend/utils/parseImpactedSkusSelection";
import { ProductConditionFiltersType } from "src/shared/trpc/common/ProductConditionFilters";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";

type SkusCountLabelProps = {
  conditionFilters: ProductConditionFiltersType;
  customSkusList: string[];
  impactedSkus: FancyBoxItem[];
};

export default function SkusCountLabel({
  conditionFilters,
  customSkusList,
  impactedSkus,
}: SkusCountLabelProps) {
  const brandId = useBrandId();
  const {
    allSkus,
    impactedCategories: categories,
    impactedPriceZones: priceZones,
    impactedProductBrandNames: productBrandNames,
    impactedSubCategories: subCategories,
    impactedSuppliers: suppliers,
    impactedTags: tagIds,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
  } = parseImpactedSkusSelection(impactedSkus);

  const query = TrpcClient.internal.getImpactSkusCount.useQuery({
    allSkus,
    applyAndConditionForCategory: conditionFilters.applyAndConditionForCategory,
    applyAndConditionForFilters: conditionFilters.applyAndConditionForFilters,
    applyAndConditionForPriceZone:
      conditionFilters.applyAndConditionForPriceZone,
    applyAndConditionForProductBrandName:
      conditionFilters.applyAndConditionForProductBrandName,
    applyAndConditionForSubCategory:
      conditionFilters.applyAndConditionForSubCategory,
    applyAndConditionForSupplier: conditionFilters.applyAndConditionForSupplier,
    applyAndConditionForTags: conditionFilters.applyAndConditionForTags,
    brandId,
    categories,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
    priceZones,
    productBrandNames,
    skusList: customSkusList,
    subCategories,
    suppliers,
    tagIds,
  });

  if (query.isLoading) {
    return <p className="mt-[5px] text-xs">Loading...</p>;
  }

  if (query.isError || query.data == null) {
    return null;
  }

  const hasFilters =
    arrayNotEmpty(customSkusList) || arrayNotEmpty(impactedSkus);

  const { count } = query.data;
  const rounded = formatNumberRounded(count);
  return (
    <p className="mt-[5px] whitespace-nowrap text-xs">
      {count === 0 && !hasFilters ? "0 SKUs" : `${rounded} SKUs for:`}
    </p>
  );
}
