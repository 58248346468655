import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import getColorForProductCategoryFilterType from "src/frontend/utils/getColorForProductCategoryFilterType";

export const CUSTOM_SKUS_SELECT_VALUE = "CUSTOM_SKUS_SELECTION";

export default function getCustomSkusFancyBoxItem(
  skusCount: number,
): FancyBoxItem {
  const type = ProductCategoryFilterType.CustomSkusSelection;
  return {
    color: getColorForProductCategoryFilterType(type),
    label: `${skusCount} SKUs`,
    type,
    value: CUSTOM_SKUS_SELECT_VALUE,
  };
}
