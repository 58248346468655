import { create } from "zustand";

type StoreState = {
  isInitialized: boolean;
};

type StoreActions = {
  convertInput: () => null;
  reset: () => void;
};

const defaultState: StoreState = {
  isInitialized: false,
};

const useCreatePricingStrategyFormStore = create<StoreActions & StoreState>(
  (set, get) => ({
    ...defaultState,
    convertInput: () => {
      const _state = get();
      return null;
    },
    reset: () => set(defaultState),
  }),
);

export default useCreatePricingStrategyFormStore;
