import { EmailAddress } from "src/shared/zod/EmailAddress";

export default function isValidEmail(str: string): boolean {
  try {
    EmailAddress.parse(str);
    return true;
  } catch (err) {
    return false;
  }
}
