import ZodDateInput from "src/shared/zod/ZodDateInput";
import { ZodJsonObject } from "src/shared/zod/ZodJsonObject";
import { z } from "zod";

export const ProductAttributesBaseObject = z.object({
  barcode: z.string().optional().nullable(),
  collection: z.string().optional().nullable(),
  color: z.string().optional().nullable(),
  count: z.number().optional().nullable(),
  country_of_origin: z.string().optional().nullable(),
  custom_attributes: ZodJsonObject.optional().nullable(),
  department: z.string().optional().nullable(),
  discontinued: z.boolean().optional().nullable(),
  expiration_date: ZodDateInput.optional().nullable(),
  finish: z.string().optional().nullable(),
  fit: z.string().optional().nullable(),
  gender: z.string().optional().nullable(),
  height: z.string().optional().nullable(),
  ingredients: z.string().optional().nullable(),
  is_free_shipping: z.boolean().optional().nullable(),
  kvi: z.string().optional().nullable(),
  lead_time: z.string().optional().nullable(),
  material: z.string().optional().nullable(),
  model_number: z.string().optional().nullable(),
  nutritional_info: z.string().optional().nullable(),
  packaging: z.string().optional().nullable(),
  producer_name: z.string().optional().nullable(),
  producer_type: z.string().optional().nullable(),
  product_brand_name: z.string().optional().nullable(),
  product_sku: z.string().optional().nullable(),
  product_type: z.string().optional().nullable(),
  quantity: z.string().optional().nullable(),
  rating: z.string().optional().nullable(),
  release_date: ZodDateInput.optional().nullable(),
  search_keywords: z.string().optional().nullable(),
  secondary_list_price: z.string().optional().nullable(),
  secondary_quantity: z.string().optional().nullable(),
  secondary_unit_of_measurement: z.string().optional().nullable(),
  shipping_dimensions: z.string().optional().nullable(),
  shipping_weight: z.string().optional().nullable(),
  shipping_weight_unit: z.string().optional().nullable(),
  size: z.string().optional().nullable(),
  size_description: z.string().optional().nullable(),
  size_unit: z.string().optional().nullable(),
  unit_of_measurement: z.string().optional().nullable(),
  variant_id: z.string().optional().nullable(),
  version: z.string().optional().nullable(),
  volume: z.string().optional().nullable(),
  volume_unit: z.string().optional().nullable(),
  warranty: z.string().optional().nullable(),
  weight: z.number().optional().nullable(),
  weight_unit: z.string().optional().nullable(),
  width: z.string().optional().nullable(),
});

export type ProductAttributesBaseObjectType = z.infer<
  typeof ProductAttributesBaseObject
>;
