import { Maybe } from "src/shared/types/maybe/Maybe";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import isNumber from "src/shared/utils/numbers/isNumber";

export default function formatNumberRounded(
  val: Maybe<bigint> | MaybeNumber,
  digits = 0,
): string {
  if (val == null || !isNumber(val)) {
    return "--";
  }

  return Intl.NumberFormat("en", {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(val);
}
