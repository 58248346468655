import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import PaginationControls from "src/frontend/components/PaginationControls";
import Row from "src/frontend/components/Row";
import MatchDetailBadge from "src/frontend/components/product-side-panel/MatchDetailBadge";
import Button from "src/frontend/components/ui/Button";
import SemiBold from "src/frontend/components/ui/SemiBold";
import { SheetTitle } from "src/frontend/components/ui/Sheet";
import Tooltip from "src/frontend/components/ui/Tooltip";
import COLORS from "src/frontend/constants/Colors";
import useGetCompetitorMatchReviewsQuery from "src/frontend/hooks/queries/useGetCompetitorMatchReviewsQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import CompetitorDetailComparisonTable from "src/frontend/pages/competitor-intelligence/CompetitorDetailComparisonTable";
import ReviewMatchModal from "src/frontend/pages/competitor-intelligence/ReviewMatchModal";
import useProductDetailSidePanelStore from "src/frontend/stores/useProductDetailSidePanelStore";
import { MatchReviewType } from "src/shared/trpc/common/enum/MatchReview";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import len from "src/shared/utils/arrays/len";
import formatDate from "src/shared/utils/dates/formatDate";
import truncateTextEnd from "src/shared/utils/strings/truncateTextEnd";
import invariant from "tiny-invariant";

export function CompetitorSidePanelContent() {
  const { isSmall } = useBreakpoints();
  const [review, setReview] = useState<MaybeNull<MatchReviewType>>(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isReviewMatchLoading, setIsReviewMatchLoading] = useState(false);
  const state = useProductDetailSidePanelStore();
  const {
    competitorMatchIndex,
    competitorNamesList,
    competitorPricingData,
    productIndex,
    products,
  } = state;
  const matchReviewsQuery = useGetCompetitorMatchReviewsQuery();
  const reviewCompetitorMatchMutation =
    TrpcClient.internal.reviewCompetitorMatch.useMutation();

  if (
    competitorPricingData == null ||
    competitorMatchIndex == null ||
    productIndex == null
  ) {
    return null;
  }

  const selectedProduct = products[productIndex];
  invariant(
    selectedProduct != null,
    `CompetitorIntelligenceType at index ${productIndex} must exist.`,
  );
  const competitorDataRecord = competitorPricingData[productIndex];
  if (competitorDataRecord == null) {
    return null;
  }

  const competitorIndex = competitorMatchIndex - 1;
  const competitorName = competitorNamesList[competitorIndex];
  const competitor = competitorDataRecord[competitorIndex];
  const noMatches = arrayEmpty(competitorDataRecord);
  const {
    competitor_product_brand,
    competitor_product_image_link,
    competitor_product_name,
    competitor_sku_description,
    match_details,
    match_reasoning,
    timestamp,
    unique_id,
  } = competitor ?? {};

  const reviews = matchReviewsQuery.data ?? [];
  const currentReview =
    unique_id == null
      ? null
      : reviews.find((review) => review.unique_id === unique_id) ?? null;

  const handleCompetitorReviewClick = async (
    review: MatchReviewType,
    reason: MaybeUndefined<string>,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    invariant(review !== null);
    if (unique_id == null) {
      return;
    }
    setIsReviewMatchLoading(true);
    await reviewCompetitorMatchMutation.mutateAsync(
      {
        reason,
        review,
        unique_id,
      },
      {
        onError,
        onSuccess,
      },
    );
    await matchReviewsQuery.refetch();
    setIsReviewMatchLoading(false);
  };

  return (
    <div className="relative">
      {competitor == null ? (
        <div className="flex-grow overflow-y-scroll">
          {noMatches ? (
            <p>No competitor matches available.</p>
          ) : (
            <>
              <SheetTitle>Competitor: {competitorName}</SheetTitle>
              <p>No matches found for this SKU.</p>
            </>
          )}
        </div>
      ) : (
        <Col className="flex-grow gap-14 pb-[120px]">
          <Col className="gap-4">
            <Row className="items-start justify-between">
              <Col>
                <SheetTitle className="font-bold">
                  Competitor: {competitorName}
                </SheetTitle>
                <p className="text-sm">
                  {competitor_product_name ?? "--"}
                  {competitor_product_brand != null &&
                  competitor_product_brand !== ""
                    ? `, by ${competitor_product_brand}`
                    : ""}
                </p>
              </Col>
              <Row className="items-start gap-2">
                {unique_id != null && (
                  <>
                    <ReviewMatchModal
                      onConfirm={(...args) =>
                        void handleCompetitorReviewClick(...args)
                      }
                      onOpenChange={setIsReviewModalOpen}
                      open={isReviewModalOpen}
                      review={review}
                      uniqueId={unique_id}
                    />
                    {(["UP", "DOWN"] as MatchReviewType[]).map((review) => {
                      const active = currentReview?.review === review;
                      const Icon =
                        review === "UP" ? ThumbsUpIcon : ThumbsDownIcon;
                      return review === "UP" ||
                        (review === "DOWN" && active) ? (
                        <Button
                          disabled={isReviewMatchLoading}
                          onClick={() => {
                            setReview(review);
                            void handleCompetitorReviewClick(review, undefined);
                          }}
                          size="icon"
                          variant="basic"
                        >
                          <Icon
                            color={active ? COLORS.PRIMARY : undefined}
                            fill={active ? COLORS.PRIMARY : "none"}
                            fillOpacity={active ? 0.4 : undefined}
                            opacity={0.8}
                            strokeWidth={1.5}
                          />
                        </Button>
                      ) : (
                        <Tooltip
                          content={
                            <p>
                              <SemiBold>Review reason:</SemiBold>{" "}
                              {currentReview?.reason}
                            </p>
                          }
                          disabled={!active || currentReview?.reason == null}
                          key={review}
                        >
                          <Button
                            disabled={isReviewModalOpen}
                            onClick={() => {
                              setReview(review);
                              setIsReviewModalOpen(true);
                            }}
                            size="icon"
                            variant="basic"
                          >
                            <Icon
                              color={active ? COLORS.PRIMARY : undefined}
                              fill={active ? COLORS.PRIMARY : "none"}
                              fillOpacity={active ? 0.4 : undefined}
                              opacity={0.8}
                              strokeWidth={1.5}
                            />
                          </Button>
                        </Tooltip>
                      );
                    })}
                  </>
                )}
              </Row>
            </Row>
            <Row className="mt-6 items-start gap-6">
              {competitor_product_image_link != null &&
                competitor_product_image_link !== "" && (
                  <Card className="block h-[177px] min-h-[177px] w-[147px] min-w-[147px] items-center justify-center border-none px-2 py-2 shadow">
                    <img
                      alt="Competitor Product Image"
                      className="h-full w-full object-contain"
                      src={competitor_product_image_link}
                    />
                  </Card>
                )}
              <Col className="pt-1">
                <p className="font-bold">Product description</p>
                <p className="text-sm">
                  {competitor_sku_description?.length
                    ? truncateTextEnd(competitor_sku_description, 800)
                    : "Description not available"}
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="items-start gap-3 rounded-lg bg-n-50 p-4">
            <MatchDetailBadge
              matchDetails={match_details ?? null}
              matchLevel={competitor.match_level}
            />
            <p className="text-sm font-normal text-neutral-400">
              {match_reasoning ?? "No match reasoning available"}
            </p>
          </Col>
          <Col className="gap-4">
            <Row className="justify-between">
              <p className="font-bold text-n-800">Match details</p>
              <p className="text-sm italic text-n-400">
                Last scraped on: {formatDate(timestamp)}
              </p>
            </Row>
            <CompetitorDetailComparisonTable
              competitor={competitor}
              product={selectedProduct}
            />
          </Col>
        </Col>
      )}
      {!noMatches && (
        <div className="fixed bottom-0 right-0 flex w-full items-center justify-center bg-n-0 p-4 shadow-md sm:w-[584px]">
          <PaginationControls
            currentPage={competitorMatchIndex}
            hidePageButtons={isSmall}
            pageSize={1}
            setPage={(page) => {
              useProductDetailSidePanelStore.setState({
                competitorMatchIndex: page,
              });
            }}
            showAllPages={len(competitorNamesList) < 8}
            totalCount={len(competitorNamesList)}
          />
        </div>
      )}
    </div>
  );
}
