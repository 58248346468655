import { Dispatch, SetStateAction } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import useAdminActionsAvailable from "src/frontend/hooks/useAdminActionsAvailable";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import invariant from "tiny-invariant";

type ScheduleDraftPricePlanForDeletionModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ScheduleDraftPricePlanForDeletionModal({
  isModalOpen,
  setIsModalOpen,
}: ScheduleDraftPricePlanForDeletionModalProps) {
  const adminActionsAvailable = useAdminActionsAvailable();
  const pricePlansQuery = usePricePlanQuery();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const { errorToast, successToast } = useToast();
  const scheduleDraftPricePlanForDeletionMutation =
    TrpcClient.admin.scheduleInternalDraftPricePlanForDeletion.useMutation();

  if (!adminActionsAvailable) {
    return null;
  }

  return (
    <Modal
      confirmText="Confirm"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            This will set schedule this price plan to be deleted in at least 7
            days from now.
          </p>
        </div>
      }
      dialogTitle="Schedule for Deletion"
      loading={scheduleDraftPricePlanForDeletionMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null);
        scheduleDraftPricePlanForDeletionMutation.mutate(
          {
            pricePlanId,
          },
          {
            onError: () => {
              errorToast("Failed to schedule price plan for deletion.");
            },
            onSuccess: () => {
              setIsModalOpen(false);
              successToast("Price plan scheduled for deletion.");
              void pricePlansQuery.refetch();
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
