import {
  brandSettingsShape,
  defaultBrandSettings,
} from "src/backend/launch-darkly/LaunchDarklyFlagSet";
import useBrandId from "src/frontend/hooks/useBrandId";
import useFlags from "src/frontend/hooks/useFlags";

export default function useBrandSettings() {
  const brandId = useBrandId();
  const flags = useFlags();
  const allBrandSettings = flags.brandSettings;
  const brandSettings = {
    ...defaultBrandSettings,
    ...allBrandSettings?.[brandId],
  };

  try {
    return brandSettingsShape.parse(brandSettings);
  } catch (error) {
    console.error("There was an error parsing brand settings:", error);
    return defaultBrandSettings;
  }
}
