import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type SortableItemProps = HtmlDivProps & {
  children: React.ReactNode;
  dragDisabled?: boolean;
  id: UniqueIdentifier;
  renderAsTableRow?: boolean;
};

export function SortableItem({
  children,
  className,
  dragDisabled = false,
  id,
  renderAsTableRow = false,
  style,
  ...rest
}: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ disabled: dragDisabled, id });

  const stylesToApply: CSSProperties = {
    ...style,
    touchAction: "none",
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (renderAsTableRow) {
    return (
      <tr
        className={cn(className)}
        ref={setNodeRef}
        style={stylesToApply}
        {...attributes}
        {...listeners}
        {...rest}
      >
        {children}
      </tr>
    );
  }

  return (
    <div
      className={cn(className)}
      ref={setNodeRef}
      style={stylesToApply}
      {...attributes}
      {...listeners}
      {...rest}
    >
      {children}
    </div>
  );
}
