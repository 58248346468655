import { ContextMenuSeparator } from "@radix-ui/react-context-menu";
import Row from "src/frontend/components/Row";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "src/frontend/components/ui/ContextMenu";
import useAdminActionsAvailable from "src/frontend/hooks/useAdminActionsAvailable";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useFlags from "src/frontend/hooks/useFlags";
import GenericAsyncFunction from "src/shared/types/generic/GenericAsyncFunction";
import GenericFunction from "src/shared/types/generic/GenericFunction";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";

export type AdminMenuActionItem = {
  fn: GenericAsyncFunction | GenericFunction;
  label: JSX.Element | string;
};

type LucaContextMenuProps = {
  adminActions?: MaybeNull<AdminMenuActionItem>[];
  children: JSX.Element;
  devActions?: MaybeNull<AdminMenuActionItem>[];
};

export default function LucaContextMenu({
  adminActions = [],
  children,
  devActions = [],
}: LucaContextMenuProps) {
  const { isSmall } = useBreakpoints();
  const adminActionsAvailable = useAdminActionsAvailable();
  const { devFeaturesEnabled } = useFlags();

  const adminActionsList = filterEmptyValues(adminActions);
  const devActionsList = filterEmptyValues(devActions);

  const noActions = arrayEmpty(adminActionsList) && arrayEmpty(devActionsList);
  const bothActionsPresent =
    arrayNotEmpty(adminActionsList) && arrayNotEmpty(devActionsList);

  const featureUnavailable = !adminActionsAvailable && !devFeaturesEnabled;
  if (noActions || isSmall || featureUnavailable) {
    return children;
  }

  const renderAdminActions =
    adminActionsAvailable && arrayNotEmpty(adminActionsList);
  const renderDevActions = devFeaturesEnabled && arrayNotEmpty(devActionsList);

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent className="max-w-80">
        {renderAdminActions && (
          <>
            <ContextMenuItem>
              <Row className="gap-2 font-bold">Admin Actions</Row>
            </ContextMenuItem>
            {adminActionsList.map((action, index) => {
              return (
                <ContextMenuItem
                  key={index}
                  onClick={() => {
                    void action.fn();
                  }}
                >
                  {action.label}
                </ContextMenuItem>
              );
            })}
          </>
        )}
        {bothActionsPresent && (
          <ContextMenuSeparator className="my-1 h-[1px] bg-border" />
        )}
        {renderDevActions && (
          <>
            <ContextMenuItem>
              <Row className="gap-2 font-bold">Dev Actions</Row>
            </ContextMenuItem>
            {devActionsList.map((action, index) => {
              return (
                <ContextMenuItem
                  key={index}
                  onClick={() => {
                    void action.fn();
                  }}
                >
                  {action.label}
                </ContextMenuItem>
              );
            })}
          </>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
}
