import React from "react";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type PageTitleProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function PageTitle({
  children,
  className,
  ...rest
}: PageTitleProps) {
  return (
    <h2 className={cn("text-2xl font-bold", className)} {...rest}>
      {children}
    </h2>
  );
}
