import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import { GroupStatusType } from "src/shared/trpc/common/enum/GroupStatus";
import PricePlanTab from "src/shared/types/PricePlanTab";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function getGroupStatusForTab(tab: PricePlanTab): GroupStatusType[] {
  switch (tab) {
    case "preview":
      return ["PREVIEW", "REJECTED"];
    case "current":
      return ["COMPLETED", "ACTIVE"];
    case "internal":
    case "draft":
      return ["INTERNAL_DRAFT", "DRAFT", "REJECTED"];
    case "past":
      return ["COMPLETED"];
    default:
      return assertUnreachable(tab);
  }
}

export default function usePricePlanGroupsQueryV2() {
  const { brandId, enabled } = useIsBrandInitialized();
  const tab = usePricePlanTab();
  const { pricePlanId } = useParams();
  const groupStatus =
    tab != null ? getGroupStatusForTab(tab as PricePlanTab) : undefined;
  return TrpcClient.internal.getPricePlanGroupsV2.useQuery(
    {
      brandId,
      groupStatus,
      pricePlanId: pricePlanId!,
    },
    {
      enabled: enabled && pricePlanId != null,
    },
  );
}
