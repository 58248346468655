import { useNavigate, useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";
import invariant from "tiny-invariant";

type DeletePricingStrategyModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function DeletePricingStrategyModal({
  isModalOpen,
  setIsModalOpen,
}: DeletePricingStrategyModalProps) {
  const navigate = useNavigate();
  const t = useToast();
  const { pricingStrategyId: pricingStrategyIdParam } = useParams();
  const pricingStrategyQuery = useGetPricingStrategiesQuery();
  const { loading, pricingStrategyId } = usePricingStrategyDropdownStateStore();
  const deletePricingStrategyMutation =
    TrpcClient.internal.deletePricingStrategy.useMutation();

  const onSuccess = async () => {
    await pricingStrategyQuery.refetch();
    usePricingStrategyDropdownStateStore.setState({
      isDeleteModalOpen: false,
      loading: false,
      pricingStrategyId: null,
    });
    setIsModalOpen(false);
    t.successToast("Pricing strategy deleted successfully.");
    if (pricingStrategyIdParam != null) {
      navigate("/company-settings/strategy");
    }
  };

  const handleDelete = () => {
    usePricingStrategyDropdownStateStore.setState({ loading: true });
    invariant(pricingStrategyId != null);
    deletePricingStrategyMutation.mutate(
      {
        pricingStrategyId,
      },
      {
        onError: () => {
          t.errorToast("Failed to delete pricing strategy.");
          usePricingStrategyDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Modal
      confirmText="Delete"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            This pricing strategy will be deleted. This action cannot be undone.
          </p>
        </div>
      }
      dialogTitle="Delete Pricing Strategy"
      loading={loading}
      onConfirm={handleDelete}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
