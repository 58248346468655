import PricePlansByStatus from "src/frontend/types/PricePlansByStatus";
import { PricePlanResponseType } from "src/shared/trpc/common/PricePlanResponse";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";

export default function getPricePlansByStatus(
  pricePlans: MaybeUndefined<PricePlanResponseType[]>,
): PricePlansByStatus {
  const previewPricePlans =
    pricePlans?.filter((plan) => plan.status === "PREVIEW") ?? [];
  const internalDraftPricePlans =
    pricePlans?.filter((plan) => plan.status === "INTERNAL_DRAFT") ?? [];
  const draftPricePlans =
    pricePlans?.filter((plan) => plan.status === "DRAFT") ?? [];
  const currentPricePlans =
    pricePlans?.filter((plan) => plan.status === "CURRENT") ?? [];
  const pastPricePlans =
    pricePlans?.filter((plan) => plan.status === "COMPLETED") ?? [];
  return {
    currentPricePlans,
    draftPricePlans,
    internalDraftPricePlans,
    pastPricePlans,
    previewPricePlans,
  };
}
