import { useEffect } from "react";
import { useNavigate } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";

export default function TestSentryRoute() {
  const navigate = useNavigate();
  const testSentryMutation = TrpcClient.internal.testSentry.useMutation();

  useEffect(() => {
    testSentryMutation.mutate();
    navigate("/", { replace: true });
  }, [navigate, testSentryMutation]);

  return null;
}
