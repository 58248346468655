import Papa from "papaparse";
import { useState, ChangeEvent, useRef } from "react";
import { useNavigate } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import Button from "src/frontend/components/ui/Button";
import Dialog from "src/frontend/components/ui/Dialog";
import { Input } from "src/frontend/components/ui/Input";
import useToast from "src/frontend/components/ui/useToast";
import useGetPriceParityGroupsQuery from "src/frontend/hooks/queries/useGetPriceParityGroupsQuery";
import { PriceParityUploadGroupType } from "src/shared/trpc/mutations/uploadPriceParityGroupsMutationSchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import groupBy from "src/shared/utils/arrays/groupBy";
import len from "src/shared/utils/arrays/len";
import isCsvFile from "src/shared/utils/csv/isCsvFile";
import sum from "src/shared/utils/math/sum";
import invariant from "tiny-invariant";
import { z } from "zod";

const PriceParityCsvUploadShape = z.object({
  price_parity_group_id: z.string(),
  price_parity_group_name: z.string(),
  sku: z.string(),
});

type PriceParityCsvUploadShapeType = z.infer<typeof PriceParityCsvUploadShape>;

type PriceParityGroups = PriceParityUploadGroupType;

type UploadPriceParityGroupCsvDialogProps = {
  disabled: boolean;
};

export default function UploadPriceParityGroupCsvDialog({
  disabled,
}: UploadPriceParityGroupCsvDialogProps) {
  const t = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<MaybeNull<PriceParityGroups[]>>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const csvFileInputRef = useRef<HTMLInputElement>(null);

  const priceParityGroupQuery = useGetPriceParityGroupsQuery();
  const uploadPriceParityGroupsMutation =
    TrpcClient.internal.uploadPriceParityGroups.useMutation();

  const onClose = () => {
    setGroups(null);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files == null) {
      t.errorToast("No file found.");
      return;
    }

    const file = files[0];
    if (!isCsvFile(file)) {
      t.errorToast({
        description: (
          <span>
            File must be a CSV file ending with a <Code>.csv</Code> extension.
          </span>
        ),
        title: "Invalid file type",
      });
      if (csvFileInputRef.current != null) {
        csvFileInputRef.current.value = "";
      }
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      if (e.target == null) {
        t.errorToast("There was an error reading the CSV file.");
        return;
      }

      let content = e.target.result;
      if (typeof content !== "string") {
        t.errorToast("Invalid file contents.");
        return;
      }

      if (!content.includes(",")) {
        content = content.replaceAll("\n", ",");
        content = content.replaceAll("\r", "");
      }

      const parseResult = Papa.parse<PriceParityCsvUploadShapeType>(content, {
        header: true,
      });

      const fields = parseResult.meta.fields ?? [];
      const skuHeader = fields
        .map((val) => val.toLowerCase())
        .find((val) => val === "price_parity_group_id");
      if (skuHeader != null) {
        const data = parseResult.data.map((val) =>
          PriceParityCsvUploadShape.parse(val),
        );
        const groupedByPriceParityId = groupBy(data, "price_parity_group_id");
        const groupsInput: PriceParityUploadGroupType[] = [];
        for (const [id, priceParityGroup] of Object.entries(
          groupedByPriceParityId,
        )) {
          const name = priceParityGroup[0].price_parity_group_name;
          invariant(
            priceParityGroup.every(
              (val) => val.price_parity_group_name === name,
            ),
          );
          groupsInput.push({
            name,
            price_parity_group_id: id,
            skus: priceParityGroup.map((val) => val.sku),
          });
        }

        setGroups(groupsInput);
      } else {
        t.errorToast("Could not parse CSV.");
      }
    };
  };

  const onSuccess = async () => {
    await priceParityGroupQuery.refetch();
    t.successToast("Groups uploaded successfully!");
    setLoading(true);
    navigate("/company-settings/relationships");
  };

  return (
    <Dialog
      confirmTitle="Continue"
      dialogTitle="Upload CSV"
      loading={loading}
      onConfirm={() => {
        invariant(groups != null);
        setLoading(true);
        t.infoToast("Starting upload.");
        uploadPriceParityGroupsMutation.mutate(
          {
            groups,
          },
          {
            onError: () => {
              setLoading(false);
              t.errorToast("Failed to upload price parity groups...");
            },
            onSuccess: () => {
              void onSuccess();
            },
          },
        );
      }}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        setDialogOpen(open);
      }}
      open={dialogOpen}
      trigger={
        <Button className="min-w-fit px-1" disabled={disabled} variant="link">
          Upload CSV
        </Button>
      }
    >
      <Col className="gap-1">
        <p>
          Upload a CSV containing multiple groups. All of the groups will be
          created when you click Continue.
        </p>
        <p className="mt-2 text-sm">
          The CSV file must contain the following columns:
        </p>
        <ul className="text-sm">
          <li>
            • <Code>price_parity_group_id</Code>
          </li>
          <li>
            • <Code>price_parity_group_name</Code> (optional)
          </li>
          <li>
            • <Code>sku</Code>
          </li>
        </ul>
        <Input
          className="mt-4 py-2 hover:cursor-pointer"
          id="sku-csv-file"
          onChange={(e) => handleUploadFile(e)}
          ref={csvFileInputRef}
          type="file"
        />
      </Col>
      <UploadedCsvSkuPreview groups={groups} />
    </Dialog>
  );
}

type UploadedCsvSkuPreviewProps = {
  groups: MaybeNull<PriceParityGroups[]>;
};
function UploadedCsvSkuPreview({ groups }: UploadedCsvSkuPreviewProps) {
  if (groups == null) {
    return null;
  }

  const groupsCount = len(groups);
  const skusCount = sum(groups.map((val) => len(val.skus)));
  return (
    <div className="text-base">
      <p className="font-semibold">Ready to upload:</p>
      <p>{groupsCount} Total Groups.</p>
      <p>{skusCount} Total SKUs.</p>
    </div>
  );
}
