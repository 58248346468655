import { User } from "firebase/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import useBrandStore from "src/frontend/stores/useBrandStore";
import { Maybe } from "src/shared/types/maybe/Maybe";
import createLaunchDarklyContext from "src/shared/utils/createLaunchDarklyContext";

export default function useFlagsContext(user: Maybe<User>) {
  const ldClient = useLDClient();
  const brand = useBrandStore((state) => state);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (ldClient != null && user != null) {
      const ctx = createLaunchDarklyContext({
        brandId: brand.brand_id,
        brandName: brand.brand_name,
        email: user.email ?? user.uid,
      });
      ldClient
        ?.identify(ctx)
        .catch((e) => {
          // TODO[@bonham000]: Should we report this error?
          console.error(e);
        })
        .finally(() => setInitialized(true));
    }
  }, [user, brand, ldClient]);

  return initialized;
}
