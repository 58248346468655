import invariant from "tiny-invariant";

export default function groupByUniqueValues<T>(
  arr: T[],
  property: keyof T,
  disallowNullOrUndefined = false,
): Record<string, T> {
  return arr.reduce(
    (groups, el) => {
      const value = el[property];
      if (disallowNullOrUndefined) {
        const stringValue = String(value);
        const stringProperty = String(property);
        invariant(
          value != null,
          `Values must not be null or undefined, received: ${stringValue} for key: ${stringProperty}.`,
        );
      }
      const key = String(value);
      invariant(!(key in groups));
      groups[key] = el;
      return groups;
    },
    {} as Record<string, T>,
  );
}
