import {
  UniqueIdentifier,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCenter,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  sortableKeyboardCoordinates,
  SortableContext,
  rectSortingStrategy,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import WithPriorityField from "src/shared/types/WithPriorityField";
import resetPriorityBasedOnOrder from "src/shared/utils/arrays/resetPriorityBasedOnOrder";

type SortableItem = Record<string, any> &
  WithPriorityField & {
    id: UniqueIdentifier;
  };

type SortableContainerProps<T extends SortableItem> = HtmlDivProps & {
  children: React.ReactNode;
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  useGrid?: boolean;
};

export default function SortableContainer<T extends SortableItem>({
  children,
  className,
  items,
  setItems,
  useGrid = false,
  ...rest
}: SortableContainerProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // This ignores click events without dragging over a short distance, which
      // allows clickable elements on the draggable surface to be registered.
      // See: https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <div
      className={cn(useGrid && "flex flex-row flex-wrap", className)}
      {...rest}
    >
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={items}
          strategy={useGrid ? rectSortingStrategy : verticalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </DndContext>
    </div>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over == null) {
      return;
    }

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.map((item) => item.id).indexOf(active.id);
        const newIndex = items.map((item) => item.id).indexOf(over.id);
        const reordered = arrayMove(items, oldIndex, newIndex);
        return resetPriorityBasedOnOrder(reordered);
      });
    }
  }
}
