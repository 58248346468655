if (
  !process.env.NEXT_PUBLIC_APP_ENV ||
  !["dev", "production"].includes(process.env.NEXT_PUBLIC_APP_ENV)
) {
  throw new Error("APP_ENV is not set to dev or production");
}

const APP_ENV = (process.env.NEXT_PUBLIC_APP_ENV || "dev") as
  | "dev"
  | "production";

const FRONTEND_ENV = {
  APP_ENV,
  IS_PROD: APP_ENV === "production",
};

export default FRONTEND_ENV;
