import PRICE_PARITY_GROUP_MAX_SIZE from "src/shared/constants/PriceParityGroupMaxSize";
import len from "src/shared/utils/arrays/len";
import isPriceParityGroupNameValid from "src/shared/validation/isPriceParityGroupNameValid";

type GenericPriceParityGroup = {
  is_active: boolean;
  name: string;
  product_ids: string[];
};

export default function isPriceParityGroupValid(
  group: GenericPriceParityGroup,
): boolean {
  if (!isPriceParityGroupNameValid(group.name)) {
    return false;
  }

  if (len(group.product_ids) > PRICE_PARITY_GROUP_MAX_SIZE) {
    return false;
  }

  return true;
}
