import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";
import SortIcon from "src/frontend/components/design-system/SortIcon";
import Tooltip from "src/frontend/components/ui/Tooltip";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type TableProps = HtmlDivProps & {
  children: React.ReactNode;
  tableClassName?: ClassNameProp;
};

const removeRightBorderFromLastColumn = "[&_tr>*:last-of-type]:border-r-0";

export function Table({
  children,
  className,
  tableClassName,
  ...rest
}: TableProps) {
  return (
    <div className={cn("overflow-x-scroll border-y", className)} {...rest}>
      <table
        className={cn(
          "w-full select-none overflow-x-scroll text-left",
          tableClassName,
          removeRightBorderFromLastColumn,
        )}
      >
        {children}
      </table>
    </div>
  );
}

type TableHeaderProps = HtmlDivProps & {
  children: React.ReactNode;
};

export function TableHeader({
  children,
  className,
  ...rest
}: TableHeaderProps) {
  return (
    <thead className={className} {...rest}>
      {children}
    </thead>
  );
}

type TableHeaderCellProps = ComponentPropsWithoutRef<"td"> & {
  borderLeft?: boolean;
  borderRight?: boolean;
  children?: React.ReactNode;
  isSortable?: boolean;
  onSortClick?: (order?: "asc" | "desc") => void;
  sortAscFirst?: boolean;
  sortOrder?: "asc" | "desc";
};

export function TableHeaderCell(props: TableHeaderCellProps) {
  const {
    borderLeft,
    borderRight,
    children,
    className,
    isSortable = false,
    onSortClick,
    sortAscFirst = false,
    sortOrder,
    ...rest
  } = props;
  const handleColumnSortClick = (e: React.MouseEvent<HTMLTableCellElement>) => {
    if (props.onClick) {
      return props.onClick(e);
    }
    if (sortAscFirst) {
      if (sortOrder === "asc") {
        onSortClick?.("desc");
      } else if (sortOrder === "desc") {
        onSortClick?.();
      } else {
        onSortClick?.("asc");
      }
    } else {
      if (sortOrder === "asc") {
        onSortClick?.();
      } else if (sortOrder === "desc") {
        onSortClick?.("asc");
      } else {
        onSortClick?.("desc");
      }
    }
  };
  return (
    <th
      {...rest}
      className={cn(
        cn(
          "text-nowrap whitespace-nowrap bg-n-0 p-2 pt-3 text-sm font-bold leading-[18px] text-n-950",
          borderRight && "border-r",
          borderLeft && "border-l",
          isSortable && "hover:cursor-pointer",
          className,
        ),
      )}
      onClick={handleColumnSortClick}
    >
      {isSortable === false && children}
      {isSortable && onSortClick && (
        <div className="flex items-center gap-1">
          {children}
          <SortIcon onSortClick={onSortClick} sortOrder={sortOrder} />
        </div>
      )}
    </th>
  );
}

type TableDataCellProps = HtmlDivProps & {
  borderLeft?: boolean;
  borderRight?: boolean;
  children: React.ReactNode;
  maxWidth?: string;
};

export function TableDataCell(props: TableDataCellProps) {
  const { borderLeft, borderRight, children, className, ...rest } = props;
  const [isTruncated, setIsTruncated] = useState(false);
  const cellRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (cellRef.current) {
      setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth);
    }
  }, [children]);
  return (
    <td
      {...rest}
      className={cn(
        "border-y-0 bg-n-0 p-2 py-3 hover:z-50",
        borderRight && "border-r",
        borderLeft && "border-l",
        className,
      )}
    >
      <Tooltip content={children} disabled={!isTruncated}>
        <span
          className={cn(
            "inline-block max-w-full truncate whitespace-nowrap text-left text-sm",
            props.maxWidth,
          )}
          ref={cellRef}
        >
          {children}
        </span>
      </Tooltip>
    </td>
  );
}
