import { IconArrowNarrowRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { PAGE_CONTAINER_ID } from "src/frontend/components/PageContainer";
import Row from "src/frontend/components/Row";
import Button from "src/frontend/components/ui/Button";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import usePricePlanGroupsQueryV2 from "src/frontend/hooks/queries/usePricePlanGroupsQueryV2";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import PricePlanGroupCard from "src/frontend/pages/pricing/PricePlanGroupCard";
import useLocationHistoryStore from "src/frontend/stores/useLocationHistoryStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import range from "src/shared/utils/arrays/range";

export default function PricePlanGroups() {
  const tab = usePricePlanTab();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const pricePlanGroupsQuery = usePricePlanGroupsQueryV2();
  const setScrollPosition = () => {
    const scrollContainer = document.getElementById(PAGE_CONTAINER_ID);
    const pricePlanScrollPosition = scrollContainer?.scrollTop ?? null;
    useLocationHistoryStore.setState({ pricePlanScrollPosition });
  };
  return (
    <div className="mt-5 flex flex-1 flex-col">
      <Row className="justify-end pb-1">
        <Link
          to={tab != null ? `/pricing/${tab}/${pricePlanId}/all` : "/pricing"}
        >
          <Button variant="hyperlink">
            Full SKU List
            <IconArrowNarrowRight className="-rotate-45 transform" size={16} />
          </Button>
        </Link>
      </Row>
      {pricePlanGroupsQuery.isLoading ? (
        <div className="mb-16 flex flex-1 flex-col gap-4">
          {range(10).map((_, i) => {
            return <Skeleton className="h-[140px] w-full" key={i} />;
          })}
        </div>
      ) : !pricePlanGroupsQuery.data?.length ? (
        <div className="my-4 flex flex-1 flex-col gap-1">
          <p className="flex flex-row text-lg font-bold">No groups found.</p>
        </div>
      ) : (
        <div className="my-4 flex flex-1 flex-col gap-4">
          {pricePlanGroupsQuery.data.map((opportunity, index) => (
            <PricePlanGroupCard
              group={opportunity}
              key={index}
              onClick={setScrollPosition}
            />
          ))}
        </div>
      )}
    </div>
  );
}
