import { ChevronDown, ChevronUp, ChevronsUpDown } from "lucide-react";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { SortDirectionType } from "src/shared/zod/SortDirection";

type SortIconProps = {
  direction: SortDirectionType;
  selectedKey: MaybeNull<ProductMasterObjectSortKeyType>;
  sortBy: ProductMasterObjectSortKeyType;
};

export default function SortIcon({
  direction,
  selectedKey,
  sortBy,
}: SortIconProps) {
  return (
    <div className="mx-1 mt-1 w-2">
      {sortBy !== selectedKey ? (
        <ChevronsUpDown className="ml-1" size={12} />
      ) : direction === "desc" ? (
        <ChevronDown className="ml-1" size={12} />
      ) : (
        <ChevronUp className="ml-1" size={12} />
      )}
    </div>
  );
}
