import { GetProductCatalogQueryInput } from "src/shared/trpc/queries/getProductCatalogQuerySchema";
import { z } from "zod";

export const DownloadProductCatalogCsvPayload = GetProductCatalogQueryInput;

export type DownloadProductCatalogCsvPayloadType = z.infer<
  typeof DownloadProductCatalogCsvPayload
>;

export const DownloadProductCatalogCsvResponse = z.object({
  csv: z.string(),
  product_ids: z.string().array(),
});

export type DownloadProductCatalogCsvResponseType = z.infer<
  typeof DownloadProductCatalogCsvResponse
>;
