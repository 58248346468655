import { ApiResponseStatus } from "src/shared/trpc/common/ApiResponseStatus";
import { z } from "zod";

export const GenericTrpcResponse = z.object({
  data: z.object({}).optional(),
  error: z.object({}).optional(),
  info: z.string().optional(),
  status: ApiResponseStatus,
});

export type GenericTrpcResponseType = z.infer<typeof GenericTrpcResponse>;
