import { ExperimentPriceBounds } from "src/shared/trpc/common/ExperimentPriceBounds";
import FinalTreatmentPriceFields from "src/shared/trpc/common/FinalTreatmentPriceFields";
import TreatmentPriceFields from "src/shared/trpc/common/TreatmentPriceFields";
import { z } from "zod";

const ExperimentPriceFields = ExperimentPriceBounds.merge(TreatmentPriceFields)
  .merge(FinalTreatmentPriceFields)
  .merge(
    z.object({
      control_discounted_price: z.number().nullable(),
      // TODO-[MIKE]: contribution margin
      control_discounted_price_contribution_margin: z.number().nullable(),
      control_discounted_price_margin: z.number().nullable(),
      control_list_price: z.number().nullable(),
      control_member_price: z.number().nullable(),
      control_member_price_contribution_margin: z.number().nullable(),
      control_member_price_margin: z.number().nullable(),
      control_price_contribution_margin: z.number().nullable(),
      control_price_margin: z.number().nullable(),
      control_subscriber_price: z.number().nullable(),
      control_subscriber_price_contribution_margin: z.number().nullable(),
      control_subscriber_price_margin: z.number().nullable(),
      discounted_price_change_percent: z.number().nullable(),
      discounted_price_margin_change_amount: z.number().nullable(),
      elasticity: z.number().nullable(),
      elasticity_group_id: z.string().nullable(),
      list_price_change_percent: z.number().nullable(),
      list_price_margin_change_amount: z.number().nullable(),
      member_elasticity: z.number().nullable(),
      member_price_change_percent: z.number().nullable(),
      member_price_margin_change_amount: z.number().nullable(),
      subscriber_price_change_percent: z.number().nullable(),
      subscriber_price_margin_change_amount: z.number().nullable(),
      treatment_discounted_price_contribution_margin: z.number().nullable(),
      treatment_discounted_price_margin: z.number().nullable(),
      treatment_list_price_contribution_margin: z.number().nullable(),
      treatment_member_price_contribution_margin: z.number().nullable(),
      treatment_member_price_margin: z.number().nullable(),
      treatment_price_contribution_margin: z.number().nullable(),
      treatment_price_margin: z.number().nullable(),
      treatment_subscriber_price_contribution_margin: z.number().nullable(),
      treatment_subscriber_price_margin: z.number().nullable(),
    }),
  );

export type ExperimentPriceFieldsType = z.infer<typeof ExperimentPriceFields>;

export default ExperimentPriceFields;
