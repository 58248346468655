import * as TabsPrimitive from "@radix-ui/react-tabs";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "src/frontend/components/ui/utils";

const Tabs = TabsPrimitive.Root;

export const tabVariants = cva("", {
  defaultVariants: {
    variant: "default",
  },
  variants: {
    variant: {
      default: "flex inline-flex justify-between text-muted-foreground",
      ghost: "",
    },
  },
});

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    className={cn(tabVariants({ className, variant }))}
    ref={ref}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

export const tabTriggerVariants = cva("", {
  defaultVariants: {
    variant: "default",
  },
  variants: {
    variant: {
      default:
        "inline-flex items-center justify-center whitespace-nowrap px-8 py-3 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 data-[state=active]:bg-muted/20 data-[state=active]:font-bold data-[state=active]:text-foreground data-[state=active]:shadow",
      ghost:
        "flex gap-2 font-normal text-muted disabled:cursor-not-allowed disabled:opacity-50 data-[state=active]:border-b-4 data-[state=active]:border-b-primary data-[state=active]:font-bold data-[state=active]:text-foreground",
      none: "",
      primary:
        "inline-flex items-center justify-center whitespace-nowrap px-8 py-3 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 data-[state=active]:bg-primary/20 data-[state=active]:font-bold data-[state=active]:text-primary data-[state=active]:shadow",
      underlined:
        "flex flex-shrink justify-center border-b-4 border-transparent p-1 text-base font-bold text-n-400 [&[data-state='active']]:border-blue-600 [&[data-state='active']]:text-n-800",
    },
  },
});

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> &
    VariantProps<typeof tabTriggerVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={cn(tabTriggerVariants({ className, variant }))}
    ref={ref}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    className={cn(
      "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className,
    )}
    ref={ref}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
