import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router";
import Bold from "src/frontend/components/Bold";
import Card from "src/frontend/components/Card";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import { ThemeToggle } from "src/frontend/components/ThemeToggle";
import Button from "src/frontend/components/ui/Button";
import { Prompt } from "src/frontend/components/ui/Prompt";
import { Switch } from "src/frontend/components/ui/Switch";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import useAuthStore from "src/frontend/stores/useAuthStore";
import useLucaAdminSettingsStore from "src/frontend/stores/useLucaAdminSettingsStore";

export default function UserSettings() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const isLucaAdmin = useIsLucaAdmin();
  const viewAsCompanyUser = useLucaAdminSettingsStore(
    (state) => state.view_as_company_user,
  );
  const handleLogout = () => {
    logout();
    queryClient.clear();
    navigate("/");
  };
  const userEmail = user?.email;
  return (
    <PageContainer enforceMaxWidth>
      <div className="flex flex-col gap-6">
        <PageTitle>User Settings</PageTitle>
        <Card className="flex flex-row items-center justify-between p-4">
          <p>Theme</p>
          <ThemeToggle />
        </Card>
        <Card className="flex flex-row items-center justify-between p-4">
          {userEmail == null ? (
            <p>Logout</p>
          ) : (
            <p>
              Logged in as: <Bold>{userEmail}</Bold>
            </p>
          )}
          <Prompt
            confirmText="Logout"
            onConfirm={() => {
              handleLogout();
            }}
            trigger={
              <Button size="sm" variant="basic">
                Logout
              </Button>
            }
          />
        </Card>
        {isLucaAdmin && (
          <>
            <PageTitle>Luca Admin Settings</PageTitle>
            <Card className="flex flex-row items-center justify-between p-4">
              <p>View as company user (toggles admin-only features on/off)</p>
              <Switch
                checked={viewAsCompanyUser}
                disabled={false}
                id="luca-admin-view-all-features-checkbox"
                label={viewAsCompanyUser ? "Enabled" : "Disabled"}
                onCheckedChange={() => {
                  useLucaAdminSettingsStore.setState({
                    view_as_company_user: !viewAsCompanyUser,
                  });
                }}
              />
            </Card>
          </>
        )}
      </div>
    </PageContainer>
  );
}
