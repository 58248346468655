import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type CodeProps = HtmlDivProps & {
  children: string;
};

export default function Code({ children, className, ...rest }: CodeProps) {
  return (
    <code
      className={cn(
        "w-fit rounded-sm bg-muted bg-opacity-20 px-1 text-code",
        className,
      )}
      {...rest}
    >
      {children}
    </code>
  );
}
