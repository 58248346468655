import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Bold from "src/frontend/components/Bold";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import Button from "src/frontend/components/ui/Button";
import Dialog from "src/frontend/components/ui/Dialog";
import FancyBox, { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import FancyBoxLabel from "src/frontend/components/ui/FancyBoxLabel";
import FancyBoxSelectedItemsList from "src/frontend/components/ui/FancyBoxSelectedItemsList";
import { Input } from "src/frontend/components/ui/Input";
import useToast from "src/frontend/components/ui/useToast";
import useTagsQuery from "src/frontend/hooks/queries/useTagsQuery";
import ProductTagBadge from "src/frontend/pages/catalog/ProductTagBadge";
import useProductCatalogFiltersStore from "src/frontend/stores/useProductCatalogFilters";
import GenericTrpcRefetchFunction from "src/frontend/types/GenericTrpcRefetchFunction";
import tagToFancyBoxItem from "src/frontend/utils/tagToFancyBoxItem";
import { DownloadProductCatalogCsvResponseType } from "src/shared/api/downloadProductCatalogCsvApiSchema";
import MAX_TAG_TITLE_LENGTH from "src/shared/constants/MaxTagTitleLength";
import PRODUCT_CATALOG_PAGE_SIZE from "src/shared/constants/ProductCatalogPageSize";
import { ProductTagType } from "src/shared/trpc/common/ProductTag";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeString from "src/shared/types/maybe/MaybeString";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import len from "src/shared/utils/arrays/len";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import deepEquals from "src/shared/utils/objects/deepEquals";
import truncateTextEnd from "src/shared/utils/strings/truncateTextEnd";
import invariant from "tiny-invariant";

type CreateTagDialogProps = {
  disabled?: boolean;
  existingTags?: FancyBoxItem[];
  fetchFullProductCatalog?: () => Promise<
    MaybeNull<DownloadProductCatalogCsvResponseType>
  >;
  productName?: MaybeString;
  refetchFn: GenericTrpcRefetchFunction;
  singleSkuSelectionProductId?: MaybeString;
  tags?: ProductTagType[];
  totalCount: number;
  trigger?: React.ReactElement;
};

export default function CreateTagDialog({
  disabled,
  existingTags = [],
  fetchFullProductCatalog,
  productName,
  refetchFn,
  singleSkuSelectionProductId,
  tags = [],
  totalCount,
  trigger,
}: CreateTagDialogProps) {
  const t = useToast();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [titleError, setTitleError] = useState<MaybeString>(null);
  const [manageTags, setManageTags] = useState(false);
  const [tagOptions, setTagOptions] = useState<FancyBoxItem[]>([]);
  const [title, setTitle] = useState("");
  const [selectedTags, setSelectedTags] = useState<FancyBoxItem[]>([]);
  const selectedProductIds = useProductCatalogFiltersStore(
    (state) => state.selectedProductIds,
  );
  const tagsQuery = useTagsQuery();
  const createOrUpdateTagMutation =
    TrpcClient.internal.createOrUpdateTag.useMutation();
  const updateTagTitleMutation =
    TrpcClient.internal.updateTagTitle.useMutation();
  const deleteTagMutation = TrpcClient.internal.deleteTag.useMutation();

  useEffect(() => {
    if (tagsQuery.data != null) {
      const options = tagsQuery.data.tags
        .filter((tag) => !tag.is_luca_tag)
        .map((tag) => tagToFancyBoxItem(tag));
      const filteredOptions = options.filter(
        (tag) =>
          tags.find((productTag) => productTag.title === tag.value) == null,
      );
      if (!deepEquals(filteredOptions, tagOptions)) {
        setTagOptions(filteredOptions);
      }
    }
  }, [tags, tagOptions, tagsQuery]);

  const onClose = () => {
    setTitle("");
    setTitleError(null);
    setManageTags(false);
    setTagOptions([]);
    setSelectedTags([]);
    setMutationLoading(false);
    setDialogOpen(false);
  };

  const refetchQueries = () => {
    void tagsQuery.refetch();
    refetchFn();
  };

  const validateTagTitle = () => {
    if (arrayEmpty(selectedTags) && title === "") {
      setTitleError("Tag title is required.");
      return false;
    }

    if (tags.find((tag) => tag.title === title) != null) {
      setTitleError("Tag already exists.");
      return false;
    }

    if (title.length > MAX_TAG_TITLE_LENGTH) {
      setTitleError(
        `Tag must be less than ${MAX_TAG_TITLE_LENGTH} characters.`,
      );
      return false;
    }
    return true;
  };

  const onConfirm = async () => {
    const tagTitleValid = validateTagTitle();
    if (tagTitleValid === false) {
      return;
    }

    setMutationLoading(true);
    let productIdsToTag: string[] = [];
    if (singleSkuSelectionProductId != null) {
      productIdsToTag = [singleSkuSelectionProductId];
    } else if (len(selectedProductIds) < PRODUCT_CATALOG_PAGE_SIZE) {
      productIdsToTag = selectedProductIds;
    } else {
      invariant(fetchFullProductCatalog != null);
      const fullProductCatalogData = await fetchFullProductCatalog();
      if (fullProductCatalogData != null) {
        productIdsToTag = fullProductCatalogData.product_ids;
      } else {
        t.errorToast("Failed to create tag...");
        return;
      }
    }

    createOrUpdateTagMutation.mutate(
      {
        create:
          title === ""
            ? undefined
            : {
                title,
              },
        productIds: productIdsToTag,
        update: selectedTags.map((tag) => tag.id!),
      },
      {
        onError: () => {
          t.errorToast("Failed to create tag...");
          setMutationLoading(false);
        },
        onSuccess: () => {
          t.successToast(`${len(productIdsToTag)} SKUs tagged successfully.`);
          refetchQueries();
          setMutationLoading(false);
          onClose();
        },
      },
    );
  };

  const updateTagTitle = (
    tagId: string,
    title: string,
    setIsEditing: Dispatch<SetStateAction<boolean>>,
  ) => {
    setMutationLoading(true);
    updateTagTitleMutation.mutate(
      {
        tagId: tagId,
        title,
      },
      {
        onError: () => {
          setMutationLoading(false);
          t.errorToast("Failed to update tag title.");
        },
        onSuccess: () => {
          t.successToast("Title updated successfully.");
          refetchQueries();
          setIsEditing(false);
          setMutationLoading(false);
        },
      },
    );
  };

  const handleDeleteTag = (tagId: string) => {
    setMutationLoading(true);
    deleteTagMutation.mutate(
      {
        tagId,
      },
      {
        onError: () => {
          t.errorToast("Failed to delete tag...");
          setMutationLoading(false);
        },
        onSuccess: () => {
          t.successToast("Tag deleted successfully.");
          refetchQueries();
          setMutationLoading(false);
        },
      },
    );
  };

  const countEstimate = formatNumberRounded(
    singleSkuSelectionProductId != null
      ? 1
      : len(selectedProductIds) < PRODUCT_CATALOG_PAGE_SIZE
        ? len(selectedProductIds)
        : totalCount,
  );

  const dialogTitle = productName
    ? `Tag ${truncateTextEnd(productName, 28)}`
    : `Tag ${countEstimate} Selected SKUs`;

  const confirmTitle = productName
    ? `Tag SKU`
    : `Tag ${countEstimate} Selected SKUs`;

  return (
    <Dialog
      confirmTitle={manageTags ? "Done" : confirmTitle}
      dialogTitle={manageTags ? "Manage Tags" : dialogTitle}
      leftButton={
        !manageTags && arrayNotEmpty(tagOptions) ? (
          <Button
            className="pl-0"
            onClick={() => setManageTags(true)}
            variant="link"
          >
            Manage Tags
          </Button>
        ) : undefined
      }
      loading={mutationLoading}
      modal={false}
      onConfirm={() => {
        if (manageTags) {
          setManageTags(false);
        } else {
          void onConfirm();
        }
      }}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        setDialogOpen(open);
      }}
      open={dialogOpen}
      trigger={
        trigger ?? (
          <Button
            disabled={
              arrayEmpty(selectedProductIds) ?? disabled ?? mutationLoading
            }
            variant="basic"
          >
            Tag Selected SKUs
          </Button>
        )
      }
    >
      {manageTags ? (
        <Col className="max-h-[400px] gap-1 overflow-y-auto">
          {(tagsQuery.data?.tags ?? []).map((tag) => {
            return (
              <div key={tag.id}>
                <ProductTagBadge
                  confirmText="Delete Tag"
                  description={
                    <span>
                      <Bold>{tag.title}</Bold> will be deleted. Any tagged
                      products will be untagged, and the tag will be removed
                      from any pricing strategies it is associated with.
                    </span>
                  }
                  enableEditing
                  loading={mutationLoading}
                  onConfirm={() => handleDeleteTag(tag.id)}
                  tag={{ ...tag, user_tagged: false }}
                  updateTagTitle={updateTagTitle}
                />
              </div>
            );
          })}
        </Col>
      ) : (
        <Col className="gap-4">
          {arrayNotEmpty(existingTags) && (
            <div>
              <Bold>Existing tags:</Bold>
              <FancyBoxSelectedItemsList selectedItems={existingTags} />
            </div>
          )}
          {arrayNotEmpty(tagOptions) && (
            <>
              <Bold>Select Tags</Bold>
              <FancyBox
                disableCreateNew
                disableEditing
                items={tagOptions}
                loading={tagsQuery.isLoading}
                renderDefaultColorForSelectedItem
                renderSelectedItemLabel={(item) => (
                  <FancyBoxLabel content={item.label} />
                )}
                searchText="Search tags"
                selectTitle="Select tags"
                selectedItems={selectedTags}
                setItems={setTagOptions}
                setSelectedItems={setSelectedTags}
              />
            </>
          )}
          <Bold>Create a New Tag</Bold>
          <Row className="mb-2 items-start justify-between gap-2">
            <div className="w-full">
              <Input
                className="w-[300px]"
                disabled={mutationLoading}
                errorMessage={titleError}
                onChange={(e) => {
                  setTitleError(null);
                  setTitle(e.target.value);
                }}
                placeholder="Tag title"
                value={title}
              />
            </div>
          </Row>
        </Col>
      )}
    </Dialog>
  );
}
