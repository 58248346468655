import {
  TrpcErrorVariants,
  TrpcErrorVariantsType,
} from "src/shared/errors/TrpcErrorVariants";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

export default function parseTrpcError(
  error: any,
): MaybeNull<TrpcErrorVariantsType> {
  try {
    const errors = Array.isArray(error.meta.responseJSON)
      ? error.meta.responseJSON
      : [error.meta.responseJSON];
    for (const responseJSON of errors) {
      const errorMessage = responseJSON.error.json.message;
      const parsedMessage = JSON.parse(errorMessage);
      return TrpcErrorVariants.parse(parsedMessage.json);
    }
    return null;
  } catch (err) {
    return null;
  }
}
