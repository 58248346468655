import React from "react";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type ColProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function Col({ children, className, ...rest }: ColProps) {
  return (
    <div className={cn("flex flex-col", className)} {...rest}>
      {children}
    </div>
  );
}
