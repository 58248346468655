import { Maybe } from "src/shared/types/maybe/Maybe";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import dayjs from "src/shared/utils/dates/dayjs";

export type LiveDuration = {
  daysLiveLabel: MaybeString;
  liveDuration: MaybeNumber;
};

export default function getLiveDuration(
  start: Maybe<Date>,
  end: Maybe<Date>,
): LiveDuration {
  if (start == null || end == null) {
    return {
      daysLiveLabel: null,
      liveDuration: null,
    };
  }
  const liveDuration = Math.abs(dayjs(start).diff(end, "days"));
  const daysLiveLabel = liveDuration > 0 ? `${liveDuration}` : "<1";
  return {
    daysLiveLabel,
    liveDuration,
  };
}
