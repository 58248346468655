import { SearchIcon } from "lucide-react";
import { Input } from "src/frontend/components/ui/Input";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";

type SearchInputProps = {
  className?: ClassNameProp;
  disabled?: boolean;
  inputClassName?: ClassNameProp;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
};

export default function SearchInputV2({
  className,
  disabled,
  onChange,
  placeholder = "Search",
  value,
}: SearchInputProps) {
  return (
    <Input
      className={cn(
        "h-9 w-full border-r bg-transparent px-4 py-2 text-sm placeholder:text-neutral-800 focus:outline-none",
        className,
        disabled && "cursor-not-allowed",
      )}
      disabled={disabled}
      icon={<SearchIcon size={14} />}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value}
    />
  );
}
