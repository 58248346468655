import { Event, Calendar, dayjsLocalizer } from "react-big-calendar";
import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import useFlags from "src/frontend/hooks/useFlags";
import dayjs from "src/shared/utils/dates/dayjs";

const localizer = dayjsLocalizer(dayjs);

const EVENTS: Event[] = [
  {
    allDay: true,
    end: new Date(2024, 0, 20),
    start: new Date(2024, 0, 4),
    title: "Handle Price Plan",
  },
  {
    allDay: true,
    end: new Date(2024, 0, 30),
    start: new Date(2024, 0, 15),
    title: "Good Eggs Price Plan",
  },

  {
    end: new Date(2024, 1, 22, 0, 0, 0),
    start: new Date(2024, 0, 8, 0, 0, 0),
    title: "Burrow Price Plan",
  },
];

type TestCalendarProps = {
  hide?: boolean;
};

/**
 * Docs: https://github.com/jquense/react-big-calendar (custom styles overrides are defined in rbc.scss).
 */
export default function TestCalendar({ hide = false }: TestCalendarProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Calendar</TestComponentHeading>
      {/* @ts-ignore */}
      <Calendar
        endAccessor="end"
        events={EVENTS}
        localizer={localizer}
        startAccessor="start"
        style={{ height: 650 }}
        views={["month", "week"]}
      />
    </div>
  );
}
