import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import usePricingRulesGroupDropdownStateStore from "src/frontend/stores/usePricingRulesGroupDropdownStateStore";
import invariant from "tiny-invariant";

type DuplicatePricingRulesGroupModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function DuplicatePricingRulesGroupModal({
  isModalOpen,
  setIsModalOpen,
}: DuplicatePricingRulesGroupModalProps) {
  const t = useToast();
  const pricingStrategyQuery = useGetPricingStrategyByIdQuery();
  const { loading, pricingRulesGroupId } =
    usePricingRulesGroupDropdownStateStore();
  const duplicatePricingRulesGroupMutation =
    TrpcClient.internal.duplicatePricingRulesGroup.useMutation();

  const onSuccess = async () => {
    await pricingStrategyQuery.refetch();
    usePricingRulesGroupDropdownStateStore.setState({
      isDuplicateModalOpen: false,
      loading: false,
      pricingRulesGroupId: null,
    });
    setIsModalOpen(false);
    t.successToast("Pricing rules group duplicated successfully.");
  };

  const handleDuplicate = () => {
    usePricingRulesGroupDropdownStateStore.setState({ loading: true });
    invariant(pricingRulesGroupId != null);
    duplicatePricingRulesGroupMutation.mutate(
      {
        pricingRulesGroupId,
      },
      {
        onError: () => {
          t.errorToast("Failed to duplicate pricing rules group.");
          usePricingRulesGroupDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Modal
      confirmText="Duplicate"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            This pricing rules group will be duplicated in the current strategy.
          </p>
        </div>
      }
      dialogTitle="Duplicate Pricing Rules Group"
      loading={loading}
      onConfirm={handleDuplicate}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
