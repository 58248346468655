import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";

export default function isPriceComparisonConstraintValid(
  constraint: PricingConstraintType,
  modeling_price: MaybeUndefined<PriceTargetType>,
): boolean {
  if (constraint.type === "PRICE_COMPARISON") {
    if (constraint.comparison_price_target == null) {
      return false;
    }

    if (
      constraint.price_target_type === modeling_price &&
      constraint.comparison_price_target !== "PRE_LUCA_PRICE"
    ) {
      return false;
    }
  }

  return true;
}
