import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { useState } from "react";
import Row from "src/frontend/components/Row";
import { Modal } from "src/frontend/components/ui/Modal";
import { Textarea } from "src/frontend/components/ui/Textarea";
import useToast from "src/frontend/components/ui/useToast";
import COLORS from "src/frontend/constants/Colors";
import useGetCompetitorMatchReviewsQuery from "src/frontend/hooks/queries/useGetCompetitorMatchReviewsQuery";
import { MatchReviewType } from "src/shared/trpc/common/enum/MatchReview";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import invariant from "tiny-invariant";

type ReviewMatchModalProps = {
  onConfirm: (
    review: MatchReviewType,
    reason: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) => void;
  onOpenChange: (state: boolean) => void;
  open: boolean;
  review: MaybeNull<MatchReviewType>;
  uniqueId: string;
};

export default function ReviewMatchModal({
  onConfirm,
  onOpenChange,
  open,
  review,
  uniqueId,
}: ReviewMatchModalProps) {
  const t = useToast();
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const matchReviewsQuery = useGetCompetitorMatchReviewsQuery();

  const reviews = matchReviewsQuery.data ?? [];
  const currentReview =
    uniqueId == null
      ? null
      : reviews.find((review) => review.unique_id === uniqueId) ?? null;
  const existingReason = currentReview?.reason ?? null;
  const reviewMatchesCurrent = review === currentReview?.review;

  const ReviewIcon = review === "UP" ? ThumbsUpIcon : ThumbsDownIcon;

  const onSuccess = () => {
    setReason("");
    onOpenChange(false);
    setIsLoading(false);
    t.successToast("Review saved successfully!");
  };

  const onError = () => {
    setIsLoading(false);
    t.errorToast({ description: "Failed to submit review." });
  };

  return (
    <Modal
      confirmText="Submit"
      dialogDescription={
        <>
          {existingReason == null || !reviewMatchesCurrent ? (
            <>
              <p>Include an optional reason for this review.</p>
              {existingReason != null && (
                <p>
                  The existing reason &quot;
                  {existingReason}
                  &quot; will be overwritten if you provide a new reason.
                </p>
              )}
              <Textarea
                autoFocus
                className="mt-4"
                onChange={(e) => setReason(e.target.value)}
                placeholder="Include optional reason..."
                value={reason}
              />
            </>
          ) : (
            <>
              {existingReason != null && (
                <p>
                  This will cancel the current review and it&apos;s reason:
                  &quot;
                  {existingReason}
                  &quot;
                </p>
              )}
            </>
          )}
        </>
      }
      dialogTitle={
        <Row className="justify-between">
          Review Competitor Match
          <ReviewIcon
            color={COLORS.PRIMARY}
            fill={COLORS.PRIMARY}
            fillOpacity={0.4}
            opacity={0.8}
            strokeWidth={1.5}
          />
        </Row>
      }
      loading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        invariant(review != null);
        onConfirm(review, reason, onSuccess, onError);
      }}
      onOpenChange={(open) => {
        if (!open) {
          setReason("");
        }
        onOpenChange(open);
      }}
      open={open}
    />
  );
}
