import Papa from "papaparse";
import { useState, useEffect, ChangeEvent, useRef } from "react";
import { ValidatePricingRulesGroupSkusResponseType } from "src/backend/internal-api/mutations/validatePricingRulesGroupSkusMutation";
import TrpcClient from "src/frontend/api/TrpcClient";
import Bold from "src/frontend/components/Bold";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import Dialog from "src/frontend/components/ui/Dialog";
import { Input } from "src/frontend/components/ui/Input";
import { Textarea } from "src/frontend/components/ui/Textarea";
import useToast from "src/frontend/components/ui/useToast";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import copyTextToClipboard from "src/frontend/utils/copyTextToClipboard";
import CsvRecord from "src/shared/types/CsvRecord";
import MaybeString from "src/shared/types/maybe/MaybeString";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import dedupeArray from "src/shared/utils/arrays/dedupeArray";
import diffArrays from "src/shared/utils/arrays/diffArrays";
import len from "src/shared/utils/arrays/len";
import isCsvFile from "src/shared/utils/csv/isCsvFile";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import lowercaseObjectKeys from "src/shared/utils/objects/lowercaseObjectKeys";

type AddSkusDialogProps = {
  disabled: boolean;
  onAddSkus: (skus: string[]) => void;
  skuValues: string[];
  titleOverride?: string;
};

export default function AddSkusDialog({
  disabled,
  onAddSkus,
  skuValues,
  titleOverride = "Or, add a list of SKUs",
}: AddSkusDialogProps) {
  const t = useToast();
  const { isMedium } = useBreakpoints();
  const [csvUploadContent, setCsvUploadContent] = useState<MaybeString>(null);
  const [skusError, setSkusError] = useState<MaybeString>(null);
  const [skusDialogOpen, setSkusDialogOpen] = useState(false);
  const csvFileInputRef = useRef<HTMLInputElement>(null);
  const [skus, setSkus] = useState(skuValues.join(","));
  const validatePricingRulesGroupSkusMutation =
    TrpcClient.internal.validatePricingRulesGroupSkus.useMutation();

  const hasSelectedSkus = arrayNotEmpty(skuValues);

  useEffect(() => {
    setSkus(skuValues.join(","));
  }, [skuValues]);

  const onClose = () => {
    setSkus(skuValues.join(","));
    setCsvUploadContent(null);
    setSkusError(null);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files == null) {
      t.errorToast("No file found.");
      return;
    }

    const file = files[0];
    if (!isCsvFile(file)) {
      t.errorToast({
        description: (
          <span>
            File must be a CSV file ending with a <Code>.csv</Code> extension.
          </span>
        ),
        title: "Invalid file type",
      });
      if (csvFileInputRef.current != null) {
        csvFileInputRef.current.value = "";
      }
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      if (e.target == null) {
        t.errorToast("There was an error reading the CSV file.");
        return;
      }

      let content = e.target.result;
      if (typeof content !== "string") {
        t.errorToast("Invalid file contents.");
        return;
      }

      if (!content.includes(",")) {
        content = content.replaceAll("\n", ",");
        content = content.replaceAll("\r", "");
      }

      const parseResult = Papa.parse<CsvRecord>(content, {
        header: true,
      });

      const fields = parseResult.meta.fields ?? [];
      const skuHeader = fields
        .map((val) => val.toLowerCase())
        .find((val) => val === "sku");
      if (skuHeader != null) {
        const skus = parseResult.data
          .map(lowercaseObjectKeys)
          .map((val) => val[skuHeader])
          .join(",");
        setCsvUploadContent(skus);
      } else {
        t.infoToast(
          "No SKU column headers found in CSV. All data will be interpreted as SKU values.",
        );
        setCsvUploadContent(content);
      }
    };
  };

  const handleCopyInvalidSkus = async (skus: string[]) => {
    await copyTextToClipboard(skus.join(","));
    t.dismiss();
    t.successToast(`${len(skus)} SKUs copied to clipboard`);
  };

  const onValidateSuccess = (
    data: ValidatePricingRulesGroupSkusResponseType,
  ) => {
    const anyInvalid = arrayNotEmpty(data.invalid);
    const invalidCount = len(data.invalid);
    const validSkus = dedupeArray(data.valid);
    const addedSkus = diffArrays(validSkus, skuValues);
    const anySkusAdded = arrayNotEmpty(addedSkus);
    const addedSkusCount = len(addedSkus);

    const numInvalidSkusToShow = 10;
    const invalidSkusToShow = data.invalid.slice(0, numInvalidSkusToShow);
    const invalidSkusSummary = (
      <Col>
        {invalidSkusToShow.map((sku, index) => (
          <Code className="my-1" key={sku + index}>
            {sku}
          </Code>
        ))}
      </Col>
    );
    const invalidSkusSummaryCount = len(invalidSkusToShow);
    const hasExtra = invalidCount > numInvalidSkusToShow;
    const extraCount = invalidCount - invalidSkusSummaryCount;

    const extraMsg = hasExtra ? (
      <div className="my-1">
        <Bold>+{extraCount} more...</Bold>
      </div>
    ) : (
      ""
    );
    const copyInvalidSkus = (
      <Button
        className="my-2"
        onClick={() => {
          void handleCopyInvalidSkus(data.invalid);
        }}
        variant="basic"
      >
        Copy invalid SKUs to clipboard
      </Button>
    );

    if (anyInvalid && !anySkusAdded) {
      t.errorToast(
        <span>
          {invalidCount} SKUs were identified as invalid and will not be added:{" "}
          {invalidSkusSummary}
          {extraMsg}
          {copyInvalidSkus}
        </span>,
      );
    } else if (anySkusAdded && anyInvalid) {
      t.warningToast(
        <div>
          <Bold>{addedSkusCount} SKUs added successfully.</Bold>
          <div className="mt-2">
            {invalidCount} were identified as invalid and will not be added:{" "}
            {invalidSkusSummary}
            {extraMsg}
            {copyInvalidSkus}
          </div>
        </div>,
      );
    } else if (addedSkusCount > 0) {
      t.successToast(`All ${addedSkusCount} SKUs added successfully.`);
    } else {
      t.infoToast("No new SKUs added.");
    }

    onAddSkus(validSkus);
    onClose();
    setSkusDialogOpen(false);
  };

  const title = `${arrayNotEmpty(skuValues) ? "Edit" : "Add"} SKUs`;
  return (
    <Dialog
      confirmTitle={title}
      dialogTitle={title}
      loading={validatePricingRulesGroupSkusMutation.isLoading}
      onConfirm={() => {
        const skuInput = skus.split(",").map((sku) => sku.trim());
        const skuUpload = csvUploadContent?.split(",") ?? "";
        const allInputSkus = dedupeArray(skuInput.concat(skuUpload));
        validatePricingRulesGroupSkusMutation.mutate(
          {
            skus: allInputSkus,
          },
          {
            onError: () => {
              t.errorToast("Failed to validate SKUs...");
            },
            onSuccess: (data) => {
              onValidateSuccess(data);
            },
          },
        );
      }}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        setSkusDialogOpen(open);
      }}
      open={skusDialogOpen}
      trigger={
        <Button className="min-w-fit px-1" disabled={disabled} variant="link">
          {isMedium
            ? hasSelectedSkus
              ? "Edit"
              : "Add SKUs."
            : hasSelectedSkus
              ? "Edit selected SKUs"
              : titleOverride}
        </Button>
      }
    >
      <Textarea
        className="max-h-[500px] min-h-[124px]"
        errorMessage={skusError}
        onChange={(event) => setSkus(event.target.value)}
        placeholder="Enter list of comma separated SKUs"
        spellCheck={false}
        value={skus}
      />
      <Col className="gap-1">
        <p>Or upload a CSV file</p>
        <Input
          className="py-2 hover:cursor-pointer"
          id="sku-csv-file"
          onChange={(e) => handleUploadFile(e)}
          ref={csvFileInputRef}
          type="file"
        />
        <p className="mt-2 text-xs">
          CSV files must either be a list of comma separated SKUs or a
          structured CSV where the SKU column header is &quot;sku&quot; (case
          insensitive).
        </p>
      </Col>
      <UploadedCsvSkuPreview csv={csvUploadContent} />
    </Dialog>
  );
}

type UploadedCsvSkuPreviewProps = {
  csv: MaybeString;
};

const UPLOAD_SKU_NUMBER_TO_PREVIEW = 25;
function UploadedCsvSkuPreview({ csv }: UploadedCsvSkuPreviewProps) {
  if (csv == null) {
    return null;
  }

  const skus = csv.includes(",") ? csv.split(",") : csv.split("\n");
  const extra =
    len(skus) > UPLOAD_SKU_NUMBER_TO_PREVIEW
      ? len(skus) - UPLOAD_SKU_NUMBER_TO_PREVIEW
      : null;
  const preview = skus.slice(0, UPLOAD_SKU_NUMBER_TO_PREVIEW);
  const totalCount = len(skus);
  return (
    <div>
      <p className="text-base">{totalCount} Total SKUs Found:</p>
      <div className="max-h-44 overflow-y-scroll">
        {preview.map((sku, index) => {
          return (
            <Badge className="m-0.2" key={sku + index} variant="outline">
              <Code>{sku}</Code>
            </Badge>
          );
        })}
        {extra != null && (
          <Badge className="m-1" variant="outline">
            +{formatNumberRounded(extra)} more...
          </Badge>
        )}
      </div>
    </div>
  );
}
