import { cn } from "src/frontend/components/ui/utils";
import { MatchDetailsType } from "src/shared/trpc/common/CompetitorPricingObject";
import {
  getPrimaryMatchType,
  getPrimaryMatchTypeLabel,
  PrimaryMatchType,
} from "src/shared/utils/primaryMatchType";

const matchTypeToBadgeStyleMap: Record<PrimaryMatchType, string> =
  Object.freeze({
    DIFFERENT_BRAND: "bg-blue-100 text-blue-600",
    DIFFERENT_FLAVOR: "bg-[#fcf6d8] text-[#d6aa0f]",
    DIFFERENT_FLAVOR_AND_SIZE: "bg-pink-50 text-pink-700",
    DIFFERENT_ORGANICNESS: "bg-orange-100 text-amber-600",
    DIFFERENT_SIZE: "bg-purple-100 text-purple-600",
    DIFFERENT_VARIETY: "bg-cyan-50 text-cyan-600",
    EXACT_MATCH: "bg-[#e0efd9] text-[#61932f]",
    SIMILAR_PRODUCT: "bg-fuchsia-50 text-fuchsia-500",
  });

export default function MatchDetailBadge({
  matchDetails,
  matchLevel,
}: {
  matchDetails: MatchDetailsType;
  matchLevel: number | null;
}) {
  const primaryMismatchType = getPrimaryMatchType(matchDetails, matchLevel);
  const primaryMismatchTypeLabel =
    getPrimaryMatchTypeLabel(primaryMismatchType);
  return (
    <p
      className={cn(
        "rounded-sm px-2 py-0.5 text-sm",
        matchTypeToBadgeStyleMap[primaryMismatchType],
      )}
    >
      {primaryMismatchTypeLabel}
    </p>
  );
}
