import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type SemiBoldProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function SemiBold({
  children,
  className,
  ...rest
}: SemiBoldProps) {
  return (
    <span className={cn("font-semibold", className)} {...rest}>
      {children}
    </span>
  );
}
