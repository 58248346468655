import { ChevronRight, LineChartIcon, PlusCircleIcon } from "lucide-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import { PricingStrategyModals } from "src/frontend/components/GlobalModalsContainer";
import Row from "src/frontend/components/Row";
import ThreeDotsVertical from "src/frontend/components/ThreeDotsVertical";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import SemiBold from "src/frontend/components/ui/SemiBold";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import COLORS from "src/frontend/constants/Colors";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import PricingStrategyIsLiveToggle from "src/frontend/pages/company/relationships/PricingStrategyIsLiveToggle";
import StrategyDropdownMenu, {
  STRATEGY_DROPDOWN_ID,
} from "src/frontend/pages/company/strategy/StrategyDropdownMenu";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { PricingStrategyType } from "src/shared/trpc/common/PricingStrategy";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";

const TRIGGER_ID_PREFIX = "strategy-is-live-trigger-";

function PriceParityGroupsLoadingSkeleton() {
  return (
    <div>
      <Row className="justify-between">
        <Skeleton className="h-12 w-72" />
        <Skeleton className="h-12 w-44" />
      </Row>
      {range(6).map((val) => (
        <Skeleton className="mt-4 h-[50px] w-full" key={val} />
      ))}
    </div>
  );
}

type InnerProps = {
  pricingStrategies: PricingStrategyType[];
};

function Inner({ pricingStrategies }: InnerProps) {
  const navigate = useNavigate();
  const [isPromptOpenId, setIsPromptOpenId] = useState("");
  const { isDeleteModalOpen, isDuplicateModalOpen, isGenerateModalOpen } =
    usePricingStrategyDropdownStateStore();
  return (
    <div>
      <PricingStrategyModals />
      <Row className="justify-end">
        <Link to="/company-settings/strategy/create">
          <Button>
            <PlusCircleIcon className="mr-2" size={16} strokeWidth={2} /> Add
            New
          </Button>
        </Link>
      </Row>
      <div className="mt-6">
        {arrayEmpty(pricingStrategies) && (
          <p>
            No product relationships exist yet. Add a new one to get started.
          </p>
        )}
        <Col className="gap-3">
          {pricingStrategies.map((strategy, index) => {
            return (
              <Card
                className="flex flex-row justify-between gap-2 px-6 py-2 hover:cursor-pointer hover:shadow"
                key={strategy.id}
                onClick={(e) => {
                  const target = e.target as Element;
                  let isPromptElement =
                    isPromptOpenId ||
                    isDeleteModalOpen ||
                    isDuplicateModalOpen ||
                    isGenerateModalOpen ||
                    target.id.startsWith(TRIGGER_ID_PREFIX);

                  let node: MaybeNull<Element> = target;
                  while (node != null) {
                    if (node.id === STRATEGY_DROPDOWN_ID) {
                      isPromptElement = true;
                      break;
                    }
                    node = node.parentElement;
                  }

                  if (!isPromptElement) {
                    navigate(`/company-settings/strategy/${strategy.id}`);
                  }
                }}
              >
                <Row className="gap-2">
                  <LineChartIcon stroke={COLORS.LUCA_RED} strokeWidth={2.5} />
                  <p>
                    <SemiBold>
                      {index + 1}. {strategy.name}
                    </SemiBold>
                  </p>
                </Row>
                <Row className="gap-3">
                  {strategy.objective != null && (
                    <Badge className="inline w-[145px] text-sm" variant="muted">
                      <p>
                        Increase{" "}
                        {formatGenericEnumLabel(strategy.objective.type)}
                      </p>
                    </Badge>
                  )}
                  <Badge className="inline w-[135px] text-sm" variant="muted">
                    <p>
                      {formatNumberRounded(len(strategy.rulesGroups))} Rules
                      Groups
                    </p>
                  </Badge>
                  <Badge className="inline w-[135px] text-sm" variant="muted">
                    <p>{formatNumberRounded(strategy?.skus_count ?? 0)} SKUs</p>
                  </Badge>
                  <PricingStrategyIsLiveToggle
                    isPromptOpen={isPromptOpenId === strategy.id}
                    setIsPromptOpen={(open) =>
                      setIsPromptOpenId(open ? strategy.id : "")
                    }
                    strategy={strategy}
                    trigger={
                      <Badge
                        className="inline w-[65px] text-sm"
                        id={`${TRIGGER_ID_PREFIX}${strategy.id}`}
                        variant={strategy.is_live ? "default" : "destructive"}
                      >
                        {strategy.is_live ? "Live" : "Inactive"}
                      </Badge>
                    }
                  />
                  <StrategyDropdownMenu
                    pricingStrategyId={strategy.id}
                    trigger={
                      <Button className="px-3 py-0" variant="ghost">
                        <ThreeDotsVertical />
                      </Button>
                    }
                  />
                  <ChevronRight opacity={0.6} size={32} strokeWidth={1.5} />
                </Row>
              </Card>
            );
          })}
        </Col>
      </div>
    </div>
  );
}

export default function StrategiesSummary() {
  const pricingStrategyQuery = useGetPricingStrategiesQuery();

  if (pricingStrategyQuery.isLoading) {
    return <PriceParityGroupsLoadingSkeleton />;
  }

  if (pricingStrategyQuery.isError) {
    return <p>Failed to load pricing strategies.</p>;
  }

  return <Inner pricingStrategies={pricingStrategyQuery.data} />;
}
