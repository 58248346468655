import * as React from "react";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import MaybeString from "src/shared/types/maybe/MaybeString";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  disableLastPassAutoFill?: boolean;
  errorClassName?: ClassNameProp;
  errorMessage?: MaybeString;
  icon?: React.ReactNode;
  testId?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      disableLastPassAutoFill,
      errorClassName,
      errorMessage,
      icon,
      testId,
      type,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="relative">
        <input
          className={cn(
            "flex h-9 w-full rounded-lg border border-input bg-transparent px-3 py-1 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-neutral-400",
            disableLastPassAutoFill && "lp-ignore",
            className,
            icon != null && "pl-8",
          )}
          data-testid={testId}
          ref={ref}
          type={type}
          {...props}
        />
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
            {icon}
          </div>
        )}
        {errorMessage != null && (
          <ErrorLabel className={errorClassName}>{errorMessage}</ErrorLabel>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
