import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import { Prompt } from "src/frontend/components/ui/Prompt";
import useToast from "src/frontend/components/ui/useToast";
import useGetCombinedPricingStrategySkuCountQuery from "src/frontend/hooks/queries/useGetCombinedPricingStrategySkuCountQuery";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import { PricingRulesGroupType } from "src/shared/trpc/common/PricingRulesGroup";
import MaybeString from "src/shared/types/maybe/MaybeString";

type PricingRulesGroupIsActiveToggleProps = {
  pricingStrategyId: MaybeString;
  rulesGroup: PricingRulesGroupType;
  trigger: React.ReactElement | string;
};

export default function PricingRulesGroupIsActiveToggle({
  pricingStrategyId,
  rulesGroup,
  trigger,
}: PricingRulesGroupIsActiveToggleProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { errorToast, successToast } = useToast();
  const [isPromptOpenState, setIsPromptOpenState] = useState(false);

  const pricingStrategiesQuery = useGetPricingStrategiesQuery();
  const pricingStrategyByIdQuery = useGetPricingStrategyByIdQuery();
  const combinedSkuCountQuery =
    useGetCombinedPricingStrategySkuCountQuery(pricingStrategyId);
  const updatePricingRulesGroupIsActiveMutation =
    TrpcClient.internal.updatePricingRulesGroupIsActive.useMutation();

  const isActive = rulesGroup?.is_active ?? false;
  const verb = isActive ? "Disable" : "Enable";

  const onSuccess = async () => {
    void pricingStrategiesQuery.refetch();
    await Promise.all([
      combinedSkuCountQuery.refetch(),
      pricingStrategyByIdQuery.refetch(),
    ]);
    setIsLoading(false);
    setIsPromptOpenState(false);
    successToast("Pricing rules updated successfully.");
  };

  const handleToggleIsActive = (strategy: PricingRulesGroupType) => {
    setIsLoading(true);
    updatePricingRulesGroupIsActiveMutation.mutate(
      {
        isActive: !isActive,
        pricingRulesGroupId: strategy.id,
      },
      {
        onError: () => {
          errorToast("Failed to update pricing rules.");
          setIsLoading(false);
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Prompt
      confirmText={`${verb} Pricing Rules`}
      description={
        <p>This change will be applied to all future price plans.</p>
      }
      loading={isLoading}
      onConfirm={() => handleToggleIsActive(rulesGroup)}
      onOpenChange={setIsPromptOpenState}
      open={isPromptOpenState}
      title={`${verb} Pricing Rules`}
      trigger={trigger}
    />
  );
}
