import TAILWIND_COLORS, {
  TailwindColorKey,
} from "src/frontend/constants/TailwindColors";

const { DARK, LIGHT } = TAILWIND_COLORS;

export default function getTailwindColor(
  key: TailwindColorKey,
  isDarkTheme: boolean,
): string {
  const COLORS = isDarkTheme ? DARK : LIGHT;
  return COLORS[key];
}
