import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function roundToNearest(
  n: number,
  nearest: number,
  direction: "ceil" | "floor",
): number {
  switch (direction) {
    case "floor":
      return Math.floor(n / nearest) * nearest;
    case "ceil":
      return Math.ceil(n / nearest) * nearest;
    default:
      return assertUnreachable(direction);
  }
}
