import { ChevronLeft } from "lucide-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { cn } from "src/frontend/components/ui/utils";
import useAuthStore from "src/frontend/stores/useAuthStore";
import useBrandStore from "src/frontend/stores/useBrandStore";

type HeaderProps = {
  backTo?: string;
  className?: string;
  disabled?: boolean;
  downloadButton?: ReactNode;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
};

export default function Header({
  backTo,
  className = "mb-12",
  disabled = false,
  leftNode,
  rightNode,
}: HeaderProps) {
  const brand_name = useBrandStore((state) => state.brand_name);
  const user_name = useAuthStore((state) => state.user?.displayName);
  const company = {
    name: brand_name,
    user_name,
  };
  return (
    <div
      className={cn(
        "relative flex flex-col justify-between sm:flex-row",
        className,
      )}
    >
      <div className="relative flex flex-row items-start">
        {backTo && (
          <Link
            className={cn(
              "relative -left-2 rounded-full px-0 py-2 hover:opacity-75",
              disabled && "pointer-events-none",
            )}
            to={backTo}
          >
            <ChevronLeft size={36} strokeWidth={3} />
          </Link>
        )}
        {leftNode ?? (
          <div className="flex flex-col">
            <h2 className="flex items-center text-3xl font-extrabold">
              {company.name}
            </h2>
            <p className="text-lg">Welcome, {company.user_name}!</p>
          </div>
        )}
      </div>
      {rightNode != null && (
        <div className="mt-3 flex max-h-fit flex-1 flex-row items-start justify-start sm:mt-0 sm:justify-end">
          {rightNode}
        </div>
      )}
    </div>
  );
}
