import { ProductAttributesBaseObject } from "src/shared/trpc/common/ProductAttributesBaseObject";
import ZodDateInput from "src/shared/zod/ZodDateInput";
import { z } from "zod";

export const ProductMasterObject = z.object({
  active_status: z.boolean().nullable(),
  attributes: z.any().nullable(),
  barcode: z.string().nullable(),
  brand_id: z.string().uuid().nullable(),
  categories: z.string().array(),
  cogs: z.number().nullable(),
  cpu: z.number().nullable(),
  discount: z.number().nullable(),
  discounted_price: z.number().nullable(),
  id: z.string(),
  int_product_attributes: ProductAttributesBaseObject.nullable(),
  inventory_level: z.number().nullable(),
  list_price: z.number().nullable(),
  member_price: z.number().nullable(),
  misc: z.any().nullable(),
  platform: z.string().nullable(),
  price_zone: z.string().nullable(),
  product_brand_name: z.string().nullable(),
  product_description: z.string().nullable(),
  product_id: z.string().nullable(),
  product_image: z.string().nullable(),
  product_name: z.string().nullable(),
  product_sku: z.string().nullable(),
  product_tags: z.string().array(),
  product_url: z.string().nullable(),
  sku: z.string().nullable(),
  sub_categories: z.string().array(),
  subscriber_price: z.number().nullable(),
  updated_at: ZodDateInput.nullable(),
  variant_id: z.string().nullable(),
});

export type ProductMasterObjectType = z.infer<typeof ProductMasterObject>;
