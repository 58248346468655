import {
  discountColumns as CIDiscountColumns,
  memberColumns as CIMemberColumns,
  subscriberColumns as CISubscriberColumns,
} from "src/frontend/hooks/columnVisibilityHooks/useCompetitorIntelligenceColumnVisibility";
import {
  discountColumns as PPDiscountColumns,
  memberColumns as PPMemberColumns,
  subscriberColumns as PPSubscriberColumns,
} from "src/frontend/hooks/columnVisibilityHooks/usePricePlanColumnVisibility";
import {
  discountColumns as PCDiscountColumns,
  memberColumns as PCMemberColumns,
  subscriberColumns as PCSubscriberColumns,
} from "src/frontend/hooks/columnVisibilityHooks/useProductCatalogColumnVisibility";
import useBrandStore from "src/frontend/stores/useBrandStore";
import {
  VisibilityFilterKeyType,
  VisibilityFilterKeyUnion,
} from "src/shared/trpc/common/UserCommonVisibilityFilters";

const featureColumns: Record<
  VisibilityFilterKeyType,
  {
    discountColumns: Set<VisibilityFilterKeyUnion>;
    memberColumns: Set<VisibilityFilterKeyUnion>;
    subscriberColumns: Set<VisibilityFilterKeyUnion>;
  }
> = {
  competitor_intelligence: {
    discountColumns: CIDiscountColumns,
    memberColumns: CIMemberColumns,
    subscriberColumns: CISubscriberColumns,
  },
  price_plan: {
    discountColumns: PPDiscountColumns,
    memberColumns: PPMemberColumns,
    subscriberColumns: PPSubscriberColumns,
  },
  product_catalog: {
    discountColumns: PCDiscountColumns,
    memberColumns: PCMemberColumns,
    subscriberColumns: PCSubscriberColumns,
  },
};

function useVisibilityColumnsToHideForBrand(page: VisibilityFilterKeyType) {
  const brand = useBrandStore();
  const {
    discounted_price_enabled,
    member_price_enabled,
    subscriber_price_enabled,
  } = brand;

  const { discountColumns, memberColumns, subscriberColumns } =
    featureColumns[page];
  let disabledColumns = new Set();

  if (!member_price_enabled) {
    disabledColumns = disabledColumns.union(memberColumns);
  }
  if (!discounted_price_enabled) {
    disabledColumns = disabledColumns.union(discountColumns);
  }
  if (!subscriber_price_enabled) {
    disabledColumns = disabledColumns.union(subscriberColumns);
  }

  return disabledColumns;
}

export default useVisibilityColumnsToHideForBrand;
