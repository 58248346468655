import MaybeNumber from "src/shared/types/maybe/MaybeNumber";

export default function roundToNearestHundredths(
  val: MaybeNumber,
): MaybeNumber {
  if (val == null) {
    return null;
  }

  return Math.round(val / 100) * 100;
}
