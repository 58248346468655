import mappedColumnVisibility from "src/frontend/hooks/columnVisibilityHooks/mappedColumnVisibility";
import useBrandStore from "src/frontend/stores/useBrandStore";
import { CompetitorIntelligenceVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";

export const memberColumns = new Set<
  keyof CompetitorIntelligenceVisibilityFiltersType
>(["member_price", "member_price_margin", "member_price_contribution_margin"]);

export const discountColumns = new Set<
  keyof CompetitorIntelligenceVisibilityFiltersType
>([
  "discounted_price",
  "discounted_price_margin",
  "discounted_price_contribution_margin",
]);

export const subscriberColumns = new Set<
  keyof CompetitorIntelligenceVisibilityFiltersType
>([
  "subscriber_price",
  "subscriber_price_margin",
  "subscriber_price_contribution_margin",
]);

export default function useCompetitorIntelligenceColumnVisibility(
  visibilityFilters: CompetitorIntelligenceVisibilityFiltersType,
) {
  const brand = useBrandStore();
  const {
    discounted_price_enabled,
    member_price_enabled,
    subscriber_price_enabled,
  } = brand;

  const filters = visibilityFilters;

  if (!member_price_enabled) {
    memberColumns.forEach((key) => (filters[key] = false));
  }
  if (!discounted_price_enabled) {
    discountColumns.forEach((key) => (filters[key] = false));
  }
  if (!subscriber_price_enabled) {
    subscriberColumns.forEach((key) => (filters[key] = false));
  }

  return mappedColumnVisibility(filters);
}
