import { ProductCatalogFiltersInput } from "src/shared/trpc/common/ProductCatalogFiltersInput";
import { ProductMasterSortKey } from "src/shared/trpc/common/ProductMasterSortKey";
import { SortDirection } from "src/shared/zod/SortDirection";
import { z } from "zod";

export const ProductCatalogQueryInput = ProductCatalogFiltersInput.merge(
  z.object({
    page: z.number().default(1),
    returnAllResults: z.boolean().default(false).optional(),
    searchQuery: z.string().optional(),
    showActiveSkus: z.boolean(),
    showInactiveSkus: z.boolean(),
    sortBy: ProductMasterSortKey,
    sortDirection: SortDirection,
  }),
);

export type ProductCatalogQueryInputType = z.infer<
  typeof ProductCatalogQueryInput
>;
