import { useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import COLORS from "src/frontend/constants/Colors";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import useRechartsStyles from "src/frontend/hooks/useRechartsStyles";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { ExperimentDistributionType } from "src/shared/trpc/common/ExperimentDistribution";
import {
  PriceTargetType,
  PriceTargetTypeVariants,
} from "src/shared/trpc/common/enum/PriceTargetType";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import getFirst from "src/shared/utils/arrays/getFirst";
import getLast from "src/shared/utils/arrays/getLast";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import roundToNearest from "src/shared/utils/numbers/roundToNearest";
import invariant from "tiny-invariant";

type PriceDistributionData = {
  count: number;
  percentage: number;
};

type MarginDistributionData = {
  amount: number;
};

function getMarginChartData(values: number[]): MarginDistributionData[] {
  return values.map((val) => ({ amount: val * 100 }));
}

function bucketPriceChangesIntoEvenlySpacedBinsForChart(
  values: number[],
): PriceDistributionData[] {
  if (arrayEmpty(values)) {
    return [];
  }

  const percentages = values.map((value) => Math.round(value * 100));
  const min = getFirst(percentages)!;
  const max = getLast(percentages)!;
  const spacer = Math.ceil(max - min);
  const bins: MaybeNumber[] = range(spacer * 2).map((_) => null);
  percentages.forEach((pct) => {
    const current = bins[spacer + pct];
    bins[spacer + pct] = (current ?? 0) + 1;
  });
  let idx = 0;
  let leftStart = null;
  let rightEnd = null;
  while (leftStart == null && idx < bins.length) {
    if (bins[idx] != null) {
      leftStart = idx;
      break;
    }
    idx++;
  }
  idx = bins.length;
  while (rightEnd == null && idx > 0) {
    if (bins[idx] != null) {
      rightEnd = idx;
      break;
    }
    idx--;
  }
  const left = leftStart ?? 0;
  const right = rightEnd ?? bins.length;
  const result: PriceDistributionData[] = [];
  bins.forEach((val, idx) => {
    if (idx >= left && idx <= right) {
      result.push({
        count: val ?? 0,
        percentage: idx - spacer,
      });
    }
  });
  const neg = getFirst(result)!.percentage;
  const pos = getLast(result)!.percentage;
  const diff = Math.abs(Math.abs(pos) - Math.abs(neg));
  if (Math.abs(neg) > Math.abs(pos)) {
    const offset: PriceDistributionData[] = [];
    let pct = pos;
    while (len(offset) <= diff) {
      offset.push({
        count: 0,
        percentage: pct,
      });
      pct++;
    }
    return [...result, ...offset];
  } else {
    const offset: PriceDistributionData[] = [];
    let pct = neg;
    while (len(offset) <= diff) {
      offset.push({
        count: 0,
        percentage: pct,
      });
      pct--;
    }
    return [...offset.reverse(), ...result];
  }
}

const AXIS_INCREMENT = 5;

function getAxisTicksByIncrement(
  distribution: PriceDistributionData[],
): number[] {
  if (arrayEmpty(distribution)) {
    return [];
  }

  const min = getFirst(distribution)!.percentage;
  const max = getLast(distribution)!.percentage;
  let start = min;
  while (start % AXIS_INCREMENT !== 0) {
    start--;
  }
  let end = max;
  while (end % AXIS_INCREMENT !== 0) {
    end++;
  }
  const ticks: number[] = [start];
  let current = start;
  while (current <= end) {
    current += AXIS_INCREMENT;
    ticks.push(current);
  }
  return ticks;
}

type DistributionChartProps = {
  distribution: PriceDistributionData[];
  priceTarget: PriceTargetType;
};

function PriceDistributionChart({
  distribution,
  priceTarget,
}: DistributionChartProps) {
  const { axisColor, backgroundColor, fontFamily, gridColor } =
    useRechartsStyles();
  return (
    <Col className="w-full">
      <p className="pb-1 pl-8 font-semibold">
        Price Percentage Change Distribution (
        {formatGenericEnumLabel(priceTarget)})
      </p>
      <ResponsiveContainer height={265} style={{ backgroundColor }} width="99%">
        <BarChart
          data={distribution}
          height={265}
          margin={{
            bottom: 30,
            left: 0,
            right: 6,
            top: 10,
          }}
          width={500}
        >
          <CartesianGrid
            stroke={gridColor}
            strokeDasharray="3 3"
            vertical={false}
          />
          <XAxis
            dataKey="percentage"
            label={{
              dy: 20,
              style: { fontFamily, fontSize: 12 },
              value: "Price Percent Change",
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tickFormatter={(value) => `${value}%`}
            ticks={getAxisTicksByIncrement(distribution)}
          />
          <YAxis
            label={{
              angle: -90,
              dx: -18,
              style: { fontFamily, fontSize: 12 },
              value: "Number of SKUs",
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tickCount={8}
          />
          <Tooltip
            // @ts-ignore
            content={<PriceDistributionTooltip />}
          />
          <Bar dataKey="count" radius={[50, 50, 0, 0]}>
            {distribution.map((entry, index) => (
              <Cell
                fill={entry.percentage < 0 ? COLORS.LUCA_RED : COLORS.MINT}
                key={`cell-${index}`}
                opacity={0.8}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Col>
  );
}

type PriceDistributionTooltipProps = {
  active: boolean;
  payload: { payload: PriceDistributionData }[];
};

function PriceDistributionTooltip({
  active,
  payload,
}: PriceDistributionTooltipProps) {
  const { tooltipStyle } = useRechartsStyles();
  if (!active || payload == null) {
    return null;
  }

  const { count = null, percentage = null } = getFirst(payload)?.payload ?? {};
  return (
    <div className="rounded-sm border bg-card p-2" style={tooltipStyle}>
      <p>
        {count} SKUs have a {formatSignedPercent(percentage)} Price Change
      </p>
    </div>
  );
}

const getMarginGradientOffset = (
  distribution: MarginDistributionData[],
): number => {
  const data = distribution.map((val) => val.amount);
  const min = Math.min(...data);
  const max = Math.max(...data);

  if (max <= 0) {
    return 0;
  }
  if (min >= 0) {
    return 1;
  }

  return max / (max - min);
};

const MARGIN_LIMIT_FRACTION = 0.75;

const getMarginVerticalAxisLimits = (
  distribution: MarginDistributionData[],
): [number, number] => {
  const data = distribution.map((val) => val.amount);
  const min = getFirst(data)!;
  const max = getLast(data)!;
  return [
    roundToNearest(min * MARGIN_LIMIT_FRACTION, 5, "floor"),
    roundToNearest(max * MARGIN_LIMIT_FRACTION, 5, "ceil"),
  ];
};

type MarginDistributionChartProps = {
  distribution: MarginDistributionData[];
  priceTarget: PriceTargetType;
};

function MarginDistributionChart({
  distribution,
  priceTarget,
}: MarginDistributionChartProps) {
  const { axisColor, backgroundColor, fontFamily, gridColor } =
    useRechartsStyles();
  const offset = getMarginGradientOffset(distribution);
  const domain = getMarginVerticalAxisLimits(distribution);
  return (
    <Col className="w-full">
      <p className="pl-8 font-semibold">
        Margin Change Distribution ({formatGenericEnumLabel(priceTarget)})
      </p>
      <ResponsiveContainer height={265} style={{ backgroundColor }} width="99%">
        <AreaChart
          data={distribution}
          height={265}
          margin={{
            bottom: 28,
            left: 6,
            right: 6,
            top: 10,
          }}
          width={500}
        >
          <CartesianGrid
            stroke={gridColor}
            strokeDasharray="3 3"
            vertical={false}
          />
          <XAxis
            label={{
              dy: 20,
              style: { fontFamily, fontSize: 12 },
              value: "SKU",
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tick={false}
            tickFormatter={(_) => ""}
          />
          <YAxis
            allowDataOverflow
            domain={domain}
            label={{
              angle: -90,
              dx: -30,
              style: { fontFamily, fontSize: 12 },
              value: "Margin Change Amount",
            }}
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tickCount={8}
            tickFormatter={(val) => `${val}%`}
            type="number"
          />
          {/* @ts-ignore */}
          <Tooltip content={<MarginDistributionTooltip />} />
          <defs>
            <linearGradient id="marginSplitColor" x1="0" x2="0" y1="0" y2="1">
              <stop offset={offset} stopColor={COLORS.MINT} stopOpacity={1} />
              <stop
                offset={offset}
                stopColor={COLORS.LUCA_RED}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Area
            dataKey="amount"
            fill="url(#marginSplitColor)"
            stroke="rgb(50, 50, 50)"
            type="monotone"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Col>
  );
}

type MarginDistributionTooltipProps = {
  active: boolean;
  payload: { payload: MarginDistributionData }[];
};

function MarginDistributionTooltip({
  active,
  payload,
}: MarginDistributionTooltipProps) {
  const { tooltipStyle } = useRechartsStyles();
  if (!active || payload == null) {
    return null;
  }

  const { amount = null } = getFirst(payload)?.payload ?? {};
  return (
    <div className="rounded-sm border bg-card p-2" style={tooltipStyle}>
      <p>Margin Change Amount = {formatSignedPercent(amount)}</p>
    </div>
  );
}

function priceOptionAvailable(
  data: ExperimentDistributionType,
  priceTargetType: PriceTargetType,
): boolean {
  const hasListPriceData = arrayNotEmpty(data.list_price);
  const hasMemberPriceData = arrayNotEmpty(data.member_price);
  const hasSubscriberPriceData = arrayNotEmpty(data.subscriber_price);
  const hasDiscountedPriceData = arrayNotEmpty(data.discounted_price);
  switch (priceTargetType) {
    case "LIST_PRICE":
      return hasListPriceData;
    case "END_CUSTOMER_PRICE":
      return false;
    case "MEMBER_PRICE":
      return hasMemberPriceData;
    case "SUBSCRIBER_PRICE":
      return hasSubscriberPriceData;
    case "DISCOUNTED_PRICE":
      return hasDiscountedPriceData;
    default:
      return assertUnreachable(priceTargetType);
  }
}

type ChartData = {
  marginDistribution: MarginDistributionData[];
  priceDistribution: PriceDistributionData[];
};

function getChartData(
  data: ExperimentDistributionType,
  priceTargetType: PriceTargetType,
): ChartData {
  const {
    discounted_price,
    discounted_price_margin,
    list_price,
    list_price_margin,
    member_price,
    member_price_margin,
    subscriber_price,
    subscriber_price_margin,
  } = data;
  switch (priceTargetType) {
    case "LIST_PRICE":
      return {
        marginDistribution: getMarginChartData(list_price_margin),
        priceDistribution:
          bucketPriceChangesIntoEvenlySpacedBinsForChart(list_price),
      };
    case "MEMBER_PRICE":
      return {
        marginDistribution: getMarginChartData(member_price_margin),
        priceDistribution:
          bucketPriceChangesIntoEvenlySpacedBinsForChart(member_price),
      };
    case "SUBSCRIBER_PRICE":
      return {
        marginDistribution: getMarginChartData(subscriber_price_margin),
        priceDistribution:
          bucketPriceChangesIntoEvenlySpacedBinsForChart(subscriber_price),
      };
    case "DISCOUNTED_PRICE":
      return {
        marginDistribution: getMarginChartData(discounted_price_margin),
        priceDistribution:
          bucketPriceChangesIntoEvenlySpacedBinsForChart(discounted_price),
      };
    case "END_CUSTOMER_PRICE":
      invariant(false, "End Customer Price not supported");
    default:
      return assertUnreachable(priceTargetType);
  }
}

export default function ExperimentsDistributionChart() {
  const [priceTargetType, setPriceTargetType] =
    useState<PriceTargetType>("LIST_PRICE");
  const query = useGetPricePlanSummaryQuery();

  if (query.isLoading) {
    return <p>Loading...</p>;
  } else if (query.isError) {
    return <p>Error...</p>;
  }

  const distribution_metrics = query.data?.distribution_metrics;
  if (distribution_metrics == null) {
    return null;
  }

  const { marginDistribution, priceDistribution } = getChartData(
    distribution_metrics,
    priceTargetType,
  );
  return (
    <Card>
      <Row className="justify-end px-4 pt-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="mr-1 mt-1 flex items-center justify-start">
              <p className="text-sm">•••</p>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {PriceTargetTypeVariants.map((priceTarget) => {
              const available = priceOptionAvailable(
                distribution_metrics,
                priceTarget,
              );
              return (
                <DropdownMenuCheckboxItem
                  checked={priceTargetType === priceTarget}
                  className="hover:cursor-pointer"
                  disabled={!available}
                  key={priceTarget}
                  onClick={() => setPriceTargetType(priceTarget)}
                >
                  <Row className="w-36">
                    <p>{formatGenericEnumLabel(priceTarget)}</p>
                  </Row>
                </DropdownMenuCheckboxItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </Row>
      <Row className="p-2 pt-0">
        <PriceDistributionChart
          distribution={priceDistribution}
          priceTarget={priceTargetType}
        />
        <MarginDistributionChart
          distribution={marginDistribution}
          priceTarget={priceTargetType}
        />
      </Row>
    </Card>
  );
}
