export default function truncateTextEnd(
  text: string,
  lengthToShow = 4,
): string {
  const minimumLength = lengthToShow + 1;
  if (text.length < minimumLength) {
    return text;
  }

  const truncated = text.slice(0, lengthToShow);
  return `${truncated}...`;
}
