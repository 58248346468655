import { ProductConditionFilters } from "src/shared/trpc/common/ProductConditionFilters";
import { z } from "zod";

export const ProductCatalogFiltersInput = ProductConditionFilters.merge(
  z.object({
    brandId: z.string(),
    categories: z.string().array(),
    includeEmptyCategory: z.boolean(),
    includeEmptyPriceZone: z.boolean(),
    includeEmptyProductBrandName: z.boolean(),
    includeEmptySubCategory: z.boolean(),
    includeEmptySupplier: z.boolean(),
    includeUntaggedSkus: z.boolean(),
    priceZones: z.string().array(),
    productBrandNames: z.string().array(),
    searchQuery: z.string().optional(),
    subCategories: z.string().array(),
    suppliers: z.string().array(),
    tagIds: z.string().array(),
  }),
);

export type ProductCatalogFiltersInputType = z.infer<
  typeof ProductCatalogFiltersInput
>;
