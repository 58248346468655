import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "src/frontend/components/ui/Toast";
import { usePlainToast } from "src/frontend/components/ui/usePlainToast";

export default function Toaster() {
  const { toasts } = usePlainToast();
  return (
    <ToastProvider swipeDirection="right">
      {toasts.map(({ action, description, id, timeout, title, ...props }) => {
        return (
          <Toast duration={timeout} key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
