export default function getMiddleElementInArray<T>(
  arr: T[],
  returnElementRightOfMiddleIfEven = false,
): T {
  const mid = Math.floor(arr.length / 2);
  if (arr.length % 2 === 0) {
    return returnElementRightOfMiddleIfEven ? arr[mid] : arr[mid - 1];
  }
  return arr[mid];
}
