import React from "react";
import Col from "src/frontend/components/Col";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import TestCalendar from "src/frontend/components/test-components/TestCalendar";
import TestChart from "src/frontend/components/test-components/TestChart";
import TestDatePicker from "src/frontend/components/test-components/TestDatePicker";
import TestDrawer from "src/frontend/components/test-components/TestDrawer";
import TestFancyBox from "src/frontend/components/test-components/TestFancyBox";
import TestHoverCard from "src/frontend/components/test-components/TestHoverCard";
import TestProgressBar from "src/frontend/components/test-components/TestProgressBar";
import TestResizable from "src/frontend/components/test-components/TestResizable";
import TestRestApiComponent from "src/frontend/components/test-components/TestRestApiComponent";
import TestSingleDatePicker from "src/frontend/components/test-components/TestSingleDatePicker";
import TestSortableContainer from "src/frontend/components/test-components/TestSortableContainer";

function Components() {
  return (
    <Col className="gap-2">
      <TestRestApiComponent />
      <TestDatePicker />
      <TestSingleDatePicker />
      <TestDrawer />
      <TestProgressBar />
      <TestResizable />
      <TestHoverCard />
      <TestCalendar />
      <TestFancyBox hide />
      <TestSortableContainer hide />
      <TestChart hide />
    </Col>
  );
}

export default function TestComponents() {
  return (
    <PageContainer>
      <PageTitle>Test Components</PageTitle>
      <p className="text-xs">
        This page is for rendering out UI components for testing or development.
      </p>
      <Components />
    </PageContainer>
  );
}
