import { ChevronsUpDown } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/frontend/components/ui/Accordion";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/frontend/components/ui/Popover";
import { ScrollArea } from "src/frontend/components/ui/ScrollArea";
import {
  INCLUDE_EMPTY_CATEGORY_ITEMS,
  INCLUDE_EMPTY_PRICE_ZONE_ITEMS,
  INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS,
  INCLUDE_EMPTY_SUB_CATEGORY_ITEMS,
  INCLUDE_EMPTY_SUPPLIER_ITEMS,
  INCLUDE_UNTAGGED_PRODUCTS_ITEM,
  SHOW_SKUS_OPTIONS,
} from "src/frontend/constants/CustomProductFilters";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import groupBy from "src/shared/utils/arrays/groupBy";

const ENABLE_SEARCH = true;

type ProductCategoryFilterProps = {
  activeInactiveFiltersEnabled?: boolean;
  height?: number;
  items: FancyBoxItem[];
  loading?: boolean;
  searchText?: string;
  selectTitle?: string;
  selectedItems: FancyBoxItem[];
  setSelectedItems: React.Dispatch<React.SetStateAction<FancyBoxItem[]>>;
  setSelectedItemsOverrideFn?: (item: FancyBoxItem) => void;
  width?: number;
};

// This is a modification of the FancyBox multi-select component.
export default function ProductCategoryFilter({
  activeInactiveFiltersEnabled,
  items,
  loading = false,
  selectTitle = "Select items",
  selectedItems,
  setSelectedItems,
  setSelectedItemsOverrideFn,
  width = 300,
}: ProductCategoryFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [openCombobox, setOpenCombobox] = useState(false);
  const [accordionValue, setAccordionValue] =
    useState<MaybeUndefined<ProductCategoryFilterType>>(undefined);

  const toggleItem = (item: FancyBoxItem) => {
    if (setSelectedItemsOverrideFn != null) {
      setSelectedItemsOverrideFn(item);
      return;
    }
    setSelectedItems((currentItems) =>
      currentItems.find((current) => current.value === item.value) == null
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value),
    );
    inputRef?.current?.focus();
  };

  const onComboboxOpenChange = (value: boolean) => {
    inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
    setOpenCombobox(value);
  };

  // Hacky way to clear the search input when the combobox is closed. Without
  // the timeout the Popover flashes back into display again briefly.
  useEffect(() => {
    let timeout: MaybeNull<NodeJS.Timeout> = null;
    if (openCombobox === false && inputValue !== "") {
      timeout = setTimeout(() => {
        setInputValue("");
      }, 100);
    }
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [inputValue, openCombobox]);

  const itemsGroupedByItem = groupBy(items, "type");
  const allSkus = itemsGroupedByItem[ProductCategoryFilterType.AllSkus] ?? [];
  const categories =
    itemsGroupedByItem[ProductCategoryFilterType.Category] ?? [];
  const subCategories =
    itemsGroupedByItem[ProductCategoryFilterType.Subcategory] ?? [];
  const brandNames =
    itemsGroupedByItem[ProductCategoryFilterType.ProductBrandName] ?? [];
  const priceZones =
    itemsGroupedByItem[ProductCategoryFilterType.PriceZone] ?? [];
  const tags = itemsGroupedByItem[ProductCategoryFilterType.Tag] ?? [];
  const suppliers =
    itemsGroupedByItem[ProductCategoryFilterType.Supplier] ?? [];

  let contentHeight = undefined;
  if (searchText !== "") {
    contentHeight = 400;
  }

  const filteredSearchList = items.filter((item) => {
    return searchText === "" || item.value.toLowerCase().includes(searchText);
  });

  return (
    <div className="h-9" style={{ maxWidth: width }}>
      <Popover onOpenChange={onComboboxOpenChange} open={openCombobox}>
        <PopoverTrigger asChild>
          <Button
            aria-expanded={openCombobox}
            className="justify-between text-foreground"
            disabled={loading}
            role="combobox"
            style={{ width }}
            variant="basic"
          >
            {loading ? (
              "Loading..."
            ) : (
              <span className="truncate">
                {selectedItems.length === 0 && selectTitle}
                {selectedItems.length === 1 && selectedItems[0].label}
                {selectedItems.length === 2 &&
                  selectedItems.map(({ label }) => label).join(", ")}
                {selectedItems.length > 2 &&
                  `${selectedItems.length} items selected`}
              </span>
            )}
            <ChevronsUpDown className="ml-2 h-3 w-3 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className=" p-0" style={{ width }}>
          <ScrollArea style={{ height: contentHeight }}>
            {ENABLE_SEARCH && (
              <SearchInput
                onChange={setSearchText}
                placeholder="Search filters..."
                value={searchText}
              />
            )}
            {searchText !== "" ? (
              <div style={{ height: contentHeight }}>
                {Object.entries(groupBy(filteredSearchList, "type")).map(
                  ([type, items]) => {
                    if (
                      (type as ProductCategoryFilterType) ===
                      ProductCategoryFilterType.AllSkus
                    ) {
                      return null;
                    }

                    return (
                      <div key={type}>
                        <p className="pl-2 pt-1 text-sm font-bold">{type}</p>
                        {items.map((item) => {
                          const isActive = selectedItems.some(
                            (selected) => selected.value === item.value,
                          );
                          return (
                            <FancyBoxCommandItem
                              isActive={isActive}
                              item={item}
                              key={item.value}
                              toggleItem={toggleItem}
                            />
                          );
                        })}
                      </div>
                    );
                  },
                )}
              </div>
            ) : (
              <Accordion
                className="w-full"
                collapsible
                onValueChange={(val) =>
                  setAccordionValue(val as ProductCategoryFilterType)
                }
                type="single"
                value={accordionValue}
              >
                {arrayNotEmpty(allSkus) && (
                  <AccordionItem value={ProductCategoryFilterType.AllSkus}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.AllSkus}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      {allSkus.map((category) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === category.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={category}
                            key={category.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(categories) && (
                  <AccordionItem value={ProductCategoryFilterType.Category}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.Category}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={categories}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_EMPTY_CATEGORY_ITEMS.value,
                        )}
                        item={INCLUDE_EMPTY_CATEGORY_ITEMS}
                        key={INCLUDE_EMPTY_CATEGORY_ITEMS.value}
                        toggleItem={toggleItem}
                      />
                      {categories.map((category) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === category.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={category}
                            key={category.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(subCategories) && (
                  <AccordionItem value={ProductCategoryFilterType.Subcategory}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.Subcategory}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={subCategories}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_EMPTY_SUB_CATEGORY_ITEMS.value,
                        )}
                        item={INCLUDE_EMPTY_SUB_CATEGORY_ITEMS}
                        key={INCLUDE_EMPTY_SUB_CATEGORY_ITEMS.value}
                        toggleItem={toggleItem}
                      />
                      {subCategories.map((subCategory) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === subCategory.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={subCategory}
                            key={subCategory.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(brandNames) && (
                  <AccordionItem
                    value={ProductCategoryFilterType.ProductBrandName}
                  >
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.ProductBrandName}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={brandNames}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS.value,
                        )}
                        item={INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS}
                        key={INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS.value}
                        toggleItem={toggleItem}
                      />
                      {brandNames.map((brandName) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === brandName.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={brandName}
                            key={brandName.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(priceZones) && (
                  <AccordionItem value={ProductCategoryFilterType.PriceZone}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.PriceZone}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={priceZones}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_EMPTY_PRICE_ZONE_ITEMS.value,
                        )}
                        item={INCLUDE_EMPTY_PRICE_ZONE_ITEMS}
                        key={INCLUDE_EMPTY_PRICE_ZONE_ITEMS.value}
                        toggleItem={toggleItem}
                      />
                      {priceZones.map((priceZone) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === priceZone.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={priceZone}
                            key={priceZone.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(suppliers) && (
                  <AccordionItem value={ProductCategoryFilterType.Supplier}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.Supplier}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={suppliers}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_EMPTY_SUPPLIER_ITEMS.value,
                        )}
                        item={INCLUDE_EMPTY_SUPPLIER_ITEMS}
                        key={INCLUDE_EMPTY_SUPPLIER_ITEMS.value}
                        toggleItem={toggleItem}
                      />
                      {suppliers.map((supplier) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === supplier.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={supplier}
                            key={supplier.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {arrayNotEmpty(tags) && (
                  <AccordionItem value={ProductCategoryFilterType.Tag}>
                    <AccordionTrigger className="px-2 font-bold">
                      {ProductCategoryFilterType.Tag}
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      <AccordionSectionControls
                        items={tags}
                        selectedItems={selectedItems}
                        toggleItem={toggleItem}
                      />
                      <FancyBoxCommandItem
                        isActive={selectedItems.some(
                          (selected) =>
                            selected.value ===
                            INCLUDE_UNTAGGED_PRODUCTS_ITEM.value,
                        )}
                        item={INCLUDE_UNTAGGED_PRODUCTS_ITEM}
                        key={INCLUDE_UNTAGGED_PRODUCTS_ITEM.value}
                        toggleItem={toggleItem}
                      />
                      {tags.map((tag) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === tag.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={tag}
                            key={tag.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
                {activeInactiveFiltersEnabled && (
                  <AccordionItem value="ACTIVE_FILTER">
                    <AccordionTrigger className="px-2 font-bold">
                      Active / Inactive SKUs
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[275px] overflow-y-auto pl-1">
                      {SHOW_SKUS_OPTIONS.map((item) => {
                        const isActive = selectedItems.some(
                          (selected) => selected.value === item.value,
                        );
                        return (
                          <FancyBoxCommandItem
                            isActive={isActive}
                            item={item}
                            key={item.value}
                            toggleItem={toggleItem}
                          />
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                )}
              </Accordion>
            )}
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  );
}

type FancyBoxCommandItemProps = {
  isActive: boolean;
  item: FancyBoxItem;
  toggleItem: (item: FancyBoxItem) => void;
};

function FancyBoxCommandItem({
  isActive,
  item,
  toggleItem,
}: FancyBoxCommandItemProps) {
  return (
    <div
      className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:cursor-pointer hover:bg-accent hover:text-accent-foreground aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
      key={item.value}
      onClick={() => {
        toggleItem(item);
      }}
    >
      <Checkbox
        checkboxClassName="mr-2"
        checked={isActive}
        id="fancy-box-checkbox"
        onCheckedChange={() => null}
        size="sm"
      />
      <Col>
        <div className="flex-1 truncate">{item.label}</div>
      </Col>
    </div>
  );
}

type AccordionSectionControlsProps = {
  items: FancyBoxItem[];
  selectedItems: FancyBoxItem[];
  toggleItem: (item: FancyBoxItem) => void;
};

function AccordionSectionControls({
  items,
  selectedItems,
  toggleItem,
}: AccordionSectionControlsProps) {
  return (
    <Row className="mb-2 ml-2 gap-1">
      <Button
        className="px-1"
        onClick={() => {
          items.forEach((item) => {
            const isActive = selectedItems.some(
              (selected) => selected.value === item.value,
            );
            if (!isActive) {
              toggleItem(item);
            }
          });
        }}
        size="none"
        variant="hyperlink"
      >
        <p className="text-[10px]">select all</p>
      </Button>
      <Button
        className="px-1"
        onClick={() => {
          items.forEach((item) => {
            const isActive = selectedItems.some(
              (selected) => selected.value === item.value,
            );
            if (isActive) {
              toggleItem(item);
            }
          });
        }}
        size="none"
        variant="hyperlink"
      >
        <p className="text-[10px]">clear</p>
      </Button>
    </Row>
  );
}
