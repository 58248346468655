import { price_comparison_target } from "@prisma/client";
import { z } from "zod";

export const PriceComparisonTarget = z.nativeEnum(price_comparison_target);

export type PriceComparisonTargetType = z.infer<typeof PriceComparisonTarget>;

export const PriceComparisonTargetVariants = Object.keys(
  PriceComparisonTarget.enum,
) as PriceComparisonTargetType[];
