import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { cn } from "src/frontend/components/ui/utils";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    className={cn(
      "!z-150 overflow-hidden rounded-md border-2 border-border bg-card px-3 py-1.5 text-xs shadow-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    ref={ref}
    sideOffset={sideOffset}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
  side?: TooltipPrimitive.PopperContentProps["side"];
};

export default function Tooltip({
  children,
  content,
  disabled = false,
  side,
}: TooltipProps) {
  return (
    <TooltipProvider delayDuration={100}>
      <TooltipRoot>
        {!disabled && <TooltipContent side={side}>{content}</TooltipContent>}
        <TooltipTrigger asChild>{children}</TooltipTrigger>
      </TooltipRoot>
    </TooltipProvider>
  );
}
