import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { Label } from "src/frontend/components/ui/Label";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

const SwitchRoot = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
      )}
    />
  </SwitchPrimitives.Root>
));
SwitchRoot.displayName = SwitchPrimitives.Root.displayName;

type SwitchProps = HtmlDivProps & {
  checked: boolean;
  disabled: boolean;
  id: string;
  label?: string;
  onCheckedChange: (checked: boolean) => void;
};

export function Switch(props: SwitchProps) {
  const { checked, className, disabled, id, label, onCheckedChange, ...rest } =
    props;
  return (
    <div {...rest} className={cn("flex items-center space-x-2", className)}>
      <SwitchRoot
        checked={checked}
        disabled={disabled}
        id={id}
        onCheckedChange={onCheckedChange}
      />
      {label != null && (
        <Label className="hover:cursor-pointer" htmlFor={id}>
          {label}
        </Label>
      )}
    </div>
  );
}
