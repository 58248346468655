import posthog from "posthog-js";
import { useEffect, useState } from "react";
import useAuthStore from "src/frontend/stores/useAuthStore";

export default function usePostHogIdentify() {
  const [identified, setIdentified] = useState(false);
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (identified === false && user != null) {
      posthog.identify(user.uid, { email: user.email });
      setIdentified(true);
    }
  }, [identified, user]);

  return null;
}
