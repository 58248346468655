import MaxWidthContainer from "src/frontend/components/MaxWidthContainer";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

// import DevContextMenu from "src/frontend/components/dev/DevContextMenu";

export const PAGE_CONTAINER_ID = "page-container-id";

type PageContainerProps = HtmlDivProps & {
  enforceMaxWidth?: boolean;
  innerClassName?: ClassNameProp;
};

export default function PageContainer({
  children,
  className,
  enforceMaxWidth,
  innerClassName,
  ...props
}: PageContainerProps) {
  return (
    <div
      className={cn(
        "h-full overflow-y-scroll bg-n-0 p-6 pb-32 md:pb-16",
        className,
      )}
      id={PAGE_CONTAINER_ID}
      {...props}
    >
      {/* <DevContextMenu> */}
      <div className={cn("flex flex-1 flex-col", innerClassName)}>
        {enforceMaxWidth ? (
          <MaxWidthContainer>{children}</MaxWidthContainer>
        ) : (
          children
        )}
      </div>
      {/* </DevContextMenu> */}
    </div>
  );
}
