import LightLabel from "src/frontend/components/LightLabel";
import Row from "src/frontend/components/Row";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import { cn } from "src/frontend/components/ui/utils";
import useBrandStore, { BrandStore } from "src/frontend/stores/useBrandStore";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import priceTargetToLabel from "src/frontend/utils/priceTargetToLabel";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";

function getPriceTargetVariantsForBrand(brand: BrandStore): PriceTargetType[] {
  const variants: PriceTargetType[] = ["LIST_PRICE"];
  if (brand.member_price_enabled) {
    variants.push("MEMBER_PRICE");
  }
  if (brand.discounted_price_enabled) {
    variants.push("DISCOUNTED_PRICE");
  }
  if (brand.subscriber_price_enabled) {
    variants.push("SUBSCRIBER_PRICE");
  }
  return variants;
}

type PriceTargetSelectInput = {
  className?: ClassNameProp;
  disabled?: boolean;
  hasError?: boolean;
  loading?: boolean;
  onlyRenderSelectComponent?: boolean;
  setPriceTarget: (target: PriceTargetType) => void;
  target: MaybeUndefined<PriceTargetType>;
  title?: string;
  width?: number;
};

export default function PriceTargetSelectInput({
  className,
  disabled = false,
  hasError = false,
  loading = false,
  onlyRenderSelectComponent,
  setPriceTarget,
  target,
  title = "Impacted price",
  width = 300,
}: PriceTargetSelectInput) {
  const brand = useBrandStore((state) => state);
  const variants = getPriceTargetVariantsForBrand(brand);

  const SelectComponent = (
    <div style={{ width }}>
      <Select
        disabled={disabled || loading}
        onValueChange={(value) => {
          const target = value as PriceTargetType;
          setPriceTarget(target);
        }}
        value={target}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select One" />
        </SelectTrigger>
        <SelectContent
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {variants.map((variant) => {
            return (
              <SelectItem key={variant} value={variant}>
                {priceTargetToLabel(variant)}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );

  if (onlyRenderSelectComponent) {
    return SelectComponent;
  }

  return (
    <div className={cn(className)}>
      <LightLabel>{title}</LightLabel>
      <Row>{SelectComponent}</Row>
      {hasError && <ErrorLabel>{title} is required.</ErrorLabel>}
    </div>
  );
}
