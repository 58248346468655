import { z } from "zod";

export const ROUNDING_CONSTRAINT_PRICE_ENDING_VALUES = [
  z.literal("X9.00"),
  z.literal("X0.00"),
  z.literal("X5.00"),
  z.literal("XX.00"),
  z.literal("XX.99"),
  z.literal("XX.95"),
  z.literal("XX.X9"),
  z.literal("XX.X5"),
  z.literal("XX.X0"),
  z.literal("XX.29"),
  z.literal("XX.49"),
  z.literal("X50.00"),
  z.literal("X00.00"),
] as const;

export const ROUNDING_CONSTRAINT_PRICE_ENDING = z.union(
  ROUNDING_CONSTRAINT_PRICE_ENDING_VALUES,
);

export type RoundingConstraintPriceEndingType = z.infer<
  typeof ROUNDING_CONSTRAINT_PRICE_ENDING
>;
