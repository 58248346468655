import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetCompetitorBrandNamesQuery() {
  const { brandId, enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getCompetitorBrandNames.useQuery(
    {
      brandId,
    },
    {
      enabled: enabled,
    },
  );
}
