import { cn } from "src/frontend/components/ui/utils";

type LightLabelProps = React.HTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode;
};

export default function LightLabel({
  children,
  className,
  ...rest
}: LightLabelProps) {
  return (
    <label
      className={cn(
        "whitespace-nowrap text-xs font-normal leading-tight text-n-800 peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        className,
      )}
      {...rest}
    >
      {children}
    </label>
  );
}
