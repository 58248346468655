import { z } from "zod";

export const CompetitorPricingAveragePrices = z.object({
  avg_last_14_days_discounted_price: z.number().nullable(),
  avg_last_14_days_list_price: z.number().nullable(),
  avg_last_14_days_member_price: z.number().nullable(),
  avg_last_14_days_subscriber_price: z.number().nullable(),
  avg_last_1_day_discounted_price: z.number().nullable(),
  avg_last_1_day_list_price: z.number().nullable(),
  avg_last_1_day_member_price: z.number().nullable(),
  avg_last_1_day_subscriber_price: z.number().nullable(),
  avg_last_30_days_discounted_price: z.number().nullable(),
  avg_last_30_days_list_price: z.number().nullable(),
  avg_last_30_days_member_price: z.number().nullable(),
  avg_last_30_days_subscriber_price: z.number().nullable(),
  avg_last_7_days_discounted_price: z.number().nullable(),
  avg_last_7_days_list_price: z.number().nullable(),
  avg_last_7_days_member_price: z.number().nullable(),
  avg_last_7_days_subscriber_price: z.number().nullable(),
});

export type CompetitorPricingAveragePricesType = z.infer<
  typeof CompetitorPricingAveragePrices
>;
