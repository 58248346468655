import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Button from "src/frontend/components/ui/Button";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";

type RevertGroupModalProps = {
  allExperimentsSelected: boolean;
  experiment_ids: Set<string>;
  groupId: string;
  resetSelection: () => void;
};

export default function RevertGroupModal({
  allExperimentsSelected,
  experiment_ids,
  groupId,
  resetSelection,
}: RevertGroupModalProps) {
  const isBrandAdmin = useIsBrandAdmin();
  const { pricePlanId } = useParams();
  const tab = usePricePlanTab();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errorToast, successToast } = useToast();
  const navigate = useNavigate();
  const revertGroupMutation = TrpcClient.internal.revertGroup.useMutation();

  return (
    <Modal
      dialogDescription="This will publish the “previous price” for the selected SKUs. Are you sure you want to publish these changes to your store?"
      dialogTitle="Revert Changes"
      loading={revertGroupMutation.isLoading}
      onConfirm={() => {
        revertGroupMutation.mutate(
          {
            allExperimentsSelected,
            experimentIds: Array.from(experiment_ids),
            groupId: groupId,
          },
          {
            onError: () => {
              errorToast("Failed to revert changes.");
            },
            onSuccess: () => {
              setIsModalOpen(false);
              resetSelection();
              successToast("Changes reverted successfully.");
              if (allExperimentsSelected) {
                navigate(
                  pricePlanId && tab
                    ? `/pricing/${tab}/${pricePlanId}`
                    : "/pricing",
                  {
                    replace: true,
                  },
                );
              }
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button
          disabled={
            (experiment_ids.size === 0 && !allExperimentsSelected) ||
            !isBrandAdmin
          }
          size="sm"
          variant="outline"
        >
          Revert
        </Button>
      }
    />
  );
}
