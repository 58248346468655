import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import MaybeBoolean from "src/shared/types/maybe/MaybeBoolean";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function isConstraintSatisfied(
  constraint: PricingConstraintType,
): MaybeBoolean {
  const { type } = constraint;
  switch (type) {
    case "ROUNDING":
    case "PRICE_COMPARISON":
      return true;
    case "COMPETITOR_PRICE":
    case "AVERAGE_MARGIN":
    case "MARGIN":
    case "PRICE_CHANGE":
      return constraint.constraint_met;
    default:
      return assertUnreachable(type);
  }
}
