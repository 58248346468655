import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { ArrowLeft } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import { Input } from "src/frontend/components/ui/Input";
import { Prompt } from "src/frontend/components/ui/Prompt";
import COLORS from "src/frontend/constants/Colors";
import getFancyBoxItemBadgeStyle from "src/frontend/utils/getFancyBoxItemBadgeStyle";
import { ProductTagType } from "src/shared/trpc/common/ProductTag";
import noop from "src/shared/utils/noop";

type ProductTagBadgeProps = {
  confirmText: string;
  description: JSX.Element | string;
  disablePrompt?: boolean;
  enableEditing?: boolean;
  loading?: boolean;
  onConfirm: () => void;
  tag: ProductTagType;
  updateTagTitle?: (
    tagId: string,
    title: string,
    setIsEditing: Dispatch<SetStateAction<boolean>>,
  ) => void;
};

export default function ProductTagBadge({
  confirmText,
  description,
  disablePrompt = false,
  enableEditing = false,
  loading = false,
  onConfirm,
  tag,
  updateTagTitle = noop,
}: ProductTagBadgeProps) {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSaveTitle = () => {
    if (title === "") {
      setShowError(true);
      return;
    }
    updateTagTitle(tag.id, title, setEditing);
  };

  if (editing) {
    return (
      <Col className="my-2">
        <p className="text-xs">
          Editing: <span className="font-semibold">{tag.title}</span>
        </p>
        <Row className="mt-1 items-start justify-between gap-2">
          <div className="w-full px-1">
            <Input
              className="w-full"
              disabled={loading}
              errorMessage={showError ? "Title is required." : undefined}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Edit tag title"
              value={title}
            />
          </div>
          <Button
            disabled={loading}
            onClick={() => handleSaveTitle()}
            variant="basic"
          >
            Save
          </Button>
          <Button
            className="w-11"
            disabled={loading}
            onClick={() => setEditing(false)}
            size="icon"
            variant="basic"
          >
            <ArrowLeft strokeWidth={2} />
          </Button>
        </Row>
      </Col>
    );
  }

  const isLucaTag = tag.is_luca_tag;
  const canEditTag =
    tag.user_tagged || (!tag.is_luca_tag && !tag.is_imported_tag);
  return (
    <Badge
      key={tag.id}
      style={isLucaTag ? getFancyBoxItemBadgeStyle(COLORS.LUCA_TAG) : {}}
    >
      <p className="overflow-hidden truncate" style={{ maxWidth: 125 }}>
        {tag.title}
      </p>
      {enableEditing && canEditTag && (
        <button
          className="ml-1 rounded-full hover:cursor-pointer focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
          disabled={loading}
          onClick={() => {
            setTitle(tag.title);
            setEditing(true);
          }}
        >
          <Pencil1Icon />
        </button>
      )}
      {disablePrompt && canEditTag ? (
        <button
          className="ml-1 rounded-full hover:cursor-pointer focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
          disabled={loading}
          onClick={onConfirm}
        >
          <TrashIcon />
        </button>
      ) : canEditTag ? (
        <Prompt
          confirmText={confirmText}
          description={description}
          onConfirm={onConfirm}
          trigger={
            <button className="ml-1 rounded-full hover:cursor-pointer focus:outline-none focus:ring-0 disabled:cursor-not-allowed">
              <TrashIcon />
            </button>
          }
        />
      ) : null}
    </Badge>
  );
}
