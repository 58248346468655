import { CopyIcon, EyeIcon, PlusCircleIcon, TrashIcon } from "lucide-react";
import React from "react";
import Row from "src/frontend/components/Row";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import useBrandSettings from "src/frontend/hooks/useBrandSettings";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";

export const STRATEGY_DROPDOWN_ID = "strategy-dropdown-menu";

type StrategyDropdownMenuProps = {
  disabled?: boolean;
  pricingStrategyId: string;
  trigger: React.ReactNode;
};

export default function StrategyDropdownMenu({
  disabled = false,
  pricingStrategyId,
  trigger,
}: StrategyDropdownMenuProps) {
  const { disablePricePlanCreation } = useBrandSettings();

  const isLucaAdmin = useIsLucaAdmin();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="focus:outline-none focus:ring-0">
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" id={STRATEGY_DROPDOWN_ID}>
        <DropdownMenuItem
          className="hover:cursor-pointer"
          disabled={disabled}
          onClick={() => {
            usePricingStrategyDropdownStateStore.setState({
              isDuplicateModalOpen: true,
              pricingStrategyId,
            });
          }}
        >
          <Row className="gap-2">
            <CopyIcon size={16} />
            <p>Duplicate</p>
          </Row>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:cursor-pointer"
          disabled={disabled}
          onClick={() => {
            usePricingStrategyDropdownStateStore.setState({
              isDeleteModalOpen: true,
              pricingStrategyId,
            });
          }}
        >
          <Row className="gap-2">
            <TrashIcon size={16} />
            <p>Delete</p>
          </Row>
        </DropdownMenuItem>
        {isLucaAdmin && (
          <DropdownMenuItem
            className="hover:cursor-pointer"
            disabled={disabled}
            onClick={() =>
              usePricingStrategyDropdownStateStore.setState({
                isGenerateModalOpen: true,
                pricingStrategyId,
              })
            }
          >
            <Row className="gap-2">
              <EyeIcon size={16} />
              <p>Plan preview</p>
            </Row>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          className="hover:cursor-pointer"
          disabled={disablePricePlanCreation}
          onClick={() =>
            usePricingStrategyDropdownStateStore.setState({
              isCreateModalOpen: true,
              pricingStrategyId,
            })
          }
        >
          <Row className="gap-2">
            <PlusCircleIcon size={16} />
            <p>Create price plan</p>
          </Row>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
