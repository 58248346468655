import React from "react";
import Card from "src/frontend/components/Card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/frontend/components/ui/Tabs";
import { cn } from "src/frontend/components/ui/utils";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import ExperimentsDistributionChart from "src/frontend/pages/pricing/ExperimentsDistributionChart";
import ModelInputsChart from "src/frontend/pages/pricing/ModelInputsChart";
import PricePlanDetails from "src/frontend/pages/pricing/summary/PricePlanDetails";
import PricePlanSummary from "src/frontend/pages/pricing/summary/PricePlanSummary";

export default function PricePlanSummaryTabs() {
  const { isExtraLarge } = useBreakpoints();
  return (
    <Card
      className={cn(
        "h-[450px] rounded-3xl border-none",
        isExtraLarge && "h-full",
      )}
    >
      <Tabs
        className="h-full w-full rounded-3xl border border-border"
        defaultValue="plan-forecast"
      >
        <TabsList className="grid w-full grid-cols-5 border-b p-4">
          <TabsTrigger
            className="rounded-md"
            value="plan-forecast"
            variant="primary"
          >
            Forecast
          </TabsTrigger>
          <TabsTrigger
            className="rounded-md"
            value="plan-impact"
            variant="primary"
          >
            Impact
          </TabsTrigger>
          <TabsTrigger
            className="rounded-md"
            value="plan-metrics"
            variant="primary"
          >
            Plan Metrics
          </TabsTrigger>
          <TabsTrigger
            className="rounded-md"
            value="model-inputs"
            variant="primary"
          >
            Elasticities
          </TabsTrigger>
          <TabsTrigger
            className="rounded-md"
            value="plan-details"
            variant="primary"
          >
            Details
          </TabsTrigger>
        </TabsList>
        <TabsContent className="m-0 p-3" value="plan-forecast">
          <PricePlanSummary type="FORECAST" />
        </TabsContent>
        <TabsContent className="m-0 p-3" value="plan-impact">
          <PricePlanSummary type="IMPACT" />
        </TabsContent>
        <TabsContent className="m-0 p-3" value="plan-metrics">
          <ExperimentsDistributionChart />
        </TabsContent>
        <TabsContent className="m-0 p-3" value="model-inputs">
          <ModelInputsChart />
        </TabsContent>
        <TabsContent className="m-0 p-3" value="plan-details">
          <PricePlanDetails />
        </TabsContent>
      </Tabs>
    </Card>
  );
}
