import { z } from "zod";

export const InStockFilter = z.union([
  z.literal("IN_STOCK"),
  z.literal("NOT_IN_STOCK"),
  z.literal("ALL"),
]);

export type InStockFilterType = z.infer<typeof InStockFilter>;

export const InStockFilterVariants: InStockFilterType[] = [
  "IN_STOCK",
  "NOT_IN_STOCK",
  "ALL",
];
