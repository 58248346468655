import { ChevronDown } from "lucide-react";
import { useEffect, useRef } from "react";
import Button from "src/frontend/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import { Input } from "src/frontend/components/ui/Input";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  ExperimentFilterColumnBothFilter,
  ExperimentFilterColumnFilterEnum,
  ExperimentFilterColumnNumberFilter,
  ExperimentFilterColumnNumberFilterEnum,
  ExperimentFilterColumnRuleTypeFilter,
  ExperimentFilterColumnStringContainsFilter,
  ExperimentFilterColumnStringFilter,
  ExperimentFilterColumnStringFilterEnum,
  FilterTypesEnumType,
} from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import invariant from "tiny-invariant";

export const TextFilters = ExperimentFilterColumnStringFilter.options;
export const NumberFilters = ExperimentFilterColumnNumberFilter.options;
export const BothFilters = ExperimentFilterColumnBothFilter.options;
export const RuleTypeFilters = ExperimentFilterColumnRuleTypeFilter.options;
export const StringContainsFilters =
  ExperimentFilterColumnStringContainsFilter.options;

export type ColumnFilterOption = {
  label: string;
  type: "item" | "label";
  value?: string;
};

type Filter<ColumnType, ColumnFilterType> = {
  columnFilterValue?: ColumnType;
  selectedFilter: ColumnFilterType;
  textFilterOptions?: string[];
  textFilterValue?: string;
  type: FilterTypesEnumType;
};

type Props<ColumnType, ColumnFilterType> = {
  columnFilterOptions: ColumnFilterOption[];
  columnFilterValue: ColumnType;
  onDeleteFilterClick: () => void;
  onFilterChange: (filter: Filter<ColumnType, ColumnFilterType>) => void;
  selectedFilter: ColumnFilterType;
  textFilterOptions?: string[];
  textFilterValue?: string;
  type: FilterTypesEnumType;
};

export default function CustomizedFilter<
  ColumnType,
  ColumnFilterType extends ExperimentFilterColumnFilterEnum,
>({
  columnFilterOptions,
  columnFilterValue,
  onDeleteFilterClick,
  onFilterChange,
  selectedFilter,
  textFilterOptions,
  textFilterValue,
  type,
}: Props<ColumnType, ColumnFilterType>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const filterLogicMap = {
    both: BothFilters,
    number: NumberFilters,
    rule_type: RuleTypeFilters,
    string: textFilterOptions?.length ? TextFilters : StringContainsFilters,
  };

  invariant(filterLogicMap[type] != null, "Invalid filter type");
  const filterLogic: string[] = filterLogicMap[type];

  useEffect(() => {
    if (selectedFilter == null || !filterLogic.includes(selectedFilter)) {
      onFilterChange({
        columnFilterValue,
        selectedFilter: filterLogic[0] as ColumnFilterType,
        textFilterOptions,
        textFilterValue,
        type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilterValue]);

  let textFilterLabel = "--";

  if (textFilterValue?.length) {
    if (filterLogic === RuleTypeFilters) {
      textFilterLabel = formatGenericEnumLabel(textFilterValue);
    } else {
      textFilterLabel = textFilterValue;
    }
  }

  return (
    <div className="flex">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button
            className="whitespace-nowrap rounded-r-none border-r-0"
            variant="basic"
          >
            {formatGenericEnumLabel(String(columnFilterValue))}
            <ChevronDown size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuGroup className="h-80 w-52 overflow-y-scroll p-4">
            {columnFilterOptions.map((item) => {
              if (item.type === "label") {
                return (
                  <DropdownMenuLabel className="font-bold" key={item.label}>
                    {item.label}
                  </DropdownMenuLabel>
                );
              }
              return (
                <DropdownMenuItem
                  key={item.label}
                  onClick={() =>
                    onFilterChange({
                      columnFilterValue: item.value as ColumnType,
                      selectedFilter,
                      textFilterOptions,
                      textFilterValue,
                      type,
                    })
                  }
                >
                  {item.label}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button
            className="w-fit whitespace-nowrap rounded-none border-r-0 bg-neutral-50 dark:bg-zinc-900"
            variant="basic"
          >
            {String(selectedFilter)}
            <ChevronDown size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onCloseAutoFocus={(e) => {
            e.preventDefault();
            if (selectedFilter.includes("contain") && inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <DropdownMenuGroup className="w-40 overflow-y-scroll p-4">
            {filterLogic.map((item) => {
              return (
                <DropdownMenuItem
                  key={item}
                  onClick={() =>
                    onFilterChange({
                      columnFilterValue,
                      selectedFilter: item as ColumnFilterType,
                      textFilterOptions,
                      textFilterValue,
                      type,
                    })
                  }
                >
                  {item}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      {NumberFilters.includes(
        selectedFilter as ExperimentFilterColumnNumberFilterEnum,
      ) && (
        <Input
          className="w-24 rounded-none border-r-0"
          onChange={(e) =>
            onFilterChange({
              columnFilterValue,
              selectedFilter,
              textFilterOptions,
              textFilterValue: e.target.value,
              type,
            })
          }
          placeholder="--"
          type="number"
          value={textFilterValue}
        />
      )}
      {(filterLogic === RuleTypeFilters ||
        TextFilters.includes(
          selectedFilter as ExperimentFilterColumnStringFilterEnum,
        )) &&
        (String(selectedFilter).includes("contain") ? (
          <Input
            className="w-24 rounded-none border-r-0"
            onChange={(e) =>
              onFilterChange({
                columnFilterValue,
                selectedFilter,
                textFilterOptions,
                textFilterValue: e.target.value,
                type,
              })
            }
            placeholder="Search"
            ref={inputRef}
            value={textFilterValue}
          />
        ) : (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                className="whitespace-nowrap rounded-none border-r-0"
                variant="basic"
              >
                {textFilterLabel}
                <ChevronDown size={18} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup className="max-h-80 w-52 overflow-y-scroll p-4">
                {textFilterOptions?.map((item) => {
                  return (
                    <DropdownMenuItem
                      key={item}
                      onClick={() =>
                        onFilterChange({
                          columnFilterValue,
                          selectedFilter,
                          textFilterOptions,
                          textFilterValue: item,
                          type,
                        })
                      }
                    >
                      {filterLogic === RuleTypeFilters
                        ? formatGenericEnumLabel(item)
                        : item}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        ))}
      <Button
        className="rounded-l-none  text-zinc-400"
        onClick={onDeleteFilterClick}
        size="icon"
        variant="basic"
      >
        x
      </Button>
    </div>
  );
}
