import useGetUserBrandsQuery from "src/frontend/hooks/queries/useGetUserBrandsQuery";
import useBrandId from "src/frontend/hooks/useBrandId";
import findBrandById from "src/frontend/utils/findBrandById";
import { GetUserBrandsResponseType } from "src/shared/trpc/queries/getUserBrandsQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";

export default function useUserBrand(): MaybeNull<GetUserBrandsResponseType> {
  const brandId = useBrandId();
  const brandsQuery = useGetUserBrandsQuery();
  if (
    brandsQuery.isLoading ||
    brandsQuery.isError ||
    arrayEmpty(brandsQuery.data)
  ) {
    return null;
  }
  return findBrandById(brandsQuery.data ?? [], brandId);
}
