import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import useFlags from "src/frontend/hooks/useFlags";

const data = [
  {
    amt: 2400,
    name: "Page A",
    pv: 2400,
    uv: 4000,
  },
  {
    amt: 2210,
    name: "Page B",
    pv: 1398,
    uv: 3000,
  },
  {
    amt: 2290,
    name: "Page C",
    pv: 9800,
    uv: 2000,
  },
  {
    amt: 2000,
    name: "Page D",
    pv: 3908,
    uv: 2780,
  },
  {
    amt: 2181,
    name: "Page E",
    pv: 4800,
    uv: 1890,
  },
  {
    amt: 2500,
    name: "Page F",
    pv: 3800,
    uv: 2390,
  },
  {
    amt: 2100,
    name: "Page G",
    pv: 4300,
    uv: 3490,
  },
];

type TestChartProps = {
  hide?: boolean;
};

// https://recharts.org/en-US/
export default function TestChart({ hide = false }: TestChartProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Chart</TestComponentHeading>
      <ResponsiveContainer
        height="100%"
        minHeight={300}
        minWidth={300}
        width="100%"
      >
        <LineChart
          data={data}
          height={500}
          margin={{
            bottom: 5,
            left: 20,
            right: 30,
            top: 5,
          }}
          width={500}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{ fontFamily: "Nunito", fontSize: 12 }}
          />
          <YAxis style={{ fontFamily: "Nunito", fontSize: 12 }} />
          <Tooltip />
          <Legend wrapperStyle={{ fontFamily: "Nunito", fontSize: 16 }} />
          <Line
            activeDot={{ r: 8 }}
            dataKey="pv"
            stroke="#8884d8"
            type="monotone"
          />
          <Line dataKey="uv" stroke="#82ca9d" type="monotone" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
