import { Skeleton } from "src/frontend/components/ui/Skeleton";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import range from "src/shared/utils/arrays/range";

type TableSkeletonProps = {
  className?: ClassNameProp;
  rowClassName?: ClassNameProp;
  rowNumber?: number;
};

export default function TableSkeleton({
  className,
  rowClassName,
  rowNumber = 25,
}: TableSkeletonProps) {
  return (
    <div className={cn("flex flex-col gap-3", className)}>
      {range(rowNumber).map((_, i) => {
        return <Skeleton className={cn("h-5 w-full", rowClassName)} key={i} />;
      })}
    </div>
  );
}
