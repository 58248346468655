import { VisibilityFilterKeyUnion } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import len from "src/shared/utils/arrays/len";
import invariant from "tiny-invariant";

function getBorderRight(
  config: VisibilityFilterKeyUnion[],
  fields: boolean[],
  field: VisibilityFilterKeyUnion,
): boolean {
  invariant(
    len(config) === len(fields),
    `Length of config and fields must be equal, but were unequal for field: ${field}.`,
  );
  const startingPoint = config.findIndex((val) => val === field);
  const configString = config.join(", ");
  invariant(
    startingPoint >= 0,
    `${field} must exist in provided config: ${configString}.`,
  );
  for (let i = startingPoint + 1; i < config.length; i++) {
    if (fields[i] === true) {
      return false;
    }
  }
  return true;
}

// TODO-[MIKE]: convert to camelCase
// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case

export default function getBorderRightForColumns(
  config: VisibilityFilterKeyUnion[],
  fields: boolean[],
): Record<VisibilityFilterKeyUnion[number], boolean> {
  const borders: Record<VisibilityFilterKeyUnion[number], boolean> = {};

  config.forEach((field) => {
    borders[field] = getBorderRight(config, fields, field);
  });

  return new Proxy(borders, {
    get: (target, prop): boolean => {
      invariant(
        prop in target,
        `Property ${String(prop)} does not exist in target.`,
      );
      return target[prop as keyof typeof target];
    },
  });
}
