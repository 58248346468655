import { Rocket } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import getAutoApprovalTimeStrings from "src/backend/utils/getAutoApprovalTimeStrings";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import useUserBrand from "src/frontend/hooks/useUserBrand";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import invariant from "tiny-invariant";

type ApprovePricePlanModalProps = {
  approvalButtonText?: string;
};

export default function ApprovePricePlanModal({
  approvalButtonText = "Approve All",
}: ApprovePricePlanModalProps) {
  const brand = useUserBrand();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const isBrandAdmin = useIsBrandAdmin();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldPushPrices, setShouldPushPrices] = useState(true);
  const { errorToast, successToast } = useToast();
  const navigate = useNavigate();
  const approvePricePlanMutation =
    TrpcClient.internal.approvePricePlan.useMutation();

  return (
    <Modal
      cancelText="No"
      confirmText="Yes"
      dialogDescription={
        <Col className="gap-1">
          {brand?.price_plan_approval_type === "AUTO_APPROVE" && (
            <p>
              This price plan is currently scheduled to go live at{" "}
              {getAutoApprovalTimeStrings().long}.{" "}
            </p>
          )}
          <p>Do you want all price changes to be approved immediately?</p>
          <p>Any other draft price plans will be expired.</p>
          <Checkbox
            checked={shouldPushPrices}
            id="should-push-prices-checkbox"
            label="Prices will be pushed live when approved."
            onCheckedChange={(_, checked) => setShouldPushPrices(checked)}
          />
        </Col>
      }
      dialogTitle={approvalButtonText}
      loading={approvePricePlanMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null, "pricePlanId cannot be null.");
        approvePricePlanMutation.mutate(
          {
            pricePlanId,
            shouldPushPrices,
          },
          {
            onError: () => {
              errorToast("Failed to launch price plan.");
            },
            onSuccess: () => {
              setIsModalOpen(false);
              successToast("Price plan launched successfully!");
              navigate(`/pricing/current/${pricePlanId}`, { replace: true });
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button disabled={pricePlanId == null || !isBrandAdmin}>
          <Rocket className="mr-4" size={20} strokeWidth={3} />
          <h2 className="text-xl font-bold">{approvalButtonText}</h2>
        </Button>
      }
    />
  );
}
