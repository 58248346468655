import React from "react";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type SummaryMetricsContainerProps = HtmlDivProps & {
  children: React.ReactNode;
  innerDivClassName?: ClassNameProp;
};

export default function SummaryMetricsContainer({
  children,
  className,
  innerDivClassName,
  ...rest
}: SummaryMetricsContainerProps) {
  return (
    <div className={cn("flex flex-1 flex-col", className)} {...rest}>
      <div
        className={cn(
          "flex flex-col justify-between gap-4 min-[1180px]:flex-row",
          innerDivClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
}
