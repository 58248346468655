import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import useBrandStore from "src/frontend/stores/useBrandStore";
import useLucaAdminSettingsStore from "src/frontend/stores/useLucaAdminSettingsStore";
import { PaidFeatureType } from "src/shared/trpc/common/enum/PaidFeature";

export default function useCanAccessFeature(feature: PaidFeatureType) {
  const isLucaAdmin = useIsLucaAdmin();
  const viewAsCompanyUser = useLucaAdminSettingsStore(
    (state) => state.view_as_company_user,
  );
  const bypassForLucaAdmin = isLucaAdmin && !viewAsCompanyUser;
  const brand = useBrandStore();
  const paidFeatures = brand.paid_features;
  return bypassForLucaAdmin || paidFeatures.includes(feature);
}
