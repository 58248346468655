export default function groupBy<T>(
  arr: T[],
  property: keyof T,
): Record<string, T[]> {
  return arr.reduce(
    (groups, el) => {
      const key = String(el[property]);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(el);
      return groups;
    },
    {} as Record<string, T[]>,
  );
}
