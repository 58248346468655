type StringColor = {
  backgroundColor: string;
  textColor: string;
};

export default function stringToColor(inputString: string): StringColor {
  // Calculate a hash value for the input string
  let hashValue = 0;
  for (let i = 0; i < inputString.length; i++) {
    hashValue = inputString.charCodeAt(i) + (hashValue << 5) - hashValue;
  }

  // Convert the hash value to RGB values for the background color
  const red = (hashValue & 0xff0000) >> 16;
  const green = (hashValue & 0x00ff00) >> 8;
  const blue = hashValue & 0x0000ff;

  // Calculate the luminance of the background color
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Choose white or black text color based on background luminance
  const textColor = luminance > 0.5 ? "#000000" : "#FFFFFF";

  // Convert RGB values to a hex color code
  const backgroundColor = `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

  return { backgroundColor, textColor };
}
