import { z } from "zod";

const TreatmentPriceFields = z.object({
  treatment_discounted_price: z.number().nullable(),
  treatment_list_price: z.number().nullable(),
  treatment_member_price: z.number().nullable(),
  treatment_subscriber_price: z.number().nullable(),
});

export type TreatmentPriceFieldsType = z.infer<typeof TreatmentPriceFields>;

export default TreatmentPriceFields;
