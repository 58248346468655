import { create } from "zustand";

export type NavigateInterruptPromptStore = {
  navigateInterruptMounted: boolean;
};

const defaultState: NavigateInterruptPromptStore = {
  navigateInterruptMounted: false,
};

const useNavigateInterruptPromptStore = create<NavigateInterruptPromptStore>(
  () => defaultState,
);

export default useNavigateInterruptPromptStore;
