import getNextMonday from "src/shared/utils/dates/getNextMonday";

type TimeStrings = {
  long: string;
  short: string;
};

export default function getAutoApprovalTimeStrings(): TimeStrings {
  const monday = getNextMonday();
  const formattedDate = monday.format("dddd MMMM Do");
  const short = `${monday.format("MM/DD/YYYY")} at 8am PST`;
  const long = `${formattedDate} at 8am PST`;
  return {
    long,
    short,
  };
}
