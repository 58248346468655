import { PlusIcon } from "@radix-ui/react-icons";
import TrpcClient from "src/frontend/api/TrpcClient";
import Bold from "src/frontend/components/Bold";
import Row from "src/frontend/components/Row";
import { Badge } from "src/frontend/components/ui/Badge";
import useToast from "src/frontend/components/ui/useToast";
import CreateTagDialog from "src/frontend/pages/catalog/CreateTagDialog";
import ProductTagBadge from "src/frontend/pages/catalog/ProductTagBadge";
import ViewTagsDialog from "src/frontend/pages/catalog/ViewTagsDialog";
import GenericTrpcRefetchFunction from "src/frontend/types/GenericTrpcRefetchFunction";
import tagToFancyBoxItem from "src/frontend/utils/tagToFancyBoxItem";
import { ProductTagType } from "src/shared/trpc/common/ProductTag";
import MaybeString from "src/shared/types/maybe/MaybeString";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import invariant from "tiny-invariant";

type ProductTagTableCellProps = {
  hideAddTag?: boolean;
  numberOfTagsToShow: number;
  productId: MaybeString;
  productName: MaybeString;
  refetchFn: GenericTrpcRefetchFunction;
  tags: ProductTagType[];
};

export default function ProductTagTableCell({
  hideAddTag,
  numberOfTagsToShow,
  productId,
  productName,
  refetchFn,
  tags,
}: ProductTagTableCellProps) {
  const t = useToast();
  const removeProductFromTagMutation =
    TrpcClient.internal.removeProductFromTag.useMutation();

  const AddTagElement = hideAddTag ? null : (
    <CreateTagDialog
      disabled={false}
      existingTags={tags.map((tag) => tagToFancyBoxItem(tag))}
      productName={productName}
      refetchFn={refetchFn}
      singleSkuSelectionProductId={productId}
      tags={tags}
      totalCount={1}
      trigger={
        <Badge className="px-1 hover:cursor-pointer">
          <button className="rounded-full focus:outline-none focus:ring-0">
            <PlusIcon />
          </button>
        </Badge>
      }
    />
  );

  if (arrayEmpty(tags)) {
    return <Row>{AddTagElement}</Row>;
  }

  const removeTag = (tagId: string) => {
    invariant(productId != null);
    removeProductFromTagMutation.mutate(
      {
        productId: productId,
        tagId,
      },
      {
        onError: () => {
          t.errorToast("There was an error removing the tag.");
        },
        onSuccess: () => {
          refetchFn();
          t.successToast("Tag removed successfully.");
        },
      },
    );
  };

  const firstTags = tags.slice(0, numberOfTagsToShow);
  return (
    <Row className="gap-2">
      {firstTags.map((tag) => {
        return (
          <ProductTagBadge
            confirmText="Remove Tag"
            description={
              <span>
                This will remove the tag <Bold>{tag.title}</Bold> from the
                product <Bold>{productName}</Bold>.
              </span>
            }
            disablePrompt
            key={tag.id}
            loading={removeProductFromTagMutation.isLoading}
            onConfirm={() => removeTag(tag.id)}
            tag={tag}
          />
        );
      })}
      <ViewTagsDialog
        numberOfTagsToShow={numberOfTagsToShow}
        productName={productName}
        removeTag={(tagId) => removeTag(tagId)}
        tags={tags}
      />
      {AddTagElement}
    </Row>
  );
}
