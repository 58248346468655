import { useEffect } from "react";
import usePrompt, { UsePromptProps } from "src/frontend/hooks/usePrompt";
import useNavigateInterruptPromptStore from "src/frontend/stores/useNavigateInterrupt";

type NavigateInterruptPromptProps = UsePromptProps & {
  message: string;
  when: boolean;
};

export function NavigateInterruptPrompt({
  message,
  when,
}: NavigateInterruptPromptProps) {
  usePrompt(when ? message : null, { beforeUnload: true });
  useEffect(() => {
    useNavigateInterruptPromptStore.setState({
      navigateInterruptMounted: true,
    });
    return () => {
      useNavigateInterruptPromptStore.setState({
        navigateInterruptMounted: false,
      });
    };
  });
  return null;
}
