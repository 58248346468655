export default async function copyTextToClipboard(text: string): Promise<void> {
  try {
    if (!navigator.clipboard) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute("readonly", "");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }

    await navigator.clipboard.writeText(text);
  } catch (err) {
    return;
  }
}
