import { Link } from "react-router-dom";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import ConfidenceScore from "src/frontend/components/ConfidenceScore";
import PriceAdjustment from "src/frontend/components/PriceAdjustment";
import Row from "src/frontend/components/Row";
import SummaryImpact from "src/frontend/components/SummaryImpact";
import PricePlanInsightsDialog from "src/frontend/components/dialogs/PricePlanInsightsDialog";
import CardWithContent from "src/frontend/components/ui/Card";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useCurrentlySelectedPricePlan from "src/frontend/hooks/useCurrentlySelectedPricePlan";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import { GroupDetailsTypeV2 } from "src/shared/trpc/queries/getGroupSummaryQuerySchemaV2";
import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";
import { Maybe } from "src/shared/types/maybe/Maybe";
import formatDate from "src/shared/utils/dates/formatDate";
import ifNotNull from "src/shared/utils/ifNotNull";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";
import truncateTextMiddle from "src/shared/utils/strings/truncateTextMiddle";

type PlanDetailsGridProps = {
  groupDetails?: GroupDetailsTypeV2;
  impactedSKUs: Maybe<number>;
  isDetailView?: boolean;
  metrics?: GetPricePlanSummaryQueryResponseType;
};

export default function PlanDetailsGrid({
  groupDetails,
  impactedSKUs,
  isDetailView,
  metrics,
}: PlanDetailsGridProps) {
  const isLucaAdmin = useIsLucaAdmin();
  const { confidence_score, price_change_percent } = groupDetails ?? {};

  const currentPricePlan = useCurrentlySelectedPricePlan();
  return (
    <CardWithContent className="flex-grow basis-0" title="Plan Details">
      <div className="mt-5 grid grid-cols-3 grid-rows-2 gap-8">
        {isDetailView && price_change_percent != null && (
          <SummaryImpact
            child={
              <PriceAdjustment priceChangePercent={price_change_percent} />
            }
            title="Avg Price Change"
          />
        )}
        {isDetailView && confidence_score != null && (
          <SummaryImpact
            child={
              <ConfidenceScore
                confidence={ifNotNull(confidence_score, (val) => val * 10)}
              />
            }
            title="Confidence Score"
          />
        )}
        <SummaryImpact
          impact={impactedSKUs}
          impactType="number"
          title="Impacted SKUs"
        />
        {currentPricePlan?.pricing_strategy_id != null && (
          <SummaryImpact
            child={
              <Col>
                <Tooltip
                  content={
                    <p>{currentPricePlan?.pricing_strategy_name ?? "Link"}</p>
                  }
                  disabled={currentPricePlan?.pricing_strategy_name == null}
                >
                  <p className="w-[150px] truncate text-sm font-bold">
                    {currentPricePlan?.pricing_strategy_name ?? "Link"}
                  </p>
                </Tooltip>
                {isLucaAdmin && (
                  <Row>
                    <Link
                      target="_blank"
                      to={`/company-settings/strategy/${currentPricePlan.pricing_strategy_id}`}
                    >
                      <Code className="whitespace-nowrap bg-transparent p-0 text-xs underline">
                        {truncateTextMiddle(
                          currentPricePlan?.pricing_strategy_id,
                          6,
                        )}
                      </Code>
                    </Link>
                  </Row>
                )}
              </Col>
            }
            title="Pricing Strategy"
          />
        )}
        {metrics?.price_plan?.start_time == null ? (
          <SummaryImpact
            child={
              <p className="whitespace-nowrap text-xl font-bold">
                {formatDate(
                  metrics?.price_plan?.published_on,
                  "MM/DD/YYYY h:mma",
                )}
              </p>
            }
            title="Published On"
          />
        ) : (
          <SummaryImpact
            child={
              <p className="whitespace-nowrap text-xl font-bold">
                {formatDate(
                  metrics?.price_plan?.start_time,
                  "MM/DD/YYYY h:mma",
                )}
              </p>
            }
            title="Launched On"
          />
        )}
        {!isDetailView && metrics?.price_plan?.insights != null && (
          <SummaryImpact
            child={
              <PricePlanInsightsDialog
                insights={metrics?.price_plan?.insights}
              />
            }
            title="Insights"
          />
        )}
        {!isDetailView && metrics?.price_plan?.status === "REJECTED" && (
          <SummaryImpact
            child={<p className="text-sm font-bold">REJECTED</p>}
            title="Status"
          />
        )}
        {!isDetailView && isLucaAdmin && (
          <SummaryImpact
            impact={roundToPrecision(metrics?.price_plan?.price_plan_lambda)}
            title="Lambda"
          />
        )}
      </div>
    </CardWithContent>
  );
}
