import { useState } from "react";
import Bold from "src/frontend/components/Bold";
import Row from "src/frontend/components/Row";
import { Badge } from "src/frontend/components/ui/Badge";
import Dialog from "src/frontend/components/ui/Dialog";
import ProductTagBadge from "src/frontend/pages/catalog/ProductTagBadge";
import { ProductTagType } from "src/shared/trpc/common/ProductTag";
import MaybeString from "src/shared/types/maybe/MaybeString";
import len from "src/shared/utils/arrays/len";

type ViewTagsDialogProps = {
  disabled?: boolean;
  loading?: boolean;
  numberOfTagsToShow: number;
  productName: MaybeString;
  removeTag: (tagId: string) => void;
  tags: ProductTagType[];
};

export default function ViewTagsDialog({
  disabled,
  loading,
  numberOfTagsToShow,
  productName,
  removeTag,
  tags,
}: ViewTagsDialogProps) {
  const [open, setOpen] = useState(false);
  const remainingCount = len(tags.slice(numberOfTagsToShow));
  return (
    <Dialog
      confirmButtonVariant="outline"
      confirmTitle="Ok"
      dialogTitle={`${productName} Tags`}
      disabled={disabled}
      loading={loading}
      onConfirm={() => {
        setOpen(false);
      }}
      onOpenChange={setOpen}
      open={open}
      trigger={
        remainingCount === 0 ? undefined : (
          <Badge className="hover:cursor-pointer">
            <p>+{remainingCount}</p>
          </Badge>
        )
      }
    >
      <Row className="my-4 max-h-[300px] flex-wrap gap-2 overflow-y-auto">
        {tags.map((tag) => {
          return (
            <ProductTagBadge
              confirmText="Remove Tag"
              description={
                <span>
                  This will remove the tag <Bold>{tag.title}</Bold> from the
                  product <Bold>{productName}</Bold>.
                </span>
              }
              key={tag.id}
              onConfirm={() => {
                removeTag(tag.id);
              }}
              tag={tag}
            />
          );
        })}
      </Row>
    </Dialog>
  );
}
