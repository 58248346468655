import { Cross2Icon } from "@radix-ui/react-icons";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import FancyBoxLabel from "src/frontend/components/ui/FancyBoxLabel";
import {
  EXCLUSION_FILTER_IDS,
  SHOW_SKU_ACTIVE,
} from "src/frontend/constants/CustomProductFilters";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import getFancyBoxItemBadgeStyle from "src/frontend/utils/getFancyBoxItemBadgeStyle";
import { ProductConditionFiltersKey } from "src/shared/trpc/common/ProductConditionFilters";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import intersperseArray from "src/shared/utils/arrays/intersperseArray";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

const OR = "[or]";
const AND = "[and]";

function shouldRenderTypeInLabel(item: FancyBoxItem): boolean {
  const { id } = item;
  const type = item.type as ProductCategoryFilterType;
  switch (type) {
    case ProductCategoryFilterType.AllSkus:
    case ProductCategoryFilterType.CustomSkusSelection:
      return false;
    case ProductCategoryFilterType.Category:
    case ProductCategoryFilterType.PriceZone:
    case ProductCategoryFilterType.ProductBrandName:
    case ProductCategoryFilterType.Subcategory:
    case ProductCategoryFilterType.Supplier:
    case ProductCategoryFilterType.Tag:
      if (id != null && EXCLUSION_FILTER_IDS.includes(id)) {
        return false;
      }

      return true;
    default:
      return assertUnreachable(type);
  }
}

function renderSelectProductCategoryLabel(item: FancyBoxItem): JSX.Element {
  const { label, type } = item ?? {};
  if (type != null && shouldRenderTypeInLabel(item)) {
    return <FancyBoxLabel content={`${type}: ${label}`} />;
  }

  return <FancyBoxLabel content={label} />;
}

function filterByType(items: FancyBoxItem[], type: ProductCategoryFilterType) {
  return items.filter(
    (item) => (item.type as ProductCategoryFilterType) === type,
  );
}

type ProductPriceZoneKey = "productPriceZones";
type ProductBrandNamesKey = "productBrandNames";
type SelectedItemsElement =
  | FancyBoxItem
  | ProductBrandNamesKey
  | ProductConditionFiltersKey
  | ProductPriceZoneKey;

function orderSelectedItems(items: FancyBoxItem[]): SelectedItemsElement[] {
  const allSkus = filterByType(items, ProductCategoryFilterType.AllSkus);
  const categories = filterByType(items, ProductCategoryFilterType.Category);
  const subCategories = filterByType(
    items,
    ProductCategoryFilterType.Subcategory,
  );
  const productBrandNames = filterByType(
    items,
    ProductCategoryFilterType.ProductBrandName,
  );
  const productPriceZones = filterByType(
    items,
    ProductCategoryFilterType.PriceZone,
  );
  const customSkus = filterByType(
    items,
    ProductCategoryFilterType.CustomSkusSelection,
  );
  const suppliers = filterByType(items, ProductCategoryFilterType.Supplier);
  const tags = filterByType(items, ProductCategoryFilterType.Tag);

  const groups = [
    intersperseArray<FancyBoxItem, ProductConditionFiltersKey>(
      sortByProperty(categories, "label"),
      "applyAndConditionForCategory",
    ),
    intersperseArray<FancyBoxItem, ProductConditionFiltersKey>(
      sortByProperty(subCategories, "label"),
      "applyAndConditionForSubCategory",
    ),
    intersperseArray<FancyBoxItem, ProductBrandNamesKey>(
      sortByProperty(productBrandNames, "label"),
      "productBrandNames",
    ),
    intersperseArray<FancyBoxItem, ProductPriceZoneKey>(
      sortByProperty(productPriceZones, "label"),
      "productPriceZones",
    ),
    intersperseArray<FancyBoxItem, ProductConditionFiltersKey>(
      sortByProperty(suppliers, "label"),
      "applyAndConditionForSupplier",
    ),
    intersperseArray<FancyBoxItem, ProductConditionFiltersKey>(
      sortByProperty(tags, "label"),
      "applyAndConditionForTags",
    ),
    sortByProperty(customSkus, "label"),
  ].filter((val) => arrayNotEmpty(val));

  const result = [
    ...allSkus,
    ...intersperseArray<SelectedItemsElement[], ProductConditionFiltersKey>(
      groups,
      "applyAndConditionForFilters",
    ).flat(),
  ];
  return result as (FancyBoxItem | ProductConditionFiltersKey)[];
}

type FancyBoxSelectedItemsListProps = HtmlDivProps & {
  applyAndConditionForCategory: boolean;
  applyAndConditionForFilters: boolean;
  applyAndConditionForPriceZone: boolean;
  applyAndConditionForProductBrandName: boolean;
  applyAndConditionForSubCategory: boolean;
  applyAndConditionForSupplier: boolean;
  applyAndConditionForTags: boolean;
  onClickRemove?: (item: FancyBoxItem) => void;
  renderLabel?: (item: FancyBoxItem) => JSX.Element;
  selectedItems: FancyBoxItem[];
  showClearFiltersButton?: boolean;
  toggleCondition: (
    categoryFilter: ProductConditionFiltersKey,
    applyAndCondition: boolean,
  ) => void;
};

export default function SelectedProductFiltersList({
  applyAndConditionForCategory,
  applyAndConditionForFilters,
  applyAndConditionForPriceZone,
  applyAndConditionForProductBrandName,
  applyAndConditionForSubCategory,
  applyAndConditionForSupplier,
  applyAndConditionForTags,
  className,
  onClickRemove,
  renderLabel,
  selectedItems,
  showClearFiltersButton,
  toggleCondition,
  ...rest
}: FancyBoxSelectedItemsListProps) {
  const conditionFilters = {
    applyAndConditionForCategory,
    applyAndConditionForFilters,
    applyAndConditionForPriceZone,
    applyAndConditionForProductBrandName,
    applyAndConditionForSubCategory,
    applyAndConditionForSupplier,
    applyAndConditionForTags,
  };
  const activeSkusElement = selectedItems.find(
    (val) => val.id === SHOW_SKU_ACTIVE,
  );
  const selectedItemsList = orderSelectedItems(selectedItems);
  return (
    <div className={className} {...rest}>
      {activeSkusElement != null && (
        <>
          <Badge
            className="mb-1 mr-1 max-w-[175px] p-1 px-2"
            key={activeSkusElement.value}
            style={getFancyBoxItemBadgeStyle(activeSkusElement.color)}
            variant={activeSkusElement.color == null ? "default" : "outline"}
          >
            {renderLabel != null
              ? renderLabel(activeSkusElement)
              : renderSelectProductCategoryLabel(activeSkusElement)}
            {onClickRemove != null && (
              <button
                className="ml-1 rounded-full hover:text-foreground"
                onClick={() => onClickRemove(activeSkusElement)}
              >
                <Cross2Icon />
              </button>
            )}
          </Badge>
          {arrayNotEmpty(selectedItemsList) && (
            <Badge
              className="mr-1 p-[1px] text-xs font-semibold hover:cursor-not-allowed"
              variant="secondary"
            >
              {AND}
            </Badge>
          )}
        </>
      )}
      {selectedItemsList.map((item, index) => {
        if (typeof item === "string") {
          switch (item) {
            case "productPriceZones":
            case "productBrandNames":
              return (
                <Badge
                  className="mr-1 p-[1px] text-xs font-semibold"
                  key={item + index}
                  variant="secondary"
                >
                  {OR}
                </Badge>
              );
            case "applyAndConditionForFilters":
            case "applyAndConditionForPriceZone":
            case "applyAndConditionForProductBrandName":
            case "applyAndConditionForCategory":
            case "applyAndConditionForSubCategory":
            case "applyAndConditionForSupplier":
            case "applyAndConditionForTags":
              const applyAndCondition = conditionFilters[item];
              return (
                <Badge
                  className="mr-1 p-[1px] text-xs font-semibold hover:cursor-pointer"
                  key={item + index}
                  onClick={() => toggleCondition(item, !applyAndCondition)}
                  variant="secondary"
                >
                  {applyAndCondition ? AND : OR}
                </Badge>
              );
            default:
              return assertUnreachable(item);
          }
        }

        const { color, value } = item;
        return (
          <Badge
            className="mb-1 mr-1 max-w-[175px] p-1 px-2"
            key={value}
            style={getFancyBoxItemBadgeStyle(color)}
            variant={color == null ? "default" : "outline"}
          >
            {renderLabel != null
              ? renderLabel(item)
              : renderSelectProductCategoryLabel(item)}
            {onClickRemove != null && (
              <button
                className="ml-1 rounded-full hover:text-foreground"
                onClick={() => onClickRemove(item)}
              >
                <Cross2Icon />
              </button>
            )}
          </Badge>
        );
      })}
      {showClearFiltersButton &&
        arrayNotEmpty(selectedItems) &&
        onClickRemove != null && (
          <Button
            className="h-6"
            onClick={() => {
              selectedItems.forEach((item) => onClickRemove(item));
            }}
            variant="link"
          >
            Clear Filters
          </Button>
        )}
    </div>
  );
}
