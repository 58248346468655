import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type ErrorLabelProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function ErrorLabel({
  children,
  className,
  ...rest
}: ErrorLabelProps) {
  return (
    <span
      className={cn("mt-1 block text-sm text-destructive", className)}
      {...rest}
    >
      {children}
    </span>
  );
}
