import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ALL_SKUS_SELECT_VALUE from "src/frontend/constants/AllSkusSelectValue";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";

const ALL_SKUS_FANCY_BOX_ITEM: FancyBoxItem = {
  label: "All SKUs",
  type: ProductCategoryFilterType.AllSkus,
  value: ALL_SKUS_SELECT_VALUE,
};

export default ALL_SKUS_FANCY_BOX_ITEM;
