import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import MaybeString from "src/shared/types/maybe/MaybeString";

export default function useGetCombinedPricingStrategySkuCountQuery(
  pricingStrategyId: MaybeString,
) {
  const { enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getCombinedPricingStrategySkuCount.useQuery(
    {
      pricingStrategyId,
    },
    {
      enabled: enabled,
    },
  );
}
