import { CompetitorMatchWithAveragePrices } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { z } from "zod";

export const ProductCompetitorMatches = z.object({
  competitor_matches: CompetitorMatchWithAveragePrices.nullable().array(),
  competitor_names_list: z.string().array(),
});

export type ProductCompetitorMatchesType = z.infer<
  typeof ProductCompetitorMatches
>;
