import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";

export default function useCurrentlySelectedPricePlan() {
  const pricePlanQuery = usePricePlanQuery();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  return (
    (pricePlanQuery.data ?? []).find((val) => val.id === pricePlanId) ?? null
  );
}
