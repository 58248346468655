import { useEffect, useRef, useState } from "react";
import Row from "src/frontend/components/Row";
import LucaContextMenu, {
  AdminMenuActionItem,
} from "src/frontend/components/admin/LucaContextMenu";
import PublishDraftResultsModal from "src/frontend/components/admin/PublishDraftResultsModal";
import { Badge } from "src/frontend/components/ui/Badge";
import CardWithContent from "src/frontend/components/ui/Card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "src/frontend/components/ui/Tabs";
import { cn } from "src/frontend/components/ui/utils";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import SummaryMetricsGrid from "src/frontend/pages/pricing/summary/SummaryMetricsGrid";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  CustomerSegmentType,
  CustomerSegmentVariants,
} from "src/shared/trpc/common/enum/CustomerSegment";
import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";
import { ImpactViewFilterType } from "src/shared/types/ImpactViewFilter";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatDate from "src/shared/utils/dates/formatDate";
import getLiveDuration from "src/shared/utils/dates/getLiveDuration";

type MetricsTabsType =
  | "PRICE_PLAN_FORECAST"
  | "PRICE_PLAN_IMPACT"
  | "RECOMMENDATION_SUMMARY";

type MetricsTabsProps = {
  isDetailView?: boolean;
  metrics?: GetPricePlanSummaryQueryResponseType;
  type: MetricsTabsType;
};

type MetricsTab = "Forecast" | "Impact";

export default function MetricsTabs({
  isDetailView,
  metrics,
  type,
}: MetricsTabsProps) {
  const [tab, setTab] = useState<MetricsTab>("Forecast");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const timeoutRef = useRef<MaybeNull<NodeJS.Timeout>>(null);
  const { customerSegment, impactViewFilter, pricePlanId } =
    usePricePlanStore();
  const {
    group_results_metrics: results,
    opportunity_metrics: opportunity,
    price_plan,
  } = metrics ?? {};
  const opportunitySegment = (opportunity ?? []).find(
    (val) => val.customer_segment === customerSegment,
  );
  const resultsSegment = (results ?? []).find(
    (val) => val.customer_segment === customerSegment,
  );
  const hasForecastData = opportunitySegment != null;
  const hasCustomerSegmentsAvailable = (opportunity ?? []).find(
    (val) => val.customer_segment != null,
  );
  const availableCustomerSegments = (opportunity ?? []).map(
    (val) => val.customer_segment,
  );
  const hasGroupResults = resultsSegment != null;
  const isDraft = resultsSegment?.is_draft;
  const isLucaAdmin = useIsLucaAdmin();
  const defaultMetricsTab: MetricsTab =
    hasGroupResults && (!isDraft || (isDraft && isLucaAdmin))
      ? "Impact"
      : "Forecast";

  useEffect(() => {
    setTab(defaultMetricsTab);
  }, [pricePlanId, setTab, defaultMetricsTab]);

  useEffect(() => {
    if (opportunity == null || arrayEmpty(opportunity)) {
      return;
    }

    const defaultSegment = opportunity[0].customer_segment;
    if (customerSegment == null) {
      if (hasCustomerSegmentsAvailable) {
        usePricePlanStore.setState({
          customerSegment: defaultSegment,
        });
      }
    } else if (!availableCustomerSegments.includes(customerSegment)) {
      usePricePlanStore.setState({
        customerSegment: defaultSegment,
      });
    }
  }, [
    availableCustomerSegments,
    customerSegment,
    hasCustomerSegmentsAvailable,
    opportunity,
  ]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const pricePlanPublishedAt =
    price_plan?.created_at == null ? null : formatDate(price_plan?.created_at);
  const forecastRefreshedLabel =
    pricePlanPublishedAt == null
      ? null
      : `, refreshed on ${pricePlanPublishedAt}`;

  const resultsRefreshedAt =
    resultsSegment?.created_at == null
      ? null
      : formatDate(resultsSegment?.created_at);
  const { daysLiveLabel: resultsDaysLiveLabel } = getLiveDuration(
    price_plan?.start_time,
    resultsSegment?.created_at,
  );
  const impactRefreshedLabel =
    resultsRefreshedAt == null ? null : `, refreshed on ${resultsRefreshedAt}`;

  const adminActions: MaybeNull<AdminMenuActionItem>[] = [
    isDraft === true
      ? {
          fn: () => {
            setIsModalOpen(true);
          },
          label: <span>Publish Draft Results</span>,
        }
      : null,
  ];

  const DraftBadge = isDraft && (
    <Badge
      className="hover:cursor-pointer"
      onClick={() => setIsModalOpen(true)}
      variant="warning"
    >
      DRAFT RESULTS
    </Badge>
  );

  // Allows for a more seamless-looking UI update when the menu state is changed.
  const toggleImpactViewFilter = (impactViewFilter: ImpactViewFilterType) => {
    timeoutRef.current = setTimeout(
      () => usePricePlanStore.setState({ impactViewFilter }),
      125,
    );
  };

  const updateCustomerSegment = (customerSegment: CustomerSegmentType) => {
    timeoutRef.current = setTimeout(
      () => usePricePlanStore.setState({ customerSegment }),
      125,
    );
  };

  const ImpactViewFilterControl = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex flex-row items-center justify-center hover:cursor-pointer">
          <div className="flex items-center justify-start">
            <p className="text-sm">•••</p>
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {hasCustomerSegmentsAvailable && (
          <>
            <DropdownMenuLabel>Customer Segments</DropdownMenuLabel>
            {CustomerSegmentVariants.map((segment) => {
              const disabled = !availableCustomerSegments.includes(segment);
              return (
                <DropdownMenuCheckboxItem
                  checked={customerSegment === segment}
                  className="hover:cursor-pointer"
                  disabled={disabled}
                  key={segment}
                  onClick={() => updateCustomerSegment(segment)}
                >
                  <Row className="w-56 justify-between">
                    <p>{formatGenericEnumLabel(segment)} Segment</p>
                  </Row>
                </DropdownMenuCheckboxItem>
              );
            })}
          </>
        )}
        {hasCustomerSegmentsAvailable && !isDetailView && (
          <DropdownMenuSeparator className="my-1" />
        )}
        {!isDetailView && (
          <>
            <DropdownMenuLabel>Price Plan Impact Filter</DropdownMenuLabel>
            <DropdownMenuCheckboxItem
              checked={impactViewFilter === "brand-level"}
              className="hover:cursor-pointer"
              onClick={() => toggleImpactViewFilter("brand-level")}
            >
              <Row className="w-56 justify-between">
                <p>Show Brand Level Metrics</p>
              </Row>
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={impactViewFilter === "price-plan-level"}
              className="hover:cursor-pointer"
              onClick={() => toggleImpactViewFilter("price-plan-level")}
            >
              <Row className="w-56 justify-between">
                <p>Show Price Plan Level Metrics</p>
              </Row>
            </DropdownMenuCheckboxItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const impactFilterToUse = isDetailView
    ? "price-plan-level"
    : impactViewFilter;

  const ForecastPricePlanLevel = (
    <SummaryMetricsGrid
      isDetailView={type === "RECOMMENDATION_SUMMARY"}
      marginConfidence={{
        lower_bound: opportunitySegment?.new_margin_lower_bound,
        upper_bound: opportunitySegment?.new_margin_upper_bound,
      }}
      marginImpact={opportunitySegment?.treatment_price_margin}
      marginImpactPercent={opportunitySegment?.margin_impact_percent}
      profitConfidence={{
        lower_bound: opportunitySegment?.new_profit_lower_bound,
        upper_bound: opportunitySegment?.new_profit_upper_bound,
      }}
      profitImpact={opportunitySegment?.profit_change_projected}
      profitImpactPercent={opportunitySegment?.profit_change_percent_projected}
      renderAsCards={type !== "RECOMMENDATION_SUMMARY"}
      revenueConfidence={{
        lower_bound: opportunitySegment?.new_revenue_lower_bound,
        upper_bound: opportunitySegment?.new_revenue_upper_bound,
      }}
      revenueImpact={opportunitySegment?.revenue_change_projected}
      revenueImpactPercent={
        opportunitySegment?.revenue_change_percent_projected
      }
      summaryMetricsType="forecast"
      unitsSoldConfidence={{
        lower_bound: opportunitySegment?.new_quantity_lower_bound,
        upper_bound: opportunitySegment?.new_quantity_upper_bound,
      }}
      unitsSoldImpact={opportunitySegment?.units_sold_change_projected}
      unitsSoldImpactPercent={
        opportunitySegment?.units_sold_change_percent_projected
      }
    />
  );
  let forecastMarginDifference = 0;
  if (
    opportunitySegment?.treatment_price_margin &&
    opportunitySegment.brand_new_margin
  ) {
    forecastMarginDifference =
      opportunitySegment.treatment_price_margin -
      opportunitySegment.brand_new_margin;
  }

  const ForecastBrandLevel = (
    <SummaryMetricsGrid
      isDetailView={type === "RECOMMENDATION_SUMMARY"}
      marginConfidence={{
        lower_bound:
          opportunitySegment?.new_margin_lower_bound != null
            ? opportunitySegment.new_margin_lower_bound -
              forecastMarginDifference
            : null,
        upper_bound:
          opportunitySegment?.new_margin_upper_bound != null
            ? opportunitySegment.new_margin_upper_bound -
              forecastMarginDifference
            : null,
      }}
      marginImpact={opportunitySegment?.brand_new_margin}
      marginImpactPercent={opportunitySegment?.brand_margin_impact_percent}
      profitConfidence={{
        lower_bound: opportunitySegment?.new_profit_lower_bound,
        upper_bound: opportunitySegment?.new_profit_upper_bound,
      }}
      profitImpact={opportunitySegment?.brand_profit_change_projected}
      profitImpactPercent={
        opportunitySegment?.brand_profit_change_percent_projected
      }
      renderAsCards={type !== "RECOMMENDATION_SUMMARY"}
      revenueConfidence={{
        lower_bound: opportunitySegment?.new_revenue_lower_bound,
        upper_bound: opportunitySegment?.new_revenue_upper_bound,
      }}
      revenueImpact={opportunitySegment?.brand_revenue_change_projected}
      revenueImpactPercent={
        opportunitySegment?.brand_revenue_change_percent_projected
      }
      summaryMetricsType="forecast"
      unitsSoldConfidence={{
        lower_bound: opportunitySegment?.new_quantity_lower_bound,
        upper_bound: opportunitySegment?.new_quantity_upper_bound,
      }}
      unitsSoldImpact={opportunitySegment?.brand_units_sold_change_projected}
      unitsSoldImpactPercent={
        opportunitySegment?.brand_units_sold_change_percent_projected
      }
    />
  );

  const ForecastComponent = (
    <div>
      <Row className="mb-3 justify-between">
        {hasForecastData ? (
          <p className="text-sm">30 day projections{forecastRefreshedLabel}</p>
        ) : (
          <p className="text-sm">Forecast is still being generated</p>
        )}
        {(hasCustomerSegmentsAvailable != null || !isDetailView) &&
          ImpactViewFilterControl}
      </Row>
      {impactFilterToUse === "price-plan-level"
        ? ForecastPricePlanLevel
        : ForecastBrandLevel}
    </div>
  );

  const ImpactPricePlanLevel = (
    <SummaryMetricsGrid
      isDetailView={type === "RECOMMENDATION_SUMMARY"}
      marginConfidence={{
        lower_bound: resultsSegment?.margin_lower_bound,
        upper_bound: resultsSegment?.margin_upper_bound,
      }}
      marginImpact={resultsSegment?.treatment_price_margin}
      marginImpactPercent={resultsSegment?.margin_change_percent}
      profitConfidence={{
        lower_bound: resultsSegment?.profit_lower_bound,
        upper_bound: resultsSegment?.profit_upper_bound,
      }}
      profitImpact={resultsSegment?.profit_change}
      profitImpactPercent={resultsSegment?.profit_change_percent}
      renderAsCards={type !== "RECOMMENDATION_SUMMARY"}
      revenueConfidence={{
        lower_bound: resultsSegment?.revenue_lower_bound,
        upper_bound: resultsSegment?.revenue_upper_bound,
      }}
      revenueImpact={resultsSegment?.revenue_change}
      revenueImpactPercent={resultsSegment?.revenue_change_percent}
      summaryMetricsType="impact"
      unitsSoldConfidence={{
        lower_bound: resultsSegment?.units_sold_lower_bound,
        upper_bound: resultsSegment?.units_sold_upper_bound,
      }}
      unitsSoldImpact={resultsSegment?.units_sold_change}
      unitsSoldImpactPercent={resultsSegment?.units_sold_change_percent}
    />
  );
  let resultsMarginDifference = 0;
  if (
    resultsSegment?.treatment_price_margin &&
    resultsSegment?.brand_treatment_margin
  ) {
    resultsMarginDifference =
      resultsSegment.treatment_price_margin -
      resultsSegment.brand_treatment_margin;
  }
  const ImpactBrandLevel = (
    <SummaryMetricsGrid
      isDetailView={type === "RECOMMENDATION_SUMMARY"}
      marginConfidence={{
        lower_bound:
          resultsSegment?.margin_lower_bound != null
            ? resultsSegment.margin_lower_bound - resultsMarginDifference
            : null,
        upper_bound:
          resultsSegment?.margin_upper_bound != null
            ? resultsSegment.margin_upper_bound - resultsMarginDifference
            : null,
      }}
      marginImpact={resultsSegment?.brand_treatment_margin}
      marginImpactPercent={resultsSegment?.brand_margin_change_percent}
      profitConfidence={{
        lower_bound: resultsSegment?.profit_lower_bound,
        upper_bound: resultsSegment?.profit_upper_bound,
      }}
      profitImpact={resultsSegment?.brand_profit_change}
      profitImpactPercent={resultsSegment?.brand_profit_change_percent}
      renderAsCards={type !== "RECOMMENDATION_SUMMARY"}
      revenueConfidence={{
        lower_bound: resultsSegment?.revenue_lower_bound,
        upper_bound: resultsSegment?.revenue_upper_bound,
      }}
      revenueImpact={resultsSegment?.brand_revenue_change}
      revenueImpactPercent={resultsSegment?.brand_revenue_change_percent}
      summaryMetricsType="impact"
      unitsSoldConfidence={{
        lower_bound: resultsSegment?.units_sold_lower_bound,
        upper_bound: resultsSegment?.units_sold_upper_bound,
      }}
      unitsSoldImpact={resultsSegment?.brand_units_sold_change}
      unitsSoldImpactPercent={resultsSegment?.brand_units_sold_change_percent}
    />
  );

  const ImpactComponent = (
    <div>
      <Row className="mb-3 justify-between">
        {hasGroupResults ? (
          <p className="text-sm">
            {resultsDaysLiveLabel} day impact{impactRefreshedLabel}
          </p>
        ) : (
          <p className="text-sm">Insufficient data to measure impact</p>
        )}
        <Row className="gap-2">
          {DraftBadge}
          {ImpactViewFilterControl}
        </Row>
      </Row>
      {impactFilterToUse === "price-plan-level"
        ? ImpactPricePlanLevel
        : ImpactBrandLevel}
      {isDraft ? (
        <PublishDraftResultsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );

  switch (type) {
    case "PRICE_PLAN_FORECAST":
      return <div className="flex-grow basis-0 p-4">{ForecastComponent}</div>;
    case "PRICE_PLAN_IMPACT":
      return <div className="flex-grow basis-0 p-4">{ImpactComponent}</div>;
    case "RECOMMENDATION_SUMMARY":
      return (
        <CardWithContent
          cardContentClassName="p-0"
          className="flex-grow basis-0"
        >
          <Tabs
            defaultValue={defaultMetricsTab}
            onValueChange={(val) => setTab(val as MetricsTab)}
            value={tab}
          >
            <TabsList className="text-s flex border-b md:text-base">
              <TabsTrigger className="flex-1 rounded-tl-lg" value="Forecast">
                <p className="text-base">Forecast</p>
              </TabsTrigger>
              <TabsTrigger className="flex-1 rounded-tr-lg" value="Impact">
                <p
                  className={cn("text-base", !hasGroupResults && "text-muted")}
                >
                  Impact
                </p>
              </TabsTrigger>
            </TabsList>
            <TabsContent className="px-4 py-2" value="Forecast">
              {ForecastComponent}
            </TabsContent>
            <TabsContent className="px-4 py-2" value="Impact">
              <LucaContextMenu adminActions={adminActions} devActions={[]}>
                {ImpactComponent}
              </LucaContextMenu>
            </TabsContent>
          </Tabs>
        </CardWithContent>
      );
    default:
      return assertUnreachable(type);
  }
}
