import { useTheme } from "next-themes";
import { useEffect } from "react";
import { Outlet } from "react-router";

export default function LoginRoot() {
  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme("light");
  }, [setTheme]);
  return <Outlet />;
}
