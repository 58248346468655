import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import { Prompt } from "src/frontend/components/ui/Prompt";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import { PricingStrategyType } from "src/shared/trpc/common/PricingStrategy";

type PricingStrategyIsLiveToggleProps = {
  isPromptOpen: boolean;
  setIsPromptOpen: (open: boolean) => void;
  strategy: PricingStrategyType;
  trigger: React.ReactElement | string;
};

export default function PricingStrategyIsLiveToggle({
  isPromptOpen,
  setIsPromptOpen,
  strategy,
  trigger,
}: PricingStrategyIsLiveToggleProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { errorToast, successToast } = useToast();

  const pricingStrategiesQuery = useGetPricingStrategiesQuery();
  const updatePricingStrategyIsLiveMutation =
    TrpcClient.internal.updatePricingStrategyIsLive.useMutation();

  const isLive = strategy?.is_live ?? false;
  const verb = isLive ? "Disable" : "Enable";

  const onSuccess = async () => {
    await pricingStrategiesQuery.refetch();
    setIsLoading(false);
    setIsPromptOpen(false);
    successToast("Pricing strategy updated successfully.");
  };

  const handleToggleIsActive = (val: PricingStrategyType) => {
    setIsLoading(true);
    updatePricingStrategyIsLiveMutation.mutate(
      {
        pricingStrategyId: val.id,
      },
      {
        onError: () => {
          errorToast("Failed to update pricing strategy.");
          setIsLoading(false);
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Prompt
      confirmText={`${verb} Strategy`}
      description={
        isLive ? (
          <p>The selected pricing strategy will be disabled.</p>
        ) : (
          <p>
            The selected pricing strategy will be marked as live (this will
            disable any other live strategy).
          </p>
        )
      }
      loading={isLoading}
      onConfirm={() => handleToggleIsActive(strategy)}
      onOpenChange={setIsPromptOpen}
      open={isPromptOpen}
      title={`${verb} Pricing Strategy`}
      trigger={trigger}
    />
  );
}
