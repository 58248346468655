import { Prisma } from "@prisma/client";
import { Maybe } from "src/shared/types/maybe/Maybe";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";

export default function roundToPrecision(
  val: Maybe<Prisma.Decimal | number>,
  precision = 2,
): MaybeNumber {
  if (val == null) {
    return null;
  }
  return Number(val.toFixed(precision));
}
