import { ConstraintType } from "src/shared/trpc/common/enum/ConstraintType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function shouldIncludeConstraintInMinMaxCalculations(
  type: ConstraintType,
): boolean {
  switch (type) {
    case "ROUNDING":
    case "AVERAGE_MARGIN":
      return false;
    case "MARGIN":
    case "PRICE_CHANGE":
    case "COMPETITOR_PRICE":
    case "PRICE_COMPARISON":
      return true;
    default:
      return assertUnreachable(type);
  }
}
