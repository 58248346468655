function customReplacer(_: string, value: any): any {
  if (typeof value === "bigint") {
    return value.toString();
  }

  if (value === Infinity) {
    return "Infinity";
  } else if (value === -Infinity) {
    return "-Infinity";
  } else if (Number.isNaN(value)) {
    return "NaN";
  }

  return value;
}

export default function jsonStringify(data: any): string {
  return JSON.stringify(data, customReplacer, 2);
}
