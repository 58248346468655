import Header from "src/frontend/components/Header";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import MaybeString from "src/shared/types/maybe/MaybeString";

type CreateUpdatePricingStrategyLoadingSkeletonProps = {
  pricingStrategyId: MaybeString;
};

export default function CreateUpdatePricingStrategyLoadingSkeleton({
  pricingStrategyId,
}: CreateUpdatePricingStrategyLoadingSkeletonProps) {
  return (
    <PageContainer enforceMaxWidth>
      <Header
        backTo={`/company-settings/strategy/${pricingStrategyId}`}
        leftNode={<PageTitle>Loading Product Rules...</PageTitle>}
      />
      <div className="mt-16 flex flex-col gap-2">
        <Skeleton className="h-6 w-36 rounded-xl" />
        <Skeleton className="h-8 w-80 rounded-xl" />
      </div>
      <div className="mt-8 flex flex-col gap-2">
        <Skeleton className="h-6 w-36 rounded-xl" />
        <Skeleton className="h-8 w-80 rounded-xl" />
        <Skeleton className="h-6 w-20 rounded-xl" />
      </div>
      <div className="mt-8 flex flex-col gap-2">
        <Skeleton className="h-6 w-36 rounded-xl" />
        <Skeleton className="h-8 w-80 rounded-xl" />
      </div>
      <div className="mt-8 flex flex-col gap-2">
        <Skeleton className="h-8 w-52 rounded-xl" />
      </div>
    </PageContainer>
  );
}
