import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import { ProgressBar } from "src/frontend/components/ui/ProgressBar";
import useFlags from "src/frontend/hooks/useFlags";
import randomInRange from "src/shared/utils/random/randomInRange";

type TestProgressBarProps = {
  hide?: boolean;
};

export default function TestProgressBar({ hide }: TestProgressBarProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Progress Bar</TestComponentHeading>
      <ProgressBar className="w-96" value={randomInRange(0, 100)} />
    </div>
  );
}
