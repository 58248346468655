import { SingleDatePicker } from "src/frontend/components/SingleDatePicker";
import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import useFlags from "src/frontend/hooks/useFlags";

type TestSingleDatePickerProps = {
  hide?: boolean;
};

export default function TestSingleDatePicker({
  hide,
}: TestSingleDatePickerProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Single Date Picker</TestComponentHeading>
      <SingleDatePicker />
    </div>
  );
}
