import { price_plan_frequency } from "@prisma/client";
import { z } from "zod";

export const PricePlanFrequency = z.nativeEnum(price_plan_frequency);

export type PricePlanFrequencyType = z.infer<typeof PricePlanFrequency>;

export const PricePlanFrequencyVariants = Object.keys(
  PricePlanFrequency.enum,
) as PricePlanFrequencyType[];
