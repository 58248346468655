import { Maybe } from "@trpc/server";
import SummaryImpact from "src/frontend/components/SummaryImpact";
import { cn } from "src/frontend/components/ui/utils";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import MetricsConfidenceInterval from "src/frontend/types/MetricsConfidenceInterval";
import SummaryMetricsType from "src/frontend/types/SummaryMetricsType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function getSummaryMetricTitle(
  metric: string,
  summaryMetricsType: SummaryMetricsType,
): string {
  switch (summaryMetricsType) {
    case "forecast":
      return `${metric} Impact`;
    case "impact":
      return `Estimated ${metric} Impact`;
    default:
      return assertUnreachable(summaryMetricsType);
  }
}

type SummaryMetricsGridProps = {
  className?: ClassNameProp;
  isDetailView?: boolean;
  marginConfidence?: MetricsConfidenceInterval;
  marginImpact: Maybe<number>;
  marginImpactPercent?: Maybe<number>;
  profitConfidence?: MetricsConfidenceInterval;
  profitImpact: Maybe<number>;
  profitImpactPercent?: Maybe<number>;
  renderAsCards?: boolean;
  revenueConfidence?: MetricsConfidenceInterval;
  revenueImpact: Maybe<number>;
  revenueImpactPercent?: Maybe<number>;
  summaryMetricsType: SummaryMetricsType;
  unitsSoldConfidence?: MetricsConfidenceInterval;
  unitsSoldImpact: Maybe<number>;
  unitsSoldImpactPercent?: Maybe<number>;
};

export default function SummaryMetricsGrid({
  className,
  isDetailView,
  marginConfidence,
  marginImpact,
  marginImpactPercent,
  profitConfidence,
  profitImpact,
  profitImpactPercent,
  renderAsCards,
  revenueConfidence,
  revenueImpact,
  revenueImpactPercent,
  summaryMetricsType,
  unitsSoldConfidence,
  unitsSoldImpact,
  unitsSoldImpactPercent,
}: SummaryMetricsGridProps) {
  const selectedMetric = usePricePlanStore((state) => state.selectedMetric);
  return (
    <div className={cn("grid w-full grid-cols-2 grid-rows-2 gap-8", className)}>
      <SummaryImpact
        className={cn(
          renderAsCards && selectedMetric === "revenue" && "bg-gray-100",
        )}
        confidenceInterval={revenueConfidence}
        impact={revenueImpact}
        impactType="currency"
        isDelta
        isDetailView={isDetailView}
        onClick={() =>
          renderAsCards &&
          usePricePlanStore.setState({ selectedMetric: "revenue" })
        }
        percentChange={revenueImpactPercent}
        renderAsCards={renderAsCards}
        title={getSummaryMetricTitle("Revenue", summaryMetricsType)}
      />
      <SummaryImpact
        className={cn(
          renderAsCards && selectedMetric === "quantity" && "bg-gray-100",
        )}
        confidenceInterval={unitsSoldConfidence}
        impact={unitsSoldImpact}
        impactType="number"
        isDelta
        isDetailView={isDetailView}
        onClick={() =>
          renderAsCards &&
          usePricePlanStore.setState({ selectedMetric: "quantity" })
        }
        percentChange={unitsSoldImpactPercent}
        renderAsCards={renderAsCards}
        title={getSummaryMetricTitle("Units", summaryMetricsType)}
      />
      <SummaryImpact
        className={cn(selectedMetric === "profit" && "bg-gray-100")}
        confidenceInterval={profitConfidence}
        impact={profitImpact}
        impactType="currency"
        isDelta
        isDetailView={isDetailView}
        onClick={() =>
          renderAsCards &&
          usePricePlanStore.setState({ selectedMetric: "profit" })
        }
        percentChange={profitImpactPercent}
        renderAsCards={renderAsCards}
        title={getSummaryMetricTitle("Profit", summaryMetricsType)}
      />
      <SummaryImpact
        className={cn(selectedMetric === "margin" && "bg-gray-100")}
        confidenceInterval={marginConfidence}
        impact={marginImpact}
        impactType="percent"
        isDelta
        isDetailView={isDetailView}
        onClick={() =>
          renderAsCards &&
          usePricePlanStore.setState({ selectedMetric: "margin" })
        }
        percentChange={marginImpactPercent}
        renderAsCards={renderAsCards}
        title={getSummaryMetricTitle("Margin", summaryMetricsType)}
      />
    </div>
  );
}
