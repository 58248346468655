import { ToastFunction } from "src/frontend/components/ui/useToast";
import parseTrpcError from "src/shared/errors/parseTrpcError";

export default function toastTrpcErrorResponse(
  toast: ToastFunction,
  fallbackMessage: string,
  err: any,
): void {
  const parsedError = parseTrpcError(err);
  const errorMessage = parsedError?.message ?? fallbackMessage;
  toast(errorMessage);
}
