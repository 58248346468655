import { SearchIcon, X } from "lucide-react";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";

type SearchInputProps = {
  className?: ClassNameProp;
  disabled?: boolean;
  inputClassName?: ClassNameProp;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
};

export default function SearchInput({
  className,
  disabled,
  inputClassName,
  onChange,
  placeholder = "Search",
  value,
}: SearchInputProps) {
  return (
    <div
      className={cn(
        "flex items-center justify-between rounded-sm border placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
        className,
      )}
    >
      <input
        className={cn(
          "h-8 w-full border-r bg-transparent px-4 py-2 text-sm focus:outline-none",
          inputClassName,
          disabled && "cursor-not-allowed",
        )}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        value={value}
      />
      <button
        className={cn(
          "flex items-center px-2 text-gray-500",
          disabled && "cursor-not-allowed",
        )}
        onClick={value ? () => onChange("") : undefined}
      >
        {value === "" ? <SearchIcon size={18} /> : <X size={18} />}
      </button>
    </div>
  );
}
