import Card from "src/frontend/components/Card";
import Centered from "src/frontend/components/Centered";
import { Skeleton } from "src/frontend/components/ui/Skeleton";

export default function SummaryMetricsSkeleton() {
  return (
    <div className="mb-8 flex flex-col justify-between gap-4 min-[980px]:flex-row">
      <Skeleton className="flex h-[226px] w-full flex-grow flex-col min-[980px]:w-1/2" />
      <Skeleton className="flex h-[226px] w-full flex-grow flex-col min-[980px]:w-1/2" />
    </div>
  );
}

const ErrorContent = (
  <Card className="flex h-[226px] w-full flex-grow flex-col min-[980px]:w-1/2">
    <Centered className="h-full">
      <p className="text-sm">Failed to load summary metrics...</p>
    </Centered>
  </Card>
);

export function SummaryMetricsErrorComponent() {
  return (
    <div className="mb-8 flex flex-col justify-between gap-4 min-[980px]:flex-row">
      {ErrorContent}
      {ErrorContent}
    </div>
  );
}
