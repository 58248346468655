import { z } from "zod";

export const ZodJsonObject = z.any().refine(
  (data) => {
    try {
      if (typeof data === "string") {
        JSON.parse(data);
      } else {
        JSON.parse(JSON.stringify(data));
      }
      return true;
    } catch (err) {
      return false;
    }
  },
  {
    message: "Must be valid JSON.",
  },
);
