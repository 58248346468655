export default function truncateTextMiddle(
  text: string,
  lengthToShow = 4,
): string {
  const minimumLength = lengthToShow * 2 + 1;
  if (text.length < minimumLength) {
    return text;
  }

  const left = text.slice(0, lengthToShow);
  const right = text.slice(-lengthToShow);
  return `${left}...${right}`;
}
