import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetGroupSummaryQuery() {
  const { enabled } = useIsBrandInitialized();
  const { groupId, pricePlanId } = useParams();
  return TrpcClient.internal.getGroupSummary.useQuery(
    {
      groupId: groupId !== "all" ? groupId : undefined,
      pricePlanId: pricePlanId!,
    },
    {
      enabled: enabled && groupId != null,
    },
  );
}
