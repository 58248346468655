import {
  isCountUnitOfMeasurement,
  isWeightUnitOfMeasurement,
} from "src/shared/constants/UnitOfMeasurementMapping";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

export default function getCompetitorUnits({
  quantity,
  secondaryQuantity,
  secondaryUom,
  uom,
}: {
  quantity: MaybeNull<number>;
  secondaryQuantity: MaybeNull<number>;
  secondaryUom: MaybeNull<string>;
  uom: MaybeNull<string>;
}): {
  countUom: MaybeNull<string>;
  countValue: MaybeNull<number>;
  weightUom: MaybeNull<string>;
  weightValue: MaybeNull<number>;
} {
  const uomIsCount = isCountUnitOfMeasurement(uom);
  const uomIsWeight = isWeightUnitOfMeasurement(uom);
  const secondaryUomIsCount = isCountUnitOfMeasurement(secondaryUom);
  const secondaryUomIsWeight = isWeightUnitOfMeasurement(secondaryUom);

  const bothUnitsAreCount = uomIsCount && secondaryUomIsCount;
  const bothUnitsAreWeight = uomIsWeight && secondaryUomIsWeight;

  if (bothUnitsAreCount || bothUnitsAreWeight) {
    const uomAreEqual = uom === secondaryUom;
    const quantitiesAreEqual = quantity === secondaryQuantity;
    const onlyOneValidQuantity =
      (quantity != null) != (secondaryQuantity != null);

    if (uomAreEqual && (quantitiesAreEqual || onlyOneValidQuantity)) {
      const quantityToReturn = quantity ?? secondaryQuantity;

      if (uomIsCount) {
        return {
          countUom: uom,
          countValue: quantityToReturn,
          weightUom: null,
          weightValue: null,
        };
      } else if (uomIsWeight) {
        return {
          countUom: null,
          countValue: null,
          weightUom: uom,
          weightValue: quantityToReturn,
        };
      }
    }

    return {
      countUom: null,
      countValue: null,
      weightUom: null,
      weightValue: null,
    };
  }

  if (uomIsCount || secondaryUomIsWeight) {
    return {
      countUom: uom,
      countValue: quantity,
      weightUom: secondaryUom,
      weightValue: secondaryQuantity,
    };
  } else if (uomIsWeight || secondaryUomIsCount) {
    return {
      countUom: secondaryUom,
      countValue: secondaryQuantity,
      weightUom: uom,
      weightValue: quantity,
    };
  }

  return {
    countUom: null,
    countValue: null,
    weightUom: null,
    weightValue: null,
  };
}
