import React from "react";
import CreatePricePlanModal from "src/frontend/components/modals/CreatePricePlanModal";
import DeletePricingRulesGroupModal from "src/frontend/components/modals/DeletePricingRulesGroupModal";
import DeletePricingStrategyModal from "src/frontend/components/modals/DeletePricingStrategyModal";
import DuplicatePricingRulesGroupModal from "src/frontend/components/modals/DuplicatePricingRulesGroupModal";
import DuplicatePricingStrategyModal from "src/frontend/components/modals/DuplicatePricingStrategyModal";
import GeneratePreviewPricePlanModal from "src/frontend/components/modals/GeneratePreviewPricePlanModal";
import usePricingRulesGroupDropdownStateStore from "src/frontend/stores/usePricingRulesGroupDropdownStateStore";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";

export function PricingStrategyModals() {
  const {
    isCreateModalOpen,
    isDeleteModalOpen,
    isDuplicateModalOpen,
    isGenerateModalOpen,
  } = usePricingStrategyDropdownStateStore();
  return (
    <>
      <DuplicatePricingStrategyModal
        isModalOpen={isDuplicateModalOpen}
        setIsModalOpen={(open) =>
          usePricingStrategyDropdownStateStore.setState({
            isDuplicateModalOpen: open,
          })
        }
      />
      <DeletePricingStrategyModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={(open) =>
          usePricingStrategyDropdownStateStore.setState({
            isDeleteModalOpen: open,
          })
        }
      />
      <CreatePricePlanModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={(open) =>
          usePricingStrategyDropdownStateStore.setState({
            isCreateModalOpen: open,
          })
        }
      />
      <GeneratePreviewPricePlanModal
        isModalOpen={isGenerateModalOpen}
        setIsModalOpen={(open) =>
          usePricingStrategyDropdownStateStore.setState({
            isGenerateModalOpen: open,
          })
        }
      />
    </>
  );
}

export function PricingRulesGroupModals() {
  const { isDeleteModalOpen, isDuplicateModalOpen } =
    usePricingRulesGroupDropdownStateStore();
  return (
    <>
      <DuplicatePricingRulesGroupModal
        isModalOpen={isDuplicateModalOpen}
        setIsModalOpen={(open) =>
          usePricingRulesGroupDropdownStateStore.setState({
            isDuplicateModalOpen: open,
          })
        }
      />
      <DeletePricingRulesGroupModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={(open) =>
          usePricingRulesGroupDropdownStateStore.setState({
            isDeleteModalOpen: open,
          })
        }
      />
    </>
  );
}
