import { UndoIcon } from "lucide-react";
import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Centered from "src/frontend/components/Centered";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import { TableDataCell } from "src/frontend/components/TableComponents";
import Button from "src/frontend/components/ui/Button";
import NumberInput from "src/frontend/components/ui/NumberInput";
import { Prompt } from "src/frontend/components/ui/Prompt";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useGetExperimentForecastQuery from "src/frontend/hooks/queries/useGetExperimentsForecastQuery";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import useEditPricePlanStore from "src/frontend/stores/useEditPricePlanStore";
import { TreatmentPriceFieldsType } from "src/shared/trpc/common/TreatmentPriceFields";
import { GetExperimentForecastQueryResponseType } from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";
import invariant from "tiny-invariant";

type EditPriceCellProps = {
  borderLeft?: boolean;
  borderRight?: boolean;
  experiment: GetExperimentForecastQueryResponseType;
  priceField: keyof TreatmentPriceFieldsType;
};

export default function EditPriceCell({
  borderLeft,
  borderRight,
  experiment,
  priceField,
}: EditPriceCellProps) {
  const t = useToast();
  const tab = usePricePlanTab();
  const isDarkTheme = useIsDarkTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [editPricePlanPromptOpen, setEditPricePlanPromptOpen] = useState(false);
  const [priceEditLoading, setPriceEditLoading] = useState(false);
  const priceEditStore = useEditPricePlanStore((state) => state);

  const editPricePlanExperimentsMutation =
    TrpcClient.internal.editPricePlanExperiments.useMutation();
  const experimentsOpportunityQuery = useGetExperimentForecastQuery();

  const { priceEdits } = priceEditStore;
  const originalTreatmentPrice = roundToPrecision(experiment[priceField]);
  const finalTreatmentPrice = roundToPrecision(
    experiment[`final_${priceField}`],
  );
  const hasBeenReverted = (priceEdits[experiment.experiment_id] ?? {
    [`${priceField}_reverted`]: false,
  })[`${priceField}_reverted`];
  const experimentPrices = priceEdits[experiment.experiment_id] ?? {};
  const editedPrice = roundToPrecision(experimentPrices[priceField]) ?? null;

  const hasEditedPrice =
    originalTreatmentPrice != null &&
    editedPrice != null &&
    finalTreatmentPrice !== editedPrice;
  const priceWasEdited = finalTreatmentPrice !== originalTreatmentPrice;

  const canEditPrice =
    finalTreatmentPrice != null && experiment.experiment_status !== "REJECTED";

  const showUndoButton =
    hasEditedPrice && !priceWasEdited && !hasBeenReverted && canEditPrice;

  const showUndoPrompt =
    !hasEditedPrice && priceWasEdited && !hasBeenReverted && canEditPrice;

  const onEditSuccess = async () => {
    const response = await experimentsOpportunityQuery.refetch();
    const experiments =
      response.data?.pages.map((page) => page.items).flat() ?? [];
    priceEditStore.setInitialState(experiments, priceEditStore.groupId!, {
      experimentId: experiment.experiment_id,
      priceField: priceField,
    });
    t.successToast("Price plan edited successfully.");
    setPriceEditLoading(false);
    setEditPricePlanPromptOpen(false);
  };

  const handleEditPricePlan = () => {
    t.infoToast("Reverting price edit...");
    setPriceEditLoading(true);
    editPricePlanExperimentsMutation.mutate(
      {
        edits: [
          {
            experiment_id: experiment.experiment_id,
            prices: {
              [priceField]: originalTreatmentPrice,
            },
          },
        ],
      },
      {
        onError: () => {
          t.errorToast("Failed to revert price edit.");
          setPriceEditLoading(false);
          setEditPricePlanPromptOpen(false);
        },
        onSuccess: () => {
          void onEditSuccess();
        },
      },
    );
  };

  const handleSubmitEdit = () => {
    if (
      hasEditedPrice ||
      finalTreatmentPrice == null ||
      editValue !== String(finalTreatmentPrice)
    ) {
      const finalEditValue = Number(editValue);
      if (finalEditValue === 0) {
        t.warningToast("Cannot set any prices to $0.00.");
      } else if (finalEditValue < 0) {
        t.warningToast("Cannot set any price to be a negative number.");
      } else {
        invariant(
          !isNaN(finalEditValue) && finalEditValue > 0,
          "finalEditValue must be a valid positive number.",
        );
        useEditPricePlanStore.setState((state) => {
          return {
            priceEdits: {
              ...state.priceEdits,
              [experiment.experiment_id]: {
                ...experimentPrices,
                [priceField]: finalEditValue,
                [`${priceField}_reverted`]: false,
              },
            },
          };
        });
      }
    }
    setEditValue("");
    setIsEditing(false);
  };

  const revertEdit = () => {
    useEditPricePlanStore.setState((state) => {
      return {
        priceEdits: {
          ...state.priceEdits,
          [experiment.experiment_id]: {
            ...experimentPrices,
            [priceField]: originalTreatmentPrice,
            [`${priceField}_reverted`]: true,
          },
        },
      };
    });
  };

  const handleCancel = () => {
    setEditValue("");
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <TableDataCell onClick={(e) => e.stopPropagation()}>
        <NumberInput
          autoFocus
          className="my-1 px-2 text-xs"
          onBlur={handleSubmitEdit}
          onChangeValue={setEditValue}
          onPressEnter={handleSubmitEdit}
          onPressEscape={handleCancel}
          value={editValue}
        />
      </TableDataCell>
    );
  }

  let displayPrice = originalTreatmentPrice;
  if (canEditPrice) {
    if (priceWasEdited && !hasEditedPrice) {
      displayPrice = finalTreatmentPrice;
    } else if (hasEditedPrice) {
      displayPrice = Number(editedPrice);
    } else {
      displayPrice = finalTreatmentPrice;
    }
  }

  if (tab === "current" || tab === "past") {
    return <TableDataCell>{formatCurrency(displayPrice)}</TableDataCell>;
  }

  return (
    <TableDataCell
      borderLeft={borderLeft}
      borderRight={borderRight}
      className={cn(canEditPrice && "hover:cursor-pointer")}
      onClick={(e) => e.stopPropagation()}
    >
      <Col className="py-1">
        <Row className="gap-1">
          <Tooltip
            content={<p>Click to edit price</p>}
            disabled={true || !canEditPrice}
          >
            <Centered
              className={cn(
                "h-5 rounded-sm py-1 font-bold",
                !hasEditedPrice &&
                  canEditPrice &&
                  "hover:border hover:border-ring",
                hasEditedPrice && !isDarkTheme && "bg-red-200",
                hasEditedPrice && isDarkTheme && "bg-red-300 text-black",
              )}
            >
              <p
                onClick={() => {
                  if (canEditPrice) {
                    setIsEditing(true);
                    setEditValue(
                      hasEditedPrice
                        ? String(editedPrice)
                        : finalTreatmentPrice != null
                          ? String(finalTreatmentPrice)
                          : "",
                    );
                  }
                }}
              >
                {formatCurrency(displayPrice)}
              </p>
            </Centered>
          </Tooltip>
          {showUndoButton && (
            <Button
              className="h-5 w-5"
              onClick={() => revertEdit()}
              size="icon"
              variant="basic"
            >
              <UndoIcon size={12} strokeWidth={2} />
            </Button>
          )}
          {showUndoPrompt && (
            <Prompt
              confirmText="Undo price edit"
              description={
                <p>
                  Undo the price edit for this product back to the original
                  price?
                </p>
              }
              loading={priceEditLoading}
              onCancel={() => setEditPricePlanPromptOpen(false)}
              onConfirm={handleEditPricePlan}
              onOpenChange={setEditPricePlanPromptOpen}
              open={editPricePlanPromptOpen}
              title="Undo price edit"
              trigger={
                <Button
                  className="h-5 w-5"
                  onClick={() => {
                    setEditPricePlanPromptOpen(true);
                  }}
                  size="icon"
                  variant="basic"
                >
                  <UndoIcon size={12} strokeWidth={2} />
                </Button>
              }
            />
          )}
        </Row>
        <Row>
          {!hasEditedPrice && priceWasEdited && (
            <p className="text-[10px] line-through">
              {formatCurrency(originalTreatmentPrice, 2, "")}
            </p>
          )}
          {hasEditedPrice && (
            <p className="text-[10px] line-through">
              {formatCurrency(finalTreatmentPrice, 2, "")}
            </p>
          )}
        </Row>
      </Col>
    </TableDataCell>
  );
}
