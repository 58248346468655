import { TypeScriptGenericObject } from "src/shared/types/generic/GenericObject";
import invariant from "tiny-invariant";

export default function lowercaseObjectKeys<T extends TypeScriptGenericObject>(
  obj: T,
): T {
  const result: TypeScriptGenericObject = {};
  for (const [k, v] of Object.entries(obj)) {
    const lowercaseKey = k.toLowerCase();
    invariant(
      !result.hasOwnProperty(lowercaseKey),
      "Duplicate key found in object with different casing. All object keys should be unique.",
    );
    result[k.toLowerCase()] = v;
  }
  return result as T;
}
