import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function getPriceBoundTypeSymbol(
  boundType: MaybeNull<PriceBoundType>,
): string {
  switch (boundType) {
    case null:
      return "";
    case "AMOUNT":
      return "$";
    case "PERCENT":
      return "%";
    default:
      return assertUnreachable(boundType);
  }
}
