import { useEffect, useState } from "react";
import LightLabel from "src/frontend/components/LightLabel";
import Row from "src/frontend/components/Row";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import { Slider } from "src/frontend/components/ui/Slider";
import useStrategyObjectiveStore from "src/frontend/stores/useStrategyObjectiveStore";
import getPrimaryObjectiveSelectLabel from "src/frontend/utils/getPrimaryObjectiveSelectLabel";
import {
  REVENUE_TRADEOFF_OPTIONS,
  PROFIT_TRADEOFF_OPTIONS,
  UNITS_TRADEOFF_OPTIONS,
} from "src/shared/constants/PrimaryObjectiveTradeoffs";
import { PricingStrategyObjectiveType } from "src/shared/trpc/common/enum/PricingStrategyObjectiveType";
import MaybeString from "src/shared/types/maybe/MaybeString";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import getMiddleElementInArray from "src/shared/utils/arrays/getMiddleElementInArray";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function getGainsText(objective: PricingStrategyObjectiveType): MaybeString {
  switch (objective) {
    case "REVENUE":
    case "UNITS_SOLD":
      return "revenue";
    case "PROFIT":
      return "profit";
    default:
      return assertUnreachable(objective);
  }
}

function getLossesText(objective: PricingStrategyObjectiveType): MaybeString {
  switch (objective) {
    case "REVENUE":
    case "UNITS_SOLD":
      return "profit";
    case "PROFIT":
      return "revenue";
    default:
      return assertUnreachable(objective);
  }
}

function getPrimaryObjectiveTradeoffValues(
  objective: MaybeUndefined<PricingStrategyObjectiveType>,
): number[] {
  switch (objective) {
    case undefined:
      return [];
    case "REVENUE":
      return REVENUE_TRADEOFF_OPTIONS;
    case "PROFIT":
      return PROFIT_TRADEOFF_OPTIONS;
    case "UNITS_SOLD":
      return UNITS_TRADEOFF_OPTIONS;
    default:
      return assertUnreachable(objective);
  }
}

const OBJECTIVE_OPTIONS: PricingStrategyObjectiveType[] = [
  "PROFIT",
  "REVENUE",
  "UNITS_SOLD",
];

type StrategyObjectiveTradeoffInputProps = {
  hasError: boolean;
  loading: boolean;
};

export default function StrategyObjectiveTradeoffInput({
  hasError,
  loading,
}: StrategyObjectiveTradeoffInputProps) {
  const state = useStrategyObjectiveStore();
  const values = getPrimaryObjectiveTradeoffValues(state.type);

  const initialSliderPosition =
    state.tradeoff == null
      ? getMiddleElementInArray(range(len(values)))
      : values.findIndex((el) => el === state.tradeoff);
  const [sliderPosition, setSliderPosition] = useState(initialSliderPosition);
  const initialDefaultValue = getMiddleElementInArray(values);

  useEffect(() => {
    setSliderPosition(initialSliderPosition);
    if (state.tradeoff == null && initialDefaultValue != null) {
      useStrategyObjectiveStore.setState({
        tradeoff: initialDefaultValue,
      });
    }
  }, [initialSliderPosition, state.tradeoff, initialDefaultValue]);

  const min = 0;
  const max = len(values) - 1;
  const tradeoffValue = state.tradeoff ?? initialDefaultValue;
  const objectiveHasTradeoff = true;
  return (
    <>
      <LightLabel>Business Goal</LightLabel>
      <Row>
        <div style={{ width: 300 }}>
          <Select
            disabled={loading}
            onValueChange={(value) => {
              const objectiveType = value as PricingStrategyObjectiveType;
              const tradeoffValues =
                getPrimaryObjectiveTradeoffValues(objectiveType);

              useStrategyObjectiveStore.setState({
                tradeoff: getMiddleElementInArray(tradeoffValues) ?? null,
                type: objectiveType,
              });
            }}
            value={state.type}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select One" />
            </SelectTrigger>
            <SelectContent
              onCloseAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              {OBJECTIVE_OPTIONS.map((variant) => {
                return (
                  <SelectItem key={variant} value={variant}>
                    {getPrimaryObjectiveSelectLabel(variant)}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
      </Row>
      {hasError && state.type == null && (
        <ErrorLabel>Business goal is required</ErrorLabel>
      )}
      {state.type != null && objectiveHasTradeoff && (
        <div className="mt-4 w-72">
          <Row className="mb-3 mt-4 justify-between gap-2">
            <p className="w-28 text-sm">
              tradeoff $1 in {getGainsText(state.type)} gains
            </p>
            <div className="h-8 border-r" />
            <p className="w-36 text-sm">
              <span className="italic">for no more than</span>
              <br />
              <span className="text-primary">
                ${tradeoffValue} in {getLossesText(state.type)} losses
              </span>
            </p>
          </Row>
          <Slider
            disabled={loading}
            max={max}
            min={min}
            onValueChange={(val) => {
              const position = val[0];
              setSliderPosition(position);
              useStrategyObjectiveStore.setState({
                tradeoff: values[position],
              });
            }}
            step={1}
            value={[sliderPosition]}
          />
        </div>
      )}
    </>
  );
}
