import { PricingStrategyObjectiveType } from "src/shared/trpc/common/enum/PricingStrategyObjectiveType";
import MaybeString from "src/shared/types/maybe/MaybeString";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function getGainsText(objective: PricingStrategyObjectiveType): MaybeString {
  switch (objective) {
    case "REVENUE":
    case "UNITS_SOLD":
      return "revenue";
    case "PROFIT":
      return "profit";
    default:
      return assertUnreachable(objective);
  }
}

function getLossesText(objective: PricingStrategyObjectiveType): MaybeString {
  switch (objective) {
    case "REVENUE":
    case "UNITS_SOLD":
      return "profit";
    case "PROFIT":
      return "revenue";
    default:
      return assertUnreachable(objective);
  }
}

type ObjectiveTradeoffSummaryProps = {
  objectiveType: PricingStrategyObjectiveType;
  tradeoff: number;
};

export default function ObjectiveTradeoffSummary({
  objectiveType,
  tradeoff,
}: ObjectiveTradeoffSummaryProps) {
  return (
    <p className="text-sm">
      Tradeoff $1 in {getGainsText(objectiveType)} gains for no more than{" "}
      <span className="text-primary">
        ${tradeoff} in {getLossesText(objectiveType)} losses
      </span>
    </p>
  );
}
