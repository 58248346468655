import {
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogRoot,
  AlertDialogHeader,
  AlertDialogFooter,
} from "src/frontend/components/ui/AlertDialog";
import Button from "src/frontend/components/ui/Button";
import noop from "src/shared/utils/noop";

type ModalProps = {
  cancelText?: string;
  confirmButtonDisabled?: boolean;
  confirmText?: string;
  dialogDescription: React.ReactNode;
  dialogTitle?: React.ReactNode;
  loading: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  trigger?: React.ReactElement | string;
};

export function Modal(props: ModalProps) {
  const {
    cancelText = "Cancel",
    confirmButtonDisabled,
    confirmText = "Continue",
    dialogDescription,
    dialogTitle = "Are you sure?",
    loading,
    onCancel = noop,
    onConfirm,
    onOpenChange,
    open,
    trigger,
  } = props;
  return (
    <AlertDialogRoot onOpenChange={onOpenChange} open={open}>
      {trigger != null && (
        <AlertDialogTrigger asChild>
          {typeof trigger === "string" ? (
            <Button variant="basic">{trigger}</Button>
          ) : (
            trigger
          )}
        </AlertDialogTrigger>
      )}
      <AlertDialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle>{dialogTitle}</AlertDialogTitle>
            <AlertDialogDescription>{dialogDescription}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-6 gap-2">
            <AlertDialogCancel disabled={loading} onClick={onCancel}>
              {cancelText}
            </AlertDialogCancel>
            <Button
              disabled={loading || confirmButtonDisabled}
              type="submit"
              variant="primary"
            >
              {loading ? "Loading..." : confirmText}
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialogRoot>
  );
}
