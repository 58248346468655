import { CopyIcon, TrashIcon } from "lucide-react";
import React from "react";
import Row from "src/frontend/components/Row";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import usePricingRulesGroupDropdownStateStore from "src/frontend/stores/usePricingRulesGroupDropdownStateStore";

type PricingRulesGroupDropdownMenuProps = {
  disabled?: boolean;
  pricingRulesGroupId: string;
  trigger: React.ReactNode;
};

export default function PricingRulesGroupDropdownMenu({
  disabled = false,
  pricingRulesGroupId,
  trigger,
}: PricingRulesGroupDropdownMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="focus:outline-none focus:ring-0">
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className="hover:cursor-pointer"
          disabled={disabled}
          onClick={() => {
            usePricingRulesGroupDropdownStateStore.setState({
              isDuplicateModalOpen: true,
              pricingRulesGroupId,
            });
          }}
        >
          <Row className="gap-2">
            <CopyIcon size={16} />
            <p>Duplicate</p>
          </Row>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:cursor-pointer"
          disabled={disabled}
          onClick={() => {
            usePricingRulesGroupDropdownStateStore.setState({
              isDeleteModalOpen: true,
              pricingRulesGroupId,
            });
          }}
        >
          <Row className="gap-2">
            <TrashIcon size={16} />
            <p>Delete</p>
          </Row>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
