import { CompetitorPricingAveragePrices } from "src/shared/trpc/common/CompetitorPricingAveragePrices";
import { CompetitorPricingObject } from "src/shared/trpc/common/CompetitorPricingObject";
import { InStockFilter } from "src/shared/trpc/common/InStockFilter";
import { ProductCatalogItem } from "src/shared/trpc/common/ProductCatalogItem";
import { CompetitorPriceLookbackPeriod } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { PriceTargetTypeEnum } from "src/shared/trpc/common/enum/PriceTargetType";
import { GetProductCatalogQueryInput } from "src/shared/trpc/queries/getProductCatalogQuerySchema";
import { PrimaryMatchTypeEnum } from "src/shared/utils/primaryMatchType";
import { z } from "zod";

export const GetCompetitorIntelligenceQueryInput =
  GetProductCatalogQueryInput.merge(
    z.object({
      inStockFilter: InStockFilter,
      matchLevelFilters: PrimaryMatchTypeEnum.refine(
        (value) => value !== PrimaryMatchTypeEnum.enum.SIMILAR_PRODUCT,
      ).array(),
      recentPriceOption: CompetitorPriceLookbackPeriod,
    }),
  );

export type GetCompetitorIntelligenceQueryInputType = z.infer<
  typeof GetCompetitorIntelligenceQueryInput
>;

const CompetitorMatchWithAveragePrices = CompetitorPricingObject.merge(
  CompetitorPricingAveragePrices,
);

export type CompetitorMatchWithAveragePricesType = z.infer<
  typeof CompetitorMatchWithAveragePrices
>;

const CompetitorIntelligence = z.object({
  competitorPricing: CompetitorMatchWithAveragePrices.nullable().array(),
  product: ProductCatalogItem,
});

export type CompetitorIntelligenceType = z.infer<typeof CompetitorIntelligence>;

export const GetCompetitorIntelligenceQueryResponse = z.object({
  availableCompetitorPriceFields: PriceTargetTypeEnum.array(),
  competitorNamesList: z.string().array(),
  data: CompetitorIntelligence.array(),
  totalCount: z.number(),
});

export type GetCompetitorIntelligenceQueryResponseType = z.infer<
  typeof GetCompetitorIntelligenceQueryResponse
>;
