import useBrandStore from "src/frontend/stores/useBrandStore";
import CsvDownloadConfig from "src/frontend/types/CsvDownloadConfig";
import downloadCsv from "src/frontend/utils/download/downloadCsv";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { DownloadNewPricePlanCsvApiResponseTypeV2 } from "src/shared/api/downloadNewPricePlanCsvApiSchemaV2";
import { CustomerSegmentType } from "src/shared/trpc/common/enum/CustomerSegment";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

export default function downloadNewPricePlanCsv(
  data: DownloadNewPricePlanCsvApiResponseTypeV2,
  customerSegment: MaybeNull<CustomerSegmentType>,
  config: CsvDownloadConfig = {
    skipExperiments: false,
  },
): void {
  const brand_name = useBrandStore.getState().brand_name;
  const { experiments } = data;
  const { skipExperiments } = config;
  const segment =
    customerSegment == null
      ? ""
      : `-${formatGenericEnumLabel(customerSegment).toLowerCase()}`;

  if (experiments !== "" && !skipExperiments) {
    const title = `${brand_name}-products-${segment}`;
    downloadCsv(experiments, title);
  }
}
