import { useEffect } from "react";
import { PAGE_CONTAINER_ID } from "src/frontend/components/PageContainer";

export default function useScrollToPageContainerTop() {
  useEffect(() => {
    const container = document.getElementById(PAGE_CONTAINER_ID);
    if (container != null) {
      container.scrollTop = 0;
    }
  }, []);
}
