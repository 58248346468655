import { pricing_strategy_constraint_type } from "@prisma/client";
import { z } from "zod";

export const ConstraintTypeEnum = z.nativeEnum(
  pricing_strategy_constraint_type,
);

export type ConstraintType = z.infer<typeof ConstraintTypeEnum>;

export const ConstraintTypeVariants = Object.keys(
  ConstraintTypeEnum.enum,
) as ConstraintType[];
