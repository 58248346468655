import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import formatDelta from "src/shared/utils/formatDelta";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import roundToNearestTenth from "src/shared/utils/numbers/roundToNearestTenth";

export default function formatCurrencyDelta(
  val: MaybeNumber,
  isDelta = true,
): string {
  const value = !isDelta ? roundToNearestTenth(val) : val;
  const currencyValue = formatCurrency(value, 0);
  if (isDelta) {
    return formatDelta(value, currencyValue) ?? "--";
  } else {
    return currencyValue;
  }
}
