import { CSSProperties } from "react";
import TABLE_PRODUCT_COLUMN_WIDTH from "src/frontend/constants/TableProductColumnWidth";
import { BreakpointState } from "src/frontend/hooks/useBreakpoints";
import getTailwindColor from "src/frontend/utils/getTailwindColor";

export default function getStickyColumnStyles({
  breakpointState,
  isDarkTheme,
  lineWeight = 1,
  width = TABLE_PRODUCT_COLUMN_WIDTH,
}: {
  breakpointState: BreakpointState;
  isDarkTheme: boolean;
  lineWeight?: number;
  width?: number;
}): CSSProperties {
  const borderColor = getTailwindColor("BORDER", isDarkTheme);
  return {
    boxShadow: `inset -${lineWeight}px 0 0 ${borderColor}`,
    maxWidth: width,
    minWidth: width,
    position: !breakpointState.isMedium ? "sticky" : undefined,
    width,
  };
}
