import { CompetitorPricingAveragePrices } from "src/shared/trpc/common/CompetitorPricingAveragePrices";
import { CompetitorPricingObject } from "src/shared/trpc/common/CompetitorPricingObject";
import { z } from "zod";

export const CompetitorMatchWithAveragePrices = CompetitorPricingObject.merge(
  CompetitorPricingAveragePrices,
);

export type CompetitorMatchWithAveragePricesType = z.infer<
  typeof CompetitorMatchWithAveragePrices
> & { competitor_end_customer_price: number | null };
