export default function pluralize(str: string, variableNumber: number): string {
  if (variableNumber === 1) {
    return str;
  } else {
    // A naive approach to pluralization which doesn't cover all English pluralization rules.
    if (str.endsWith("y")) {
      return str.slice(0, -1) + "ies";
    } else {
      return str + "s";
    }
  }
}
