import { DetailedHTMLProps } from "react";
import { cn } from "src/frontend/components/ui/utils";

export default function Card({
  className,
  ...props
}: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={cn(
        "rounded-2xl border border-solid border-border bg-card",
        className,
      )}
      {...props}
    />
  );
}
