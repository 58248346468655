import { Location } from "react-router-dom";
import CompanySettingsTab from "src/shared/types/CompanySettingsTab";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import capitalize from "src/shared/utils/strings/capitalize";

export default function getCompanySettingsTabFromLocation(
  location: Location,
): CompanySettingsTab {
  const path = location.pathname;
  if (path.startsWith("/company-settings/")) {
    const tab = path
      .split("/")[2]
      .split("-")
      .map(capitalize)
      .join(" ") as CompanySettingsTab;
    switch (tab) {
      case "Strategy":
      case "Price Settings":
      case "Relationships":
        return tab;
      default:
        return assertUnreachable(tab);
    }
  }

  return "Strategy";
}
