import invariant from "tiny-invariant";

export const UnitOfMeasurementMapping = {
  count: [
    "bag",
    "bags",
    "bottle",
    "bottles",
    "box",
    "boxes",
    "bulk",
    "bunch",
    "bunches",
    "bundle",
    "bundles",
    "can",
    "canister",
    "cans",
    "carton",
    "case",
    "cases",
    "clam shell",
    "container",
    "containers",
    "count",
    "counts",
    "crate",
    "crates",
    "ct",
    "double rolls",
    "each",
    "eggs",
    "farmstyle slices",
    "head",
    "jar",
    "jars",
    "loaf",
    "loaves",
    "log",
    "meals, 2 servings each",
    "meals, 3 servings each",
    "multipack",
    "pack",
    "package",
    "packets",
    "packs",
    "pc",
    "piece",
    "pieces",
    "pk",
    "ply sheets",
    "ply",
    "pot",
    "pots",
    "pouch",
    "pouches",
    "roll",
    "rolls",
    "serving",
    "servings",
    "stick",
    "sticks",
    "tin",
    "tins",
    "tub",
    "tube",
    "unit",
    "units",
  ],
  cup: ["cup", "cups"],
  dozen: ["dozen", "dozens", "half dozen", "half dozens", "dz"],
  "fl oz": ["fluid ounce", "fluid ounces", "fl oz", "fl", "fz"],
  ft: ["foot", "feet", "ft"],
  g: ["gram", "grams", "g"],
  gal: ["gallon", "gallons", "half gallon", "half gallons", "gal"],
  in: ["inch", "inches", "in"],
  kg: ["kilogram", "kilograms", "kg"],
  l: ["liter", "liters", "l"],
  lb: ["pound", "pounds", "lb", "lbs"],
  ml: ["ml", "milliliter", "milliliters"],
  oz: ["ounce", "ounces", "oz"],
  pt: ["pint", "pints", "pt"],
  qt: ["quart", "quarts", "qt"],
  "sq ft": ["square foot", "square feet", "sf", "sq ft"],
} as const;

const allUnitsArray = Object.values(UnitOfMeasurementMapping).flat();
const uniqueUnits = new Set();
allUnitsArray.forEach((unit) => {
  invariant(uniqueUnits.has(unit) === false, `${unit} is not unique`);
  uniqueUnits.add(unit);
});

export function getUnitOfMeasurement(
  unit: string | null,
): keyof typeof UnitOfMeasurementMapping | null {
  if (unit == null) {
    return null;
  }
  for (const [key, values] of Object.entries(UnitOfMeasurementMapping)) {
    if (unit.toLowerCase() === key.toLowerCase()) {
      return key as keyof typeof UnitOfMeasurementMapping;
    }
    for (const value of values) {
      if (unit.toLowerCase() === value.toLowerCase()) {
        return key as keyof typeof UnitOfMeasurementMapping;
      }
    }
  }
  return null;
}

export function isWeightUnitOfMeasurement(unit: string | null): boolean {
  return (
    unit != null &&
    !isCountUnitOfMeasurement(unit) &&
    getUnitOfMeasurement(unit) != null
  );
}

export function isCountUnitOfMeasurement(unit: string | null): boolean {
  return (
    unit != null &&
    (UnitOfMeasurementMapping.count as readonly string[]).includes(
      unit.toLowerCase(),
    )
  );
}
