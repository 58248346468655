import ApprovePricePlanModal from "src/frontend/components/modals/ApprovePricePlanModal";
import ConfirmPriceChangesModal from "src/frontend/components/modals/ConfirmPriceChangesModal";
import useUserBrand from "src/frontend/hooks/useUserBrand";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function ApprovePricePlanAction() {
  const brand = useUserBrand();
  if (brand?.price_plan_approval_type == null) {
    return null;
  }

  switch (brand.price_plan_approval_type) {
    case "AUTO_APPROVE":
      return <ApprovePricePlanModal approvalButtonText="Approve Now" />;
    case "MANUAL_APPROVE":
      return <ApprovePricePlanModal />;
    case "MANUAL_CONFIRM":
      return <ConfirmPriceChangesModal />;
    default:
      return assertUnreachable(brand.price_plan_approval_type);
  }
}
