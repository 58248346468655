import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type BoldProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function Bold({ children, className, ...rest }: BoldProps) {
  return (
    <span className={cn("font-bold", className)} {...rest}>
      {children}
    </span>
  );
}
