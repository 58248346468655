import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useBrandId from "src/frontend/hooks/useBrandId";
import { useDebounce } from "usehooks-ts";

export default function useOnNavigateSideEffect() {
  const location = useLocation();
  const debouncedValue = useDebounce<string>(location.pathname, 1000);
  const brandId = useBrandId();
  const { mutate: notifySlackOnNavigationEventMutation } =
    TrpcClient.internal.notifySlackOnNavigationEvent.useMutation();

  const notifySlack = useCallback(() => {
    if (brandId != null && brandId !== "") {
      notifySlackOnNavigationEventMutation({
        brandId,
        pathname: location.pathname,
      });
    }
  }, [brandId, location.pathname, notifySlackOnNavigationEventMutation]);

  useEffect(() => {
    notifySlack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
}
