import { PriceParityGroupDuplicateProductErrorInfo } from "src/shared/trpc/mutations/createOrUpdatePriceParityGroupMutationSchema";
import { z } from "zod";

export const TrpcErrorType = z.union([
  z.literal("InternalServerError"),
  z.literal("TestError"),
  z.literal("DeletePricingStrategyError"),
  z.literal("DeletePricingRulesGroupError"),
  z.literal("PriceParityGroupDuplicateProductError"),
  // ...etc
]);

export type TrpcErrorType = z.infer<typeof TrpcErrorType>;

export const TestError = z.object({
  data: z.any(),
  message: z.string(),
  type: z.literal("TestError"),
});

export type TestErrorType = z.infer<typeof TestError>;

export const InternalServerError = z.object({
  data: z.any(),
  message: z.string(),
  type: z.literal("InternalServerError"),
});

export type InternalServerErrorType = z.infer<typeof InternalServerError>;

export const DuplicateProductsError = z.object({
  data: PriceParityGroupDuplicateProductErrorInfo,
  message: z.string(),
  type: z.literal("PriceParityGroupDuplicateProductError"),
});

export type DuplicateProductsErrorType = z.infer<typeof DuplicateProductsError>;

export const DeletePricingStrategyError = z.object({
  message: z.string(),
  type: z.literal("DeletePricingStrategyError"),
});

export type DeletePricingStrategyErrorType = z.infer<
  typeof DeletePricingStrategyError
>;

export const DeletePricingRulesGroupError = z.object({
  message: z.string(),
  type: z.literal("DeletePricingRulesGroupError"),
});

export type DeletePricingRulesGroupErrorType = z.infer<
  typeof DeletePricingRulesGroupError
>;

export const TrpcErrorVariants = z.union([
  TestError,
  DuplicateProductsError,
  DeletePricingStrategyError,
  DeletePricingRulesGroupError,
]);

export type TrpcErrorVariantsType = z.infer<typeof TrpcErrorVariants>;
