import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";

export default function formatDelta(
  delta: MaybeNumber,
  formattedValue?: number | string,
): MaybeString {
  if (delta == null) {
    return null;
  }

  const result = formattedValue ?? delta;
  return delta <= 0 ? `${result}` : `+${result}`;
}
