import { competitor_price_comparison_type } from "@prisma/client";
import { z } from "zod";

export const CompetitorPriceComparisonTypeEnum = z.nativeEnum(
  competitor_price_comparison_type,
);

export type CompetitorPriceComparisonType = z.infer<
  typeof CompetitorPriceComparisonTypeEnum
>;

export const CompetitorPriceComparisonTypeVariants = Object.keys(
  CompetitorPriceComparisonTypeEnum.enum,
) as CompetitorPriceComparisonType[];
