import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import getConstraintErrorMessage from "src/shared/validation/getConstraintErrorMessage";
import isCompetitorPriceConstraintValid from "src/shared/validation/isCompetitorPriceConstraintValid";
import isPriceComparisonConstraintValid from "src/shared/validation/isPriceComparisonConstraintValid";
import isPriceTargetTypeValid from "src/shared/validation/isPriceTargetTypeValid";
import isRoundingConstraintValid from "src/shared/validation/isRoundingConstraintValid";

export default function isPricingConstraintConstraintValid(
  constraint: PricingConstraintType,
  modeling_price: MaybeUndefined<PriceTargetType>,
): boolean {
  return (
    constraint.type != null &&
    isPriceTargetTypeValid(constraint.price_target_type) &&
    isRoundingConstraintValid(constraint) &&
    getConstraintErrorMessage(constraint) === null &&
    isCompetitorPriceConstraintValid(constraint) &&
    isPriceComparisonConstraintValid(constraint, modeling_price)
  );
}
