import { Badge } from "src/frontend/components/ui/Badge";
import { cn } from "src/frontend/components/ui/utils";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";

type ElasticityBadgeProps = {
  elasticity: MaybeNumber;
};

export const LOW_ELASTICITY = -1.3;
export const MEDIUM_ELASTICITY = -1.6;

const COLORS = {
  HIGH: "#CFDDFF",
  LOW: "#D7F5C9",
  MEDIUM: "#EFE4FF",
};

export default function ElasticityBadge({ elasticity }: ElasticityBadgeProps) {
  const isDarkTheme = useIsDarkTheme();
  if (elasticity == null) {
    return "--";
  }

  const value = formatNumberRounded(elasticity, 2);
  if (elasticity >= LOW_ELASTICITY) {
    return (
      <Badge
        className={cn(
          "whitespace-nowrap pl-1 text-left text-xs font-semibold text-black",
          isDarkTheme && "opacity-85",
        )}
        style={{ backgroundColor: COLORS.LOW }}
        variant={"info"}
      >
        Low: {value}
      </Badge>
    );
  } else if (elasticity >= MEDIUM_ELASTICITY && elasticity <= LOW_ELASTICITY) {
    return (
      <Badge
        className={cn(
          "whitespace-nowrap pl-1 text-left text-xs font-semibold text-black",
          isDarkTheme && "opacity-85",
        )}
        style={{ backgroundColor: COLORS.MEDIUM }}
        variant={"info"}
      >
        Medium: {value}
      </Badge>
    );
  } else {
    return (
      <Badge
        className={cn(
          "whitespace-nowrap pl-1 text-left text-xs font-semibold text-black",
          isDarkTheme && "opacity-85",
        )}
        style={{ backgroundColor: COLORS.HIGH }}
        variant={"info"}
      >
        High: {value}
      </Badge>
    );
  }
}
