import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import TrpcClient from "src/frontend/api/TrpcClient";
import { ExperimentFilter } from "src/frontend/pages/pricing/details/PricePlanGroupDetail";
import { ExperimentFilterColumnEnum } from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import { useDebounce } from "usehooks-ts";

export default function useGetExperimentsForecastQuery() {
  const { groupId, pricePlanId } = useParams();
  const [searchParams] = useSearchParams();
  const filters =
    searchParams?.get("filters") != null &&
    searchParams.get("filters")!.length > 0
      ? (JSON.parse(searchParams.get("filters")!) as ExperimentFilter[])
          .map((filter) => ({
            column: filter.columnFilterValue,
            columnFilter: filter.selectedFilter,
            columnFilterValue: filter.textFilterValue ?? "",
            type: filter.type,
          }))
          .filter(
            (f) =>
              f.columnFilterValue != null && f.columnFilterValue.length > 0,
          )
      : [];

  const sortColum = searchParams.get(
    "sortColumn",
  ) as ExperimentFilterColumnEnum | null;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc" | null;

  const searchQuery = searchParams.get("searchQuery");
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  return TrpcClient.internal.getExperimentsForecast.useInfiniteQuery(
    {
      filters,
      groupId: groupId !== "all" ? groupId : undefined,
      pricePlanId: pricePlanId!,
      searchQuery: debouncedSearchQuery ?? undefined,
      sortBy: sortColum ?? undefined,
      sortDirection: sortOrder ?? undefined,
    },
    {
      enabled: pricePlanId != null,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: 0,
      refetchOnWindowFocus: false,
    },
  );
}
