import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import COLORS from "src/frontend/constants/Colors";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import getColorForProductCategoryFilterType from "src/frontend/utils/getColorForProductCategoryFilterType";
import { TagType } from "src/shared/trpc/common/Tag";

export default function tagToFancyBoxItem(tag: TagType): FancyBoxItem {
  const type = ProductCategoryFilterType.Tag;
  const color = tag.is_luca_tag
    ? COLORS.LUCA_TAG
    : getColorForProductCategoryFilterType(type);
  const item: FancyBoxItem = {
    color,
    id: tag.id,
    isLucaTag: tag.is_luca_tag,
    label: tag.title,
    type,
    value: tag.title,
  };
  return item;
}
