import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import usePricingRulesGroupDropdownStateStore from "src/frontend/stores/usePricingRulesGroupDropdownStateStore";
import invariant from "tiny-invariant";

type DeletePricingRulesGroupModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function DeletePricingRulesGroupModal({
  isModalOpen,
  setIsModalOpen,
}: DeletePricingRulesGroupModalProps) {
  const t = useToast();
  const pricingStrategyQuery = useGetPricingStrategyByIdQuery();
  const { loading, pricingRulesGroupId } =
    usePricingRulesGroupDropdownStateStore();
  const deletePricingRulesGroupMutation =
    TrpcClient.internal.deletePricingRulesGroup.useMutation();

  const onSuccess = async () => {
    await pricingStrategyQuery.refetch();
    usePricingRulesGroupDropdownStateStore.setState({
      isDeleteModalOpen: false,
      loading: false,
      pricingRulesGroupId: null,
    });
    setIsModalOpen(false);
    t.successToast("Pricing rules group deleted successfully.");
  };

  const handleDelete = () => {
    usePricingRulesGroupDropdownStateStore.setState({ loading: true });
    invariant(pricingRulesGroupId != null);
    deletePricingRulesGroupMutation.mutate(
      {
        pricingRulesGroupId,
      },
      {
        onError: () => {
          t.errorToast("Failed to delete pricing rules group.");
          usePricingRulesGroupDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Modal
      confirmText="Delete"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            This pricing rules group will be deleted. This action cannot be
            undone.
          </p>
        </div>
      }
      dialogTitle="Delete Pricing Rules Group"
      loading={loading}
      onConfirm={handleDelete}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
