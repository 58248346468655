import { PricingStrategyObjectiveType } from "src/shared/trpc/common/enum/PricingStrategyObjectiveType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import capitalize from "src/shared/utils/strings/capitalize";

export default function getPrimaryObjectiveSelectLabel(
  objective: PricingStrategyObjectiveType,
): string {
  const label = objective
    .split("_")
    .map((val) => capitalize(val.toLowerCase()))
    .join(" ");

  switch (objective) {
    case "REVENUE":
    case "PROFIT":
    case "UNITS_SOLD":
      return `Increase ${label}`;
    default:
      return assertUnreachable(objective);
  }
}
