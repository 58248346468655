import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { ProductMasterObjectType } from "src/shared/trpc/common/ProductMasterObject";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { GetExperimentForecastQueryResponseType } from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import { create } from "zustand";

export type ProductDetailSidePanelTab =
  | "Competitor Pricing"
  | "Constraints"
  | "Product";

export type CompetitorDetailStoreInfo = {
  data: CompetitorMatchWithAveragePricesType[];
};

export type ProductDetailSidePanelStore = {
  competitorMatchIndex: MaybeNumber;
  competitorNamesList: string[];
  competitorPricingData: MaybeNull<
    MaybeNull<CompetitorMatchWithAveragePricesType>[][]
  >;
  experimentModelingPrice: MaybeNull<PriceTargetType>;
  isOpen: boolean;
  productIndex: MaybeNumber;
  products:
    | GetExperimentForecastQueryResponseType[]
    | ProductMasterObjectType[];
  tab: ProductDetailSidePanelTab;
};

const defaultState: ProductDetailSidePanelStore = {
  competitorMatchIndex: null,
  competitorNamesList: [],
  competitorPricingData: null,
  experimentModelingPrice: null,
  isOpen: false,
  productIndex: null,
  products: [],
  tab: "Product",
};

type Actions = {
  reset: () => void;
};

const useProductDetailSidePanelStore = create<
  Actions & ProductDetailSidePanelStore
>((set) => ({
  reset: (): void => {
    set(defaultState);
  },
  ...defaultState,
}));

export default useProductDetailSidePanelStore;
