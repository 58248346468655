import { competitor_price_lookback_period } from "@prisma/client";
import { z } from "zod";

export const CompetitorPriceLookbackPeriod = z.nativeEnum(
  competitor_price_lookback_period,
);

export type CompetitorPriceLookbackPeriodType = z.infer<
  typeof CompetitorPriceLookbackPeriod
>;

export const CompetitorPriceLookbackPeriodVariants = Object.keys(
  CompetitorPriceLookbackPeriod.enum,
) as CompetitorPriceLookbackPeriodType[];
