import { Dispatch, SetStateAction, useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import useAdminActionsAvailable from "src/frontend/hooks/useAdminActionsAvailable";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import invariant from "tiny-invariant";

type PublishDraftResultsModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PublishDraftResultsModal({
  isModalOpen,
  setIsModalOpen,
}: PublishDraftResultsModalProps) {
  const adminActionsAvailable = useAdminActionsAvailable();
  const [published, setPublished] = useState(false);
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const pricePlanMetrics = useGetPricePlanSummaryQuery();
  const { errorToast, successToast } = useToast();
  const publishDraftResultsMutation =
    TrpcClient.admin.publishDraftResults.useMutation();

  if (!adminActionsAvailable || published) {
    return null;
  }

  return (
    <Modal
      dialogDescription={
        <div>
          <p>
            Publish the current <Code>DRAFT</Code> price plan results for
            customers to see.
          </p>
        </div>
      }
      dialogTitle="Publish Results"
      loading={publishDraftResultsMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null);
        publishDraftResultsMutation.mutate(
          { pricePlanId },
          {
            onError: () => {
              errorToast("Failed to update price plan results.");
            },
            onSuccess: () => {
              setPublished(true);
              setIsModalOpen(false);
              successToast("Results published successfully!");
              void pricePlanMetrics.refetch();
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
