import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { ROUNDING_CONSTRAINT_PRICE_ENDING } from "src/shared/trpc/common/RoundingConstraintPriceEnding";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";

export default function isRoundingConstraintValid(
  constraint: PricingConstraintType,
): boolean {
  if (constraint.type === "ROUNDING") {
    const roundingSelectionValid = constraint.round_to.every((val) => {
      try {
        ROUNDING_CONSTRAINT_PRICE_ENDING.parse(val);
        return true;
      } catch (_) {
        return false;
      }
    });

    if (arrayEmpty(constraint.round_to) || !roundingSelectionValid) {
      return false;
    }
  }

  return true;
}
