import { useLocation } from "react-router-dom";
import Bold from "src/frontend/components/Bold";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";

export default function MockCompetitorPriceLinkPage() {
  const isLucaAdmin = useIsLucaAdmin();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const price = Number(params.get("price"));
  const productName = params.get("productName");
  const competitorName = params.get("competitorName");
  if (!isLucaAdmin) {
    return null;
  }
  return (
    <PageContainer>
      <PageTitle>Mock Competitor Pricing Details</PageTitle>
      <p className="mt-2">
        Price: <Bold>{formatCurrency(price)}</Bold>
      </p>
      <p className="mt-2">
        Product: <Bold>{productName}</Bold>
      </p>
      <p className="mt-2">
        Competitor: <Bold>{competitorName}</Bold>
      </p>
    </PageContainer>
  );
}
