import { useMemo } from "react";
import FancyBox, { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import {
  getPrimaryMatchTypeLabel,
  MatchLevelFiltersExceptForSimilarProduct,
  PrimaryMatchTypeEnum,
} from "src/shared/utils/primaryMatchType";

const options = PrimaryMatchTypeEnum.options;

const items: FancyBoxItem[] = options
  .filter((option) => option !== "SIMILAR_PRODUCT")
  .map((option) => {
    return {
      id: option,
      label: getPrimaryMatchTypeLabel(option),
      value: option,
    };
  });

export default function CompetitorIntelligenceMatchLevelFilter() {
  const selectedItemValues = useCompetitorIntelligenceFiltersStore(
    (state) => state.matchLevelFilters,
  );

  const selectedItems: FancyBoxItem[] = useMemo(() => {
    return selectedItemValues.map((value) => {
      return {
        id: value,
        label: getPrimaryMatchTypeLabel(value),
        value,
      };
    });
  }, [selectedItemValues]);

  return (
    <FancyBox
      disableCreateNew
      disableEditing
      disableSearch
      disableSelectedItemsDisplay
      items={items}
      selectedItems={selectedItems}
      setItems={() => {
        return;
      }}
      setSelectedItems={() => {
        return;
      }}
      setSelectedItemsOverrideFn={(item: FancyBoxItem) => {
        const value = item.value as MatchLevelFiltersExceptForSimilarProduct;

        const deselected: boolean = selectedItemValues.includes(value);

        const newSelectedItemValues: MatchLevelFiltersExceptForSimilarProduct[] =
          deselected
            ? selectedItemValues.filter((val) => val !== value)
            : [...selectedItemValues, value];

        useCompetitorIntelligenceFiltersStore.setState({
          matchLevelFilters: newSelectedItemValues,
        });
      }}
      truncateOnNthItem={1}
      width={220}
    />
  );
}
