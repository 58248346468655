import capitalize from "src/shared/utils/strings/capitalize";
import snakeCaseToCamelCase, {
  SnakeCaseToCamelCase,
} from "src/shared/utils/strings/snakeCaseToCamelCase";

export default function mappedColumnVisibility<
  T extends Record<string, boolean>,
>(
  visibilityFilters: T,
): Record<`has${Capitalize<SnakeCaseToCamelCase<string & keyof T>>}`, boolean> {
  const mappedVisibilityColumns = Object.entries(visibilityFilters).reduce(
    (acc, [key, value]) => {
      const camelCaseKey = snakeCaseToCamelCase(
        key as T[keyof T] extends string ? T[keyof T] : never,
      );

      const capitalizedCamelCaseKey = capitalize(camelCaseKey);

      const newKey: `has${typeof capitalizedCamelCaseKey}` = `has${capitalizedCamelCaseKey}`;
      acc[newKey] = value;
      return acc;
    },
    {} as Record<
      `has${Capitalize<SnakeCaseToCamelCase<string & keyof T>>}`,
      boolean
    >,
  );

  return mappedVisibilityColumns;
}
