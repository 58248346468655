import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import { ACTIVE_SKUS_OPTION } from "src/frontend/constants/CustomProductFilters";
import { ProductConditionFiltersType } from "src/shared/trpc/common/ProductConditionFilters";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { ProductMasterWithCompetitorMatchesType } from "src/shared/trpc/common/ProductMasterWithCompetitorMatches";
import { SortDirectionType } from "src/shared/zod/SortDirection";
import { create } from "zustand";

export type PriceParityGroupStore = ProductConditionFiltersType & {
  groupSelectProductIds: string[];
  impactedSkus: FancyBoxItem[];
  impactedSkusOptions: FancyBoxItem[];
  isInitialized: boolean;
  is_active: boolean;
  name: string;
  page: number;
  searchText: string;
  selectedProductIds: string[];
  selectedProducts: ProductMasterWithCompetitorMatchesType[];
  sortBy: ProductMasterObjectSortKeyType;
  sortDirection: SortDirectionType;
};

const defaultState: PriceParityGroupStore = {
  applyAndConditionForCategory: false,
  applyAndConditionForFilters: false,
  applyAndConditionForPriceZone: false,
  applyAndConditionForProductBrandName: false,
  applyAndConditionForSubCategory: false,
  applyAndConditionForSupplier: false,
  applyAndConditionForTags: false,
  groupSelectProductIds: [],
  impactedSkus: [ACTIVE_SKUS_OPTION],
  impactedSkusOptions: [],
  isInitialized: false,
  is_active: true,
  name: "",
  page: 1,
  searchText: "",
  selectedProductIds: [],
  selectedProducts: [],
  sortBy: "product_name",
  sortDirection: "asc",
};

type StoreActions = {
  reset: () => void;
  setImpactedSkus: (
    items: FancyBoxItem[] | ((prevItems: FancyBoxItem[]) => FancyBoxItem[]),
  ) => void;
};

const usePriceParityGroupStore = create<PriceParityGroupStore & StoreActions>(
  (set) => ({
    reset: (): void => {
      set(defaultState);
    },
    setImpactedSkus: (arg) =>
      set((state) => ({
        impactedSkus: arg instanceof Function ? arg(state.impactedSkus) : arg,
      })),
    ...defaultState,
  }),
);

export default usePriceParityGroupStore;
