import { useCallback, useEffect, useState } from "react";
import apiClient from "src/frontend/api/ApiClient";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import Header from "src/frontend/components/Header";
import LightLabel from "src/frontend/components/LightLabel";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import PaginationControls from "src/frontend/components/PaginationControls";
import ProductCategoryFilter from "src/frontend/components/ProductCategoryFilter";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import SelectedProductFiltersList from "src/frontend/components/SelectedProductFiltersList";
import UserColumnVisibilityFilterComponent from "src/frontend/components/UserColumnVisibilityFilterComponentV2";
import TableSkeleton from "src/frontend/components/skeletons/TableSkeleton";
import DownloadButton from "src/frontend/components/ui/DownloadButton";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useTagsQuery from "src/frontend/hooks/queries/useTagsQuery";
import useUserColumnVisibilityFiltersQuery from "src/frontend/hooks/queries/useUserColumnVisibilityFiltersQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import CreateTagDialog from "src/frontend/pages/catalog/CreateTagDialog";
import ProductCatalogTable from "src/frontend/pages/catalog/ProductCatalogTable";
import useProductCatalogFiltersStore from "src/frontend/stores/useProductCatalogFilters";
import downloadProductCatalogCsv from "src/frontend/utils/download/downloadProductCatalogCsv";
import getCategoryFiltersFromBrandCategoriesAndTags from "src/frontend/utils/getCategoryFiltersFromBrandCategoriesAndTags";
import logClientError from "src/frontend/utils/logClientError";
import parseImpactedSkusSelection from "src/frontend/utils/parseImpactedSkusSelection";
import PRODUCT_CATALOG_PAGE_SIZE from "src/shared/constants/ProductCatalogPageSize";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import formatDateWithTime from "src/shared/utils/dates/formatDateWithTime";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import deepEquals from "src/shared/utils/objects/deepEquals";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";
import { useDebounce } from "usehooks-ts";

export default function ProductCatalog() {
  const t = useToast();
  const { isExtraLarge } = useBreakpoints();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const visibilityFiltersQuery = useUserColumnVisibilityFiltersQuery();
  const filters = useProductCatalogFiltersStore();
  const {
    applyAndConditionForCategory,
    applyAndConditionForFilters,
    applyAndConditionForPriceZone,
    applyAndConditionForProductBrandName,
    applyAndConditionForSubCategory,
    applyAndConditionForSupplier,
    applyAndConditionForTags,
    filteredSkus,
    page,
    searchText,
    sortBy,
    sortDirection,
  } = filters;

  const debouncedSearchTerm = useDebounce<string>(searchText, 400);
  const [totalCount, setTotalCount] = useState(0);
  const { brandId, enabled } = useIsBrandInitialized();
  const [skusFilterOptions, setSkusFilterOptions] = useState<FancyBoxItem[]>(
    [],
  );

  const setPage = useCallback((page: number) => {
    useProductCatalogFiltersStore.setState({ page, selectedProductIds: [] });
  }, []);

  const setSearchTerm = (term: string) => {
    useProductCatalogFiltersStore.setState({ searchText: term });
  };

  const { setFilteredSkus } = useProductCatalogFiltersStore.getState();

  const resetPage = useCallback(() => {
    setPage(1);
  }, [setPage]);

  const setSelectedItemsOverrideFn = (item: FancyBoxItem) => {
    useProductCatalogFiltersStore.setState(({ filteredSkus: filters }) => {
      return {
        filteredSkus:
          filters.find((current) => current.value === item.value) == null
            ? filters.concat(item)
            : filters.filter((l) => l.value !== item.value),
      };
    });
    resetPage();
  };

  const handleRemoveItem = (item: FancyBoxItem) => {
    setFilteredSkus((currentItems) =>
      !currentItems.includes(item)
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value),
    );
    resetPage();
  };

  const {
    impactedCategories: categories,
    impactedPriceZones: priceZones,
    impactedProductBrandNames: productBrandNames,
    impactedSubCategories: subCategories,
    impactedSuppliers: suppliers,
    impactedTags: tagIds,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
    showActiveSkus,
    showInactiveSkus,
  } = parseImpactedSkusSelection(filteredSkus);

  const tagsQuery = useTagsQuery();
  const productCategoriesQuery =
    TrpcClient.internal.getBrandProductCategories.useQuery({ brandId });
  const productCatalogQuery = TrpcClient.internal.getProductCatalog.useQuery(
    {
      applyAndConditionForCategory,
      applyAndConditionForFilters,
      applyAndConditionForPriceZone,
      applyAndConditionForProductBrandName,
      applyAndConditionForSubCategory,
      applyAndConditionForSupplier,
      applyAndConditionForTags,
      brandId,
      categories,
      includeEmptyCategory,
      includeEmptyPriceZone,
      includeEmptyProductBrandName,
      includeEmptySubCategory,
      includeEmptySupplier,
      includeUntaggedSkus,
      page,
      priceZones,
      productBrandNames,
      searchQuery: debouncedSearchTerm,
      showActiveSkus,
      showInactiveSkus,
      sortBy,
      sortDirection,
      subCategories,
      suppliers,
      tagIds,
    },
    {
      enabled,
    },
  );

  useEffect(() => {
    resetPage();
  }, [debouncedSearchTerm, resetPage]);

  useEffect(() => {
    if (productCatalogQuery.data?.totalCount != null) {
      if (productCatalogQuery.data.totalCount !== totalCount) {
        setTotalCount(productCatalogQuery.data.totalCount);
      }
    }
  }, [totalCount, productCatalogQuery]);

  useEffect(() => {
    if (tagsQuery.data != null && productCategoriesQuery.data != null) {
      const initialImpactedSkusOptions =
        getCategoryFiltersFromBrandCategoriesAndTags(
          tagsQuery.data,
          productCategoriesQuery.data,
        );
      if (!deepEquals(initialImpactedSkusOptions, skusFilterOptions)) {
        setSkusFilterOptions(initialImpactedSkusOptions);
      }
    }
  }, [tagsQuery, productCategoriesQuery, skusFilterOptions]);

  const fetchFullProductCatalog = async () => {
    return apiClient.downloadProductCatalogCsv({
      applyAndConditionForCategory,
      applyAndConditionForFilters,
      applyAndConditionForPriceZone,
      applyAndConditionForProductBrandName,
      applyAndConditionForSubCategory,
      applyAndConditionForSupplier,
      applyAndConditionForTags,
      brandId,
      categories,
      includeEmptyCategory,
      includeEmptyPriceZone,
      includeEmptyProductBrandName,
      includeEmptySubCategory,
      includeEmptySupplier,
      includeUntaggedSkus,
      page,
      priceZones,
      productBrandNames,
      searchQuery: debouncedSearchTerm,
      showActiveSkus,
      showInactiveSkus,
      sortBy,
      sortDirection,
      subCategories,
      suppliers,
      tagIds,
    });
  };

  const handleDownload = async () => {
    t.infoToast("Requesting download, please wait a moment...");
    setDownloadLoading(true);

    try {
      const result = await fetchFullProductCatalog();
      downloadProductCatalogCsv(result.csv);
      t.successToast("Product catalog CSV downloaded successfully.");
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError);
      t.errorToast("There was a problem downloading the CSV data.");
    } finally {
      setDownloadLoading(false);
    }
  };

  const refreshedOnTime = productCatalogQuery.data?.products[0]?.updated_at;
  const refreshedOnLabel =
    refreshedOnTime != null ? (
      <span className="text-base font-normal">
        Refreshed on {formatDateWithTime(refreshedOnTime)}
      </span>
    ) : null;

  const totalResultsCount =
    productCatalogQuery.data?.totalCount != null
      ? formatNumberRounded(productCatalogQuery.data.totalCount)
      : null;

  const showPagination =
    !productCatalogQuery.isLoading && !productCatalogQuery.isError;

  return (
    <PageContainer>
      <Header
        className="mb-6"
        leftNode={<PageTitle>Product Catalog {refreshedOnLabel}</PageTitle>}
      />
      <div
        className={cn(
          "flex flex-row items-center justify-between gap-2",
          isExtraLarge && "flex-col items-start",
        )}
      >
        <Row className="flex-wrap gap-2">
          <Col className="gap-1">
            <LightLabel>Filter</LightLabel>
            <ProductCategoryFilter
              activeInactiveFiltersEnabled
              height={450}
              items={skusFilterOptions}
              selectTitle="Filter products"
              selectedItems={filteredSkus}
              setSelectedItems={setFilteredSkus}
              setSelectedItemsOverrideFn={setSelectedItemsOverrideFn}
            />
          </Col>
          <Col className="gap-1">
            <LightLabel>Search</LightLabel>
            <Row className="gap-1">
              <SearchInput
                className="w-[300px]"
                onChange={(val) => {
                  setSearchTerm(val);
                }}
                placeholder="Search by SKU or product name..."
                value={searchText}
              />
              <DownloadButton
                disabled={downloadLoading}
                onClick={() => void handleDownload()}
                tooltipLabel="Download product catalog as CSV"
              />
            </Row>
          </Col>
        </Row>
        <Col>
          <div className="h-[15px]" />
          <CreateTagDialog
            disabled={productCatalogQuery.isLoading}
            fetchFullProductCatalog={fetchFullProductCatalog}
            refetchFn={productCatalogQuery.refetch}
            totalCount={totalCount}
          />
        </Col>
      </div>
      <Row className="my-3 w-full justify-between">
        <Row>
          <p className="mt-1 whitespace-nowrap text-xs">
            {totalResultsCount != null ? (
              <span>
                {totalResultsCount} SKUs{" "}
                {arrayNotEmpty(filteredSkus) ? "for:" : ""}
              </span>
            ) : (
              <span>Loading...</span>
            )}
          </p>
          <SelectedProductFiltersList
            applyAndConditionForCategory={applyAndConditionForCategory}
            applyAndConditionForFilters={applyAndConditionForFilters}
            applyAndConditionForPriceZone={applyAndConditionForPriceZone}
            applyAndConditionForProductBrandName={
              applyAndConditionForProductBrandName
            }
            applyAndConditionForSubCategory={applyAndConditionForSubCategory}
            applyAndConditionForSupplier={applyAndConditionForSupplier}
            applyAndConditionForTags={applyAndConditionForTags}
            className="ml-1"
            onClickRemove={handleRemoveItem}
            selectedItems={sortByProperty(filteredSkus, "label")}
            showClearFiltersButton
            toggleCondition={(item, state) => {
              useProductCatalogFiltersStore.setState({ [item]: state });
            }}
          />
        </Row>
        <UserColumnVisibilityFilterComponent visibilityFilterType="product_catalog" />
      </Row>
      <div className="relative overflow-y-auto">
        {productCatalogQuery.isLoading || visibilityFiltersQuery.isLoading ? (
          <TableSkeleton className="gap-3" rowClassName="h-5" rowNumber={25} />
        ) : productCatalogQuery.isError || visibilityFiltersQuery.isError ? (
          <div>Could not load product catalog data...</div>
        ) : productCatalogQuery.data.totalCount === 0 ? (
          <div>
            <p>No results.</p>
          </div>
        ) : (
          <ProductCatalogTable
            products={productCatalogQuery.data.products}
            refetchProducts={productCatalogQuery.refetch}
            visibilityFilters={visibilityFiltersQuery.data.product_catalog}
          />
        )}
        {totalCount > 0 && showPagination && (
          <div className="relative my-4 flex w-full items-center justify-center">
            <PaginationControls
              currentPage={page}
              disabled={productCatalogQuery.isLoading}
              pageSize={PRODUCT_CATALOG_PAGE_SIZE}
              setPage={setPage}
              totalCount={totalCount}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
