import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Centered from "src/frontend/components/Centered";
import Col from "src/frontend/components/Col";
import PageContainer, {
  PAGE_CONTAINER_ID,
} from "src/frontend/components/PageContainer";
import Button from "src/frontend/components/ui/Button";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import usePricePlanGroupsQueryV2 from "src/frontend/hooks/queries/usePricePlanGroupsQueryV2";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import useUserBrand from "src/frontend/hooks/useUserBrand";
import PricePlanGroups from "src/frontend/pages/pricing/PricePlanGroups";
import PricePlanSummaryTabs from "src/frontend/pages/pricing/PricePlanSummaryTabs";
import useLocationHistoryStore from "src/frontend/stores/useLocationHistoryStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import PricePlanTab from "src/shared/types/PricePlanTab";
import MaybeString from "src/shared/types/maybe/MaybeString";
import getLast from "src/shared/utils/arrays/getLast";
import range from "src/shared/utils/arrays/range";
import invariant from "tiny-invariant";

function wasOnGroupDetailView(location: MaybeString): boolean {
  if (location == null) {
    return false;
  }

  // Matches -> /pricing/<any-string/<uuid>
  const pattern =
    /\/pricing\/[^\/]+\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  return pattern.test(location);
}

export default function PricePlanSummary() {
  const t = useToast();
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const isLucaAdmin = useIsLucaAdmin();
  const { pricePlanId } = useParams();
  const tab = usePricePlanTab();
  const brand = useUserBrand();
  const pricePlanSummaryQuery = useGetPricePlanSummaryQuery();
  const pricePlanGroupsQuery = usePricePlanGroupsQueryV2();
  const locationHistory = useLocationHistoryStore();

  const regeneratePricePlanMutation =
    TrpcClient.admin.regeneratePricePlan.useMutation();
  const resetBrandPricePlanGenerationStateMutation =
    TrpcClient.admin.resetBrandPricePlanGenerationState.useMutation();

  useEffect(() => {
    const { history, pricePlanScrollPosition } = locationHistory;
    const lastLocation = getLast(history);
    const onOnGroupDetailView = wasOnGroupDetailView(lastLocation);
    if (pricePlanScrollPosition != null && onOnGroupDetailView) {
      const scrollContainer = document.getElementById(PAGE_CONTAINER_ID);
      if (scrollContainer != null && pricePlanScrollPosition != null) {
        scrollContainer.scrollTop = pricePlanScrollPosition;
        useLocationHistoryStore.setState({ pricePlanScrollPosition: null });
      }
    }
  }, [locationHistory]);

  useEffect(() => {
    if (pricePlanId == null) {
      return;
    }
    usePricePlanStore.setState({
      pricePlanId: pricePlanId,
      tab: (tab as PricePlanTab) ?? undefined,
    });
  }, [pricePlanId, tab]);

  const pricePlan = pricePlanSummaryQuery.data?.price_plan;

  if (
    pricePlanId == null ||
    (pricePlanSummaryQuery.isLoading == false && pricePlan == null)
  ) {
    return "No price plan found.";
  }

  const handleRegeneratePricePlan = () => {
    try {
      invariant(pricePlan != null);
      setRegenerateLoading(true);
      regeneratePricePlanMutation.mutate(
        {
          pricePlanId: pricePlan.id,
        },
        {
          onError: () => {
            t.errorToast("Failed to regenerate price plan.");
          },
          onSuccess: () => {
            t.successToast("Price plan generation triggered.");
          },
        },
      );
    } catch (err: any) {
      t.errorToast("Failed to regenerate price plan.");
    }
  };

  const handleResetBrandPricePlanGenerationState = () => {
    try {
      invariant(brand != null);
      resetBrandPricePlanGenerationStateMutation.mutate(
        {
          brandId: brand.brand_id,
        },
        {
          onError: () => {
            t.errorToast("Failed to reset brand price plan generation state.");
          },
          onSuccess: () => {
            t.successToast(
              "Brand price plan generation state reset successfully.",
            );
          },
        },
      );
    } catch (err: any) {
      t.errorToast("Failed to reset brand price plan generation state.");
    }
  };

  const queriesLoading =
    pricePlanGroupsQuery.isLoading || pricePlanSummaryQuery.isLoading;

  return (
    <PageContainer className="overflow-y-visible pt-0">
      {!queriesLoading &&
        (pricePlan?.generation_in_progress === true ||
          pricePlan?.generation_failed === true) && (
          <Centered className="h-[300px]">
            <Col className="w-[350px] gap-4 text-center">
              <h1 className="text-2xl font-bold">
                Price Plan{" "}
                {pricePlan.generation_failed
                  ? "creation failed"
                  : "creation in process"}
                .
              </h1>
              {pricePlan.generation_failed ? (
                <p>Price plan generation failed.</p>
              ) : (
                <p>
                  Price plan creation can take up to one hour. We&apos;ll notify
                  you when it is complete.
                </p>
              )}
              {(pricePlan.generation_in_progress ||
                pricePlan.generation_failed) &&
                isLucaAdmin && (
                  <Col className="gap-3">
                    {pricePlan.generation_failed &&
                      pricePlan.generation_in_progress && (
                        <p>Another generation is in progress.</p>
                      )}
                    <p className="font-semibold">Admin Actions:</p>
                    <Tooltip
                      content={
                        <p className="w-[350px] text-sm">
                          This will attempt to regenerate the price plan. Only
                          run this if you are confident the previous generation
                          failed for some reason and is not still executing.
                        </p>
                      }
                      side="right"
                    >
                      <Button
                        className="m-auto w-fit"
                        disabled={regenerateLoading}
                        onClick={() => handleRegeneratePricePlan()}
                        variant="basic"
                      >
                        Regenerate Price Plan
                      </Button>
                    </Tooltip>
                    <Tooltip
                      content={
                        <p className="w-[350px] text-sm">
                          This is useful if a previous price plan generation
                          failed and the brand needs to be allowed to generate
                          new price plans.
                        </p>
                      }
                      side="right"
                    >
                      <Button
                        className="m-auto w-fit"
                        disabled={
                          brand?.price_plan_generation_in_progress === false
                        }
                        onClick={() =>
                          handleResetBrandPricePlanGenerationState()
                        }
                        variant="basic"
                      >
                        Reset Brand Price Plan Generation State
                      </Button>
                    </Tooltip>
                  </Col>
                )}
            </Col>
          </Centered>
        )}
      {pricePlanSummaryQuery.isLoading && (
        <Skeleton className="mb-8 h-[450px] w-full" />
      )}
      {pricePlanGroupsQuery.isLoading && (
        <div className="mb-16 flex flex-1 flex-col gap-4">
          {range(10).map((_, i) => {
            return <Skeleton className="h-[140px] w-full" key={i} />;
          })}
        </div>
      )}
      {pricePlan != null &&
        !pricePlan?.generation_in_progress &&
        !pricePlan?.generation_failed && <PricePlanSummaryTabs />}
      {pricePlan != null &&
        !pricePlan?.generation_in_progress &&
        !pricePlan?.generation_failed && (
          <div className="flex flex-1 flex-col gap-3">
            {pricePlanGroupsQuery.isLoading === false && <PricePlanGroups />}
          </div>
        )}
    </PageContainer>
  );
}
