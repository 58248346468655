import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";

type ConstraintBoundProps = {
  bound: MaybeNumber;
  showSign?: boolean;
  type: MaybeNull<PriceBoundType>;
};

export default function ConstraintBound({
  bound,
  showSign = true,
  type,
}: ConstraintBoundProps) {
  if (bound == null || type == null) {
    return "n/a";
  }

  const sign = bound != null && bound > 0 && showSign ? "+" : "";
  switch (type) {
    case "AMOUNT":
      return `${sign}${formatCurrency(bound)}`;
    case "PERCENT":
      return `${sign}${formatPercentage(bound / 100)}`;
    default:
      return assertUnreachable(type);
  }
}
