import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import LocalStorageKey from "src/frontend/constants/LocalStorageKey";
import { ProductConditionFiltersType } from "src/shared/trpc/common/ProductConditionFilters";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { SortDirectionType } from "src/shared/zod/SortDirection";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type ProductCatalogFiltersStore = ProductConditionFiltersType & {
  filteredSkus: FancyBoxItem[];
  page: number;
  searchText: string;
  selectedProductIds: string[];
  sortBy: ProductMasterObjectSortKeyType;
  sortDirection: SortDirectionType;
};

const defaultState: ProductCatalogFiltersStore = {
  applyAndConditionForCategory: false,
  applyAndConditionForFilters: false,
  applyAndConditionForPriceZone: false,
  applyAndConditionForProductBrandName: false,
  applyAndConditionForSubCategory: false,
  applyAndConditionForSupplier: false,
  applyAndConditionForTags: false,
  filteredSkus: [],
  page: 1,
  searchText: "",
  selectedProductIds: [],
  sortBy: "product_name",
  sortDirection: "asc",
};

type Actions = {
  reset: () => void;
  setFilteredSkus: (
    newItems: FancyBoxItem[] | ((prevItems: FancyBoxItem[]) => FancyBoxItem[]),
  ) => void;
};

const useProductCatalogFiltersStore = create<
  Actions & ProductCatalogFiltersStore
>()(
  persist(
    (set) => ({
      reset: (): void => {
        set(defaultState);
      },
      setFilteredSkus: (newItems) =>
        set((state) => ({
          filteredSkus:
            newItems instanceof Function
              ? newItems(state.filteredSkus)
              : newItems,
        })),
      ...defaultState,
    }),
    {
      name: LocalStorageKey.ProductCatalogFilters,
    },
  ),
);

export default useProductCatalogFiltersStore;
