import * as React from "react";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      "rounded-xl border bg-card text-card-foreground shadow",
      className,
    )}
    ref={ref}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    ref={ref}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    className={cn("font-semibold leading-none tracking-tight", className)}
    ref={ref}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    className={cn("text-sm text-muted-foreground", className)}
    ref={ref}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className={cn("p-6 pt-0", className)} ref={ref} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn("flex items-center p-6 pt-0", className)}
    ref={ref}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};

type CardWithContentProps = React.HTMLAttributes<HTMLDivElement> & {
  cardContentClassName?: ClassNameProp;
  children: React.ReactNode;
  description?: string;
  footer?: React.ReactNode;
  headerClassName?: ClassNameProp;
  rightNode?: React.ReactNode;
  title?: string;
  titleContainerClassName?: ClassNameProp;
};

export default function CardWithContent(props: CardWithContentProps) {
  const {
    cardContentClassName,
    children,
    className,
    description,
    footer,
    headerClassName,
    rightNode,
    title,
    titleContainerClassName,
    ...rest
  } = props;
  return (
    <Card className={className} {...rest}>
      {(title != null || description != null) && (
        <CardHeader className={headerClassName}>
          {title && (
            <div
              className={cn(
                "flex h-5 flex-row items-center justify-between",
                titleContainerClassName,
              )}
            >
              <CardTitle>{title}</CardTitle>
              {rightNode && rightNode}
            </div>
          )}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <CardContent className={cardContentClassName}>{children}</CardContent>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
}
