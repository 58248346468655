import SummaryMetricsContainer from "src/frontend/components/SummaryMetricsContainer";
import { cn } from "src/frontend/components/ui/utils";
import MetricsTabs from "src/frontend/pages/pricing/summary/MetricsTabs";
import PlanDetailsGrid from "src/frontend/pages/pricing/summary/PlanDetailsGrid";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import { GroupDetailsTypeV2 } from "src/shared/trpc/queries/getGroupSummaryQuerySchemaV2";
import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";

type GroupSummaryMetricsProps = HtmlDivProps & {
  groupDetails?: GroupDetailsTypeV2;
  isDetailView?: boolean;
  metrics?: GetPricePlanSummaryQueryResponseType;
};

export default function GroupSummaryMetrics({
  className,
  groupDetails,
  isDetailView,
  metrics,
  ...rest
}: GroupSummaryMetricsProps) {
  return (
    <SummaryMetricsContainer className={cn("flex", className)} {...rest}>
      <MetricsTabs
        isDetailView={isDetailView}
        metrics={metrics}
        type="RECOMMENDATION_SUMMARY"
      />
      <PlanDetailsGrid
        groupDetails={groupDetails}
        impactedSKUs={metrics?.impacted_skus}
        isDetailView={isDetailView}
        metrics={metrics}
      />
    </SummaryMetricsContainer>
  );
}
