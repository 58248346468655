import { LDContext } from "launchdarkly-node-client-sdk";

type Args = {
  brandId: string;
  brandName: string;
  email: string;
};

export default function createLaunchDarklyContext({
  brandId,
  brandName,
  email,
}: Args): LDContext {
  const custom =
    brandId !== "" && brandName !== ""
      ? {
          brandId,
          brandName,
        }
      : undefined;
  return {
    custom,
    email,
    key: email,
  };
}
