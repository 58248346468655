import { BaseOpportunitiesMetrics } from "src/shared/trpc/common/BaseOpportunitiesMetrics";
import { CompetitorMatchWithAveragePrices } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import ExperimentPriceFields from "src/shared/trpc/common/ExperimentPriceFields";
import { GroupResultsMetrics } from "src/shared/trpc/common/GroupResultsMetrics";
import { PricingConstraint } from "src/shared/trpc/common/PricingConstraint";
import { ProductMasterObject } from "src/shared/trpc/common/ProductMasterObject";
import { ProductTag } from "src/shared/trpc/common/ProductTag";
import { SortDirection } from "src/shared/zod/SortDirection";
import { z } from "zod";

const ExperimentFilterColumn = z.enum([
  "brand",
  "category",
  "sub_category",
  "product_type",
  "kvi",
  "price_zone",
  "supplier",
  "producer",
  "weight",
  "weight_unit",
  "elasticity",
  "member_elasticity",
  "new_list_price",
  "previous_list_price",
  "new_member_price",
  "previous_member_price",
  "inventory",
  "rules_met",
  "cost",
  "profit_120d",
  "profit_30d",
  "profit_60d",
  "profit_90d",
  "profit_rank_120d",
  "profit_rank_30d",
  "profit_rank_60d",
  "profit_rank_90d",
  "revenue_120d",
  "revenue_30d",
  "revenue_60d",
  "revenue_90d",
  "revenue_rank_120d",
  "revenue_rank_30d",
  "revenue_rank_60d",
  "revenue_rank_90d",
  "units_sold_120d",
  "units_sold_30d",
  "units_sold_60d",
  "units_sold_90d",
  "units_sold_rank_120d",
  "units_sold_rank_30d",
  "units_sold_rank_60d",
  "units_sold_rank_90d",
]);

export type ExperimentFilterColumnEnum = z.infer<typeof ExperimentFilterColumn>;

export const ExperimentFilterColumnBothFilter = z.enum([
  "is equal to",
  "is not equal to",
  "is less than",
  "is greater than",
  "is",
  "is not",
]);

export type ExperimentFilterColumnBothFilterEnum = z.infer<
  typeof ExperimentFilterColumnBothFilter
>;

export const ExperimentFilterColumnNumberFilter = z.enum([
  "is equal to",
  "is not equal to",
  "is less than",
  "is greater than",
]);

export type ExperimentFilterColumnNumberFilterEnum = z.infer<
  typeof ExperimentFilterColumnNumberFilter
>;

export const ExperimentFilterColumnRuleTypeFilter = z.enum([
  "is applied",
  "is met",
  "is not met",
]);

export const ExperimentFilterColumnStringContainsFilter = z.enum([
  "contains",
  "does not contain",
]);

export const ExperimentFilterColumnStringIsFilterEnum = z.enum([
  "is",
  "is not",
]);

export const ExperimentFilterColumnStringFilter = z.enum([
  ...ExperimentFilterColumnStringIsFilterEnum.options,
  ...ExperimentFilterColumnStringContainsFilter.options,
]);

export const ExperimentSortColumn = z.enum([
  ...ExperimentFilterColumn.options,
  "product",
]);

export type ExperimentSortColumnEnum = z.infer<typeof ExperimentSortColumn>;

export type ExperimentFilterColumnStringFilterEnum = z.infer<
  typeof ExperimentFilterColumnStringFilter
>;

export const ExperimentFilterColumnFilter = z.enum([
  ...ExperimentFilterColumnNumberFilter.options,
  ...ExperimentFilterColumnStringFilter.options,
  ...ExperimentFilterColumnBothFilter.options,
  ...ExperimentFilterColumnRuleTypeFilter.options,
] as const);

export type ExperimentFilterColumnFilterEnum = z.infer<
  typeof ExperimentFilterColumnFilter
>;

export const filterTypesEnum = z.enum([
  "number",
  "string",
  "both",
  "rule_type",
]);
export type FilterTypesEnumType = z.infer<typeof filterTypesEnum>;

export const ExperimentForecastFilter = z.object({
  column: ExperimentFilterColumn,
  columnFilter: ExperimentFilterColumnFilter,
  columnFilterValue: z.string(),
  type: filterTypesEnum,
});

export type ExperimentForecastFilterType = z.infer<
  typeof ExperimentForecastFilter
>;

export const GetExperimentForecastQueryInput = z.object({
  cursor: z.number().nullish(),
  filters: z.array(ExperimentForecastFilter).optional(),
  groupId: z.string().optional(),
  pricePlanId: z.string(),
  searchQuery: z.string().optional(),
  sortBy: ExperimentSortColumn.optional(),
  sortDirection: SortDirection.optional(),
});

export type GetExperimentForecastQueryInputType = z.infer<
  typeof GetExperimentForecastQueryInput
>;

export const GetExperimentForecastQueryResponse = ProductMasterObject.merge(
  ExperimentPriceFields.merge(
    z.object({
      categories: z.string().array(),
      competitor_matches: CompetitorMatchWithAveragePrices.nullable().array(),
      competitor_names_list: z.string().array(),
      competitor_reference_price: z.number().nullable(),
      constraints: PricingConstraint.array(),
      cpu_current: z.number().nullable(),
      cpu_last_thirty: z.number().nullable(),
      experiment_id: z.string(),
      experiment_status: z.string().nullable(),
      group_id: z.string().nullable(),
      kvi: z.string().nullable(),
      opportunity_metrics: BaseOpportunitiesMetrics.array(),
      price_parity_group_id: z.string().nullable(),
      price_parity_group_name: z.string().nullable(),
      price_zone: z.string().nullable(),
      producer: z.string().nullable(),
      product_brand_name: z.string().nullable(),
      product_id: z.string().nullable(),
      product_name: z.string().nullable(),
      product_type: z.string().nullable(),
      profit_120d: z.number().nullable(),
      profit_30d: z.number().nullable(),
      profit_60d: z.number().nullable(),
      profit_90d: z.number().nullable(),
      profit_rank_120d: z.bigint().nullable(),
      profit_rank_30d: z.bigint().nullable(),
      profit_rank_60d: z.bigint().nullable(),
      profit_rank_90d: z.bigint().nullable(),
      results_metrics: GroupResultsMetrics.array().nullable(),
      revenue_120d: z.number().nullable(),
      revenue_30d: z.number().nullable(),
      revenue_60d: z.number().nullable(),
      revenue_90d: z.number().nullable(),
      revenue_rank_120d: z.bigint().nullable(),
      revenue_rank_30d: z.bigint().nullable(),
      revenue_rank_60d: z.bigint().nullable(),
      revenue_rank_90d: z.bigint().nullable(),
      sku: z.string().nullable(),
      sub_categories: z.string().array(),
      supplier: z.string().nullable(),
      tags: ProductTag.array(),
      units_sold_120d: z.number().nullable(),
      units_sold_30d: z.number().nullable(),
      units_sold_60d: z.number().nullable(),
      units_sold_90d: z.number().nullable(),
      units_sold_rank_120d: z.bigint().nullable(),
      units_sold_rank_30d: z.bigint().nullable(),
      units_sold_rank_60d: z.bigint().nullable(),
      units_sold_rank_90d: z.bigint().nullable(),
      weight: z.number().nullable(),
      weight_label: z.string().nullable(),
    }),
  ),
);

export type GetExperimentForecastQueryResponseType = z.infer<
  typeof GetExperimentForecastQueryResponse
>;
