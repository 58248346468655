export default function sortByProperty<T>(
  arr: T[],
  propertyName: keyof T,
  ascending = true,
): T[] {
  return arr.slice().sort((a, b) => {
    if (a[propertyName] > b[propertyName]) {
      return ascending ? 1 : -1;
    } else if (a[propertyName] < b[propertyName]) {
      return ascending ? -1 : 1;
    }
    return 0;
  });
}
