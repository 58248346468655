import { UndoIcon } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Button from "src/frontend/components/ui/Button";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetExperimentsForecastQuery from "src/frontend/hooks/queries/useGetExperimentsForecastQuery";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import len from "src/shared/utils/arrays/len";

type UndoDeclinedExperimentsModalProps = {
  experiment_ids?: string[];
};

export default function UndoDeclinedExperimentsModal({
  experiment_ids,
}: UndoDeclinedExperimentsModalProps) {
  const isBrandAdmin = useIsBrandAdmin();

  const { groupId, pricePlanId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errorToast, successToast } = useToast();
  const experimentsOpportunityQuery = useGetExperimentsForecastQuery();
  const undoDeclinedExperimentsMutation =
    TrpcClient.internal.undoDeclinedExperiments.useMutation();

  const disabled =
    (experiment_ids?.length != null && experiment_ids.length === 0) ||
    !isBrandAdmin;

  const onSuccess = async () => {
    await experimentsOpportunityQuery.refetch();
    setIsModalOpen(false);
    setIsLoading(false);
    successToast({
      description: "Successfully updated prices.",
    });
  };

  return (
    <Modal
      confirmText="Yes, undo all"
      dialogDescription={
        <p>
          Are you sure you want to undo all the declined price changes for{" "}
          {experiment_ids != null ? `these ${len(experiment_ids)}` : "all"}{" "}
          SKUs?
        </p>
      }
      dialogTitle="Undo Changes"
      loading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        undoDeclinedExperimentsMutation.mutate(
          {
            experimentIds: experiment_ids,
            groupId,
            pricePlanId,
          },
          {
            onError: () => {
              setIsLoading(false);
              errorToast({ description: "Failed to undo price changes." });
            },
            onSuccess: () => {
              void onSuccess();
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button disabled={disabled} size="sm" variant="outline">
          <UndoIcon className="mr-1" size={16} /> Undo declined prices
        </Button>
      }
    />
  );
}
