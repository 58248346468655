export default function numberOrNull(val: any): number | null {
  if (typeof val === "number") {
    return val;
  }
  if (typeof val === "string") {
    const converted = Number(val);
    if (Number.isNaN(converted)) {
      return null;
    }
    return converted;
  }
  return null;
}
