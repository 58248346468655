import { CustomerSegment } from "src/shared/trpc/common/enum/CustomerSegment";
import { ImpactFilter } from "src/shared/types/ImpactViewFilter";
import { z } from "zod";

export const DownloadNewPricePlanCsvApiPayloadV2 = z.object({
  brandId: z.string(),
  customerSegment: CustomerSegment.nullable(),
  groupIds: z.string().array(),
  impactFilter: ImpactFilter,
  pricePlanId: z.string(),
});

export type DownloadNewPricePlanCsvApiPayloadTypeV2 = z.infer<
  typeof DownloadNewPricePlanCsvApiPayloadV2
>;

export const DownloadNewPricePlanCsvApiResponseV2 = z.object({
  experiments: z.string(),
});

export type DownloadNewPricePlanCsvApiResponseTypeV2 = z.infer<
  typeof DownloadNewPricePlanCsvApiResponseV2
>;
