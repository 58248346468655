import { Outlet, useLocation } from "react-router";
import ApprovePricePlanAction from "src/frontend/components/ApprovePricePlanAction";
import Row from "src/frontend/components/Row";
import RevertGroupsModal from "src/frontend/components/modals/RevertGroupsModal";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import usePricePlanGroupsQueryV2 from "src/frontend/hooks/queries/usePricePlanGroupsQueryV2";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import PricePlansDropdownMenu from "src/frontend/pages/pricing/PricePlansDropdownMenu";
import UploadPricePlanDialog from "src/frontend/pages/pricing/UploadPricePlanDialog";
import getPricePlanTabFromLocation from "src/frontend/utils/getPricePlanTabFromLocation";
import isDraftsTab from "src/frontend/utils/isDraftsTab";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";

function PlanActions() {
  const tab = usePricePlanTab();
  const pricePlanSummaryQuery = useGetPricePlanSummaryQuery();
  const pricePlanGroupsQuery = usePricePlanGroupsQueryV2();

  const queriesLoading =
    pricePlanGroupsQuery.isLoading || pricePlanSummaryQuery.isLoading;

  const pricePlan = pricePlanSummaryQuery.data?.price_plan;
  const groupIds = pricePlanGroupsQuery.data?.map((rec) => rec.group_id) ?? [];

  const actionsAvailable =
    !queriesLoading &&
    !pricePlan?.generation_in_progress &&
    !pricePlan?.generation_failed;

  const hasResults =
    pricePlanSummaryQuery.data != null
      ? arrayNotEmpty(pricePlanSummaryQuery.data.group_results_metrics)
      : undefined;

  return (
    <Row className="gap-2">
      {tab === "draft" && actionsAvailable && (
        <div className="flex flex-row">
          <ApprovePricePlanAction />
        </div>
      )}
      {tab === "current" && <RevertGroupsModal groupIds={groupIds} />}
      <PricePlansDropdownMenu
        disabled={!actionsAvailable}
        hasResults={hasResults}
        isDeleteEnabled={isDraftsTab(tab) || tab === "current"}
        isDownloadEnabled={pricePlanSummaryQuery.data != null}
        pricePlan={pricePlan!}
      />
    </Row>
  );
}

export default function Pricing() {
  const location = useLocation();
  const page = getPricePlanTabFromLocation(location);

  const isPlanListPage = page === "drafts" || page === "launched";
  const isDraftsTab = ["drafts", "draft", "internal", "preview"].includes(
    page ?? "",
  );

  return (
    <div className="h-full overflow-y-scroll bg-n-0">
      <Row className="justify-between border-b px-6 py-4">
        <h1 className="font-bold">{isDraftsTab ? "Drafts" : "Launched"}</h1>
        {isPlanListPage ? <UploadPricePlanDialog /> : <PlanActions />}
      </Row>
      <Outlet />
    </div>
  );
}
