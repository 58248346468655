import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { Edit3Icon, InfoIcon, Trash2 } from "lucide-react";
import React, { useState } from "react";
import Bold from "src/frontend/components/Bold";
import Row from "src/frontend/components/Row";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableDataCell,
} from "src/frontend/components/TableComponents";
import ConstraintBoundsSummary from "src/frontend/components/constraints/ConstraintBoundsSummary";
import SortableContainer from "src/frontend/components/sortable/SortableContainer";
import { SortableItem } from "src/frontend/components/sortable/SortableItem";
import Button from "src/frontend/components/ui/Button";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import ConstraintDialog from "src/frontend/pages/company/rules/ConstraintDialog";
import usePricingRulesGroupConstraintStore from "src/frontend/stores/usePricingRulesGroupConstraintStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";

type ConstraintsListProps = {
  addConstraint: (constraint: PricingConstraintType) => void;
  constraintDialogOpen: boolean;
  constraints: PricingConstraintType[];
  disabled: boolean;
  editConstraint: (constraint: PricingConstraintType) => void;
  removeConstraint: (constraint: PricingConstraintType) => void;
  setConstraintDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConstraints: React.Dispatch<React.SetStateAction<PricingConstraintType[]>>;
};

export default function ConstraintsList({
  addConstraint,
  constraintDialogOpen,
  constraints,
  disabled,
  editConstraint,
  removeConstraint,
  setConstraintDialogOpen,
  setConstraints,
}: ConstraintsListProps) {
  const { isSmall } = useBreakpoints();
  const [constraintToEdit, setConstraintToEdit] =
    useState<MaybeNull<PricingConstraintType>>(null);
  const constraintsExist = arrayNotEmpty(constraints);
  const resetConstraintDialog = usePricingRulesGroupConstraintStore(
    (state) => state.reset,
  );
  return (
    <div>
      {constraintsExist && (
        <SortableContainer items={constraints} setItems={setConstraints}>
          <Table>
            <TableHeader>
              <tr className="align-top">
                <TableHeaderCell className="w-4" />
                <TableHeaderCell className="w-16">Priority</TableHeaderCell>
                <TableHeaderCell className="w-44">
                  <p>Type</p>
                </TableHeaderCell>
                <TableHeaderCell className="">
                  <p>Price Bounds</p>
                </TableHeaderCell>
                <TableHeaderCell className="w-16" />
              </tr>
            </TableHeader>
            <tbody>
              {constraints.map((constraint, index) => {
                return (
                  <SortableItem
                    className="h-12 border-b last:border-b-0"
                    dragDisabled={isSmall || disabled}
                    id={constraint.id}
                    key={constraint.id}
                    renderAsTableRow
                  >
                    <TableDataCell>
                      <div className="my-1 py-1">
                        <DragHandleDots2Icon className="text-muted" />
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <div className="my-1 py-1">
                        <Bold>{index + 1}</Bold>
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <Row className="my-1 justify-between py-1 pr-2">
                        <Bold>{formatGenericEnumLabel(constraint.type)}</Bold>
                        {constraint.type === "ROUNDING" && (
                          <Tooltip
                            content={
                              <p>
                                Rounding constraints are always applied to final
                                prices after all other rules
                              </p>
                            }
                          >
                            <InfoIcon size={16} />
                          </Tooltip>
                        )}
                        {constraint.type === "PRICE_COMPARISON" &&
                          constraint.comparison_price_target !==
                            "PRE_LUCA_PRICE" && (
                            <Tooltip
                              content={
                                <p>
                                  Price comparison constraints are calculated
                                  when the price plan is generated, unless they
                                  reference the Pre Luca Price.
                                </p>
                              }
                            >
                              <InfoIcon size={16} />
                            </Tooltip>
                          )}
                      </Row>
                    </TableDataCell>
                    <TableDataCell>
                      <div className="my-1 py-1">
                        <p className="line-clamp-1">
                          <ConstraintBoundsSummary
                            constraint={constraint}
                            hideActualValues
                          />
                        </p>
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <Row className="my-1 justify-end gap-2 py-1">
                        <Button
                          disabled={disabled}
                          onClick={() => {
                            setConstraintDialogOpen(true);
                            setConstraintToEdit(constraint);
                            usePricingRulesGroupConstraintStore.setState({
                              ...constraint,
                              lower_bound_input:
                                constraint.lower_bound == null
                                  ? ""
                                  : String(constraint.lower_bound),
                              upper_bound_input:
                                constraint.upper_bound == null
                                  ? ""
                                  : String(constraint.upper_bound),
                            });
                          }}
                          size="icon"
                          variant="ghost"
                        >
                          <Edit3Icon
                            opacity={0.85}
                            size={18}
                            strokeWidth={1.5}
                          />
                        </Button>
                        <Button
                          disabled={disabled}
                          onClick={() => {
                            removeConstraint(constraint);
                          }}
                          size="icon"
                          variant="ghost"
                        >
                          <Trash2 opacity={0.85} size={18} strokeWidth={1.5} />
                        </Button>
                      </Row>
                    </TableDataCell>
                  </SortableItem>
                );
              })}
            </tbody>
          </Table>
        </SortableContainer>
      )}
      <ConstraintDialog
        constraintToEdit={constraintToEdit}
        disabled={disabled}
        key={JSON.stringify(constraintToEdit)}
        onOpenChange={(open) => {
          setConstraintDialogOpen(open);
          if (!open) {
            resetConstraintDialog();
            setConstraintToEdit(null);
          }
        }}
        onSave={(data: PricingConstraintType) => {
          if (constraintToEdit == null) {
            addConstraint(data);
          } else {
            editConstraint(data);
          }
        }}
        open={constraintDialogOpen}
      />
    </div>
  );
}
