import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import capitalize from "src/shared/utils/strings/capitalize";

export default function priceTargetToLabel(
  priceTarget: PriceTargetType,
): string {
  switch (priceTarget) {
    case "LIST_PRICE":
    case "MEMBER_PRICE":
    case "SUBSCRIBER_PRICE":
    case "END_CUSTOMER_PRICE":
    case "DISCOUNTED_PRICE":
      return priceTarget
        .split("_")
        .map((val) => capitalize(val.toLowerCase()))
        .join(" ");
    default:
      return assertUnreachable(priceTarget);
  }
}
