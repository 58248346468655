import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useUserColumnVisibilityFiltersQuery() {
  const { enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getUserColumnVisibilityFilters.useQuery(
    undefined,
    {
      enabled,
    },
  );
}
