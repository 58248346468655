import { MoveDownLeft, MoveUpRight } from "lucide-react";
import Card from "src/frontend/components/Card";
import Row from "src/frontend/components/Row";
import { cn } from "src/frontend/components/ui/utils";
import COLORS from "src/frontend/constants/Colors";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import MetricsConfidenceInterval from "src/frontend/types/MetricsConfidenceInterval";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { Maybe } from "src/shared/types/maybe/Maybe";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrencyDelta from "src/shared/utils/formatCurrencyDelta";
import formatDelta from "src/shared/utils/formatDelta";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";

function getFormattedImpact(
  impact: Maybe<number | string>,
  impactType: MaybeUndefined<ImpactType>,
  isDelta: boolean,
): number | string {
  if (typeof impact == "number") {
    switch (impactType) {
      case "currency":
        return formatCurrencyDelta(impact, isDelta);
      case "number":
        const result = roundToPrecision(impact, 0);
        const formatted = formatNumberRounded(result);
        if (isDelta) {
          return formatDelta(result, formatted) ?? "--";
        } else {
          return formatted ?? "--";
        }
      case "percent":
        return formatPercentage(impact);
      case null:
      case undefined:
        return impact ?? "--";
      default:
        return assertUnreachable(impactType);
    }
  }

  return impact ?? "--";
}

type ImpactType = "currency" | "number" | "percent";

type SummaryImpactProps = HtmlDivProps & {
  child?: React.ReactNode;
  confidenceInterval?: MetricsConfidenceInterval;
  impact?: MaybeNull<number | string>;
  impactType?: ImpactType;
  isDelta?: boolean;
  isDetailView?: boolean;
  percentChange?: MaybeNull<number>;
  percentage?: MaybeNull<number>;
  renderAsCards?: boolean;
  title: string;
  valueClassName?: ClassNameProp;
};

export default function SummaryImpact({
  child,
  className,
  confidenceInterval,
  impact,
  impactType,
  isDelta = false,
  isDetailView,
  percentChange,
  percentage,
  renderAsCards,
  title,
  valueClassName,
  ...rest
}: SummaryImpactProps) {
  const value = getFormattedImpact(impact, impactType, isDelta);
  const Icon =
    percentChange != null && percentChange > 0 ? MoveUpRight : MoveDownLeft;
  const Comp = renderAsCards
    ? Card
    : ({ children, ...rest }: HtmlDivProps) => <div {...rest}>{children}</div>;
  return (
    <Comp
      className={cn(
        "flex w-40 flex-col justify-evenly gap-1",
        renderAsCards &&
          "w-full p-6 hover:cursor-pointer hover:bg-gray-50 focus:bg-gray-100 aria-selected:bg-gray-100",
        className,
      )}
      tabIndex={renderAsCards ? 1 : undefined}
      {...rest}
    >
      <Row className="justify-between">
        <p className="whitespace-nowrap text-sm">{title}</p>
        {percentChange != null && (
          <Icon
            size={18}
            stroke={percentChange > 0 ? COLORS.MINT : COLORS.LUCA_RED}
            strokeWidth={2.25}
          />
        )}
      </Row>
      {child != null && (
        <div className="flex h-7 flex-row items-baseline gap-1">{child}</div>
      )}
      {child == null && (
        <div className="flex h-7 flex-row items-baseline gap-1">
          <p className={cn("text-xl font-bold", valueClassName)}>{value}</p>
          {percentChange != null && (
            <p className="text-base">({formatSignedPercent(percentChange)})</p>
          )}
          {percentage != null && (
            <p className="text-base">({formatPercentage(percentage)})</p>
          )}
        </div>
      )}
      {!isDetailView && confidenceInterval != null ? (
        <p className="whitespace-nowrap text-xs text-muted-foreground">
          80% CI:{" "}
          {getFormattedImpact(
            confidenceInterval.lower_bound,
            impactType,
            isDelta,
          )}
          ,{" "}
          {getFormattedImpact(
            confidenceInterval.upper_bound,
            impactType,
            isDelta,
          )}
        </p>
      ) : (
        <div className="h-[16px]" />
      )}
    </Comp>
  );
}
