import { ChevronRight, EqualIcon, PlusCircleIcon } from "lucide-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import SemiBold from "src/frontend/components/ui/SemiBold";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import COLORS from "src/frontend/constants/Colors";
import useGetPriceParityGroupsQuery from "src/frontend/hooks/queries/useGetPriceParityGroupsQuery";
import PriceParityGroupActiveToggle from "src/frontend/pages/company/relationships/PriceParityGroupActiveToggle";
import { PriceParitySummaryGroup } from "src/shared/trpc/common/PriceParitySummaryGroup";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";

const TRIGGER_ID_PREFIX = "group-active-trigger-";

function PriceParityGroupsLoadingSkeleton() {
  return (
    <div>
      <Row className="justify-between">
        <Skeleton className="h-12 w-72" />
        <Skeleton className="h-12 w-44" />
      </Row>
      {range(6).map((val) => (
        <Skeleton className="mt-4 h-[50px] w-full" key={val} />
      ))}
    </div>
  );
}

type InnerProps = {
  priceParityGroups: PriceParitySummaryGroup[];
};

function Inner({ priceParityGroups }: InnerProps) {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [isPromptOpenId, setIsPromptOpenId] = useState("");

  const lowercaseSearch = searchInput.toLowerCase();
  const filteredProducts =
    searchInput === ""
      ? priceParityGroups
      : priceParityGroups.filter((group) => {
          return (
            group.name.toLowerCase().includes(lowercaseSearch) ||
            group.product_short_list.some(
              ({ product_name, sku }) =>
                product_name.toLowerCase().includes(lowercaseSearch) ||
                sku.toLowerCase().includes(lowercaseSearch),
            )
          );
        });

  return (
    <div>
      <Row className="justify-between">
        <SearchInput
          className="w-96"
          disabled={arrayEmpty(priceParityGroups)}
          onChange={setSearchInput}
          placeholder="Search by group name, SKU or product name"
          value={searchInput}
        />
        <Link to="/company-settings/relationships/create">
          <Button>
            <PlusCircleIcon className="mr-2" size={16} strokeWidth={2} /> Add
            New
          </Button>
        </Link>
      </Row>
      <div className="mt-6">
        {arrayEmpty(priceParityGroups) && (
          <p>
            No product relationships exist yet. Add a new one to get started.
          </p>
        )}
        {searchInput !== "" && arrayEmpty(filteredProducts) && (
          <p>
            No groups exist with any products matching the search input (match
            by SKU or product name).
          </p>
        )}
        <Col className="gap-3">
          {filteredProducts.map((group) => {
            return (
              <Card
                className="flex h-[58px] flex-row justify-between px-6 py-2 hover:cursor-pointer hover:shadow"
                key={group.id}
                onClick={(e) => {
                  const target = e.target as Element;
                  const isPromptElement =
                    isPromptOpenId || target.id.startsWith(TRIGGER_ID_PREFIX);
                  if (!isPromptElement) {
                    navigate(
                      `/company-settings/relationships/price-parity/${group.id}`,
                    );
                  }
                }}
              >
                <Row className="gap-2">
                  <EqualIcon stroke={COLORS.LUCA_RED} strokeWidth={2.5} />
                  <p>
                    <SemiBold>Price Parity:</SemiBold> {group.name}
                  </p>
                </Row>
                <Row className="gap-2">
                  <Badge className="text-sm" variant="muted">
                    <p>{len(group.product_short_list)} SKUs</p>
                  </Badge>
                  <PriceParityGroupActiveToggle
                    group={group}
                    isPromptOpen={isPromptOpenId === group.id}
                    setIsPromptOpen={(open) =>
                      setIsPromptOpenId(open ? group.id : "")
                    }
                    trigger={
                      <Badge
                        className="text-sm"
                        id={`${TRIGGER_ID_PREFIX}${group.id}`}
                        variant={group.is_active ? "default" : "destructive"}
                      >
                        {group.is_active ? "Live" : "Inactive"}
                      </Badge>
                    }
                  />
                  <ChevronRight size={32} strokeWidth={1.5} />
                </Row>
              </Card>
            );
          })}
        </Col>
      </div>
    </div>
  );
}

export default function ProductRelationships() {
  const query = useGetPriceParityGroupsQuery();

  if (query.isLoading) {
    return <PriceParityGroupsLoadingSkeleton />;
  }

  if (query.isError) {
    return <p>Could not load price parity groups.</p>;
  }

  return <Inner priceParityGroups={query.data} />;
}
