import { create } from "zustand";

export type AdminDeleteGroupsStore = {
  experimentIds: string[];
  groupIds: string[];
  isModalOpen: boolean;
  pricePlanIds: string[];
};

const defaultState: AdminDeleteGroupsStore = {
  experimentIds: [],
  groupIds: [],
  isModalOpen: false,
  pricePlanIds: [],
};

const useAdminDeleteGroupStore = create<AdminDeleteGroupsStore>(
  () => defaultState,
);

export default useAdminDeleteGroupStore;
