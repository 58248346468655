import { Building2Icon, CodeXmlIcon, UserCircle, UserIcon } from "lucide-react";
import Image from "next/legacy/image";
import React from "react";
import { useNavigate } from "react-router";
import Row from "src/frontend/components/Row";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import COLORS from "src/frontend/constants/Colors";
import useFlags from "src/frontend/hooks/useFlags";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import useAuthStore from "src/frontend/stores/useAuthStore";

// eslint-disable-next-line @typescript-eslint/ban-types
type AppHeaderProps = {};

export default function AppHeader(_: AppHeaderProps) {
  const { devFeaturesEnabled } = useFlags();
  const navigate = useNavigate();
  const isDarkTheme = useIsDarkTheme();
  const user = useAuthStore((state) => state.user);
  const userPhotoURL = user?.photoURL;
  const userDisplayName = user?.displayName;
  return (
    <Row
      className="fixed top-0 z-50 h-[54px] w-full justify-between border-b px-4"
      style={{
        backgroundColor: isDarkTheme ? "rgb(24, 24, 24)" : COLORS.LUCA_PURPLE,
      }}
    >
      <div className="pt-3">
        <Image
          alt="Luca"
          className="w-[126px]"
          height={32}
          src="/luca-logo-dark.png"
          title="Luca"
          width={58}
        />
      </div>
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger
            className="focus:outline-none focus:ring-0"
            data-testid="user-settings-dropdown"
          >
            <Row className="gap-2 rounded-md bg-muted bg-opacity-20 px-4 py-1">
              {userDisplayName != null && (
                <p style={{ color: "rgb(235, 235, 235)" }}>{userDisplayName}</p>
              )}
              {userPhotoURL != null ? (
                <img
                  alt="user-photo-avatar"
                  className="h-6 w-6 rounded-full"
                  src={userPhotoURL}
                />
              ) : (
                <UserCircle />
              )}
            </Row>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="hover:cursor-pointer"
              data-testid="user-settings-link"
              onClick={() => {
                navigate("/user/settings");
              }}
            >
              <Row className="gap-2">
                <UserIcon size={16} />
                <p>User Settings</p>
              </Row>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                navigate("/team/settings");
              }}
            >
              <Row className="gap-2">
                <Building2Icon size={16} />
                <p>Team Settings</p>
              </Row>
            </DropdownMenuItem>
            {devFeaturesEnabled && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onClick={() => {
                  navigate("/dev/components");
                }}
              >
                <Row className="gap-2">
                  <CodeXmlIcon size={16} />
                  <p>Test Components</p>
                </Row>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </Row>
  );
}
