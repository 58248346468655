import SummaryMetricsContainer from "src/frontend/components/SummaryMetricsContainer";
import { cn } from "src/frontend/components/ui/utils";
import ConfidenceDistributionChart from "src/frontend/pages/pricing/summary/ConfidenceDistributionChart";
import MetricsTabs from "src/frontend/pages/pricing/summary/MetricsTabs";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";

type ImpactSummaryMetricsProps = HtmlDivProps & {
  isDetailView?: boolean;
  metrics?: GetPricePlanSummaryQueryResponseType;
};

export default function ImpactSummaryMetrics({
  className,
  isDetailView,
  metrics,
  ...rest
}: ImpactSummaryMetricsProps) {
  return (
    <SummaryMetricsContainer className={cn("flex", className)} {...rest}>
      <MetricsTabs
        isDetailView={isDetailView}
        metrics={metrics}
        type="PRICE_PLAN_IMPACT"
      />
      <ConfidenceDistributionChart
        intervals={metrics?.confidence_intervals?.results ?? null}
        opportunity={null}
        results={metrics?.group_results_metrics ?? null}
        type="results"
      />
    </SummaryMetricsContainer>
  );
}
