import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import useToast from "src/frontend/components/ui/useToast";
import useUserSignupCompleteLocalStorage from "src/frontend/hooks/useUserSignupCompleteLocalStorage";
import useAuthStore from "src/frontend/stores/useAuthStore";
import getBackendTrpcUrl from "src/frontend/utils/getBackendTrpcUrl";
import getBearerAuthHeader from "src/frontend/utils/getBearerAuthHeader";
import logClientError from "src/frontend/utils/logClientError";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";
import invariant from "tiny-invariant";

export default function useCompleteUserSignup() {
  const { errorToast } = useToast();
  const [signupFailed, setSignupFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthStore((state) => state.user);
  const [userSignupLocalStorageKey, setUserSignupLocalStorageKey] =
    useUserSignupCompleteLocalStorage();

  const handleUserSignup = useCallback(async () => {
    if (signupFailed || isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      const token = await useAuthStore.getState().user?.getIdToken();
      invariant(token != null, "Token should not be null.");
      const url = getBackendTrpcUrl();
      await axios.post(`${url}/api/signup`, undefined, {
        headers: {
          Authorization: getBearerAuthHeader(token),
        },
      });
      setUserSignupLocalStorageKey(true);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.UserSignupErrorMissingEmail, {
        extra: { user },
      });
      setSignupFailed(true);
      setUserSignupLocalStorageKey(false);
      if (err?.response?.status === 401) {
        errorToast("Unauthorized.");
      }

      useAuthStore.getState().logout();
    }
    setIsLoading(false);
  }, [user, isLoading, signupFailed, errorToast, setUserSignupLocalStorageKey]);

  useEffect(() => {
    if (user != null && !isLoading && !userSignupLocalStorageKey) {
      const { email } = user;
      if (email == null || email === "") {
        logClientError(
          new Error("Missing email in user signup."),
          SentryErrorEvent.UserSignupErrorMissingEmail,
          { extra: { user } },
        );
        return;
      }

      void handleUserSignup();
    }
  }, [
    isLoading,
    handleUserSignup,
    setUserSignupLocalStorageKey,
    user,
    userSignupLocalStorageKey,
  ]);

  return userSignupLocalStorageKey;
}
