import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import Button from "src/frontend/components/ui/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "src/frontend/components/ui/Drawer";
import useFlags from "src/frontend/hooks/useFlags";

type TestDrawerProps = {
  hide?: boolean;
};

export default function TestDrawer({ hide }: TestDrawerProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Drawer</TestComponentHeading>
      <Drawer>
        <DrawerTrigger>
          <Button variant="basic">Open Drawer</Button>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader className="m-auto w-[250px] text-center">
            <DrawerTitle>Are you absolutely sure?</DrawerTitle>
            <DrawerDescription>This action cannot be undone.</DrawerDescription>
          </DrawerHeader>
          <DrawerFooter className="m-auto w-[250px] text-center">
            <Button>Submit</Button>
            <DrawerClose>
              <Button variant="basic">Cancel</Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
