import { Settings } from "lucide-react";
import { Link } from "react-router-dom";
import { MobileNavigationSheet } from "src/frontend/components/ui/MobileNavigationSheet";

type MobileNavBarProps = {
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

export default function MobileNavBar(props: MobileNavBarProps) {
  return (
    <div className="fixed bottom-0 z-50 flex h-[70px] w-full flex-row items-center justify-between border border-border bg-sidebar px-4">
      <MobileNavigationSheet {...props} />
      <Link to="/user/settings">
        <Settings size={46} strokeWidth={0.88} />
      </Link>
    </div>
  );
}
