import { Maybe } from "src/shared/types/maybe/Maybe";

const LUCA_EMAIL_MARKER = "@askluca.com";

const IS_LUCA_ALLOWLIST = ["desigarciatrelles@gmail.com"];

export default function isUserLucaAdmin(email: Maybe<string>): boolean {
  return (
    email != null &&
    (email.endsWith(LUCA_EMAIL_MARKER) || IS_LUCA_ALLOWLIST.includes(email))
  );
}
