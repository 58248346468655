import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import Spinner from "src/frontend/components/ui/Spinner";
import { cn } from "src/frontend/components/ui/utils";

export const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:opacity-50 disabled:hover:cursor-not-allowed",
  {
    defaultVariants: {
      size: "default",
      variant: "default",
    },
    variants: {
      size: {
        circle: "h-9 w-9 rounded-full",
        default: "h-9 px-4 py-2",
        icon: "h-9 w-9",
        lg: "h-10 rounded-md px-6",
        none: "",
        sm: "h-8 rounded-md px-3 text-xs",
        smIcon: "h-7 w-7",
        xs: "h-9 w-auto px-4",
      },
      variant: {
        basic:
          "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground disabled:border-muted",
        circle:
          "rounded-full border border-input bg-transparent hover:enabled:bg-accent hover:enabled:text-accent-foreground",
        default:
          "items-center whitespace-nowrap bg-primary font-bold leading-tight text-white hover:bg-opacity-80",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        hyperlink: "text-primary underline-offset-4 hover:underline",
        invisible:
          "border-transparent !outline-none focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-0",
        link: "underline underline-offset-4",
        outline:
          "border border-ring bg-transparent hover:bg-accent hover:text-accent-foreground disabled:border-muted",
        primary:
          "items-center whitespace-nowrap rounded bg-primary leading-tight text-primary-foreground hover:bg-opacity-80",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      },
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    testId?: string;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      isLoading,
      size,
      testId,
      variant,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ className, size, variant }))}
        data-testid={testId}
        disabled={isLoading}
        ref={ref}
        {...props}
      >
        {isLoading ? <Spinner size={20} /> : children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export default Button;
