import { ChevronLeftCircleIcon, ChevronRightCircleIcon } from "lucide-react";
import { useEffect, useRef } from "react";
import { CopyText } from "src/frontend/components/CopyText";
import Row from "src/frontend/components/Row";
import ConstraintsSummaryList from "src/frontend/components/constraints/ConstraintsSummaryList";
import { CompetitorSidePanelContent } from "src/frontend/components/product-side-panel/CompetitorSidePanelContent";
import ProductSidePanelContent from "src/frontend/components/product-side-panel/ProductSidePanelContent";
import Button from "src/frontend/components/ui/Button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "src/frontend/components/ui/Sheet";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/frontend/components/ui/Tabs";
import { cn } from "src/frontend/components/ui/utils";
import useProductDetailSidePanelStore, {
  ProductDetailSidePanelTab,
} from "src/frontend/stores/useProductDetailSidePanelStore";
import {
  GetExperimentForecastQueryResponse,
  GetExperimentForecastQueryResponseType,
} from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";

export default function ProductSidePanel() {
  const timeoutRef = useRef<MaybeNull<NodeJS.Timeout>>(null);
  const state = useProductDetailSidePanelStore();
  const {
    competitorPricingData,
    experimentModelingPrice,
    isOpen,
    productIndex,
    products,
    tab,
  } = state;

  useEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleOpenChange = (open: boolean) => {
    useProductDetailSidePanelStore.setState({ isOpen: open });
    if (!open) {
      // Resetting the store state disrupts the Sheet's close animation unless we delay it.
      timeoutRef.current = setTimeout(state.reset, 500);
    }
  };

  if (productIndex == null) {
    return null;
  }

  const product = products[productIndex];
  if (product == null) {
    return null;
  }

  const paginateProduct = (index: number) => {
    useProductDetailSidePanelStore.setState({
      productIndex: index,
    });
  };

  const { product_brand_name, product_name, sku } = product;
  const isExperiment = GetExperimentForecastQueryResponse.safeParse(product);

  const experiment = isExperiment.success
    ? (product as GetExperimentForecastQueryResponseType)
    : null;
  const competitorDataAvailable =
    competitorPricingData != null &&
    competitorPricingData.some((val) => arrayNotEmpty(val));

  return (
    <Sheet onOpenChange={(open) => handleOpenChange(open)} open={isOpen}>
      <Tabs
        onValueChange={(newTab) => {
          useProductDetailSidePanelStore.setState({
            tab: newTab as ProductDetailSidePanelTab,
          });
        }}
        value={tab}
      >
        <SheetContent
          className="flex h-full flex-col bg-card  p-0 sm:max-w-[585px]"
          hideCloseIcon
          onOpenAutoFocus={(e) => e.preventDefault()}
          side="right"
        >
          <SheetHeader className="flex flex-col justify-center bg-n-50 px-8 pb-[1px] pt-6">
            <Row className="justify-between">
              <div>
                <SheetTitle className="font-bold">
                  <CopyText
                    className="hover:cursor-pointer"
                    textToCopy={product_name}
                  >
                    {product_name} Detail
                  </CopyText>
                </SheetTitle>
                <p className="text-sm">
                  <CopyText
                    className="hover:cursor-pointer"
                    textToCopy={product_brand_name}
                  >
                    {product_brand_name}
                  </CopyText>
                  {product_brand_name != null && " | "}SKU{" "}
                  <CopyText className="hover:cursor-pointer" textToCopy={sku}>
                    {sku}
                  </CopyText>
                </p>
              </div>
              <Row className="gap-2">
                <Button
                  disabled={productIndex === 0}
                  onClick={() => paginateProduct(productIndex - 1)}
                  size="icon"
                  variant="ghost"
                >
                  <ChevronLeftCircleIcon strokeWidth={1.5} />
                </Button>
                <Button
                  disabled={productIndex === products.length - 1}
                  onClick={() => paginateProduct(productIndex + 1)}
                  size="icon"
                  variant="ghost"
                >
                  <ChevronRightCircleIcon strokeWidth={1.5} />
                </Button>
              </Row>
            </Row>
            <TabsList className="mt-2 flex w-full flex-col justify-around sm:flex-row sm:items-end">
              <TabsTrigger
                className={cn(
                  "flex-1 disabled:cursor-not-allowed disabled:font-normal",
                  tab !== "Competitor Pricing" && "font-normal",
                )}
                disabled={!competitorDataAvailable}
                value="Competitor Pricing"
                variant="underlined"
              >
                <p className="text-base">Competitor Pricing</p>
              </TabsTrigger>
              <TabsTrigger
                className={cn(
                  "flex-1 disabled:cursor-not-allowed disabled:font-normal",
                  tab !== "Product" && "font-normal",
                )}
                value="Product"
                variant="underlined"
              >
                <p className="text-base">Product</p>
              </TabsTrigger>
              <TabsTrigger
                className={cn(
                  "flex-1 disabled:cursor-not-allowed disabled:font-normal",
                  tab !== "Constraints" && "font-normal",
                )}
                disabled={experiment == null}
                value="Constraints"
                variant="underlined"
              >
                <p className="text-base">Constraints</p>
              </TabsTrigger>
            </TabsList>
          </SheetHeader>
          <div className="flex-grow overflow-y-scroll">
            <TabsContent className="p-10" value="Product">
              <ProductSidePanelContent product={product} />
            </TabsContent>
            {competitorDataAvailable && (
              <TabsContent className="p-10" value="Competitor Pricing">
                <CompetitorSidePanelContent />
              </TabsContent>
            )}
            {experiment != null && experimentModelingPrice != null && (
              <TabsContent className="p-10" value="Constraints">
                <ConstraintsSummaryList
                  constraints={experiment.constraints}
                  experiment={experiment}
                  modelingPrice={experimentModelingPrice}
                  priceParityGroupName={experiment.price_parity_group_name}
                  productPrices={{
                    discounted_price: experiment.control_discounted_price,
                    list_price: experiment.control_list_price,
                    member_price: experiment.control_member_price,
                    subscriber_price: experiment.control_subscriber_price,
                  }}
                />
              </TabsContent>
            )}
          </div>
        </SheetContent>
      </Tabs>
    </Sheet>
  );
}
