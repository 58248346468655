import dayjs from "src/shared/utils/dates/dayjs";

export default function getNextMonday(
  startingDate: dayjs.Dayjs = dayjs(),
): dayjs.Dayjs {
  let date = startingDate;

  // Avoid starting on Monday.
  if (date.weekday() === 1) {
    date.add(1, "day");
  }

  // Find the next Monday.
  while (date.weekday() !== 1) {
    date = date.add(1, "day");
  }
  return date;
}
