import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Button from "src/frontend/components/ui/Button";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";

type RevertGroupsModalProps = {
  groupIds: string[];
};

export default function RevertGroupsModal({
  groupIds,
}: RevertGroupsModalProps) {
  const isBrandAdmin = useIsBrandAdmin();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errorToast, successToast } = useToast();
  const revertGroupsMutation = TrpcClient.internal.revertGroups.useMutation();

  return (
    <Modal
      dialogDescription="This will publish the “previous price” for all the SKUs in the currently active price plan. Are you sure you want to publish these changes to your store?"
      dialogTitle="Revert All"
      loading={revertGroupsMutation.isLoading}
      onConfirm={() => {
        revertGroupsMutation.mutate(
          { groupIds },
          {
            onError: () => {
              errorToast("Failed to revert changes.");
            },
            onSuccess: () => {
              setIsModalOpen(false);
              successToast("Changes reverted successfully.");
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button
          disabled={arrayEmpty(groupIds) || !isBrandAdmin}
          size="sm"
          variant="outline"
        >
          Revert All
        </Button>
      }
    />
  );
}
