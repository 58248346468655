import invariant from "tiny-invariant";

export default function calculateFinalPrice(
  allPrices: (number | null)[],
): number | null {
  const prices: number[] = allPrices.filter(
    (val): val is number => val != null,
  );
  if (prices.length === 0) {
    return null;
  }
  prices.forEach((val) => {
    invariant(val != null, "Price must not be null.");
  });
  return Math.min(...prices);
}
