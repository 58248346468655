import { z } from "zod";

export const ProductConditionFilters = z.object({
  applyAndConditionForCategory: z.boolean(),
  applyAndConditionForFilters: z.boolean(),
  applyAndConditionForPriceZone: z.boolean(),
  applyAndConditionForProductBrandName: z.boolean(),
  applyAndConditionForSubCategory: z.boolean(),
  applyAndConditionForSupplier: z.boolean(),
  applyAndConditionForTags: z.boolean(),
});

export type ProductConditionFiltersKey = keyof z.infer<
  typeof ProductConditionFilters
>;

export type ProductConditionFiltersType = z.infer<
  typeof ProductConditionFilters
>;
