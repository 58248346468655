import React from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
} from "recharts";
import Card from "src/frontend/components/Card";
import COLORS from "src/frontend/constants/Colors";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import useRechartsStyles from "src/frontend/hooks/useRechartsStyles";
import { ElasticityCategoryType } from "src/shared/trpc/common/ElasticityCategory";
import getFirst from "src/shared/utils/arrays/getFirst";
import len from "src/shared/utils/arrays/len";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import truncateTextEnd from "src/shared/utils/strings/truncateTextEnd";

type CustomTooltipProps = {
  active: boolean;
  payload: { payload: ElasticityCategoryType }[];
};

function CustomTooltip({ active, payload }: CustomTooltipProps) {
  const { tooltipStyle } = useRechartsStyles();
  if (!active || payload == null) {
    return null;
  }

  const { category, elasticity = null } = getFirst(payload)?.payload ?? {};
  return (
    <div className="rounded-sm border bg-card p-2" style={tooltipStyle}>
      <p>
        <span className="font-semibold">Category:</span> {category}
      </p>
      <p>
        <span className="font-semibold">Elasticity:</span>{" "}
        {formatNumberRounded(elasticity, 2)}
      </p>
    </div>
  );
}

export default function ModelInputsChart() {
  const query = useGetPricePlanSummaryQuery();
  const { axisColor, backgroundColor, fontFamily, gridColor } =
    useRechartsStyles();

  if (query.isLoading) {
    return <p>Loading...</p>;
  } else if (query.isError) {
    return <p>Error...</p>;
  }

  const model_inputs = query.data?.model_inputs;
  if (model_inputs == null) {
    return null;
  }

  const count = len(model_inputs.elasticities);
  const xAxisOffset = count > 20 ? 90 : 70;
  return (
    <Card className="py-3">
      <p className="pb-1 pl-8 font-semibold">Product Elasticities</p>
      <ResponsiveContainer height={290} style={{ backgroundColor }} width="99%">
        <ScatterChart
          height={290}
          margin={{
            bottom: xAxisOffset,
            left: 6,
            right: 6,
            top: 10,
          }}
        >
          <CartesianGrid stroke={gridColor} strokeDasharray="3 3" />
          <XAxis
            angle={-75}
            dataKey="category"
            label={{
              dy: xAxisOffset,
              style: { fontFamily, fontSize: count > 20 ? 10 : 12 },
              value: "Product Category",
            }}
            name="category"
            stroke={axisColor}
            style={{ fontFamily, fontSize: 10 }}
            textAnchor="end"
            tickFormatter={(val) => truncateTextEnd(val, 12)}
            type="category"
          />
          <YAxis
            dataKey="elasticity"
            label={{
              angle: -90,
              dx: -22,
              style: { fontFamily, fontSize: 12 },
              value: "Elasticity",
            }}
            name="elasticity"
            stroke={axisColor}
            style={{ fontFamily, fontSize: 12 }}
            tickCount={8}
            type="number"
          />
          <Tooltip
            // @ts-ignore
            content={<CustomTooltip />}
          />
          <Scatter
            data={model_inputs.elasticities}
            fill={COLORS.PRIMARY}
            name="category"
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Card>
  );
}
