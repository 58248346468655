import MaybeString from "src/shared/types/maybe/MaybeString";
import { create } from "zustand";

export type StoreState = {
  isDeleteModalOpen: boolean;
  isDuplicateModalOpen: boolean;
  loading: boolean;
  pricingRulesGroupId: MaybeString;
};

const defaultState: StoreState = {
  isDeleteModalOpen: false,
  isDuplicateModalOpen: false,
  loading: false,
  pricingRulesGroupId: null,
};

type StoreActions = {
  reset: () => void;
};

const usePricingRulesGroupDropdownStateStore = create<
  StoreActions & StoreState
>((set) => ({
  reset: (): void => {
    set(defaultState);
  },
  ...defaultState,
}));

export default usePricingRulesGroupDropdownStateStore;
