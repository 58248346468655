import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import isNumber from "src/shared/utils/numbers/isNumber";

export default function formatPercentage(
  val: MaybeNull<number>,
  maximumFractionDigits = 2,
): string {
  if (val == null || !isNumber(val)) {
    return "--";
  }

  return Intl.NumberFormat("en", {
    maximumFractionDigits,
    minimumFractionDigits: 0,
    style: "percent",
  }).format(val);
}
