import { z } from "zod";

export const PlaceholderApiRequestPayload = z.object({
  message: z.string(),
});

export type PlaceholderApiRequestPayloadType = z.infer<
  typeof PlaceholderApiRequestPayload
>;

export const PlaceholderApiResponse = z.object({
  message: z.string(),
  status: z.string(),
});

export type PlaceholderApiResponseType = z.infer<typeof PlaceholderApiResponse>;
