import ChangeDirectionIcon from "src/frontend/components/ChangeDirectionIcon";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { Maybe } from "src/shared/types/maybe/Maybe";

type PriceAdjustmentProps = {
  priceChangePercent: Maybe<number>;
};

export default function PriceAdjustment({
  priceChangePercent,
}: PriceAdjustmentProps) {
  if (priceChangePercent == null) {
    return <span>--</span>;
  }

  const priceChangeDirectionText = formatSignedPercent(priceChangePercent);
  return (
    <>
      <ChangeDirectionIcon priceChangePercent={priceChangePercent} />
      <p className="text-xl font-bold">{priceChangeDirectionText}</p>
    </>
  );
}
