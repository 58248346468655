import Header from "src/frontend/components/Header";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import TableSkeleton from "src/frontend/components/skeletons/TableSkeleton";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import PRODUCT_CATALOG_PAGE_SIZE from "src/shared/constants/ProductCatalogPageSize";

export default function CreateUpdatePriceParityGroupSkeleton() {
  return (
    <PageContainer>
      <Header
        backTo="/company-settings/relationships/create"
        leftNode={<PageTitle>Create Price Parity Group</PageTitle>}
      />
      <div className="flex flex-row gap-12">
        <div className="w-1/2">
          <div className="mt-4 flex flex-col gap-2">
            <Skeleton className="h-6 w-36 rounded-xl" />
            <Skeleton className="h-8 w-80 rounded-xl" />
          </div>
          <div className="mt-4 flex flex-col gap-2">
            <Skeleton className="h-6 w-36 rounded-xl" />
            <Skeleton className="h-8 w-80 rounded-xl" />
            <Skeleton className="h-6 w-20 rounded-xl" />
          </div>
          <div className="mt-6 flex flex-row justify-between gap-2">
            <Skeleton className="h-6 w-36 rounded-xl" />
            <Skeleton className="h-6 w-80 rounded-xl" />
          </div>
          <TableSkeleton
            className="mt-4"
            rowNumber={PRODUCT_CATALOG_PAGE_SIZE}
          />
        </div>
        <div className="w-1/2 p-6">
          <div className="mt-4 flex flex-col gap-2">
            <Skeleton className="h-6 w-44 rounded-xl" />
            <Skeleton className="h-4 w-28 rounded-xl" />
            <Skeleton className="h-8 w-80 rounded-xl" />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
