import {
  getUnitOfMeasurement,
  isWeightUnitOfMeasurement,
} from "src/shared/constants/UnitOfMeasurementMapping";
import calculateFinalPrice from "src/shared/utils/calculateFinalPrice";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";

export function getFormattedFinalPricePerUnit({
  count,
  prices,
  quantity,
  unit,
}: {
  count: number | null;
  prices: (number | null)[];
  quantity: number | null;
  unit: string | null;
}): string {
  const finalPrice = calculateFinalPrice(prices);

  if (finalPrice == null || quantity == null || count == null) {
    return "--";
  }
  let pricePerUnit = finalPrice / (quantity * count);
  if (!isWeightUnitOfMeasurement(unit)) {
    pricePerUnit = finalPrice / quantity;
  }

  const uom = getUnitOfMeasurement(unit);

  const uomIsCustom = uom == null;

  if (uomIsCustom) {
    console.error(
      `Price per unit is being calculated with a custom unit of measurement: ${unit} should be added to the uom map.`,
    );
    return `${formatCurrency(pricePerUnit)}${
      unit?.length ? ` / ${unit.toLowerCase()}` : ""
    }`;
  }

  return `${formatCurrency(pricePerUnit)}${unit?.length ? ` / ${uom}` : ""}`;
}
