import { CSSProperties } from "react";

export default function getFancyBoxItemBadgeStyle(
  color?: string,
): CSSProperties {
  return {
    backgroundColor: `${color}30`,
    borderColor: `${color}20`,
    color,
  };
}
