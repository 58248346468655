import { ProductMasterWithCompetitorMatches } from "src/shared/trpc/common/ProductMasterWithCompetitorMatches";
import { ProductTag } from "src/shared/trpc/common/ProductTag";
import { z } from "zod";

export const ProductCatalogItem = ProductMasterWithCompetitorMatches.merge(
  z.object({
    contribution_margin: z.number().nullable(),
    discounted_price: z.number().nullable(),
    discounted_price_contribution_margin: z.number().nullable(),
    discounted_price_margin: z.number().nullable(),
    end_customer_price: z.number().nullable(),
    last_thirty_avg_final_price_paid: z.number().nullable(),
    last_thirty_margin: z.number().nullable(),
    last_thirty_profit: z.number().nullable(),
    last_thirty_reference_date: z.date().nullable(),
    last_thirty_revenue: z.number().nullable(),
    last_thirty_units: z.number().nullable(),
    list_price: z.number().nullable(),
    margin: z.number().nullable(),
    member_margin: z.number().nullable(),
    member_price: z.number().nullable(),
    // TODO-[MIKE]: contribution margin - catalog
    member_price_contribution_margin: z.number().nullable(),
    most_recent_group_id: z.string().nullable(),
    most_recent_price_plan_date: z.date().nullable(),
    most_recent_price_plan_id: z.string().nullable(),
    subscriber_price: z.number().nullable(),
    subscriber_price_contribution_margin: z.number().nullable(),
    subscriber_price_margin: z.number().nullable(),
    supplier: z.string().nullable(),
    tags: ProductTag.array(),
  }),
);

export type ProductCatalogItemType = z.infer<typeof ProductCatalogItem>;
