import { useState } from "react";
import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import FancyBox, { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import useFlags from "src/frontend/hooks/useFlags";

const OPTIONS = [
  {
    color: "#ef4444",
    label: "Next.js",
    value: "next.js",
  },
  {
    color: "#eab308",
    label: "SvelteKit",
    value: "sveltekit",
  },
  {
    color: "#22c55e",
    label: "Nuxt.js",
    value: "nuxt.js",
  },
  {
    color: "#06b6d4",
    label: "Remix",
    value: "remix",
  },
  {
    color: "#3b82f6",
    label: "Astro",
    value: "astro",
  },
  {
    color: "#8b5cf6",
    label: "WordPress",
    value: "wordpress",
  },
];

type TestFancyBoxProps = {
  hide?: boolean;
};

export default function TestFancyBox({ hide = false }: TestFancyBoxProps) {
  const [items, setItems] = useState<FancyBoxItem[]>(OPTIONS);
  const [selectedItems, setSelectedItems] = useState<FancyBoxItem[]>([]);
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Fancy Box</TestComponentHeading>
      <FancyBox
        items={items}
        selectedItems={selectedItems}
        setItems={setItems}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
}
