export type SnakeCaseToCamelCase<S extends string> =
  S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<SnakeCaseToCamelCase<U>>}`
    : S;

export default function snakeCaseToCamelCase<T extends string>(
  snake: T,
): SnakeCaseToCamelCase<T> {
  return snake
    .toLowerCase()
    .replace(/(_\w)/g, (matches) =>
      matches[1].toUpperCase(),
    ) as SnakeCaseToCamelCase<T>;
}
