import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type LinkTextProps = HtmlDivProps & {
  children: React.ReactNode;
  onClick: () => void;
};

export default function LinkText({
  children,
  className,
  onClick,
  ...rest
}: LinkTextProps) {
  return (
    <span
      className={cn("text-primary hover:cursor-pointer", className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </span>
  );
}
