import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import isConstraintSatisfied from "src/shared/utils/constraints/isConstraintSatisfied";
import shouldIncludeConstraintInMinMaxCalculations from "src/shared/utils/constraints/shouldIncludeConstraintInMinMaxCalculations";

export default function getConstraintCountsForExperimentSegment(
  experiment: { constraints: PricingConstraintType[] },
  segment: PriceTargetType,
): {
  constraintsCount: number;
  constraintsMetCount: number;
} {
  const constraints = experiment.constraints.filter((constraint) =>
    shouldIncludeConstraintInMinMaxCalculations(constraint.type),
  );

  const listPriceConstraints = constraints.filter(
    (constraint) => constraint.price_target_type === segment,
  );
  const constraintsCount = listPriceConstraints.length;
  const constraintsMetCount = listPriceConstraints.filter((constraint) =>
    isConstraintSatisfied(constraint),
  ).length;

  return {
    constraintsCount,
    constraintsMetCount,
  };
}
