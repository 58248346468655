import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { CompetitorPriceLookbackPeriodType } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function getCompetitorRecentPriceFieldKey(
  recentPriceOption: CompetitorPriceLookbackPeriodType,
  displayPriceTarget: PriceTargetType,
): keyof CompetitorMatchWithAveragePricesType {
  switch (recentPriceOption) {
    case "MOST_RECENT": {
      return `competitor_${displayPriceTarget.toLowerCase()}` as keyof CompetitorMatchWithAveragePricesType;
    }
    case "LAST_1_DAY": {
      return `avg_last_1_day_${displayPriceTarget.toLowerCase()}` as keyof CompetitorMatchWithAveragePricesType;
    }
    case "LAST_7_DAYS": {
      return `avg_last_7_days_${displayPriceTarget.toLowerCase()}` as keyof CompetitorMatchWithAveragePricesType;
    }
    case "LAST_14_DAYS": {
      return `avg_last_14_days_${displayPriceTarget.toLowerCase()}` as keyof CompetitorMatchWithAveragePricesType;
    }
    case "LAST_30_DAYS": {
      return `avg_last_30_days_${displayPriceTarget.toLowerCase()}` as keyof CompetitorMatchWithAveragePricesType;
    }
    default:
      return assertUnreachable(recentPriceOption);
  }
}
