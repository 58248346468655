import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";

export default function useGetPriceParityGroupById(
  priceParityGroupId: MaybeUndefined<string>,
) {
  const { enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getPriceParityGroupById.useQuery(
    {
      priceParityGroupId,
    },
    {
      enabled,
    },
  );
}
