import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import Button from "src/frontend/components/ui/Button";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "src/frontend/components/ui/HoverCard";
import useFlags from "src/frontend/hooks/useFlags";

type TestHoverCardProps = {
  hide?: boolean;
};

export default function TestHoverCard({ hide }: TestHoverCardProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Hover Card</TestComponentHeading>
      <HoverCard>
        <HoverCardTrigger>
          <Button variant="basic">Hover</Button>
        </HoverCardTrigger>
        <HoverCardContent>
          Hover card demo - <i>this is the card ~ ✨</i>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
}
