import { Maybe } from "@trpc/server";

export default function formatCurrency(
  val: Maybe<bigint> | Maybe<number>,
  digits = 2,
  fallback?: string,
): string {
  if (val == null) {
    return fallback ?? "--";
  }

  return Intl.NumberFormat("en", {
    currency: "USD",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: "currency",
  }).format(val);
}
