import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLocationHistoryStore from "src/frontend/stores/useLocationHistoryStore";
import getLast from "src/shared/utils/arrays/getLast";
import limitArrayLength from "src/shared/utils/arrays/limitArrayLength";

export default function useHistoryTracker() {
  const location = useLocation();
  const history = useLocationHistoryStore((state) => state.history);
  useEffect(() => {
    const currentLocation = location.pathname;
    const previousLocation = getLast(history);
    if (previousLocation == null) {
      useLocationHistoryStore.setState({ history: [currentLocation] });
    } else if (location.pathname !== previousLocation) {
      useLocationHistoryStore.setState((current) => {
        return {
          history: limitArrayLength(
            current.history.concat(currentLocation),
            100,
          ),
        };
      });
    }
  }, [history, location.pathname]);
}
