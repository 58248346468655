import { InfoIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import LightLabel from "src/frontend/components/LightLabel";
import PriceTargetSelectInput from "src/frontend/components/PriceTargetSelectInput";
import Row from "src/frontend/components/Row";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import Dialog from "src/frontend/components/ui/Dialog";
import NumberInput from "src/frontend/components/ui/NumberInput";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import StrategyObjectiveTradeoffInput from "src/frontend/pages/company/strategy/StrategyObjectiveTradeoffInput";
import useStrategyObjectiveStore from "src/frontend/stores/useStrategyObjectiveStore";
import getPriceBoundTypeSymbol from "src/frontend/utils/constraints/getPriceBoundTypeSymbol";
import areGenericPriceBoundsValid from "src/shared/validation/getGenericPriceBoundsErrorMessage";
import isStrategyObjectiveValid from "src/shared/validation/isStrategyObjectiveValid";

type StrategyObjectiveDialogProps = {
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export default function StrategyObjectiveDialog({
  onOpenChange,
  open,
}: StrategyObjectiveDialogProps) {
  const t = useToast();
  const navigate = useNavigate();
  const { pricingStrategyId = null } = useParams();
  const [loading, setLoading] = useState(false);
  const pricingStrategiesQuery = useGetPricingStrategiesQuery();
  const pricingStrategyByIdQuery = useGetPricingStrategyByIdQuery();
  const [showErrors, setShowErrors] = useState(false);
  const state = useStrategyObjectiveStore();

  const createOrUpdateStrategyObjectiveMutation =
    TrpcClient.internal.createOrUpdateStrategyObjective.useMutation();

  const onSaveSuccess = async (resultPricingStrategyId: string) => {
    void pricingStrategiesQuery.refetch();
    await pricingStrategyByIdQuery.refetch();
    setLoading(false);
    onOpenChange(false);
    t.successToast("Strategy objective saved successfully.");
    if (pricingStrategyId == null) {
      navigate(`/company-settings/strategy/${resultPricingStrategyId}`);
    }
  };

  const onSave = () => {
    setLoading(true);
    createOrUpdateStrategyObjectiveMutation.mutate(
      {
        objective: state.convertInput(),
        pricingStrategyId,
      },
      {
        onError: () => {
          t.errorToast("Failed to save pricing strategy business goals.");
          setLoading(false);
        },
        onSuccess: (data) => {
          void onSaveSuccess(data.pricingStrategyId);
        },
      },
    );
  };

  return (
    <Dialog
      confirmTitle="Save"
      dialogTitle="Define Strategy Objective"
      loading={loading}
      maxWidth={650}
      modal={false}
      onConfirm={() => {
        const objectiveInput = state.convertInput();
        if (!isStrategyObjectiveValid(objectiveInput)) {
          setShowErrors(true);
          return;
        }

        if (
          state.constraint_type != null &&
          areGenericPriceBoundsValid(objectiveInput, true) !== null
        ) {
          setShowErrors(true);
          return;
        }

        onSave();
      }}
      onOpenChange={(open) => {
        setShowErrors(false);
        onOpenChange(open);
      }}
      open={open}
    >
      <div>
        <div className="mb-4">
          <Row className="my-2">
            <LightLabel>Modeling price</LightLabel>
            <Tooltip
              content={
                <p className="w-[300px]">
                  This is the price that our model will optimize (e.g list
                  price). Any other price (e.g subscriber price) will be set
                  based on this price.
                </p>
              }
              side="right"
            >
              <InfoIcon className="ml-2" size={14} />
            </Tooltip>
          </Row>
          <PriceTargetSelectInput
            onlyRenderSelectComponent
            setPriceTarget={(modeling_price) => {
              useStrategyObjectiveStore.setState({ modeling_price });
            }}
            target={state.modeling_price}
          />
        </div>
        <StrategyObjectiveTradeoffInput hasError={showErrors} loading={false} />
        {state.constraint_type != null && (
          <div className="mt-4">
            <LightLabel>Total margin</LightLabel>
            <Row className="mb-2 justify-between gap-2">
              <p>Should be between: </p>
              <Row>
                <Col>
                  <LightLabel className="mb-[2px]">Lower Bound</LightLabel>
                  <Row>
                    <NumberInput
                      className="w-36 rounded border border-opacity-30 px-4 py-2"
                      disabled={loading}
                      onChangeValue={(val) => {
                        useStrategyObjectiveStore.setState({
                          lower_bound_input: val,
                        });
                      }}
                      placeholder="Lower bound"
                      value={state.lower_bound_input}
                    />
                    <p className="ml-1 w-4 text-sm">
                      {getPriceBoundTypeSymbol(state.bound_type ?? null)}
                    </p>
                  </Row>
                </Col>
                <Col>
                  <LightLabel className="mb-[2px]">Upper Bound</LightLabel>
                  <Row>
                    <NumberInput
                      className="w-36 rounded border border-opacity-30 px-4 py-2"
                      disabled={loading}
                      onChangeValue={(val) => {
                        if (state.bound_type === "PERCENT") {
                          if (Number(val) > 100) {
                            return;
                          }
                        }

                        useStrategyObjectiveStore.setState({
                          upper_bound_input: val,
                        });
                      }}
                      placeholder="Upper bound"
                      value={state.upper_bound_input}
                    />
                    <p className="ml-1 w-4 text-sm">
                      {getPriceBoundTypeSymbol(state.bound_type ?? null)}
                    </p>
                  </Row>
                </Col>
              </Row>
            </Row>
            {showErrors &&
              areGenericPriceBoundsValid(state.convertInput(), true) !==
                null && (
                <ErrorLabel>
                  {areGenericPriceBoundsValid(state.convertInput(), true)}
                </ErrorLabel>
              )}
          </div>
        )}
      </div>
    </Dialog>
  );
}
