import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import { cn } from "src/frontend/components/ui/utils";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";

const LOADING_SELECT_KEY = "LOADING_SELECT_KEY";

type CompetitorIntelligencePriceSelectFilterProps = {
  availableCompetitorPriceTargetFields?: PriceTargetType[];
  className?: string;
  loading: boolean;
};

export default function CompetitorIntelligencePriceSelectFilter({
  availableCompetitorPriceTargetFields = [],
  className,
  loading,
}: CompetitorIntelligencePriceSelectFilterProps) {
  const displayPriceTarget = useCompetitorIntelligenceFiltersStore(
    (state) => state.displayPriceTarget,
  );
  return (
    <div className={cn("w-40", className)}>
      <Select
        disabled={loading}
        onValueChange={(value: PriceTargetType) => {
          useCompetitorIntelligenceFiltersStore.setState({
            displayPriceTarget: value,
          });
        }}
        value={loading ? LOADING_SELECT_KEY : displayPriceTarget}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select price" />
        </SelectTrigger>
        <SelectContent
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {loading ? (
            <SelectItem disabled value={LOADING_SELECT_KEY}>
              Loading...
            </SelectItem>
          ) : (
            availableCompetitorPriceTargetFields.map((opt) => {
              return (
                <SelectItem key={opt} value={opt}>
                  {formatGenericEnumLabel(opt)}
                </SelectItem>
              );
            })
          )}
        </SelectContent>
      </Select>
    </div>
  );
}
