import PricePlansByStatus from "src/frontend/types/PricePlansByStatus";
import PricePlanTab from "src/shared/types/PricePlanTab";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export default function getTabHasPricePlan(
  tab: PricePlanTab,
  byStatus: PricePlansByStatus,
): boolean {
  switch (tab) {
    case "preview":
      return arrayNotEmpty(byStatus.previewPricePlans);
    case "internal":
      return arrayNotEmpty(byStatus.internalDraftPricePlans);
    case "draft":
      return arrayNotEmpty(byStatus.draftPricePlans);
    case "current":
      return arrayNotEmpty(byStatus.currentPricePlans);
    case "past":
      return arrayNotEmpty(byStatus.pastPricePlans);
    default:
      return assertUnreachable(tab);
  }
}
