import { GetBrandProductCategoriesQueryResponseType } from "src/backend/internal-api/queries/getBrandProductCategoriesQuery";
import { GetsTagsQueryResponseType } from "src/backend/internal-api/queries/getTagsQuery";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import getInitialCategoryFilters from "src/frontend/utils/getInitialCategoryFilters";
import { PricingRulesGroupType } from "src/shared/trpc/common/PricingRulesGroup";

export default function getCategoryFiltersFromBrandCategoriesAndTags(
  tags: GetsTagsQueryResponseType,
  productCategories: GetBrandProductCategoriesQueryResponseType,
  rulesGroup?: PricingRulesGroupType,
  includeAllSkusOption?: boolean,
): FancyBoxItem[] {
  return getInitialCategoryFilters({
    categories: productCategories.categories,
    includeAllSkusOption,
    priceZones: productCategories.priceZones,
    productBrandNames: productCategories.productBrandNames,
    rulesGroup,
    subCategories: productCategories.subCategories,
    suppliers: productCategories.suppliers,
    tags: tags.tags,
  });
}
