import React from "react";
import { BrandScheduleEventType } from "src/backend/prisma-utils/getBrandUpcomingSchedule";
import Col from "src/frontend/components/Col";
import LightLabel from "src/frontend/components/LightLabel";
import CardWithContent from "src/frontend/components/ui/Card";
import { cn } from "src/frontend/components/ui/utils";
import getAppUrlForClientLink from "src/frontend/utils/getAppUrlForClientLink";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import len from "src/shared/utils/arrays/len";
import dayjs from "src/shared/utils/dates/dayjs";

type UpcomingScheduleProps = {
  schedule: BrandScheduleEventType[];
};

export default function UpcomingSchedule({ schedule }: UpcomingScheduleProps) {
  return (
    <CardWithContent className="w-full lg:w-fit" title="Upcoming">
      {arrayEmpty(schedule) && <p>You have no upcoming events.</p>}
      {schedule.map((event, index) => {
        const isLast = index === len(schedule) - 1;
        const date = dayjs(event.date).format("D-MMM-YYYY");
        const { pricePlanId } = event;
        return (
          <Col
            className={cn("mb-3 pb-2", !isLast && "border-b")}
            key={event.description}
          >
            <LightLabel>{date}</LightLabel>
            {pricePlanId != null ? (
              <a
                className="hover:underline"
                href={`${getAppUrlForClientLink()}/price-plan/${pricePlanId}`}
                target="_blank"
              >
                <p>{event.description}</p>
              </a>
            ) : (
              <p>{event.description}</p>
            )}
          </Col>
        );
      })}
    </CardWithContent>
  );
}
