import * as React from "react";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import { cn } from "src/frontend/components/ui/utils";
import MaybeString from "src/shared/types/maybe/MaybeString";

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    errorMessage?: MaybeString;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, errorMessage, ...props }, ref) => {
    return (
      <div>
        <textarea
          className={cn(
            "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          {...props}
        />
        {errorMessage != null && (
          <ErrorLabel className="mt-1">{errorMessage}</ErrorLabel>
        )}
      </div>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
