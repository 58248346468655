import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from "src/frontend/components/ui/Resizable";
import useFlags from "src/frontend/hooks/useFlags";

type TestResizableProps = {
  hide?: boolean;
};

export default function TestResizable({ hide }: TestResizableProps) {
  const { devFeaturesEnabled } = useFlags();
  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Resizable</TestComponentHeading>
      <ResizablePanelGroup
        className="max-w-md rounded-lg border"
        direction="horizontal"
      >
        <ResizablePanel defaultSize={50}>
          <div className="flex h-[200px] items-center justify-center p-6">
            <span className="font-semibold">One</span>
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={50}>
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={25}>
              <div className="flex h-full items-center justify-center p-6">
                <span className="font-semibold">Two</span>
              </div>
            </ResizablePanel>
            <ResizableHandle />
            <ResizablePanel defaultSize={75}>
              <div className="flex h-full items-center justify-center p-6">
                <span className="font-semibold">Three</span>
              </div>
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
