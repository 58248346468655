import { Maybe } from "src/shared/types/maybe/Maybe";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import dayjs from "src/shared/utils/dates/dayjs";
import formatDate from "src/shared/utils/dates/formatDate";

export default function formatDateWithTime(
  date: Maybe<Date | dayjs.Dayjs>,
  template = "MM/DD/YYYY h:mma",
): MaybeNull<string> {
  return formatDate(date, template);
}
