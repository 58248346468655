import { useRef, useCallback, useEffect } from "react";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";
import { Maybe } from "src/shared/types/maybe/Maybe";

/**
 * Source of this file: https://github.com/remix-run/react-router/issues/8139#issuecomment-1382428200
 */

// You can abstract `useBlocker` to use the browser's `window.confirm` dialog to
// determine whether or not the user should navigate within the current origin.
// `useBlocker` can also be used in conjunction with `useBeforeUnload` to
// prevent navigation away from the current origin.
//
// IMPORTANT: There are edge cases with this behavior in which React Router
// cannot reliably access the correct location in the history stack. In such
// cases the user may attempt to stay on the page but the app navigates anyway,
// or the app may stay on the correct page but the browser's history stack gets
// out of whack. You should test your own implementation thoroughly to make sure
// the tradeoffs are right for your users.

export type UsePromptProps = {
  beforeUnload?: boolean;
};

export default function usePrompt(
  message: Maybe<string>,
  { beforeUnload }: UsePromptProps = {},
) {
  const blocker = useBlocker(
    useCallback(
      () => (message != null ? !window.confirm(message) : false),
      [message],
    ),
  );
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload && message != null) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload],
    ),
    { capture: true },
  );
}
