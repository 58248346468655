import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";

export default function formatPercentageDirect(pct: MaybeNumber): MaybeString {
  if (pct == null) {
    return "--";
  }

  return `${roundToPrecision(pct, 1)}%`;
}
