import React from "react";

type Props = {
  size?: number;
};

export const FilterIcon = ({ size = 12 }: Props) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.2458C1 2.41282 1.67527 1.73755 2.50825 1.73755H11.4917C12.3247 1.73755 13 2.41282 13 3.2458V3.2458C13 3.81885 12.7825 4.37054 12.3915 4.78947L9.33789 8.06124C8.99224 8.43158 8.8 8.91929 8.8 9.42588V11.3195C8.8 11.6983 8.586 12.0446 8.24721 12.2139L6.64721 13.0139C5.98231 13.3464 5.2 12.8629 5.2 12.1195V9.42587C5.2 8.91929 5.00776 8.43158 4.66211 8.06124L1.60846 4.78947C1.21746 4.37054 1 3.81885 1 3.2458V3.2458Z"
        stroke="#282828"
        strokeWidth="1.5"
      />
    </svg>
  );
};
