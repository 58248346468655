import { CSSProperties } from "react";
import { CopyText } from "src/frontend/components/CopyText";
import ProductTagTableCell from "src/frontend/components/ProductTagTableCell";
import Row from "src/frontend/components/Row";
import SortIcon from "src/frontend/components/SortIcon";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { cn } from "src/frontend/components/ui/utils";
import { ProductCatalogConfigMapSimple } from "src/frontend/constants/UserColumnVisibilityConfigV2";
import useProductCatalogColumnVisibility from "src/frontend/hooks/columnVisibilityHooks/useProductCatalogColumnVisibility";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import useProductCatalogFiltersStore from "src/frontend/stores/useProductCatalogFilters";
import useProductDetailSidePanelStore from "src/frontend/stores/useProductDetailSidePanelStore";
import GenericTrpcRefetchFunction from "src/frontend/types/GenericTrpcRefetchFunction";
import getAppUrlForClientLink from "src/frontend/utils/getAppUrlForClientLink";
import getBorderRightForColumns from "src/frontend/utils/table/getBorderRightForColumns";
import getStickyColumnStyles from "src/frontend/utils/table/getStickyColumnStyles";
import getTableRowColSpan from "src/frontend/utils/table/getTableRowColSpan";
import { ProductCatalogItemType } from "src/shared/trpc/common/ProductCatalogItem";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { ProductCatalogVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import dayjs from "src/shared/utils/dates/dayjs";
import formatDate from "src/shared/utils/dates/formatDate";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";

function getLastThirtyDateRange(products: ProductCatalogItemType[]): string {
  const product = products.find(
    (product) => product.last_thirty_reference_date != null,
  );
  if (product != null) {
    const lastThirtyReferenceDate = product.last_thirty_reference_date;
    const thirtyDatesBefore = dayjs(lastThirtyReferenceDate).subtract(
      30,
      "days",
    );
    const format = "MM/DD";
    const start = formatDate(thirtyDatesBefore, format);
    const end = formatDate(lastThirtyReferenceDate, format);
    const range = `${start} - ${end}`;
    return ` (${range})`;
  }

  return "";
}

const tagWidthStyles: CSSProperties = {
  width: "auto",
};

const MIN_WIDTH = 100;
const minWidthStyles: CSSProperties = {
  minWidth: MIN_WIDTH,
};

type ProductCatalogTableProps = {
  products: ProductCatalogItemType[];
  refetchProducts: GenericTrpcRefetchFunction;
  visibilityFilters: ProductCatalogVisibilityFiltersType;
};

export default function ProductCatalogTable({
  products,
  refetchProducts,
  visibilityFilters,
}: ProductCatalogTableProps) {
  const isDarkTheme = useIsDarkTheme();
  const breakpointState = useBreakpoints();
  const sortBy = useProductCatalogFiltersStore((state) => state.sortBy);
  const sortDirection = useProductCatalogFiltersStore(
    (state) => state.sortDirection,
  );

  const selectedProductIds = useProductCatalogFiltersStore(
    (state) => state.selectedProductIds,
  );

  const {
    hasCategory: hasCategories,
    hasCost,
    hasDiscountedPrice,
    hasDiscountedPriceContributionMargin,
    hasDiscountedPriceMargin,
    hasInventory,
    hasLast30FinalPrice: hasLastThirtyPrice,
    hasLast30Profit: hasLastThirtyProfit,
    hasLast30Revenue: hasLastThirtyRevenue,
    hasLast30UnitsSold: hasLastThirtyUnitsSold,
    hasLastPricePlan,
    hasListPrice,
    hasListPriceContributionMargin,
    hasListPriceMargin,
    hasMemberPrice,
    // TODO-[MIKE]: contribution margin - catalog
    hasMemberPriceContributionMargin,
    hasMemberPriceMargin,
    hasPriceZone,
    hasProductBrandName,
    hasProductSku,
    hasSubcategory: hasSubcategories,
    hasSubscriberPrice,
    hasSubscriberPriceContributionMargin,
    hasSubscriberPriceMargin,
    hasSupplier,
    hasTags,
  } = useProductCatalogColumnVisibility(visibilityFilters);

  const [skuWidth, productSkuWidth, productNameWidth] = [
    75,
    hasProductSku ? 100 : 0,
    225,
  ];

  const skuOffset = 35;
  const productSkuOffset = skuOffset + skuWidth;
  const productNameOffset = productSkuOffset + productSkuWidth;

  const checkboxStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 1,
    width: skuOffset,
  });
  const skuStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: skuWidth,
  });
  const productSkuStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: productSkuWidth,
  });
  const productStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 1,
    width: productNameWidth,
  });

  const toggleSortKey = (key: ProductMasterObjectSortKeyType) => {
    if (sortBy === key) {
      useProductCatalogFiltersStore.setState((state) => {
        return {
          page: 1,
          sortDirection: state.sortDirection === "asc" ? "desc" : "asc",
        };
      });
    } else {
      useProductCatalogFiltersStore.setState({
        page: 1,
        sortBy: key,
        sortDirection: "desc",
      });
    }
  };

  const competitorPricingData = products.map((val) => val.competitor_matches);

  const productDefinitionCols = [
    hasProductBrandName,
    hasCategories,
    hasSubcategories,
    hasPriceZone,
    hasSupplier,
    hasTags,
  ];
  const productDefinitionColSpan = getTableRowColSpan(productDefinitionCols);
  const {
    category: hasCategoryBorderRight,
    price_zone: hasPriceZoneBorderRight,
    product_brand_name: hasProductBrandNameBorderRight,
    subcategory: hasSubcategoryBorderRight,
    supplier: hasSupplierBorderRight,
    tags: hasTagsBorderRight,
  } = getBorderRightForColumns(
    ProductCatalogConfigMapSimple["Product definition"],
    productDefinitionCols,
  );

  const currentPricingCols = [
    hasListPrice,
    hasDiscountedPrice,
    hasMemberPrice,
    hasSubscriberPrice,
    hasInventory,
    hasListPriceMargin,
    hasListPriceContributionMargin,
    hasDiscountedPriceMargin,
    hasDiscountedPriceContributionMargin,
    // TODO-[MIKE]: contribution margin - catalog
    hasMemberPriceMargin,
    hasMemberPriceContributionMargin,
    hasSubscriberPriceMargin,
    hasSubscriberPriceContributionMargin,
    hasCost,
  ];
  const currentPricingColSpan = getTableRowColSpan(currentPricingCols);
  // TODO-[MIKE]: contribution margin - catalog
  const {
    cost: hasCostBorderRight,
    discounted_price: hasDiscountedPriceBorderRight,
    discounted_price_contribution_margin:
      hasDiscountedPriceContributionMarginBorderRight,
    discounted_price_margin: hasDiscountedPriceMarginBorderRight,
    inventory: hasInventoryBorderRight,
    list_price: hasListPriceBorderRight,
    list_price_contribution_margin: hasListPriceContributionMarginBorderRight,
    list_price_margin: hasListPriceMarginBorderRight,
    member_price: hasMemberPriceBorderRight,
    member_price_contribution_margin:
      hasMemberPriceContributionMarginBorderRight,
    member_price_margin: hasMemberPriceMarginBorderRight,
    subscriber_price: hasSubscriberPriceBorderRight,
    subscriber_price_contribution_margin:
      hasSubscriberPriceContributionMarginBorderRight,
    subscriber_price_margin: hasSubscriberPriceMarginBorderRight,
  } = getBorderRightForColumns(
    ProductCatalogConfigMapSimple["Current pricing information"],
    currentPricingCols,
  );

  const lastThirtyCols = [
    hasLastThirtyPrice,
    hasLastThirtyUnitsSold,
    hasLastThirtyRevenue,
    hasLastThirtyProfit,
  ];
  const lastThirtyColSpan = getTableRowColSpan(lastThirtyCols);
  const {
    last_30_final_price: hasLastThirtyPriceBorderRight,
    last_30_profit: hasLastThirtyProfitBorderRight,
    last_30_revenue: hasLastThirtyRevenueBorderRight,
    last_30_units_sold: hasLastThirtyUnitsSoldBorderRight,
  } = getBorderRightForColumns(
    ProductCatalogConfigMapSimple["Last 30 days"],
    lastThirtyCols,
  );

  return (
    <Table className="bg-card">
      <TableHeader>
        <tr>
          <TableHeaderCell
            className="left-0 border-b bg-n-0 text-center"
            colSpan={hasProductSku ? 4 : 3}
            style={checkboxStickyColumnStyles}
          >
            My Brand
          </TableHeaderCell>
          {productDefinitionColSpan > 0 && (
            <TableHeaderCell
              borderRight
              className="border-b px-0 py-1 text-center"
              colSpan={productDefinitionColSpan}
            >
              Product Definition
            </TableHeaderCell>
          )}
          {currentPricingColSpan > 0 && (
            <TableHeaderCell
              className="border-b px-0 py-1 text-center"
              colSpan={currentPricingColSpan}
            >
              Current Pricing Information
            </TableHeaderCell>
          )}
          {lastThirtyColSpan > 0 && (
            <TableHeaderCell
              borderLeft
              className="border-b px-0 py-1 text-center"
              colSpan={lastThirtyColSpan}
            >
              Last 30 Days{getLastThirtyDateRange(products)}
            </TableHeaderCell>
          )}
          {hasLastPricePlan && (
            <TableHeaderCell
              borderLeft
              className="border-b px-0 py-1 text-center"
              colSpan={1}
            >
              Misc
            </TableHeaderCell>
          )}
        </tr>
        <tr className="border-b align-top">
          <TableHeaderCell
            className="left-0"
            style={checkboxStickyColumnStyles}
          >
            <Checkbox
              checked={selectedProductIds.length === products.length}
              id="check-all-experiments"
              onCheckedChange={(_, checked) => {
                useProductCatalogFiltersStore.setState({
                  selectedProductIds: !checked
                    ? []
                    : products.map((product) => product.id),
                });
              }}
              size="sm"
            />
          </TableHeaderCell>
          <TableHeaderCell
            className="hover:cursor-pointer"
            onClick={() => toggleSortKey("sku")}
            style={{ ...skuStickyColumnStyles, left: skuOffset }}
          >
            <Row className="items-start">
              <p className="pl-1">SKU</p>
              <SortIcon
                direction={sortDirection}
                selectedKey={sortBy}
                sortBy="sku"
              />
            </Row>
          </TableHeaderCell>
          {hasProductSku && (
            <TableHeaderCell
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("product_sku")}
              style={{
                ...productSkuStickyColumnStyles,
                left: productSkuOffset,
              }}
            >
              <Row className="items-start">
                <p className="pl-1">Product Id</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="product_sku"
                />
              </Row>
            </TableHeaderCell>
          )}
          <TableHeaderCell
            className="hover:cursor-pointer"
            onClick={() => toggleSortKey("product_name")}
            style={{ ...productStickyColumnStyles, left: productNameOffset }}
          >
            <Row className="items-start">
              <p>Product Name</p>
              <SortIcon
                direction={sortDirection}
                selectedKey={sortBy}
                sortBy="product_name"
              />
            </Row>
          </TableHeaderCell>
          {hasProductBrandName && (
            <TableHeaderCell
              borderRight={hasProductBrandNameBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("product_brand_name")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Product Brand Name</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="product_brand_name"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasCategories && (
            <TableHeaderCell
              borderRight={hasCategoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("categories")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Category</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="categories"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSubcategories && (
            <TableHeaderCell
              borderRight={hasSubcategoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("sub_categories")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subcategory</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="sub_categories"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasPriceZone && (
            <TableHeaderCell
              borderRight={hasPriceZoneBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("price_zone")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Price Zone</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="price_zone"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSupplier && (
            <TableHeaderCell
              borderRight={hasSupplierBorderRight}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Supplier</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasTags && (
            <TableHeaderCell
              borderRight={hasTagsBorderRight}
              style={tagWidthStyles}
            >
              <Row className="items-start">
                <p>Tags</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasListPrice && (
            <TableHeaderCell
              borderRight={hasListPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("list_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="list_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPrice && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("discounted_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="discounted_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasMemberPrice && (
            <TableHeaderCell
              borderRight={hasMemberPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("member_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="member_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPrice && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("subscriber_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="subscriber_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasInventory && (
            <TableHeaderCell
              borderRight={hasInventoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("inventory_level")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Inventory</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="inventory_level"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasListPriceMargin && (
            <TableHeaderCell
              borderRight={hasListPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasListPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasListPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPriceMargin && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasMemberPriceMargin && (
            <TableHeaderCell
              borderRight={hasMemberPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {/* // TODO-[MIKE]: contribution margin - catalog */}
          {hasMemberPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasMemberPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPriceMargin && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasCost && (
            <TableHeaderCell
              borderRight={hasCostBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("cpu")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Cost Per Unit</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="cpu"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasLastThirtyPrice && (
            <TableHeaderCell
              borderRight={hasLastThirtyPriceBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Avg Final Price Paid</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasLastThirtyUnitsSold && (
            <TableHeaderCell
              borderRight={hasLastThirtyUnitsSoldBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Units Sold</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasLastThirtyRevenue && (
            <TableHeaderCell
              borderRight={hasLastThirtyRevenueBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Revenue</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasLastThirtyProfit && (
            <TableHeaderCell
              borderRight={hasLastThirtyProfitBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Profit</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasLastPricePlan && (
            <TableHeaderCell
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Latest Price Plan</p>
              </Row>
            </TableHeaderCell>
          )}
        </tr>
      </TableHeader>
      <tbody>
        {products.map((product, productIndex) => {
          return (
            <tr
              className={cn(
                "cursor-pointer bg-n-0 last:border-b-0 [&:hover>*]:bg-n-100 [&>*]:duration-150",
              )}
              key={product.id}
              onClick={() => {
                useProductDetailSidePanelStore.setState({
                  competitorMatchIndex: 1,
                  competitorNamesList: product.competitor_names_list,
                  competitorPricingData,
                  isOpen: true,
                  productIndex,
                  products,
                  tab: "Product",
                });
              }}
            >
              <TableDataCell
                className="left-0 bg-n-0"
                onClick={(e) => e.stopPropagation()}
                style={checkboxStickyColumnStyles}
              >
                <Checkbox
                  checked={selectedProductIds.includes(product.id)}
                  id={product.id}
                  onCheckedChange={(_, checked) => {
                    useProductCatalogFiltersStore.setState((state) => {
                      if (!checked) {
                        return {
                          selectedProductIds: state.selectedProductIds.filter(
                            (id) => id !== product.id,
                          ),
                        };
                      } else {
                        return {
                          selectedProductIds: state.selectedProductIds.concat(
                            product.id,
                          ),
                        };
                      }
                    });
                  }}
                  size="sm"
                />
              </TableDataCell>
              <TableDataCell
                className="bg-n-0"
                style={{ ...skuStickyColumnStyles, left: skuOffset }}
              >
                <CopyText includeTooltip isCode textToCopy={product.sku}>
                  <p className="truncate text-ellipsis text-left text-sm">
                    {product.sku}
                  </p>
                </CopyText>
              </TableDataCell>
              {hasProductSku && (
                <TableDataCell
                  className="bg-n-0"
                  style={{
                    ...productSkuStickyColumnStyles,
                    left: productSkuOffset,
                  }}
                >
                  <CopyText
                    includeTooltip
                    isCode
                    textToCopy={product.product_sku}
                  >
                    <p className="truncate text-ellipsis text-left text-sm">
                      {product.product_sku}
                    </p>
                  </CopyText>
                </TableDataCell>
              )}
              <TableDataCell
                className="bg-n-0"
                style={{
                  ...productStickyColumnStyles,
                  left: productNameOffset,
                }}
              >
                {product.product_name}
              </TableDataCell>
              {hasProductBrandName && (
                <TableDataCell
                  borderRight={hasProductBrandNameBorderRight}
                  maxWidth="max-w-[12ch]"
                  style={minWidthStyles}
                >
                  {product.product_brand_name ?? "--"}
                </TableDataCell>
              )}
              {hasCategories && (
                <TableDataCell
                  borderRight={hasCategoryBorderRight}
                  maxWidth="max-w-[12ch]"
                  style={minWidthStyles}
                >
                  {arrayEmpty(product.categories)
                    ? "--"
                    : product.categories.join(", ")}
                </TableDataCell>
              )}
              {hasSubcategories && (
                <TableDataCell
                  borderRight={hasSubcategoryBorderRight}
                  maxWidth="max-w-[12ch]"
                  style={minWidthStyles}
                >
                  {arrayEmpty(product.sub_categories)
                    ? "--"
                    : product.sub_categories.join(", ")}
                </TableDataCell>
              )}
              {hasPriceZone && (
                <TableDataCell
                  borderRight={hasPriceZoneBorderRight}
                  maxWidth="max-w-[12ch]"
                  style={minWidthStyles}
                >
                  {product.price_zone ?? "--"}
                </TableDataCell>
              )}
              {hasSupplier && (
                <TableDataCell
                  borderRight={hasSupplierBorderRight}
                  maxWidth="max-w-[12ch]"
                  style={minWidthStyles}
                >
                  {product.supplier ?? "--"}
                </TableDataCell>
              )}
              {hasTags && (
                <TableDataCell
                  borderRight={hasTagsBorderRight}
                  onClick={(e) => e.stopPropagation()}
                  style={tagWidthStyles}
                >
                  <ProductTagTableCell
                    key={product.id}
                    numberOfTagsToShow={3}
                    productId={product.id}
                    productName={product.product_name}
                    refetchFn={refetchProducts}
                    tags={product.tags}
                  />
                </TableDataCell>
              )}
              {hasListPrice && (
                <TableDataCell
                  borderRight={hasListPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.list_price)}
                </TableDataCell>
              )}
              {hasDiscountedPrice && (
                <TableDataCell
                  borderRight={hasDiscountedPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.discounted_price)}
                </TableDataCell>
              )}
              {hasMemberPrice && (
                <TableDataCell
                  borderRight={hasMemberPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.member_price)}
                </TableDataCell>
              )}
              {hasSubscriberPrice && (
                <TableDataCell
                  borderRight={hasSubscriberPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.subscriber_price)}
                </TableDataCell>
              )}
              {hasInventory && (
                <TableDataCell
                  borderRight={hasInventoryBorderRight}
                  style={minWidthStyles}
                >
                  {formatNumberRounded(product.inventory_level)}
                </TableDataCell>
              )}
              {hasListPriceMargin && (
                <TableDataCell
                  borderRight={hasListPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.margin)}
                </TableDataCell>
              )}
              {hasListPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasListPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.contribution_margin)}
                </TableDataCell>
              )}
              {hasDiscountedPriceMargin && (
                <TableDataCell
                  borderRight={hasDiscountedPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.discounted_price_margin)}
                </TableDataCell>
              )}
              {hasDiscountedPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasDiscountedPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.discounted_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasMemberPriceMargin && (
                <TableDataCell
                  borderRight={hasMemberPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.member_margin)}
                </TableDataCell>
              )}
              {/* // TODO-[MIKE]: contribution margin - catalog */}
              {hasMemberPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasMemberPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.member_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasSubscriberPriceMargin && (
                <TableDataCell
                  borderRight={hasSubscriberPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.subscriber_price_margin)}
                </TableDataCell>
              )}
              {hasSubscriberPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasSubscriberPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.subscriber_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasCost && (
                <TableDataCell
                  borderRight={hasCostBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.cpu)}
                </TableDataCell>
              )}
              {hasLastThirtyPrice && (
                <TableDataCell
                  borderRight={hasLastThirtyPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.last_thirty_avg_final_price_paid)}
                </TableDataCell>
              )}
              {hasLastThirtyUnitsSold && (
                <TableDataCell
                  borderRight={hasLastThirtyUnitsSoldBorderRight}
                  style={minWidthStyles}
                >
                  {formatNumberRounded(product.last_thirty_units)}
                </TableDataCell>
              )}
              {hasLastThirtyRevenue && (
                <TableDataCell
                  borderRight={hasLastThirtyRevenueBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.last_thirty_revenue)}
                </TableDataCell>
              )}
              {hasLastThirtyProfit && (
                <TableDataCell
                  borderRight={hasLastThirtyProfitBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.last_thirty_profit)}
                </TableDataCell>
              )}
              {hasLastPricePlan && (
                <TableDataCell style={minWidthStyles}>
                  {product.most_recent_price_plan_date == null ? (
                    "--"
                  ) : (
                    <a
                      className="underline hover:font-bold"
                      href={`${getAppUrlForClientLink()}/price-plan/${
                        product.most_recent_price_plan_id
                      }/${product.most_recent_group_id}`}
                      onClick={(e) => e.stopPropagation()}
                      target="_blank"
                    >
                      {formatDate(product.most_recent_price_plan_date)}
                    </a>
                  )}
                </TableDataCell>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
