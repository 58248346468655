import React from "react";
import { cn } from "src/frontend/components/ui/utils";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type RowProps = HtmlDivProps &
  React.HtmlHTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    collapseOnLarge?: boolean;
    collapseOnMedium?: boolean;
    collapseOnMobile?: boolean;
  };

const Row = React.forwardRef<HTMLDivElement, RowProps>(
  (
    {
      children,
      className,
      collapseOnLarge,
      collapseOnMedium,
      collapseOnMobile,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          "flex flex-row items-center",
          collapseOnMobile &&
            "flex-col items-start lg:flex-row lg:items-center",
          collapseOnMedium &&
            "flex-col items-start xl:flex-row xl:items-center",
          collapseOnLarge &&
            "flex-col items-start 3xl:flex-row 3xl:items-center",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  },
);
Row.displayName = "Row";

export default Row;
