import getCsvTitle from "src/shared/utils/csv/getCsvTitle";

export default function downloadCsv(csv: string, csvTitle: string): void {
  const title = getCsvTitle(csvTitle);
  const blob = new Blob(["\uFEFF" + csv], { type: "text/csv; charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = title;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
