import { Tag } from "src/shared/trpc/common/Tag";
import { z } from "zod";

export const ProductTag = Tag.and(
  z.object({
    user_tagged: z.boolean(),
  }),
);

export type ProductTagType = z.infer<typeof ProductTag>;
