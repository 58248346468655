import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";

const CONFIDENCE_SCORE_COLOR_SWATCH = [
  "#E9E9E9",
  "#FC7C7C",
  "#FF957D",
  "#FCAA7C",
  "#FCC17C",
  "#FCD87C",
  "#FCEF7C",
  "#E9EC69",
  "#A7EE70",
  "#6CDC37",
  "#45D02F",
];

export default function ConfidenceScore(props: { confidence: MaybeNumber }) {
  const isDarkTheme = useIsDarkTheme();
  if (props.confidence == null) {
    return <>--</>;
  }

  const confidence = Math.round(props.confidence);
  const confidenceScoreColor = CONFIDENCE_SCORE_COLOR_SWATCH[confidence];
  const emptyConfidenceColor = isDarkTheme ? "#35373c" : "#E9E9E9";

  return (
    <div className="flex gap-0.5">
      {Array.from({ length: 10 })
        .fill("")
        .map((_, index) => {
          return (
            <div
              className="h-5 w-2 opacity-75"
              key={index}
              style={{
                backgroundColor:
                  index < confidence
                    ? confidenceScoreColor
                    : emptyConfidenceColor,
              }}
            />
          );
        })}
    </div>
  );
}
