import LocalStorageKey from "src/frontend/constants/LocalStorageKey";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type LucaAdminUserSettings = {
  view_as_company_user: boolean;
};

const defaultState: LucaAdminUserSettings = {
  view_as_company_user: false,
};

const useLucaAdminSettingsStore = create<LucaAdminUserSettings>()(
  persist(() => defaultState, {
    name: LocalStorageKey.LucaAdminSettings,
  }),
);

export default useLucaAdminSettingsStore;
