import { GenericTrpcResponse } from "src/shared/trpc/common/GenericTrpcResponse";
import { z } from "zod";

export const UploadPricePlanMutationInput = z.object({
  new_discounted_price: z.coerce.number().optional(),
  new_list_price: z.coerce.number(),
  new_member_price: z.coerce.number().optional(),
  new_subscriber_price: z.coerce.number().optional(),
  old_discounted_price: z.coerce.number().optional(),
  old_list_price: z.coerce.number().optional(),
  old_member_price: z.coerce.number().optional(),
  old_subscriber_price: z.coerce.number().optional(),
  price_plan_id: z.string().optional(),
  sku: z.string().optional(),
});

export type UploadPricePlanMutationInputType = z.infer<
  typeof UploadPricePlanMutationInput
>;

export const UploadPricePlanMutationResponse = GenericTrpcResponse;

export type UploadPricePlanMutationResponseType = z.infer<
  typeof UploadPricePlanMutationResponse
>;
