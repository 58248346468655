import React from "react";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";

type MaxWidthContainerProps = HtmlDivProps & {
  children: React.ReactNode;
};

export default function MaxWidthContainer(props: MaxWidthContainerProps) {
  const { children, ...rest } = props;
  return (
    <div className="max-w-[1150px]" {...rest}>
      {children}
    </div>
  );
}
