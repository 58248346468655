import { GetHomePageResponseType } from "src/backend/internal-api/queries/getHomePageQuery";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import Spacer from "src/frontend/components/Spacer";
import SummaryMetricsContainer from "src/frontend/components/SummaryMetricsContainer";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import BrandSalesChart from "src/frontend/pages/home/BrandSalesChart";
import Scorecard from "src/frontend/pages/home/Scorecard";
import UpcomingSchedule from "src/frontend/pages/home/UpcomingSchedule";
import useBrandStore from "src/frontend/stores/useBrandStore";

function HomePageTitle() {
  const brand = useBrandStore();
  return <PageTitle>{brand.brand_name} Home</PageTitle>;
}

type HomePageComponentProps = {
  data: GetHomePageResponseType;
};

function HomePageComponent({ data }: HomePageComponentProps) {
  return (
    <PageContainer enforceMaxWidth>
      <HomePageTitle />
      <Spacer className="h-8" />
      <Col className="gap-6">
        <SummaryMetricsContainer innerDivClassName="justify-start mb-0">
          <Scorecard
            brand_scorecard={data.brand_scorecard}
            company_scorecard={data.company_scorecard}
          />
          <UpcomingSchedule schedule={data.schedule} />
        </SummaryMetricsContainer>
        <BrandSalesChart
          pricePlanLaunchDates={data.price_plan_launches}
          sales={data.brand_sales}
        />
      </Col>
    </PageContainer>
  );
}

function HomePageLoadingSkeleton() {
  return (
    <PageContainer enforceMaxWidth>
      <HomePageTitle />
      <Spacer className="h-8" />
      <Col className="gap-6">
        <SummaryMetricsContainer innerDivClassName="justify-start mb-0">
          <Skeleton className="h-[272px] w-full md:w-1/2" />
          <Skeleton className="h-[272px] w-full md:w-1/2" />
        </SummaryMetricsContainer>
        <Skeleton className="h-[392px] w-auto" />
      </Col>
    </PageContainer>
  );
}

function HomePageErrorComponent() {
  return (
    <PageContainer enforceMaxWidth>
      <HomePageTitle />
      <Spacer className="h-8" />
      <p>Error loading home page data...</p>
    </PageContainer>
  );
}

export default function HomePage() {
  const { brandId, enabled } = useIsBrandInitialized();
  const homePageQuery = TrpcClient.internal.getHomePage.useQuery(
    { brandId },
    { enabled, refetchOnMount: false },
  );

  if (homePageQuery.isLoading) {
    return <HomePageLoadingSkeleton />;
  } else if (homePageQuery.isError) {
    return <HomePageErrorComponent />;
  }

  return <HomePageComponent data={homePageQuery.data} />;
}
