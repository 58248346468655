import { useEffect, useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import { RadioGroupOptions } from "src/frontend/components/ui/RadioGroup";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useGetUserBrandsQuery from "src/frontend/hooks/queries/useGetUserBrandsQuery";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import useBrandStore from "src/frontend/stores/useBrandStore";
import findBrandById from "src/frontend/utils/findBrandById";
import { PricePlanApprovalType } from "src/shared/trpc/common/enum/PricePlanApprovalType";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

const options: PricePlanApprovalType[] = [
  "AUTO_APPROVE",
  "MANUAL_APPROVE",
  "MANUAL_CONFIRM",
];

function getRadioLabel(approvalType: PricePlanApprovalType): {
  description: string;
  label: string;
} {
  switch (approvalType) {
    case "AUTO_APPROVE":
      return {
        description:
          "All new price plans will be automatically approved on Monday of the following week at 8 am PST.",
        label: "Auto Approve",
      };
    case "MANUAL_APPROVE":
      return {
        description:
          "All new price plans must be approved by a user on your account.",
        label: "Manual Approve",
      };
    case "MANUAL_CONFIRM":
      return {
        description:
          "All new price plans will be downloaded from Luca’s dashboard and manually uploaded to your system (not recommended).",
        label: "Manual Confirm",
      };
    default:
      return assertUnreachable(approvalType);
  }
}

type InnerProps = {
  brandApprovalType: PricePlanApprovalType;
  isBrandAdmin: boolean;
};

function Inner({ brandApprovalType, isBrandAdmin }: InnerProps) {
  const { errorToast, successToast } = useToast();
  const brandsQuery = useGetUserBrandsQuery();
  const [approvalType, setApprovalType] = useState(brandApprovalType);
  const updatePriceApprovalTypeMutation =
    TrpcClient.internal.updateBrandPricePlanApprovalType.useMutation();

  useEffect(() => {
    setApprovalType(brandApprovalType);
  }, [brandApprovalType]);

  const handleSetApprovalType = (type: PricePlanApprovalType) => {
    updatePriceApprovalTypeMutation.mutate(
      {
        approvalType: type,
      },
      {
        onError: () => {
          errorToast("Failed to update approval setting.");
        },
        onSuccess: () => {
          void brandsQuery.refetch();
          successToast(
            "Updated brand approval setting. This will apply to all existing and future price plans.",
          );
          setApprovalType(type);
        },
      },
    );
  };

  return (
    <RadioGroupOptions
      disabled={
        !isBrandAdmin ||
        brandsQuery.isLoading ||
        updatePriceApprovalTypeMutation.isLoading
      }
      onValueChange={(val: PricePlanApprovalType) => handleSetApprovalType(val)}
      options={options.map((value) => {
        const { description, label } = getRadioLabel(value);
        return {
          label: (isSelected: boolean) => {
            return (
              <p>
                <span className={cn(isSelected && "font-bold")}>{label}</span> -{" "}
                <span>{description}</span>
              </p>
            );
          },
          value,
        };
      })}
      value={approvalType}
    />
  );
}

export default function BrandApprovalSettings() {
  const brandId = useBrandStore((state) => state.brand_id);
  const isBrandAdmin = useIsBrandAdmin();
  const brandsQuery = useGetUserBrandsQuery();

  if (brandsQuery.isLoading) {
    return (
      <Col className="gap-2">
        <Skeleton className="h-4 w-64" />
        <Skeleton className="h-4 w-64" />
        <Skeleton className="h-4 w-64" />
      </Col>
    );
  }

  if (brandsQuery.isError) {
    return (
      <div>
        <p>Failed to load brand approval settings.</p>
      </div>
    );
  }

  const brand = findBrandById(brandsQuery.data ?? [], brandId);

  return (
    <Inner
      brandApprovalType={brand.price_plan_approval_type}
      isBrandAdmin={isBrandAdmin}
    />
  );
}
