import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import ALL_SKUS_FANCY_BOX_ITEM from "src/frontend/constants/AllSkusFancyBoxItem";
import {
  INCLUDE_EMPTY_CATEGORY_ITEMS,
  INCLUDE_EMPTY_PRICE_ZONE_ITEMS,
  INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS,
  INCLUDE_EMPTY_SUB_CATEGORY_ITEMS,
  INCLUDE_EMPTY_SUPPLIER_ITEMS,
  INCLUDE_UNTAGGED_PRODUCTS_ITEM,
} from "src/frontend/constants/CustomProductFilters";
import ProductCategoryFilterType from "src/frontend/types/ProductCategoryFilterType";
import brandProductCategoryToFancyBoxItem from "src/frontend/utils/brandProductCategoryToFancyBoxItem";
import tagToFancyBoxItem from "src/frontend/utils/tagToFancyBoxItem";
import { PricingRulesGroupType } from "src/shared/trpc/common/PricingRulesGroup";
import { TagType } from "src/shared/trpc/common/Tag";

type Args = {
  categories: string[];
  includeAllSkusOption?: boolean;
  priceZones: string[];
  productBrandNames: string[];
  rulesGroup?: PricingRulesGroupType;
  subCategories: string[];
  suppliers: string[];
  tags: TagType[];
};

export default function getInitialCategoryFilters({
  categories,
  includeAllSkusOption = false,
  priceZones,
  productBrandNames,
  rulesGroup,
  subCategories,
  suppliers,
  tags,
}: Args): FancyBoxItem[] {
  const base =
    rulesGroup?.all_skus ?? includeAllSkusOption
      ? [ALL_SKUS_FANCY_BOX_ITEM]
      : [];

  const {
    include_empty_category = false,
    include_empty_price_zone = false,
    include_empty_product_brand_name = false,
    include_empty_sub_category = false,
    include_empty_supplier = false,
    include_untagged_skus = false,
  } = rulesGroup ?? {};

  if (include_empty_category) {
    base.push(INCLUDE_EMPTY_CATEGORY_ITEMS);
  }

  if (include_empty_sub_category) {
    base.push(INCLUDE_EMPTY_SUB_CATEGORY_ITEMS);
  }

  if (include_empty_supplier) {
    base.push(INCLUDE_EMPTY_SUPPLIER_ITEMS);
  }

  if (include_empty_product_brand_name) {
    base.push(INCLUDE_EMPTY_PRODUCT_BRAND_NAME_ITEMS);
  }

  if (include_empty_price_zone) {
    base.push(INCLUDE_EMPTY_PRICE_ZONE_ITEMS);
  }

  if (include_untagged_skus) {
    base.push(INCLUDE_UNTAGGED_PRODUCTS_ITEM);
  }

  return base.concat([
    ...tags.map((tag) => tagToFancyBoxItem(tag)),
    ...categories.map((val) =>
      brandProductCategoryToFancyBoxItem(
        val,
        ProductCategoryFilterType.Category,
      ),
    ),
    ...subCategories.map((val) =>
      brandProductCategoryToFancyBoxItem(
        val,
        ProductCategoryFilterType.Subcategory,
      ),
    ),
    ...productBrandNames.map((val) =>
      brandProductCategoryToFancyBoxItem(
        val,
        ProductCategoryFilterType.ProductBrandName,
      ),
    ),
    ...suppliers.map((val) =>
      brandProductCategoryToFancyBoxItem(
        val,
        ProductCategoryFilterType.Supplier,
      ),
    ),
    ...priceZones.map((val) =>
      brandProductCategoryToFancyBoxItem(
        val,
        ProductCategoryFilterType.PriceZone,
      ),
    ),
  ]);
}
