import { z } from "zod";

export const ExperimentPriceBounds = z.object({
  discounted_price_max: z.number().nullable(),
  discounted_price_min: z.number().nullable(),
  list_price_max: z.number().nullable(),
  list_price_min: z.number().nullable(),
  member_price_max: z.number().nullable(),
  member_price_min: z.number().nullable(),
  subscriber_price_max: z.number().nullable(),
  subscriber_price_min: z.number().nullable(),
});

export type ExperimentPriceBoundsType = z.infer<typeof ExperimentPriceBounds>;
