import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import useAdminActionsAvailable from "src/frontend/hooks/useAdminActionsAvailable";
import useBrandStore from "src/frontend/stores/useBrandStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import invariant from "tiny-invariant";

type PublishInternalDraftPricePlanModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PublishInternalDraftPricePlanModal({
  isModalOpen,
  setIsModalOpen,
}: PublishInternalDraftPricePlanModalProps) {
  const adminActionsAvailable = useAdminActionsAvailable();
  const [deleteInternalDraftPricePlans, setDeleteInternalDraftPricePlans] =
    useState(false);
  const brandName = useBrandStore((state) => state.brand_name);
  const pricePlansQuery = usePricePlanQuery();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const navigate = useNavigate();
  const { errorToast, successToast } = useToast();
  const publishInternalDraftPricePlanMutation =
    TrpcClient.admin.publishInternalDraftPricePlan.useMutation();

  if (!adminActionsAvailable) {
    return null;
  }

  return (
    <Modal
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            The current <Code>INTERNAL_DRAFT</Code> price plan status will be
            updated to <Code>DRAFT</Code>.
          </p>
          <p>
            Slack and email notifications will also be sent to {brandName} users
            that a new price plan is available for review.
          </p>
          <Checkbox
            checked={deleteInternalDraftPricePlans}
            id="delete-internal-drafts-checkbox"
            label={
              <span>
                Delete other <Code>INTERNAL_DRAFT</Code> price plans?
              </span>
            }
            onCheckedChange={(_, checked) =>
              setDeleteInternalDraftPricePlans(checked)
            }
          />
        </div>
      }
      dialogTitle="Publish Price Plan"
      loading={publishInternalDraftPricePlanMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null);
        publishInternalDraftPricePlanMutation.mutate(
          {
            deleteInternalDraftPricePlans,
            pricePlanId,
          },
          {
            onError: () => {
              errorToast("Failed to publish price plan.");
            },
            onSuccess: () => {
              setIsModalOpen(false);
              successToast("Price plan published successfully!");
              void pricePlansQuery.refetch();
              navigate("/pricing/draft", { replace: true });
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
