import { CustomerSegment } from "src/shared/trpc/common/enum/CustomerSegment";
import { z } from "zod";

export const BaseOpportunitiesMetrics = z.object({
  average_unit_price_paid_last_thirty: z.number().nullable(),
  brand_margin_impact_percent: z.number().nullable(),
  brand_new_margin: z.number().nullable(),
  brand_old_margin: z.number().nullable(),
  brand_profit_change_percent_projected: z.number().nullable(),
  brand_profit_change_projected: z.number().nullable(),
  brand_profit_last_thirty: z.number().nullable(),
  brand_profit_next_thirty: z.number().nullable(),
  brand_revenue_change_percent_projected: z.number().nullable(),
  brand_revenue_change_projected: z.number().nullable(),
  brand_revenue_last_thirty: z.number().nullable(),
  brand_revenue_next_thirty: z.number().nullable(),
  brand_units_sold_change_percent_projected: z.number().nullable(),
  brand_units_sold_change_projected: z.number().nullable(),
  brand_units_sold_last_thirty: z.number().nullable(),
  brand_units_sold_next_thirty: z.number().nullable(),
  control_price_margin: z.number().nullable(),
  customer_segment: CustomerSegment.nullable(),
  experiment_id: z.string().nullish(),
  impacted_skus: z.number().nullable(),
  margin_impact_percent: z.number().nullable(),
  new_margin_lower_bound: z.number().nullable(),
  new_margin_upper_bound: z.number().nullable(),
  new_profit_lower_bound: z.number().nullable(),
  new_profit_upper_bound: z.number().nullable(),
  new_quantity_lower_bound: z.number().nullable(),
  new_quantity_upper_bound: z.number().nullable(),
  new_revenue_lower_bound: z.number().nullable(),
  new_revenue_upper_bound: z.number().nullable(),
  profit_change_percent_projected: z.number().nullable(),
  profit_change_projected: z.number().nullable(),
  profit_last_thirty: z.number().nullable(),
  profit_next_thirty: z.number().nullable(),
  revenue_change_percent_projected: z.number().nullable(),
  revenue_change_projected: z.number().nullable(),
  revenue_last_thirty: z.number().nullable(),
  revenue_next_thirty: z.number().nullable(),
  treatment_price_margin: z.number().nullable(),
  units_sold_change_percent_projected: z.number().nullable(),
  units_sold_change_projected: z.number().nullable(),
  units_sold_last_thirty: z.number().nullable(),
  units_sold_next_thirty: z.number().nullable(),
});

export type BaseOpportunitiesMetricsType = z.infer<
  typeof BaseOpportunitiesMetrics
>;
