import { useState } from "react";
import DatePicker, { DateValueType } from "react-tailwindcss-datepicker";
import TestComponentHeading from "src/frontend/components/test-components/TestComponentHeading";
import useFlags from "src/frontend/hooks/useFlags";

type TestDatePickerProps = {
  hide?: boolean;
};

// https://react-tailwindcss-datepicker.vercel.app/
export default function TestDatePicker({ hide = false }: TestDatePickerProps) {
  const { devFeaturesEnabled } = useFlags();
  const [value, setValue] = useState<DateValueType>(null);

  const handleValueChange = (val: DateValueType) => {
    if (val == null) {
      return;
    }

    console.info(
      `Date range updated, new range: ${val.startDate?.toLocaleString()} to ${val.endDate?.toLocaleString()}`,
    );

    setValue(val);
  };

  if (!devFeaturesEnabled || hide) {
    return null;
  }

  return (
    <div>
      <TestComponentHeading>Date Picker</TestComponentHeading>
      <div className="w-[350px]">
        <DatePicker
          inputId="date-picker"
          onChange={handleValueChange}
          value={value}
        />
      </div>
    </div>
  );
}
