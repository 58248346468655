import React, { useState } from "react";
import apiClient from "src/frontend/api/ApiClient";
import Col from "src/frontend/components/Col";
import Button from "src/frontend/components/ui/Button";
import useToast from "src/frontend/components/ui/useToast";

export default function TestRestApiComponent() {
  const t = useToast();
  const [loading, setLoading] = useState(false);
  const handleRequest = async () => {
    try {
      setLoading(true);
      t.infoToast("Sending request...");
      const response = await apiClient.placeholderApi({
        message: "Hello!!!",
      });
      setLoading(false);
      t.successToast({
        description: (
          <Col className="gap-1">
            <p className="font-semibold">Response Message:</p>
            <p>{response.message}</p>
          </Col>
        ),
        title: "Request Successful",
      });
    } catch (err: any) {
      setLoading(false);
      t.errorToast({
        description: (
          <Col className="gap-2">
            <p className="font-semibold">Error:</p>
            <p>{err.message ?? err}</p>
          </Col>
        ),
        title: "Request Failed",
      });
    }
  };
  return (
    <div>
      <Button
        className="mt-6 w-fit"
        disabled={loading}
        onClick={() => void handleRequest()}
        variant="basic"
      >
        Test REST API
      </Button>
    </div>
  );
}
