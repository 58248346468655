import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import { create } from "zustand";

export type LocationHistoryStore = {
  history: string[];
  pricePlanScrollPosition: MaybeNumber;
};

const defaultState: LocationHistoryStore = {
  history: [],
  pricePlanScrollPosition: null,
};

const useLocationHistoryStore = create<LocationHistoryStore>(
  () => defaultState,
);

export default useLocationHistoryStore;
