import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  CompetitorPriceLookbackPeriodType,
  CompetitorPriceLookbackPeriodVariants,
} from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";

type CompetitorIntelligencePriceTimeRangeSelectProps = {
  loading: boolean;
};

export default function CompetitorIntelligencePriceTimeRangeSelect({
  loading,
}: CompetitorIntelligencePriceTimeRangeSelectProps) {
  const recentPriceOption = useCompetitorIntelligenceFiltersStore(
    (state) => state.recentPriceOption,
  );
  return (
    <div className="w-36">
      <Select
        disabled={loading}
        onValueChange={(value: CompetitorPriceLookbackPeriodType) => {
          useCompetitorIntelligenceFiltersStore.setState({
            recentPriceOption: value,
          });
        }}
        value={recentPriceOption}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select an objective" />
        </SelectTrigger>
        <SelectContent
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {CompetitorPriceLookbackPeriodVariants.map((opt) => {
            return (
              <SelectItem key={opt} value={opt}>
                {formatGenericEnumLabel(opt)}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
}
