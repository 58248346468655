import {
  ProductMasterObject,
  ProductMasterObjectType,
} from "src/shared/trpc/common/ProductMasterObject";
import unionOfLiterals from "src/shared/utils/unionOfLiterals";
import { z } from "zod";

type ProductMasterKeys = keyof ProductMasterObjectType;

export const ProductMasterSortKey = unionOfLiterals<ProductMasterKeys>(
  Object.keys(ProductMasterObject.shape) as ProductMasterKeys[],
);

export type ProductMasterObjectSortKeyType = z.infer<
  typeof ProductMasterSortKey
>;
