import { IconPencil } from "@tabler/icons-react";
import { useState } from "react";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import Button from "src/frontend/components/ui/Button";
import { Input } from "src/frontend/components/ui/Input";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import MaybeString from "src/shared/types/maybe/MaybeString";

type EditTextProps = {
  className?: ClassNameProp;
  disableEditing?: boolean;
  disabled?: boolean;
  errorMessage?: MaybeString;
  inputClassName?: ClassNameProp;
  isSaving?: boolean;
  onSave: (value: string) => void;
  placeholder?: string;
  value: string;
};

export default function EditText({
  className,
  disableEditing = false,
  disabled = false,
  errorMessage,
  inputClassName,
  isSaving = false,
  onSave,
  placeholder,
  value,
}: EditTextProps) {
  const t = useToast();
  const [editValue, setEditValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const saveChanges = () => {
    if (editValue === "") {
      t.warningToast("Cannot save an empty value.");
      return;
    }
    setIsEditing(false);
    if (editValue !== value) {
      onSave(editValue);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      saveChanges();
    } else if (e.code === "Escape") {
      setIsEditing(false);
    }
  };

  return (
    <Col>
      <Row className={cn(className)}>
        {isEditing ? (
          <Input
            autoFocus
            className={cn(inputClassName)}
            disabled={disabled || isSaving}
            onBlur={saveChanges}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={handleOnKeyDown}
            placeholder={placeholder}
            value={editValue}
          />
        ) : isSaving ? (
          <p className="text-base font-normal text-muted-foreground">
            Saving...
          </p>
        ) : (
          <p
            className={cn(
              !disableEditing && "hover:cursor-pointer",
              value === "" && "text-muted",
            )}
            onClick={() => {
              if (!disableEditing) {
                setIsEditing(true);
                setEditValue(value);
              }
            }}
          >
            {value === "" ? placeholder : value}
          </p>
        )}
        {!isEditing && !disableEditing && (
          <Button
            className="ml-[2px] hover:rounded-full"
            disabled={disabled || isSaving}
            onClick={() => {
              setIsEditing(true);
              setEditValue(value);
            }}
            size="icon"
            variant="ghost"
          >
            <IconPencil size={20} />
          </Button>
        )}
      </Row>
      {errorMessage != null && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Col>
  );
}
