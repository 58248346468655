import Code from "src/frontend/components/Code";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import copyTextToClipboard from "src/frontend/utils/copyTextToClipboard";
import MaybeString from "src/shared/types/maybe/MaybeString";

type CopyTextProps = HtmlDivProps & {
  children: React.ReactNode;
  disabled?: boolean;
  includeTooltip?: boolean;
  isCode?: boolean;
  textToCopy?: MaybeString;
};

export function CopyText(props: CopyTextProps) {
  const { successToast } = useToast();
  const { children, disabled, includeTooltip, isCode, textToCopy, ...rest } =
    props;

  const handleCopy = () => {
    if (textToCopy != null && !disabled) {
      void copyTextToClipboard(textToCopy);
      if (isCode) {
        successToast(
          <span>
            Copied <Code>{textToCopy}</Code> to clipboard.
          </span>,
        );
      } else {
        successToast(`Copied '${textToCopy}' to clipboard.`);
      }
    }
  };

  const Content = (
    <span
      className="hover:cursor-pointer"
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        handleCopy();
      }}
    >
      {children}
    </span>
  );

  if (includeTooltip && textToCopy != null) {
    return (
      <Tooltip
        content={
          <p>
            Click to copy: {isCode ? <Code>{textToCopy}</Code> : textToCopy}
          </p>
        }
      >
        {Content}
      </Tooltip>
    );
  }

  return Content;
}
