import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { Maybe } from "src/shared/types/maybe/Maybe";
import MaybeString from "src/shared/types/maybe/MaybeString";

type PriceBoundsLike = {
  bound_type?: Maybe<PriceBoundType>;
  lower_bound?: Maybe<number>;
  upper_bound?: Maybe<number>;
};

export default function getGenericPriceBoundsErrorMessage<
  T extends PriceBoundsLike,
>(constraint: T, allowEmpty = false): MaybeString {
  const { bound_type, lower_bound, upper_bound } = constraint;

  if (lower_bound != null && upper_bound != null) {
    if (lower_bound > upper_bound) {
      return "Lower bound cannot be greater than upper bound";
    }
  }

  if (lower_bound == null && upper_bound == null && !allowEmpty) {
    return "At least one bound must be defined";
  }

  if (lower_bound != null && bound_type == null) {
    return "Comparison type must be defined";
  }

  if (upper_bound != null && bound_type == null) {
    return "Comparison type must be defined";
  }

  return null;
}
