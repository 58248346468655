import { BrandResultsMetrics } from "src/shared/trpc/common/BrandResultsMetrics";
import { MarginResultsMetrics } from "src/shared/trpc/common/MarginResultsMetrics";
import { z } from "zod";

export const BaseResultsMetricsWithMargin =
  BrandResultsMetrics.merge(MarginResultsMetrics);

export type BaseResultsMetricsWithMarginResponseType = z.infer<
  typeof BaseResultsMetricsWithMargin
>;
