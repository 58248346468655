import { useLocation } from "react-router";

export const usePricePlanRoute = () => {
  const location = useLocation();
  const path = location.pathname;
  if (path.startsWith("/pricing")) {
    return path.split("/")[1];
  }
  return null;
};
