import { ExperimentPriceEditType } from "src/backend/internal-api/mutations/editPricePlanExperimentsMutation";
import { TreatmentPriceFieldsType } from "src/shared/trpc/common/TreatmentPriceFields";
import { TreatmentPriceInputFieldsType } from "src/shared/trpc/common/TreatmentPriceInputFields";
import { GetExperimentForecastQueryResponseType } from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import MaybeString from "src/shared/types/maybe/MaybeString";
import groupByUniqueValues from "src/shared/utils/arrays/groupByUniqueValues";
import filterMaybeValuesFromObject from "src/shared/utils/objects/filterMaybeValuesFromObject";
import invariant from "tiny-invariant";
import { create } from "zustand";

export type ExperimentPriceEditInfo = TreatmentPriceInputFieldsType & {
  treatment_discounted_price_reverted: boolean;
  treatment_list_price_reverted: boolean;
  treatment_member_price_reverted: boolean;
  treatment_subscriber_price_reverted: boolean;
};

type StoreState = {
  groupId: MaybeString;
  priceEdits: Record<string, ExperimentPriceEditInfo>;
};

type StoreActions = {
  getEditedPrices: (
    experiments: GetExperimentForecastQueryResponseType[],
  ) => ExperimentPriceEditType[];
  reset: () => void;
  setInitialState: (
    experiments: GetExperimentForecastQueryResponseType[],
    groupId: string,
    revertedExperimentState?: {
      experimentId: string;
      priceField: keyof TreatmentPriceFieldsType;
    },
  ) => void;
};

const defaultState: StoreState = {
  groupId: null,
  priceEdits: {},
};

function experimentHasPriceEdits(
  experiment: GetExperimentForecastQueryResponseType,
  priceEdits: ExperimentPriceEditInfo,
): boolean {
  const {
    final_treatment_discounted_price,
    final_treatment_list_price,
    final_treatment_member_price,
    final_treatment_subscriber_price,
  } = experiment;
  const {
    treatment_discounted_price,
    treatment_list_price,
    treatment_member_price,
    treatment_subscriber_price,
  } = priceEdits;
  return (
    (final_treatment_list_price != null &&
      final_treatment_list_price !== treatment_list_price) ||
    (final_treatment_member_price != null &&
      final_treatment_member_price !== treatment_member_price) ||
    (final_treatment_discounted_price != null &&
      final_treatment_discounted_price !== treatment_discounted_price) ||
    (final_treatment_subscriber_price != null &&
      final_treatment_subscriber_price !== treatment_subscriber_price)
  );
}

const useEditPricePlanStore = create<StoreActions & StoreState>((set, get) => ({
  ...defaultState,
  getEditedPrices: (experiments) => {
    const state = get();
    const edits = Object.entries(state.priceEdits);
    const experimentMap = groupByUniqueValues(experiments, "experiment_id");
    const result: ExperimentPriceEditType[] = [];
    for (const [experimentId, priceEdits] of edits) {
      const exp = experimentMap[experimentId];
      if (exp != null && experimentHasPriceEdits(exp, priceEdits)) {
        result.push({
          experiment_id: experimentId,
          prices: filterMaybeValuesFromObject(priceEdits),
        });
      }
    }
    return result;
  },
  reset: () => set(defaultState),
  setInitialState: (experiments, groupId, revertedExperimentState) => {
    const state = get();
    const priceEdits = experiments.reduce(
      (result: Record<string, ExperimentPriceEditInfo>, exp) => {
        if (revertedExperimentState != null) {
          const {
            experimentId: revertedExperimentId,
            priceField: revertExperimentPriceField,
          } = revertedExperimentState;
          const priorState = state.priceEdits[exp.experiment_id];
          if (exp.experiment_id === revertedExperimentId) {
            const price_key = revertExperimentPriceField;
            const price_reverted_key = `${revertExperimentPriceField}_reverted`;
            invariant(
              price_key in priorState,
              `${price_key} must exist in the price edit state.`,
            );
            invariant(
              price_reverted_key in priorState,
              `${price_reverted_key} must exist in the price edit state.`,
            );
            result[exp.experiment_id] = {
              ...priorState,
              [price_key]: exp[price_key] ?? undefined,
              [price_reverted_key]: false,
            };
          } else {
            result[exp.experiment_id] = priorState;
          }
        } else {
          const {
            final_treatment_discounted_price,
            final_treatment_list_price,
            final_treatment_member_price,
            final_treatment_subscriber_price,
          } = exp;
          result[exp.experiment_id] = {
            treatment_discounted_price:
              final_treatment_discounted_price ?? undefined,
            treatment_discounted_price_reverted: false,
            treatment_list_price: final_treatment_list_price ?? undefined,
            treatment_list_price_reverted: false,
            treatment_member_price: final_treatment_member_price ?? undefined,
            treatment_member_price_reverted: false,
            treatment_subscriber_price:
              final_treatment_subscriber_price ?? undefined,
            treatment_subscriber_price_reverted: false,
          };
        }

        return result;
      },
      {},
    );
    set({
      groupId,
      priceEdits,
    });
  },
}));

export default useEditPricePlanStore;
