import { Cross2Icon } from "@radix-ui/react-icons";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import FancyBoxLabel from "src/frontend/components/ui/FancyBoxLabel";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import getFancyBoxItemBadgeStyle from "src/frontend/utils/getFancyBoxItemBadgeStyle";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";

type FancyBoxSelectedItemsListProps = HtmlDivProps & {
  onClickRemove?: (item: FancyBoxItem) => void;
  renderDefaultColor?: boolean;
  renderLabel?: (item: FancyBoxItem) => JSX.Element;
  selectedItems: FancyBoxItem[];
  showClearFiltersButton?: boolean;
};

export default function FancyBoxSelectedItemsList({
  className,
  onClickRemove,
  renderDefaultColor,
  renderLabel,
  selectedItems,
  showClearFiltersButton,
  ...rest
}: FancyBoxSelectedItemsListProps) {
  return (
    <div className={className} {...rest}>
      {selectedItems.map((item) => {
        const { color, isLucaTag = false, value } = item;
        const shouldRenderColor =
          isLucaTag || (color != null && !renderDefaultColor);
        return (
          <Badge
            className="mb-2 mr-2 max-w-[175px] p-1 px-2"
            key={value}
            style={shouldRenderColor ? getFancyBoxItemBadgeStyle(color) : {}}
            variant={shouldRenderColor ? "outline" : "default"}
          >
            {renderLabel != null ? (
              renderLabel(item)
            ) : (
              <FancyBoxLabel content={item.label} />
            )}
            {onClickRemove != null && (
              <button
                className="ml-1 rounded-full hover:text-foreground"
                onClick={() => onClickRemove(item)}
              >
                <Cross2Icon />
              </button>
            )}
          </Badge>
        );
      })}
      {showClearFiltersButton &&
        arrayNotEmpty(selectedItems) &&
        onClickRemove != null && (
          <Button
            onClick={() => {
              selectedItems.forEach((item) => onClickRemove(item));
            }}
            variant="link"
          >
            Clear Filters
          </Button>
        )}
    </div>
  );
}
