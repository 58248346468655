import { IconSparkles } from "@tabler/icons-react";
import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Dialog from "src/frontend/components/ui/Dialog";
import { Textarea } from "src/frontend/components/ui/Textarea";
import useToast from "src/frontend/components/ui/useToast";

export default function FeatureRequestDialog() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const t = useToast();
  const sendSlackProductNotificationMutation =
    TrpcClient.internal.sendSlackProductNotification.useMutation();
  return (
    <Dialog
      confirmTitle="Submit"
      dialogTitle="Feature Request"
      disabled={message === ""}
      loading={sendSlackProductNotificationMutation.isLoading}
      onConfirm={() => {
        const messageContent = `New Feature Request: ${message}`;
        sendSlackProductNotificationMutation.mutate(
          {
            message: messageContent,
          },
          {
            onError: () => {
              t.errorToast("Failed to submit feedback...");
            },
            onSuccess: () => {
              t.successToast("Thanks for your feedback!");
              setMessage("");
              setOpen(false);
            },
          },
        );
      }}
      onOpenChange={setOpen}
      open={open}
      trigger={
        // NOTE: Keep styles in sync with SideBarNavItem.
        <div className="flex h-11 items-center p-2 px-5 hover:cursor-pointer hover:bg-muted hover:bg-opacity-40 dark:bg-background dark:hover:bg-background sm:dark:bg-sidebar">
          <div className="mr-3 flex items-center gap-3">
            <IconSparkles size={20} stroke={1.5} />
            <p className="text-sm">Feature Request</p>
          </div>
        </div>
      }
    >
      <Textarea
        className="max-h-[500px] min-h-[124px]"
        onChange={(event) => setMessage(event.target.value)}
        placeholder="Wish Luca could do something new? Let us know about it!"
        spellCheck={false}
        value={message}
      />
    </Dialog>
  );
}
