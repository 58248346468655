import React from "react";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import getWidthScaleFactor from "src/frontend/utils/getWidthScaleFactor";
import { ConstraintType } from "src/shared/trpc/common/enum/ConstraintType";
import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { PricingStrategyObjectiveConstraintType } from "src/shared/trpc/common/enum/PricingStrategyObjectiveConstraintTypeEnum";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import getFirst from "src/shared/utils/arrays/getFirst";
import getLast from "src/shared/utils/arrays/getLast";
import sortNumerically from "src/shared/utils/arrays/sortNumerically";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import isNumber from "src/shared/utils/numbers/isNumber";

const MAX_LENGTH_DEFAULT = 425;

type CombinedType = ConstraintType | PricingStrategyObjectiveConstraintType;

function shouldEnableBaselineForConstraintBounds(type: CombinedType): boolean {
  switch (type) {
    case "MARGIN":
    case "AVERAGE_MARGIN":
      return false;
    case "ROUNDING":
    case "PRICE_CHANGE":
    case "PRICE_COMPARISON":
    case "COMPETITOR_PRICE":
      return true;
    default:
      return assertUnreachable(type);
  }
}

type MarkerLabelProps = {
  baselineValue?: MaybeNumber;
  isPercentType: boolean;
  lowerBoundValue?: MaybeNumber;
  type: MarkerType;
  upperBoundValue?: MaybeNumber;
  value: number;
};

function MarkerLabel({
  baselineValue,
  isPercentType,
  lowerBoundValue,
  type,
  upperBoundValue,
  value,
}: MarkerLabelProps) {
  let label: MaybeNumber = null;
  switch (type) {
    case "Baseline":
      if (baselineValue != null) {
        label = baselineValue;
      }
      break;
    case "Lower bound":
      if (lowerBoundValue != null) {
        label = lowerBoundValue;
      }
      break;
    case "Upper bound":
      if (upperBoundValue != null) {
        label = upperBoundValue;
      }
      break;
    default:
      assertUnreachable(type);
  }

  if (label != null) {
    return <p>{formatCurrency(label)}</p>;
  }

  const formattedValue = isPercentType
    ? formatPercentage(value / 100)
    : formatCurrency(value);

  return <p>{formattedValue}</p>;
}

type ConstraintBoundsVisualizationProps = {
  baselineLabel?: string;
  baselineValue?: MaybeNumber;
  boundType: MaybeNull<PriceBoundType>;
  className?: ClassNameProp;
  constraintType: CombinedType;
  lowerBound: MaybeNumber | string;
  lowerBoundValue?: MaybeNumber;
  maxLength?: number;
  upperBound: MaybeNumber | string;
  upperBoundValue?: MaybeNumber;
};

type MarkerType = "Baseline" | "Lower bound" | "Upper bound";

type Marker = {
  type: MarkerType;
  value: number;
};

const MARKER_DIAMETER = 16;

export default function ConstraintBoundsVisualization({
  baselineLabel,
  baselineValue,
  boundType,
  className,
  constraintType,
  lowerBound,
  lowerBoundValue,
  maxLength = MAX_LENGTH_DEFAULT,
  upperBound,
  upperBoundValue,
}: ConstraintBoundsVisualizationProps) {
  const reference = 0;
  const enableBaseline =
    shouldEnableBaselineForConstraintBounds(constraintType);
  const markers: Marker[] = filterEmptyValues(
    (
      [
        enableBaseline ? { type: "Baseline", value: reference } : {},
        { type: "Lower bound", value: lowerBound },
        { type: "Upper bound", value: upperBound },
      ] as Marker[]
    )
      .filter((val) => isNumber(val.value))
      .map((val) => ({ ...val, value: Number(val.value) })),
  ).sort((a, b) => a.value - b.value);

  const isPercentType = boundType === "PERCENT";
  const values = markers.map((val) => val.value);
  const min = getFirst(sortNumerically(values))!;
  const max = getLast(sortNumerically(values))!;
  const length = max - min === 0 ? maxLength : max - min;
  const scaleFactor = getWidthScaleFactor(length, maxLength);
  const both_positive = values.every((val) => val >= 0);
  const both_negative = values.every((val) => val <= 0);
  const defaultOffset = reference - min;
  const bounds_equal = lowerBound === upperBound;
  const offset = !enableBaseline
    ? defaultOffset
    : both_positive
      ? min
      : both_negative
        ? length
        : defaultOffset;

  return (
    <Row className={cn("justify-center", className)}>
      <div
        className="relative h-[2px] rounded-full bg-muted px-2"
        style={{ width: Math.min(length, MAX_LENGTH_DEFAULT) * scaleFactor }}
      >
        {markers.map((marker, index) => {
          const { type, value } = marker;
          const left_position =
            value * scaleFactor + offset * scaleFactor - MARKER_DIAMETER / 2;
          const typeLabel =
            type === "Baseline" && baselineLabel != null
              ? baselineLabel
              : type !== "Baseline" && bounds_equal
                ? "Upper & Lower Bound"
                : type;

          return (
            <div
              className="absolute rounded-full border border-primary bg-primary bg-opacity-50"
              key={value + index}
              style={{
                bottom: -7,
                height: MARKER_DIAMETER,
                left: left_position,
                width: MARKER_DIAMETER,
              }}
            >
              <Col
                className="relative bg-background"
                style={{
                  fontSize: 10,
                  top: 18,
                  width: 85,
                  zIndex: index,
                }}
              >
                <MarkerLabel
                  baselineValue={baselineValue}
                  isPercentType={isPercentType}
                  lowerBoundValue={lowerBoundValue}
                  type={type}
                  upperBoundValue={upperBoundValue}
                  value={value}
                />
                <p style={{ fontSize: 10 }}>{typeLabel}</p>
              </Col>
            </div>
          );
        })}
      </div>
    </Row>
  );
}
