import { Navigate, Outlet, useParams } from "react-router";
import logServerError from "src/backend/sentry/logServerError";
import PricePlanName from "src/frontend/pages/pricing/PricePlanName";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

const validTabs = ["preview", "internal", "draft", "current", "past", "new"];

export default function PricePlan() {
  const { pricePlanId, tab } = useParams();

  if (tab == null || !validTabs.includes(tab)) {
    logServerError(
      new Error("Invalid tab provided."),
      SentryErrorEvent.RouteErrorInvalidTab,
    );
    return <Navigate to="/" />;
  }

  if (pricePlanId == null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="px-6 py-6">
        <PricePlanName />
      </div>
      <Outlet />
    </>
  );
}
