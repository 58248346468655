import TrpcClient from "src/frontend/api/TrpcClient";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import useBrandId from "src/frontend/hooks/useBrandId";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";
import invariant from "tiny-invariant";

type GeneratePreviewPricePlanModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function GeneratePreviewPricePlanModal({
  isModalOpen,
  setIsModalOpen,
}: GeneratePreviewPricePlanModalProps) {
  const t = useToast();
  const brandId = useBrandId();
  const pricePlansQuery = usePricePlanQuery();
  const pricingStrategyQuery = useGetPricingStrategiesQuery();
  const { loading, pricingStrategyId } = usePricingStrategyDropdownStateStore();
  const generatePreviewPricePlanMutation =
    TrpcClient.internal.generatePreviewPricePlan.useMutation();

  const onSuccess = async () => {
    await Promise.all([
      pricePlansQuery.refetch(),
      pricingStrategyQuery.refetch(),
    ]);
    usePricingStrategyDropdownStateStore.setState({
      isGenerateModalOpen: false,
      loading: false,
      pricingStrategyId: null,
    });
    setIsModalOpen(false);
    t.successToast("New price plan preview generated successfully.");
    usePricePlanStore.setState({
      pricePlanId: undefined,
      tab: undefined,
    });
  };

  const handleGeneratePricePlan = () => {
    usePricingStrategyDropdownStateStore.setState({ loading: true });
    t.infoToast(
      "Generating a new price plan preview... This will take a moment.",
    );
    invariant(pricingStrategyId != null);
    generatePreviewPricePlanMutation.mutate(
      {
        brandId,
        pricingStrategyId,
      },
      {
        onError: () => {
          t.errorToast("Failed to generate price plan preview.");
          usePricingStrategyDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
  };

  return (
    <Modal
      confirmText="Generate"
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>
            A new price plan preview will be generated using the current pricing
            strategy.
          </p>
        </div>
      }
      dialogTitle="Generate Price Plan Preview"
      loading={loading}
      onConfirm={handleGeneratePricePlan}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
