import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import Spinner from "src/frontend/components/ui/Spinner";
import useLoadingScreenStore from "src/frontend/stores/useLoadingScreenStore";

export default function LoadingScreen() {
  const state = useLoadingScreenStore((state) => state);
  const { isLoading, loadingText } = state;
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className="absolute z-50 flex items-center justify-center bg-slate-500 bg-opacity-5"
      style={{
        height: "100vh",
        left: 0,
        top: 0,
        width: "100vw",
      }}
    >
      <Card className="min-w-[275px] bg-opacity-80 p-10">
        <Col className="items-center justify-center gap-4">
          <Spinner size={60} />
          {loadingText != null && <p className="font-medium">{loadingText}</p>}
        </Col>
      </Card>
    </div>
  );
}
