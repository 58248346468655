import NULL_PLACEHOLDER from "src/shared/constants/NullPlaceholder";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";

export default function formatPricePercentChange(
  new_price: MaybeNumber,
  old_price: MaybeNumber,
  flipSign = false,
): string {
  if (new_price != null && old_price != null) {
    const pct_change = (new_price - old_price) / old_price;
    return formatPercentage(pct_change * (flipSign ? -1 : 1));
  }

  return NULL_PLACEHOLDER;
}
