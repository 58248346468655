import { Trash2Icon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GetBrandProductCategoriesQueryResponseType } from "src/backend/internal-api/queries/getBrandProductCategoriesQuery";
import { GetsTagsQueryResponseType } from "src/backend/internal-api/queries/getTagsQuery";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import Header from "src/frontend/components/Header";
import LightLabel from "src/frontend/components/LightLabel";
import { NavigateInterruptPrompt } from "src/frontend/components/NavigateInterruptPrompt";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import PaginationControls from "src/frontend/components/PaginationControls";
import ProductCategoryFilter from "src/frontend/components/ProductCategoryFilter";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import SelectedProductFiltersList from "src/frontend/components/SelectedProductFiltersList";
import Spacer from "src/frontend/components/Spacer";
import ErrorPage from "src/frontend/components/error/ErrorPage";
import CreateUpdatePriceParityGroupSkeleton from "src/frontend/components/skeletons/CreateUpdatePriceParityGroupSkeleton";
import TableSkeleton from "src/frontend/components/skeletons/TableSkeleton";
import Button from "src/frontend/components/ui/Button";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import { Input } from "src/frontend/components/ui/Input";
import { Prompt } from "src/frontend/components/ui/Prompt";
import { Switch } from "src/frontend/components/ui/Switch";
import useToast from "src/frontend/components/ui/useToast";
import ALL_SKUS_FANCY_BOX_ITEM from "src/frontend/constants/AllSkusFancyBoxItem";
import ALL_SKUS_SELECT_VALUE from "src/frontend/constants/AllSkusSelectValue";
import useGetBrandProductCategoriesQuery from "src/frontend/hooks/queries/useGetBrandProductCategoriesQuery";
import useGetPriceParityGroupById from "src/frontend/hooks/queries/useGetPriceParityGroupById";
import useGetPriceParityGroupsQuery from "src/frontend/hooks/queries/useGetPriceParityGroupsQuery";
import useTagsQuery from "src/frontend/hooks/queries/useTagsQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import SkuSelectionTable from "src/frontend/pages/company/relationships/SkuSelectionTable";
import UploadPriceParityGroupCsvDialog from "src/frontend/pages/company/relationships/UploadPriceParityGroupCsvDialog";
import usePriceParityGroupStore from "src/frontend/stores/usePriceParityGroupStore";
import getCategoryFiltersFromBrandCategoriesAndTags from "src/frontend/utils/getCategoryFiltersFromBrandCategoriesAndTags";
import parseImpactedSkusSelection from "src/frontend/utils/parseImpactedSkusSelection";
import toastTrpcErrorResponse from "src/frontend/utils/toastTrpcErrorResponse";
import PRICE_PARITY_GROUP_MAX_SIZE from "src/shared/constants/PriceParityGroupMaxSize";
import PRODUCT_CATALOG_PAGE_SIZE from "src/shared/constants/ProductCatalogPageSize";
import parseTrpcError from "src/shared/errors/parseTrpcError";
import { PriceParityGroupType } from "src/shared/trpc/common/PriceParityGroup";
import { CreateOrUpdatePriceParityGroupInputType } from "src/shared/trpc/mutations/createOrUpdatePriceParityGroupMutationSchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import len from "src/shared/utils/arrays/len";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import deepEquals from "src/shared/utils/objects/deepEquals";
import pluralize from "src/shared/utils/strings/pluralize";
import isPriceParityGroupNameValid from "src/shared/validation/isPriceParityGroupNameValid";
import { useDebounce } from "usehooks-ts";

type InnerProps = {
  priceParityGroup: MaybeNull<PriceParityGroupType>;
  productCategories: GetBrandProductCategoriesQueryResponseType;
  tags: GetsTagsQueryResponseType;
};

function Inner({ priceParityGroup, productCategories, tags }: InnerProps) {
  const t = useToast();
  const navigate = useNavigate();
  const isLucaAdmin = useIsLucaAdmin();
  const [totalCount, setTotalCount] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { isSmall } = useBreakpoints();
  const width = isSmall ? 225 : 250;
  const state = usePriceParityGroupStore();
  const {
    groupSelectProductIds,
    impactedSkus,
    is_active,
    isInitialized,
    name,
    page,
    selectedProductIds,
    selectedProducts,
    sortBy,
    sortDirection,
  } = state;
  const debouncedSearchTerm = useDebounce<string>(searchInput, 400);
  const impactedSkusOptions = getCategoryFiltersFromBrandCategoriesAndTags(
    tags,
    productCategories,
  );

  const initialState = useMemo(() => {
    return {
      is_active: priceParityGroup?.is_active ?? true,
      name: priceParityGroup?.name ?? "",
      selectedProducts: priceParityGroup?.products ?? [],
    };
  }, [priceParityGroup]);

  useEffect(() => {
    if (!isInitialized) {
      if (priceParityGroup != null) {
        usePriceParityGroupStore.setState({
          ...initialState,
          isInitialized: true,
        });
      }
    }
  }, [isInitialized, initialState, priceParityGroup]);

  useEffect(() => {
    return () => state.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    impactedCategories: categories,
    impactedPriceZones: priceZones,
    impactedProductBrandNames: productBrandNames,
    impactedSubCategories: subCategories,
    impactedSuppliers: suppliers,
    impactedTags: tagIds,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
    showActiveSkus,
    showInactiveSkus,
  } = parseImpactedSkusSelection(impactedSkus);
  const { brandId, enabled } = useIsBrandInitialized();
  const getPriceParityGroupsQuery = useGetPriceParityGroupsQuery();
  const createOrUpdatePriceParityGroupMutation =
    TrpcClient.internal.createOrUpdatePriceParityGroup.useMutation();
  const deletePriceParityGroupMutation =
    TrpcClient.internal.deletePriceParityGroup.useMutation();
  const productCatalogQuery = TrpcClient.internal.getProductCatalog.useQuery(
    {
      applyAndConditionForCategory: state.applyAndConditionForCategory,
      applyAndConditionForFilters: state.applyAndConditionForFilters,
      applyAndConditionForPriceZone: state.applyAndConditionForPriceZone,
      applyAndConditionForProductBrandName:
        state.applyAndConditionForProductBrandName,
      applyAndConditionForSubCategory: state.applyAndConditionForSubCategory,
      applyAndConditionForSupplier: state.applyAndConditionForSupplier,
      applyAndConditionForTags: state.applyAndConditionForTags,
      brandId,
      categories,
      includeEmptyCategory,
      includeEmptyPriceZone,
      includeEmptyProductBrandName,
      includeEmptySubCategory,
      includeEmptySupplier,
      includeUntaggedSkus,
      page,
      priceZones,
      productBrandNames,
      searchQuery: debouncedSearchTerm,
      showActiveSkus,
      showInactiveSkus,
      sortBy: sortBy,
      sortDirection,
      subCategories,
      suppliers,
      tagIds,
    },
    {
      enabled,
    },
  );

  useEffect(() => {
    if (productCatalogQuery.data?.totalCount != null) {
      if (productCatalogQuery.data.totalCount !== totalCount) {
        setTotalCount(productCatalogQuery.data.totalCount);
      }
    }
  }, [totalCount, productCatalogQuery]);

  const setPage = useCallback((page: number) => {
    usePriceParityGroupStore.setState({ page });
  }, []);

  const setSelectedItemsOverrideFn = (item: FancyBoxItem) => {
    if (item.value === ALL_SKUS_SELECT_VALUE) {
      usePriceParityGroupStore.setState({
        impactedSkus: [ALL_SKUS_FANCY_BOX_ITEM],
      });
    } else {
      usePriceParityGroupStore.setState((current) => {
        const currentItems = current.impactedSkus.filter(
          (item) => item.value !== ALL_SKUS_SELECT_VALUE,
        );
        const impactedSkus =
          currentItems.find((current) => current.value === item.value) == null
            ? currentItems.concat(item)
            : currentItems.filter((l) => l.value !== item.value);
        return {
          ...current,
          impactedSkus,
        };
      });
    }
  };

  const handleRemoveItem = (item: FancyBoxItem) => {
    usePriceParityGroupStore.setState((current) => {
      const currentItems = current.impactedSkus;
      const newImpactedSkus = !currentItems.includes(item)
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value);
      return {
        ...current,
        impactedSkus: newImpactedSkus,
      };
    });
  };

  const handleAddSelectedProducts = () => {
    const selected = (productCatalogQuery.data?.products ?? []).filter(
      (product) => selectedProductIds.includes(product.id),
    );
    const currentProductIds = selectedProducts.map((val) => val.id);
    const unique = selected.filter(
      (val) => !currentProductIds.includes(val.id),
    );
    const finalList = selectedProducts.concat(unique);
    if (len(finalList) > PRICE_PARITY_GROUP_MAX_SIZE) {
      t.warningToast(
        `You cannot add more than ${PRICE_PARITY_GROUP_MAX_SIZE} SKUs to a price parity group.`,
      );
      return;
    }
    usePriceParityGroupStore.setState({
      selectedProductIds: [],
      selectedProducts: sortByProperty(finalList, "product_name"),
    });
  };

  const handleRemoveSelectedProducts = () => {
    usePriceParityGroupStore.setState({
      groupSelectProductIds: [],
      selectedProducts: selectedProducts.filter(
        (val) => !groupSelectProductIds.includes(val.id),
      ),
    });
  };

  const totalResultsCount =
    productCatalogQuery.data?.totalCount != null
      ? formatNumberRounded(productCatalogQuery.data.totalCount)
      : null;

  const showPagination =
    !productCatalogQuery.isLoading && !productCatalogQuery.isError;

  const onSuccess = async (verb: string) => {
    await getPriceParityGroupsQuery.refetch();
    setSubmitting(false);
    setIsPromptOpen(false);
    t.successToast(`Pricing strategy ${verb}d successfully.`);
    navigate("/company-settings/relationships");
  };

  const handleSavePriceParityGroup = () => {
    if (name === "") {
      setShowErrors(true);
      return;
    }

    setSubmitting(true);
    const group = {
      is_active,
      name,
      product_ids: selectedProducts.map((val) => val.id),
    };
    const input: CreateOrUpdatePriceParityGroupInputType =
      priceParityGroup == null
        ? {
            create: group,
            update: null,
          }
        : {
            create: null,
            update: { ...group, id: priceParityGroup.id },
          };
    createOrUpdatePriceParityGroupMutation.mutate(input, {
      onError: (err) => {
        const parsedError = parseTrpcError(err);
        if (parsedError?.type === "PriceParityGroupDuplicateProductError") {
          const { data } = parsedError;
          const duplicateProductNames = filterEmptyValues(
            data.duplicate_products.map((val) => val.product_name),
          );
          const duplicateProductIds = data.duplicate_products.map(
            (val) => val.id,
          );
          const count = len(duplicateProductNames);
          t.errorToast({
            description: (
              <Col>
                <p className="mb-1">
                  {count === 1
                    ? `The following ${count} duplicate product exists in other groups:`
                    : `The following ${count} duplicate products exist in other groups:`}
                </p>
                {duplicateProductNames.map((name) => (
                  <p key={name}>&quot;{name}&quot;</p>
                ))}
                <div>
                  <Button
                    className="mt-2"
                    onClick={() => {
                      usePriceParityGroupStore.setState({
                        selectedProducts: selectedProducts.filter(
                          (val) => !duplicateProductIds.includes(val.id),
                        ),
                      });
                      t.successToast(
                        `${count} ${pluralize("product", count)} removed.`,
                      );
                    }}
                    variant="basic"
                  >
                    Remove {count > 1 ? "all" : ""} from current group
                  </Button>
                </div>
              </Col>
            ),
            title: "Failed to save price parity rule.",
          });
        } else {
          t.errorToast("An unknown error occurred.");
        }
        setSubmitting(false);
      },
      onSuccess: () => {
        void onSuccess("save");
      },
    });
  };

  const handleDeletePriceParityGroup = (priceParityGroupId: string) => {
    setSubmitting(true);
    deletePriceParityGroupMutation.mutate(
      { priceParityGroupId },
      {
        onError: (err) => {
          setSubmitting(false);
          toastTrpcErrorResponse(
            t.errorToast,
            "Failed to delete price parity group.",
            err,
          );
        },
        onSuccess: () => {
          void onSuccess("delete");
        },
      },
    );
  };

  const formTouched = !deepEquals(initialState, {
    is_active,
    name,
    selectedProducts,
  });

  return (
    <PageContainer>
      <NavigateInterruptPrompt
        beforeUnload
        message="Are you sure you want to leave? Your changes will be discarded."
        when={formTouched && !submitting}
      />
      <Header
        backTo={
          priceParityGroup == null
            ? "/company-settings/relationships/create"
            : "/company-settings/relationships"
        }
        disabled={submitting}
        leftNode={<PageTitle>Create Price Parity Group</PageTitle>}
        rightNode={
          <Row className="gap-4">
            <Switch
              checked={is_active}
              className="h-9"
              disabled={submitting}
              id="price-parity-group-is-active-switch"
              label={`Rule is ${is_active ? "enabled" : "disabled"}`}
              onCheckedChange={(isActive) =>
                usePriceParityGroupStore.setState({ is_active: isActive })
              }
            />
            <Button
              disabled={!formTouched || submitting}
              onClick={handleSavePriceParityGroup}
            >
              Save
            </Button>
            {priceParityGroup != null && (
              <Prompt
                description="This will delete the current price parity group."
                loading={submitting}
                onConfirm={() =>
                  handleDeletePriceParityGroup(priceParityGroup.id)
                }
                onOpenChange={setIsPromptOpen}
                open={isPromptOpen}
                title="Are you sure?"
                trigger={
                  <Button
                    disabled={submitting}
                    onClick={() => setIsPromptOpen(true)}
                    variant="basic"
                  >
                    <Trash2Icon size={20} />
                  </Button>
                }
              />
            )}
          </Row>
        }
      />
      <div className="flex flex-row gap-12">
        <div className="w-1/2">
          <Row className="justify-between">
            <div>
              <LightLabel>Name</LightLabel>
              <Input
                disabled={submitting}
                errorMessage={
                  showErrors && !isPriceParityGroupNameValid(name)
                    ? "Name is required."
                    : undefined
                }
                onChange={(event) =>
                  usePriceParityGroupStore.setState({
                    name: event.target.value,
                  })
                }
                placeholder="Name"
                style={{ width }}
                value={name}
              />
            </div>
            {isLucaAdmin && (
              <UploadPriceParityGroupCsvDialog disabled={false} />
            )}
          </Row>
          <Spacer className="h-4" />
          <LightLabel>Filter</LightLabel>
          <Row className="gap-3">
            <ProductCategoryFilter
              activeInactiveFiltersEnabled
              height={275}
              items={impactedSkusOptions}
              loading={submitting}
              selectTitle="Select product groups"
              selectedItems={impactedSkus}
              setSelectedItems={state.setImpactedSkus}
              setSelectedItemsOverrideFn={setSelectedItemsOverrideFn}
              width={width}
            />
            <SearchInput
              className="w-full max-w-[300px]"
              disabled={submitting}
              onChange={(val) => {
                setSearchInput(val);
                setPage(1);
              }}
              placeholder="Search by Product"
              value={searchInput}
            />
          </Row>
          <Row className="my-3 w-full items-start">
            <p className="mt-1 whitespace-nowrap text-xs">
              {totalResultsCount != null ? (
                <span>
                  {totalResultsCount} SKUs{" "}
                  {arrayNotEmpty(impactedSkus) ? "for:" : ""}
                </span>
              ) : (
                <span>Loading...</span>
              )}
            </p>
            <SelectedProductFiltersList
              applyAndConditionForCategory={state.applyAndConditionForCategory}
              applyAndConditionForFilters={state.applyAndConditionForFilters}
              applyAndConditionForPriceZone={
                state.applyAndConditionForPriceZone
              }
              applyAndConditionForProductBrandName={
                state.applyAndConditionForProductBrandName
              }
              applyAndConditionForSubCategory={
                state.applyAndConditionForSubCategory
              }
              applyAndConditionForSupplier={state.applyAndConditionForSupplier}
              applyAndConditionForTags={state.applyAndConditionForTags}
              className="ml-1"
              onClickRemove={handleRemoveItem}
              selectedItems={sortByProperty(impactedSkus, "label")}
              showClearFiltersButton
              toggleCondition={(item, state) => {
                usePriceParityGroupStore.setState({ [item]: state });
              }}
            />
          </Row>
          <div className="relative overflow-y-auto">
            <Row className="my-2 justify-between">
              <LightLabel>Select products</LightLabel>
              <Button
                disabled={submitting || arrayEmpty(selectedProductIds)}
                onClick={handleAddSelectedProducts}
                variant="basic"
              >
                Add Selected SKUs
              </Button>
            </Row>
            {productCatalogQuery.isLoading ? (
              <TableSkeleton
                className="gap-3"
                rowClassName="h-5"
                rowNumber={PRODUCT_CATALOG_PAGE_SIZE}
              />
            ) : productCatalogQuery.isError ? (
              <div>Could not load products...</div>
            ) : productCatalogQuery.data.totalCount === 0 ? (
              <div>
                <p>No results.</p>
              </div>
            ) : totalCount === 0 ? null : (
              <SkuSelectionTable
                enableSorting
                loading={submitting}
                onSelectAllSkus={(ids) => {
                  usePriceParityGroupStore.setState({
                    selectedProductIds: ids,
                  });
                }}
                onSelectSku={(productId, checked) => {
                  usePriceParityGroupStore.setState((state) => {
                    if (!checked) {
                      return {
                        selectedProductIds: state.selectedProductIds.filter(
                          (id) => id !== productId,
                        ),
                      };
                    } else {
                      return {
                        selectedProductIds:
                          state.selectedProductIds.concat(productId),
                      };
                    }
                  });
                }}
                products={productCatalogQuery.data.products}
                selectedProductIds={selectedProductIds}
              />
            )}
            {totalCount > 0 && showPagination && (
              <div className="relative my-4 flex w-full items-center justify-center">
                <PaginationControls
                  currentPage={page}
                  disabled={productCatalogQuery.isLoading}
                  pageSize={PRODUCT_CATALOG_PAGE_SIZE}
                  setPage={setPage}
                  totalCount={totalCount}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-[500px] rounded-2xl bg-muted bg-opacity-10 p-6">
          <Row className="h-9 justify-between">
            <LightLabel>Price Parity Group</LightLabel>
            {arrayNotEmpty(selectedProducts) && (
              <Button
                disabled={submitting || arrayEmpty(groupSelectProductIds)}
                onClick={handleRemoveSelectedProducts}
                variant="basic"
              >
                Remove Selected SKUs
              </Button>
            )}
          </Row>
          <p className="mt-2 whitespace-nowrap text-xs">
            {formatNumberRounded(len(selectedProducts))} total SKUs
            {len(selectedProducts) === PRICE_PARITY_GROUP_MAX_SIZE && (
              <span>. Max number of SKUS reached.</span>
            )}
          </p>
          <div className="mt-4">
            {arrayEmpty(selectedProducts) ? (
              <p>Select some products to get started.</p>
            ) : (
              <SkuSelectionTable
                className="max-h-[1470px] overflow-y-scroll"
                enableSorting={false}
                loading={submitting}
                onSelectAllSkus={(ids) => {
                  usePriceParityGroupStore.setState({
                    groupSelectProductIds: ids,
                  });
                }}
                onSelectSku={(productId, checked) => {
                  usePriceParityGroupStore.setState((state) => {
                    if (!checked) {
                      return {
                        groupSelectProductIds:
                          state.groupSelectProductIds.filter(
                            (id) => id !== productId,
                          ),
                      };
                    } else {
                      return {
                        groupSelectProductIds:
                          state.groupSelectProductIds.concat(productId),
                      };
                    }
                  });
                }}
                products={selectedProducts}
                selectedProductIds={groupSelectProductIds}
              />
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default function CreateUpdatePriceParityGroup() {
  const { priceParityGroupId } = useParams();
  const tagsQuery = useTagsQuery();
  const getPriceParityGroupsQuery =
    useGetPriceParityGroupById(priceParityGroupId);
  const getBrandProductCategoriesQuery = useGetBrandProductCategoriesQuery();

  if (
    tagsQuery.isLoading ||
    getPriceParityGroupsQuery.isLoading ||
    getBrandProductCategoriesQuery.isLoading
  ) {
    return <CreateUpdatePriceParityGroupSkeleton />;
  }

  if (
    tagsQuery.isError ||
    getPriceParityGroupsQuery.isError ||
    getBrandProductCategoriesQuery.isError
  ) {
    return <ErrorPage label="Failed to load price parity group." />;
  }

  return (
    <Inner
      priceParityGroup={getPriceParityGroupsQuery.data}
      productCategories={getBrandProductCategoriesQuery.data}
      tags={tagsQuery.data}
    />
  );
}
