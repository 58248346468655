import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  InStockFilterType,
  InStockFilterVariants,
} from "src/shared/trpc/common/InStockFilter";

// eslint-disable-next-line @typescript-eslint/ban-types
type CompetitorIntelligenceInStockFilterProps = {};

export default function CompetitorIntelligenceInStockFilter(
  _: CompetitorIntelligenceInStockFilterProps,
) {
  const inStockFilter = useCompetitorIntelligenceFiltersStore(
    (state) => state.inStockFilter,
  );
  return (
    <div className="w-36">
      <Select
        onValueChange={(value: InStockFilterType) => {
          useCompetitorIntelligenceFiltersStore.setState({
            inStockFilter: value,
          });
        }}
        value={inStockFilter}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select price" />
        </SelectTrigger>
        <SelectContent
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {InStockFilterVariants.map((opt) => {
            return (
              <SelectItem key={opt} value={opt}>
                {formatGenericEnumLabel(opt)}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
}
