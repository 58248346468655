import { z } from "zod";

// From: https://github.com/colinhacks/zod/discussions/2790#discussioncomment-7096060
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function unionOfLiterals<T extends number | string>(
  constants: readonly T[],
) {
  const literals = constants.map((x) => z.literal(x)) as unknown as readonly [
    z.ZodLiteral<T>,
    z.ZodLiteral<T>,
    ...z.ZodLiteral<T>[],
  ];
  return z.union(literals);
}
