import * as Sentry from "@sentry/nextjs";
import { ScopeContext } from "@sentry/types/types/scope";
import { Request } from "express";
import { NextApiRequest } from "next";
import TrpcContext from "src/backend/types/TrpcContext";
import SentryErrorEvent, {
  getErrorFingerprint,
  getErrorSeverity,
} from "src/shared/utils/sentryErrorUtils";

type ServerErrorInfo = {
  ctx?: TrpcContext;
  extra?: ScopeContext["extra"];
  req?: NextApiRequest | Request;
};

export default function logServerError(
  err: Error,
  sentryErrorEvent: SentryErrorEvent,
  info?: ServerErrorInfo,
): void {
  console.error(err);
  console.error(err.cause);
  if (info?.extra != null) {
    console.error(info.extra);
  }
  Sentry.captureException(err, {
    extra: {
      ...info?.extra,
      req: info?.req,
      trpcContext: info?.ctx,
    },
    fingerprint: getErrorFingerprint(err, sentryErrorEvent),
    level: getErrorSeverity(sentryErrorEvent),
    tags: {
      event: sentryErrorEvent,
    },
  });
}
