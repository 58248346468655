import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "src/frontend/components/ui/utils";
import { GenericObject } from "src/shared/types/generic/GenericObject";

const badgeVariants = cva(
  "inline-flex items-center rounded-sm px-2.5 py-0.5 text-center text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    defaultVariants: {
      variant: "default",
    },
    variants: {
      variant: {
        default:
          "border-primary bg-primary bg-opacity-20 text-primary shadow hover:bg-primary/50 hover:text-primary-foreground",
        destructive:
          "border-destructive bg-destructive bg-opacity-20 text-destructive shadow hover:bg-destructive/50 hover:text-primary-foreground",
        info: "border-none bg-info text-black dark:bg-opacity-90",
        muted: "border bg-muted bg-opacity-10 text-foreground",
        outline: "border bg-outline text-foreground",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        success: "border-none bg-success bg-opacity-60 text-black",
        warning: "border-warning bg-warning bg-opacity-20 text-warning shadow",
      },
    },
  },
);

export type BadgeProps = GenericObject &
  React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants>;

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, forwardedRef) => {
    return (
      <div
        className={cn(badgeVariants({ variant }), className)}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

Badge.displayName = "Badge";

export { Badge, badgeVariants };
