import { useState } from "react";
import Code from "src/frontend/components/Code";
import LucaContextMenu from "src/frontend/components/admin/LucaContextMenu";
import PublishInternalDraftPricePlanModal from "src/frontend/components/admin/PublishInternalDraftPricePlanModal";
import ScheduleDraftPricePlanForDeletionModal from "src/frontend/components/admin/ScheduleDraftPricePlanForDeletionModal";
import SummaryMetricsSkeleton, {
  SummaryMetricsErrorComponent,
} from "src/frontend/components/skeletons/SummaryMetricsSkeleton";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import ForecastSummaryMetrics from "src/frontend/pages/pricing/summary/ForecastSummaryMetrics";
import ImpactSummaryMetrics from "src/frontend/pages/pricing/summary/ImpactSummaryMetrics";
import useAdminDeleteGroupStore from "src/frontend/stores/useAdminDeleteGroupStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import getPricePlansByStatus from "src/frontend/utils/getPricePlansByStatus";
import getTabHasPricePlan from "src/frontend/utils/getTabHasPricePlan";
import PricePlanTab from "src/shared/types/PricePlanTab";

type PricePlanSummaryProps = {
  type: "FORECAST" | "IMPACT";
};

export default function PricePlanSummary({ type }: PricePlanSummaryProps) {
  const tab = usePricePlanTab();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const pricePlanMetrics = useGetPricePlanSummaryQuery();
  const pricePlansQuery = usePricePlanQuery();
  const pricePlansByStatus = getPricePlansByStatus(pricePlansQuery.data);
  const hasPricePlan =
    tab != null && getTabHasPricePlan(tab as PricePlanTab, pricePlansByStatus);
  const [
    isPublishPricePlanAdminModelOpen,
    setIsPublishPricePlanAdminModelOpen,
  ] = useState(false);
  const [
    isArchivePricePlanAdminModelOpen,
    setIsArchivePricePlanAdminModelOpen,
  ] = useState(false);

  if (pricePlanId == null || (!hasPricePlan && !pricePlansQuery.isLoading)) {
    return null;
  }

  if (pricePlanMetrics.isLoading) {
    return <SummaryMetricsSkeleton />;
  }

  if (pricePlanMetrics.error) {
    return <SummaryMetricsErrorComponent />;
  }

  const data = pricePlanMetrics.data;
  if (data == null) {
    return (
      <div className="my-4">
        <p>No price plan found.</p>
      </div>
    );
  }

  return (
    <LucaContextMenu
      adminActions={[
        tab === "draft"
          ? {
              fn: () => {
                useAdminDeleteGroupStore.setState({
                  isModalOpen: true,
                  pricePlanIds: [pricePlanId],
                });
              },
              label: <span>Delete Price Plan</span>,
            }
          : null,
        tab === "internal"
          ? {
              fn: () => {
                setIsPublishPricePlanAdminModelOpen(true);
              },
              label: (
                <span>
                  Publish <Code>INTERNAL_DRAFT</Code> price plan
                </span>
              ),
            }
          : null,
        tab === "internal"
          ? {
              fn: () => {
                setIsArchivePricePlanAdminModelOpen(true);
              },
              label: (
                <span>
                  Schedule <Code>INTERNAL_DRAFT</Code> price plan for deletion
                </span>
              ),
            }
          : null,
      ]}
    >
      <>
        {type === "FORECAST" ? (
          <ForecastSummaryMetrics metrics={data} />
        ) : (
          <ImpactSummaryMetrics metrics={data} />
        )}
        <PublishInternalDraftPricePlanModal
          isModalOpen={isPublishPricePlanAdminModelOpen}
          setIsModalOpen={setIsPublishPricePlanAdminModelOpen}
        />
        <ScheduleDraftPricePlanForDeletionModal
          isModalOpen={isArchivePricePlanAdminModelOpen}
          setIsModalOpen={setIsArchivePricePlanAdminModelOpen}
        />
      </>
    </LucaContextMenu>
  );
}
